module Gargantext.Components.GraphQL.Node where

import Gargantext.Prelude
import Gargantext.Types (NodeType)
import Gargantext.Utils.GraphQL as GGQL
import Gargantext.Types (NodeType)
import GraphQL.Client.Args (Args, (=>>))
import GraphQL.Client.Variable (Var(..))
import Type.Proxy (Proxy(..))


type Corpus
  = { id           :: Int
    , name         :: String
    , parent_id    :: Int
    , type_id      :: Int 
    , node_type    :: NodeType }

type Node
  = { id        :: Int
    , name      :: String
    , parent_id :: Int
    , type_id   :: Int 
    , node_type :: NodeType }

type NodesCorpusQuery =
  { nodes_corpus :: Args
             { corpus_id :: Var "id" Int }
             { id :: Unit
             , name :: Unit
             , parent_id :: Unit
             , type_id   :: Unit 
             , node_type :: Unit } }

type NodesQuery =
  { nodes :: Args
       { node_id :: Var "id" Int }
       { id :: Unit
       , name :: Unit
       , parent_id :: Unit
       , type_id :: Unit 
       , node_type :: Unit } }

nodesQuery :: NodesQuery
nodesQuery = { nodes: { node_id: Var :: _ "id" Int } =>>
               GGQL.getFieldsStandard (Proxy :: _ Node)
             }

nodesCorpusQuery :: NodesCorpusQuery
nodesCorpusQuery = { nodes_corpus: { corpus_id: Var :: _ "id" Int } =>>
               GGQL.getFieldsStandard (Proxy :: _ Corpus)
             }

nodeParentQuery = { node_parent: { node_id: Var :: _ "id" Int
                                 , parent_type: Var :: _ "parent_type" NodeType } =>>
                    GGQL.getFieldsStandard (Proxy :: _ Node)
                  }

nodeChildrenQuery = { node_children: { node_id: Var :: _ "id" Int
                                     , child_type: Var :: _ "child_type" NodeType } =>>
                      GGQL.getFieldsStandard (Proxy :: _ Node)
                    }
