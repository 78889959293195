'use strict';

/**
 * @function show
 * @param {Window} window
 * @param {string} querySelector
 * @unpure {Object} window.$
 */
export function _show(window, querySelector, events) {
  let $modal = window.$(querySelector);
  $modal.modal('show');
  console.log('events', events);
  if(events.onHide) {
    $modal.on('hidden.bs.modal', events.onHide());
  }
  if(events.onShow) {
    $modal.on('shown.bs.modal', events.onShow());
  }
}
/**
 * @function hide
 * @param {Window} window
 * @param {string} querySelector
 * @unpure {Object} window.$
 */
export function _hide(window, querySelector) {
  window.$(querySelector).modal('hide');
  // @XXX Bootstrap not removing some modal elements on "hide" method
  // @https://stackoverflow.com/questions/50168312/bootstrap-4-close-modal-backdrop-doesnt-disappear
  window.$('body').removeClass('modal-open');
  window.$('body').css('padding-right', '0');
  window.$('.modal-backdrop').remove();
}
