-----------------------------------------------------------
-- This is an automatically generated file: do not edit"
-- Generated by fullcase.js on Wed Nov 24 2021
-----------------------------------------------------------

module Data.CodePoint.Unicode.Internal.Casing where

import Prelude

import Data.Array as Array
import Data.CodePoint.Unicode.Internal (bsearch, uTowlower, uTowtitle, uTowupper)
import Data.Maybe (Maybe(..))

type CaseRec =
  { code :: Int
  , lower :: Array Int
  , title :: Array Int
  , upper :: Array Int
  , fold :: Int
  , foldFull :: Array Int
  }

rules :: Array CaseRec
rules =
  [ { code: 0x0041, lower: [], title: [], upper: [], fold: 0x0061, foldFull: [ 0x0061 ] }
  , { code: 0x0042, lower: [], title: [], upper: [], fold: 0x0062, foldFull: [ 0x0062 ] }
  , { code: 0x0043, lower: [], title: [], upper: [], fold: 0x0063, foldFull: [ 0x0063 ] }
  , { code: 0x0044, lower: [], title: [], upper: [], fold: 0x0064, foldFull: [ 0x0064 ] }
  , { code: 0x0045, lower: [], title: [], upper: [], fold: 0x0065, foldFull: [ 0x0065 ] }
  , { code: 0x0046, lower: [], title: [], upper: [], fold: 0x0066, foldFull: [ 0x0066 ] }
  , { code: 0x0047, lower: [], title: [], upper: [], fold: 0x0067, foldFull: [ 0x0067 ] }
  , { code: 0x0048, lower: [], title: [], upper: [], fold: 0x0068, foldFull: [ 0x0068 ] }
  , { code: 0x0049, lower: [], title: [], upper: [], fold: 0x0069, foldFull: [ 0x0069 ] }
  , { code: 0x004A, lower: [], title: [], upper: [], fold: 0x006A, foldFull: [ 0x006A ] }
  , { code: 0x004B, lower: [], title: [], upper: [], fold: 0x006B, foldFull: [ 0x006B ] }
  , { code: 0x004C, lower: [], title: [], upper: [], fold: 0x006C, foldFull: [ 0x006C ] }
  , { code: 0x004D, lower: [], title: [], upper: [], fold: 0x006D, foldFull: [ 0x006D ] }
  , { code: 0x004E, lower: [], title: [], upper: [], fold: 0x006E, foldFull: [ 0x006E ] }
  , { code: 0x004F, lower: [], title: [], upper: [], fold: 0x006F, foldFull: [ 0x006F ] }
  , { code: 0x0050, lower: [], title: [], upper: [], fold: 0x0070, foldFull: [ 0x0070 ] }
  , { code: 0x0051, lower: [], title: [], upper: [], fold: 0x0071, foldFull: [ 0x0071 ] }
  , { code: 0x0052, lower: [], title: [], upper: [], fold: 0x0072, foldFull: [ 0x0072 ] }
  , { code: 0x0053, lower: [], title: [], upper: [], fold: 0x0073, foldFull: [ 0x0073 ] }
  , { code: 0x0054, lower: [], title: [], upper: [], fold: 0x0074, foldFull: [ 0x0074 ] }
  , { code: 0x0055, lower: [], title: [], upper: [], fold: 0x0075, foldFull: [ 0x0075 ] }
  , { code: 0x0056, lower: [], title: [], upper: [], fold: 0x0076, foldFull: [ 0x0076 ] }
  , { code: 0x0057, lower: [], title: [], upper: [], fold: 0x0077, foldFull: [ 0x0077 ] }
  , { code: 0x0058, lower: [], title: [], upper: [], fold: 0x0078, foldFull: [ 0x0078 ] }
  , { code: 0x0059, lower: [], title: [], upper: [], fold: 0x0079, foldFull: [ 0x0079 ] }
  , { code: 0x005A, lower: [], title: [], upper: [], fold: 0x007A, foldFull: [ 0x007A ] }
  , { code: 0x00B5, lower: [], title: [], upper: [], fold: 0x03BC, foldFull: [ 0x03BC ] }
  , { code: 0x00C0, lower: [], title: [], upper: [], fold: 0x00E0, foldFull: [ 0x00E0 ] }
  , { code: 0x00C1, lower: [], title: [], upper: [], fold: 0x00E1, foldFull: [ 0x00E1 ] }
  , { code: 0x00C2, lower: [], title: [], upper: [], fold: 0x00E2, foldFull: [ 0x00E2 ] }
  , { code: 0x00C3, lower: [], title: [], upper: [], fold: 0x00E3, foldFull: [ 0x00E3 ] }
  , { code: 0x00C4, lower: [], title: [], upper: [], fold: 0x00E4, foldFull: [ 0x00E4 ] }
  , { code: 0x00C5, lower: [], title: [], upper: [], fold: 0x00E5, foldFull: [ 0x00E5 ] }
  , { code: 0x00C6, lower: [], title: [], upper: [], fold: 0x00E6, foldFull: [ 0x00E6 ] }
  , { code: 0x00C7, lower: [], title: [], upper: [], fold: 0x00E7, foldFull: [ 0x00E7 ] }
  , { code: 0x00C8, lower: [], title: [], upper: [], fold: 0x00E8, foldFull: [ 0x00E8 ] }
  , { code: 0x00C9, lower: [], title: [], upper: [], fold: 0x00E9, foldFull: [ 0x00E9 ] }
  , { code: 0x00CA, lower: [], title: [], upper: [], fold: 0x00EA, foldFull: [ 0x00EA ] }
  , { code: 0x00CB, lower: [], title: [], upper: [], fold: 0x00EB, foldFull: [ 0x00EB ] }
  , { code: 0x00CC, lower: [], title: [], upper: [], fold: 0x00EC, foldFull: [ 0x00EC ] }
  , { code: 0x00CD, lower: [], title: [], upper: [], fold: 0x00ED, foldFull: [ 0x00ED ] }
  , { code: 0x00CE, lower: [], title: [], upper: [], fold: 0x00EE, foldFull: [ 0x00EE ] }
  , { code: 0x00CF, lower: [], title: [], upper: [], fold: 0x00EF, foldFull: [ 0x00EF ] }
  , { code: 0x00D0, lower: [], title: [], upper: [], fold: 0x00F0, foldFull: [ 0x00F0 ] }
  , { code: 0x00D1, lower: [], title: [], upper: [], fold: 0x00F1, foldFull: [ 0x00F1 ] }
  , { code: 0x00D2, lower: [], title: [], upper: [], fold: 0x00F2, foldFull: [ 0x00F2 ] }
  , { code: 0x00D3, lower: [], title: [], upper: [], fold: 0x00F3, foldFull: [ 0x00F3 ] }
  , { code: 0x00D4, lower: [], title: [], upper: [], fold: 0x00F4, foldFull: [ 0x00F4 ] }
  , { code: 0x00D5, lower: [], title: [], upper: [], fold: 0x00F5, foldFull: [ 0x00F5 ] }
  , { code: 0x00D6, lower: [], title: [], upper: [], fold: 0x00F6, foldFull: [ 0x00F6 ] }
  , { code: 0x00D8, lower: [], title: [], upper: [], fold: 0x00F8, foldFull: [ 0x00F8 ] }
  , { code: 0x00D9, lower: [], title: [], upper: [], fold: 0x00F9, foldFull: [ 0x00F9 ] }
  , { code: 0x00DA, lower: [], title: [], upper: [], fold: 0x00FA, foldFull: [ 0x00FA ] }
  , { code: 0x00DB, lower: [], title: [], upper: [], fold: 0x00FB, foldFull: [ 0x00FB ] }
  , { code: 0x00DC, lower: [], title: [], upper: [], fold: 0x00FC, foldFull: [ 0x00FC ] }
  , { code: 0x00DD, lower: [], title: [], upper: [], fold: 0x00FD, foldFull: [ 0x00FD ] }
  , { code: 0x00DE, lower: [], title: [], upper: [], fold: 0x00FE, foldFull: [ 0x00FE ] }
  , { code: 0x00DF, lower: [ 0x00DF ], title: [ 0x0053, 0x0073 ], upper: [ 0x0053, 0x0053 ], fold: 0, foldFull: [ 0x0073, 0x0073 ] }
  , { code: 0x00DF, lower: [ 0x00DF ], title: [ 0x0053, 0x0073 ], upper: [ 0x0053, 0x0053 ], fold: 0, foldFull: [ 0x0073, 0x0073 ] }
  , { code: 0x0100, lower: [], title: [], upper: [], fold: 0x0101, foldFull: [ 0x0101 ] }
  , { code: 0x0102, lower: [], title: [], upper: [], fold: 0x0103, foldFull: [ 0x0103 ] }
  , { code: 0x0104, lower: [], title: [], upper: [], fold: 0x0105, foldFull: [ 0x0105 ] }
  , { code: 0x0106, lower: [], title: [], upper: [], fold: 0x0107, foldFull: [ 0x0107 ] }
  , { code: 0x0108, lower: [], title: [], upper: [], fold: 0x0109, foldFull: [ 0x0109 ] }
  , { code: 0x010A, lower: [], title: [], upper: [], fold: 0x010B, foldFull: [ 0x010B ] }
  , { code: 0x010C, lower: [], title: [], upper: [], fold: 0x010D, foldFull: [ 0x010D ] }
  , { code: 0x010E, lower: [], title: [], upper: [], fold: 0x010F, foldFull: [ 0x010F ] }
  , { code: 0x0110, lower: [], title: [], upper: [], fold: 0x0111, foldFull: [ 0x0111 ] }
  , { code: 0x0112, lower: [], title: [], upper: [], fold: 0x0113, foldFull: [ 0x0113 ] }
  , { code: 0x0114, lower: [], title: [], upper: [], fold: 0x0115, foldFull: [ 0x0115 ] }
  , { code: 0x0116, lower: [], title: [], upper: [], fold: 0x0117, foldFull: [ 0x0117 ] }
  , { code: 0x0118, lower: [], title: [], upper: [], fold: 0x0119, foldFull: [ 0x0119 ] }
  , { code: 0x011A, lower: [], title: [], upper: [], fold: 0x011B, foldFull: [ 0x011B ] }
  , { code: 0x011C, lower: [], title: [], upper: [], fold: 0x011D, foldFull: [ 0x011D ] }
  , { code: 0x011E, lower: [], title: [], upper: [], fold: 0x011F, foldFull: [ 0x011F ] }
  , { code: 0x0120, lower: [], title: [], upper: [], fold: 0x0121, foldFull: [ 0x0121 ] }
  , { code: 0x0122, lower: [], title: [], upper: [], fold: 0x0123, foldFull: [ 0x0123 ] }
  , { code: 0x0124, lower: [], title: [], upper: [], fold: 0x0125, foldFull: [ 0x0125 ] }
  , { code: 0x0126, lower: [], title: [], upper: [], fold: 0x0127, foldFull: [ 0x0127 ] }
  , { code: 0x0128, lower: [], title: [], upper: [], fold: 0x0129, foldFull: [ 0x0129 ] }
  , { code: 0x012A, lower: [], title: [], upper: [], fold: 0x012B, foldFull: [ 0x012B ] }
  , { code: 0x012C, lower: [], title: [], upper: [], fold: 0x012D, foldFull: [ 0x012D ] }
  , { code: 0x012E, lower: [], title: [], upper: [], fold: 0x012F, foldFull: [ 0x012F ] }
  , { code: 0x0130, lower: [ 0x0069, 0x0307 ], title: [ 0x0130 ], upper: [ 0x0130 ], fold: 0, foldFull: [ 0x0069, 0x0307 ] }
  , { code: 0x0130, lower: [ 0x0069, 0x0307 ], title: [ 0x0130 ], upper: [ 0x0130 ], fold: 0, foldFull: [ 0x0069, 0x0307 ] }
  , { code: 0x0132, lower: [], title: [], upper: [], fold: 0x0133, foldFull: [ 0x0133 ] }
  , { code: 0x0134, lower: [], title: [], upper: [], fold: 0x0135, foldFull: [ 0x0135 ] }
  , { code: 0x0136, lower: [], title: [], upper: [], fold: 0x0137, foldFull: [ 0x0137 ] }
  , { code: 0x0139, lower: [], title: [], upper: [], fold: 0x013A, foldFull: [ 0x013A ] }
  , { code: 0x013B, lower: [], title: [], upper: [], fold: 0x013C, foldFull: [ 0x013C ] }
  , { code: 0x013D, lower: [], title: [], upper: [], fold: 0x013E, foldFull: [ 0x013E ] }
  , { code: 0x013F, lower: [], title: [], upper: [], fold: 0x0140, foldFull: [ 0x0140 ] }
  , { code: 0x0141, lower: [], title: [], upper: [], fold: 0x0142, foldFull: [ 0x0142 ] }
  , { code: 0x0143, lower: [], title: [], upper: [], fold: 0x0144, foldFull: [ 0x0144 ] }
  , { code: 0x0145, lower: [], title: [], upper: [], fold: 0x0146, foldFull: [ 0x0146 ] }
  , { code: 0x0147, lower: [], title: [], upper: [], fold: 0x0148, foldFull: [ 0x0148 ] }
  , { code: 0x0149, lower: [ 0x0149 ], title: [ 0x02BC, 0x004E ], upper: [ 0x02BC, 0x004E ], fold: 0, foldFull: [ 0x02BC, 0x006E ] }
  , { code: 0x0149, lower: [ 0x0149 ], title: [ 0x02BC, 0x004E ], upper: [ 0x02BC, 0x004E ], fold: 0, foldFull: [ 0x02BC, 0x006E ] }
  , { code: 0x014A, lower: [], title: [], upper: [], fold: 0x014B, foldFull: [ 0x014B ] }
  , { code: 0x014C, lower: [], title: [], upper: [], fold: 0x014D, foldFull: [ 0x014D ] }
  , { code: 0x014E, lower: [], title: [], upper: [], fold: 0x014F, foldFull: [ 0x014F ] }
  , { code: 0x0150, lower: [], title: [], upper: [], fold: 0x0151, foldFull: [ 0x0151 ] }
  , { code: 0x0152, lower: [], title: [], upper: [], fold: 0x0153, foldFull: [ 0x0153 ] }
  , { code: 0x0154, lower: [], title: [], upper: [], fold: 0x0155, foldFull: [ 0x0155 ] }
  , { code: 0x0156, lower: [], title: [], upper: [], fold: 0x0157, foldFull: [ 0x0157 ] }
  , { code: 0x0158, lower: [], title: [], upper: [], fold: 0x0159, foldFull: [ 0x0159 ] }
  , { code: 0x015A, lower: [], title: [], upper: [], fold: 0x015B, foldFull: [ 0x015B ] }
  , { code: 0x015C, lower: [], title: [], upper: [], fold: 0x015D, foldFull: [ 0x015D ] }
  , { code: 0x015E, lower: [], title: [], upper: [], fold: 0x015F, foldFull: [ 0x015F ] }
  , { code: 0x0160, lower: [], title: [], upper: [], fold: 0x0161, foldFull: [ 0x0161 ] }
  , { code: 0x0162, lower: [], title: [], upper: [], fold: 0x0163, foldFull: [ 0x0163 ] }
  , { code: 0x0164, lower: [], title: [], upper: [], fold: 0x0165, foldFull: [ 0x0165 ] }
  , { code: 0x0166, lower: [], title: [], upper: [], fold: 0x0167, foldFull: [ 0x0167 ] }
  , { code: 0x0168, lower: [], title: [], upper: [], fold: 0x0169, foldFull: [ 0x0169 ] }
  , { code: 0x016A, lower: [], title: [], upper: [], fold: 0x016B, foldFull: [ 0x016B ] }
  , { code: 0x016C, lower: [], title: [], upper: [], fold: 0x016D, foldFull: [ 0x016D ] }
  , { code: 0x016E, lower: [], title: [], upper: [], fold: 0x016F, foldFull: [ 0x016F ] }
  , { code: 0x0170, lower: [], title: [], upper: [], fold: 0x0171, foldFull: [ 0x0171 ] }
  , { code: 0x0172, lower: [], title: [], upper: [], fold: 0x0173, foldFull: [ 0x0173 ] }
  , { code: 0x0174, lower: [], title: [], upper: [], fold: 0x0175, foldFull: [ 0x0175 ] }
  , { code: 0x0176, lower: [], title: [], upper: [], fold: 0x0177, foldFull: [ 0x0177 ] }
  , { code: 0x0178, lower: [], title: [], upper: [], fold: 0x00FF, foldFull: [ 0x00FF ] }
  , { code: 0x0179, lower: [], title: [], upper: [], fold: 0x017A, foldFull: [ 0x017A ] }
  , { code: 0x017B, lower: [], title: [], upper: [], fold: 0x017C, foldFull: [ 0x017C ] }
  , { code: 0x017D, lower: [], title: [], upper: [], fold: 0x017E, foldFull: [ 0x017E ] }
  , { code: 0x017F, lower: [], title: [], upper: [], fold: 0x0073, foldFull: [ 0x0073 ] }
  , { code: 0x0181, lower: [], title: [], upper: [], fold: 0x0253, foldFull: [ 0x0253 ] }
  , { code: 0x0182, lower: [], title: [], upper: [], fold: 0x0183, foldFull: [ 0x0183 ] }
  , { code: 0x0184, lower: [], title: [], upper: [], fold: 0x0185, foldFull: [ 0x0185 ] }
  , { code: 0x0186, lower: [], title: [], upper: [], fold: 0x0254, foldFull: [ 0x0254 ] }
  , { code: 0x0187, lower: [], title: [], upper: [], fold: 0x0188, foldFull: [ 0x0188 ] }
  , { code: 0x0189, lower: [], title: [], upper: [], fold: 0x0256, foldFull: [ 0x0256 ] }
  , { code: 0x018A, lower: [], title: [], upper: [], fold: 0x0257, foldFull: [ 0x0257 ] }
  , { code: 0x018B, lower: [], title: [], upper: [], fold: 0x018C, foldFull: [ 0x018C ] }
  , { code: 0x018E, lower: [], title: [], upper: [], fold: 0x01DD, foldFull: [ 0x01DD ] }
  , { code: 0x018F, lower: [], title: [], upper: [], fold: 0x0259, foldFull: [ 0x0259 ] }
  , { code: 0x0190, lower: [], title: [], upper: [], fold: 0x025B, foldFull: [ 0x025B ] }
  , { code: 0x0191, lower: [], title: [], upper: [], fold: 0x0192, foldFull: [ 0x0192 ] }
  , { code: 0x0193, lower: [], title: [], upper: [], fold: 0x0260, foldFull: [ 0x0260 ] }
  , { code: 0x0194, lower: [], title: [], upper: [], fold: 0x0263, foldFull: [ 0x0263 ] }
  , { code: 0x0196, lower: [], title: [], upper: [], fold: 0x0269, foldFull: [ 0x0269 ] }
  , { code: 0x0197, lower: [], title: [], upper: [], fold: 0x0268, foldFull: [ 0x0268 ] }
  , { code: 0x0198, lower: [], title: [], upper: [], fold: 0x0199, foldFull: [ 0x0199 ] }
  , { code: 0x019C, lower: [], title: [], upper: [], fold: 0x026F, foldFull: [ 0x026F ] }
  , { code: 0x019D, lower: [], title: [], upper: [], fold: 0x0272, foldFull: [ 0x0272 ] }
  , { code: 0x019F, lower: [], title: [], upper: [], fold: 0x0275, foldFull: [ 0x0275 ] }
  , { code: 0x01A0, lower: [], title: [], upper: [], fold: 0x01A1, foldFull: [ 0x01A1 ] }
  , { code: 0x01A2, lower: [], title: [], upper: [], fold: 0x01A3, foldFull: [ 0x01A3 ] }
  , { code: 0x01A4, lower: [], title: [], upper: [], fold: 0x01A5, foldFull: [ 0x01A5 ] }
  , { code: 0x01A6, lower: [], title: [], upper: [], fold: 0x0280, foldFull: [ 0x0280 ] }
  , { code: 0x01A7, lower: [], title: [], upper: [], fold: 0x01A8, foldFull: [ 0x01A8 ] }
  , { code: 0x01A9, lower: [], title: [], upper: [], fold: 0x0283, foldFull: [ 0x0283 ] }
  , { code: 0x01AC, lower: [], title: [], upper: [], fold: 0x01AD, foldFull: [ 0x01AD ] }
  , { code: 0x01AE, lower: [], title: [], upper: [], fold: 0x0288, foldFull: [ 0x0288 ] }
  , { code: 0x01AF, lower: [], title: [], upper: [], fold: 0x01B0, foldFull: [ 0x01B0 ] }
  , { code: 0x01B1, lower: [], title: [], upper: [], fold: 0x028A, foldFull: [ 0x028A ] }
  , { code: 0x01B2, lower: [], title: [], upper: [], fold: 0x028B, foldFull: [ 0x028B ] }
  , { code: 0x01B3, lower: [], title: [], upper: [], fold: 0x01B4, foldFull: [ 0x01B4 ] }
  , { code: 0x01B5, lower: [], title: [], upper: [], fold: 0x01B6, foldFull: [ 0x01B6 ] }
  , { code: 0x01B7, lower: [], title: [], upper: [], fold: 0x0292, foldFull: [ 0x0292 ] }
  , { code: 0x01B8, lower: [], title: [], upper: [], fold: 0x01B9, foldFull: [ 0x01B9 ] }
  , { code: 0x01BC, lower: [], title: [], upper: [], fold: 0x01BD, foldFull: [ 0x01BD ] }
  , { code: 0x01C4, lower: [], title: [], upper: [], fold: 0x01C6, foldFull: [ 0x01C6 ] }
  , { code: 0x01C5, lower: [], title: [], upper: [], fold: 0x01C6, foldFull: [ 0x01C6 ] }
  , { code: 0x01C7, lower: [], title: [], upper: [], fold: 0x01C9, foldFull: [ 0x01C9 ] }
  , { code: 0x01C8, lower: [], title: [], upper: [], fold: 0x01C9, foldFull: [ 0x01C9 ] }
  , { code: 0x01CA, lower: [], title: [], upper: [], fold: 0x01CC, foldFull: [ 0x01CC ] }
  , { code: 0x01CB, lower: [], title: [], upper: [], fold: 0x01CC, foldFull: [ 0x01CC ] }
  , { code: 0x01CD, lower: [], title: [], upper: [], fold: 0x01CE, foldFull: [ 0x01CE ] }
  , { code: 0x01CF, lower: [], title: [], upper: [], fold: 0x01D0, foldFull: [ 0x01D0 ] }
  , { code: 0x01D1, lower: [], title: [], upper: [], fold: 0x01D2, foldFull: [ 0x01D2 ] }
  , { code: 0x01D3, lower: [], title: [], upper: [], fold: 0x01D4, foldFull: [ 0x01D4 ] }
  , { code: 0x01D5, lower: [], title: [], upper: [], fold: 0x01D6, foldFull: [ 0x01D6 ] }
  , { code: 0x01D7, lower: [], title: [], upper: [], fold: 0x01D8, foldFull: [ 0x01D8 ] }
  , { code: 0x01D9, lower: [], title: [], upper: [], fold: 0x01DA, foldFull: [ 0x01DA ] }
  , { code: 0x01DB, lower: [], title: [], upper: [], fold: 0x01DC, foldFull: [ 0x01DC ] }
  , { code: 0x01DE, lower: [], title: [], upper: [], fold: 0x01DF, foldFull: [ 0x01DF ] }
  , { code: 0x01E0, lower: [], title: [], upper: [], fold: 0x01E1, foldFull: [ 0x01E1 ] }
  , { code: 0x01E2, lower: [], title: [], upper: [], fold: 0x01E3, foldFull: [ 0x01E3 ] }
  , { code: 0x01E4, lower: [], title: [], upper: [], fold: 0x01E5, foldFull: [ 0x01E5 ] }
  , { code: 0x01E6, lower: [], title: [], upper: [], fold: 0x01E7, foldFull: [ 0x01E7 ] }
  , { code: 0x01E8, lower: [], title: [], upper: [], fold: 0x01E9, foldFull: [ 0x01E9 ] }
  , { code: 0x01EA, lower: [], title: [], upper: [], fold: 0x01EB, foldFull: [ 0x01EB ] }
  , { code: 0x01EC, lower: [], title: [], upper: [], fold: 0x01ED, foldFull: [ 0x01ED ] }
  , { code: 0x01EE, lower: [], title: [], upper: [], fold: 0x01EF, foldFull: [ 0x01EF ] }
  , { code: 0x01F0, lower: [ 0x01F0 ], title: [ 0x004A, 0x030C ], upper: [ 0x004A, 0x030C ], fold: 0, foldFull: [ 0x006A, 0x030C ] }
  , { code: 0x01F0, lower: [ 0x01F0 ], title: [ 0x004A, 0x030C ], upper: [ 0x004A, 0x030C ], fold: 0, foldFull: [ 0x006A, 0x030C ] }
  , { code: 0x01F1, lower: [], title: [], upper: [], fold: 0x01F3, foldFull: [ 0x01F3 ] }
  , { code: 0x01F2, lower: [], title: [], upper: [], fold: 0x01F3, foldFull: [ 0x01F3 ] }
  , { code: 0x01F4, lower: [], title: [], upper: [], fold: 0x01F5, foldFull: [ 0x01F5 ] }
  , { code: 0x01F6, lower: [], title: [], upper: [], fold: 0x0195, foldFull: [ 0x0195 ] }
  , { code: 0x01F7, lower: [], title: [], upper: [], fold: 0x01BF, foldFull: [ 0x01BF ] }
  , { code: 0x01F8, lower: [], title: [], upper: [], fold: 0x01F9, foldFull: [ 0x01F9 ] }
  , { code: 0x01FA, lower: [], title: [], upper: [], fold: 0x01FB, foldFull: [ 0x01FB ] }
  , { code: 0x01FC, lower: [], title: [], upper: [], fold: 0x01FD, foldFull: [ 0x01FD ] }
  , { code: 0x01FE, lower: [], title: [], upper: [], fold: 0x01FF, foldFull: [ 0x01FF ] }
  , { code: 0x0200, lower: [], title: [], upper: [], fold: 0x0201, foldFull: [ 0x0201 ] }
  , { code: 0x0202, lower: [], title: [], upper: [], fold: 0x0203, foldFull: [ 0x0203 ] }
  , { code: 0x0204, lower: [], title: [], upper: [], fold: 0x0205, foldFull: [ 0x0205 ] }
  , { code: 0x0206, lower: [], title: [], upper: [], fold: 0x0207, foldFull: [ 0x0207 ] }
  , { code: 0x0208, lower: [], title: [], upper: [], fold: 0x0209, foldFull: [ 0x0209 ] }
  , { code: 0x020A, lower: [], title: [], upper: [], fold: 0x020B, foldFull: [ 0x020B ] }
  , { code: 0x020C, lower: [], title: [], upper: [], fold: 0x020D, foldFull: [ 0x020D ] }
  , { code: 0x020E, lower: [], title: [], upper: [], fold: 0x020F, foldFull: [ 0x020F ] }
  , { code: 0x0210, lower: [], title: [], upper: [], fold: 0x0211, foldFull: [ 0x0211 ] }
  , { code: 0x0212, lower: [], title: [], upper: [], fold: 0x0213, foldFull: [ 0x0213 ] }
  , { code: 0x0214, lower: [], title: [], upper: [], fold: 0x0215, foldFull: [ 0x0215 ] }
  , { code: 0x0216, lower: [], title: [], upper: [], fold: 0x0217, foldFull: [ 0x0217 ] }
  , { code: 0x0218, lower: [], title: [], upper: [], fold: 0x0219, foldFull: [ 0x0219 ] }
  , { code: 0x021A, lower: [], title: [], upper: [], fold: 0x021B, foldFull: [ 0x021B ] }
  , { code: 0x021C, lower: [], title: [], upper: [], fold: 0x021D, foldFull: [ 0x021D ] }
  , { code: 0x021E, lower: [], title: [], upper: [], fold: 0x021F, foldFull: [ 0x021F ] }
  , { code: 0x0220, lower: [], title: [], upper: [], fold: 0x019E, foldFull: [ 0x019E ] }
  , { code: 0x0222, lower: [], title: [], upper: [], fold: 0x0223, foldFull: [ 0x0223 ] }
  , { code: 0x0224, lower: [], title: [], upper: [], fold: 0x0225, foldFull: [ 0x0225 ] }
  , { code: 0x0226, lower: [], title: [], upper: [], fold: 0x0227, foldFull: [ 0x0227 ] }
  , { code: 0x0228, lower: [], title: [], upper: [], fold: 0x0229, foldFull: [ 0x0229 ] }
  , { code: 0x022A, lower: [], title: [], upper: [], fold: 0x022B, foldFull: [ 0x022B ] }
  , { code: 0x022C, lower: [], title: [], upper: [], fold: 0x022D, foldFull: [ 0x022D ] }
  , { code: 0x022E, lower: [], title: [], upper: [], fold: 0x022F, foldFull: [ 0x022F ] }
  , { code: 0x0230, lower: [], title: [], upper: [], fold: 0x0231, foldFull: [ 0x0231 ] }
  , { code: 0x0232, lower: [], title: [], upper: [], fold: 0x0233, foldFull: [ 0x0233 ] }
  , { code: 0x023A, lower: [], title: [], upper: [], fold: 0x2C65, foldFull: [ 0x2C65 ] }
  , { code: 0x023B, lower: [], title: [], upper: [], fold: 0x023C, foldFull: [ 0x023C ] }
  , { code: 0x023D, lower: [], title: [], upper: [], fold: 0x019A, foldFull: [ 0x019A ] }
  , { code: 0x023E, lower: [], title: [], upper: [], fold: 0x2C66, foldFull: [ 0x2C66 ] }
  , { code: 0x0241, lower: [], title: [], upper: [], fold: 0x0242, foldFull: [ 0x0242 ] }
  , { code: 0x0243, lower: [], title: [], upper: [], fold: 0x0180, foldFull: [ 0x0180 ] }
  , { code: 0x0244, lower: [], title: [], upper: [], fold: 0x0289, foldFull: [ 0x0289 ] }
  , { code: 0x0245, lower: [], title: [], upper: [], fold: 0x028C, foldFull: [ 0x028C ] }
  , { code: 0x0246, lower: [], title: [], upper: [], fold: 0x0247, foldFull: [ 0x0247 ] }
  , { code: 0x0248, lower: [], title: [], upper: [], fold: 0x0249, foldFull: [ 0x0249 ] }
  , { code: 0x024A, lower: [], title: [], upper: [], fold: 0x024B, foldFull: [ 0x024B ] }
  , { code: 0x024C, lower: [], title: [], upper: [], fold: 0x024D, foldFull: [ 0x024D ] }
  , { code: 0x024E, lower: [], title: [], upper: [], fold: 0x024F, foldFull: [ 0x024F ] }
  , { code: 0x0345, lower: [], title: [], upper: [], fold: 0x03B9, foldFull: [ 0x03B9 ] }
  , { code: 0x0370, lower: [], title: [], upper: [], fold: 0x0371, foldFull: [ 0x0371 ] }
  , { code: 0x0372, lower: [], title: [], upper: [], fold: 0x0373, foldFull: [ 0x0373 ] }
  , { code: 0x0376, lower: [], title: [], upper: [], fold: 0x0377, foldFull: [ 0x0377 ] }
  , { code: 0x037F, lower: [], title: [], upper: [], fold: 0x03F3, foldFull: [ 0x03F3 ] }
  , { code: 0x0386, lower: [], title: [], upper: [], fold: 0x03AC, foldFull: [ 0x03AC ] }
  , { code: 0x0388, lower: [], title: [], upper: [], fold: 0x03AD, foldFull: [ 0x03AD ] }
  , { code: 0x0389, lower: [], title: [], upper: [], fold: 0x03AE, foldFull: [ 0x03AE ] }
  , { code: 0x038A, lower: [], title: [], upper: [], fold: 0x03AF, foldFull: [ 0x03AF ] }
  , { code: 0x038C, lower: [], title: [], upper: [], fold: 0x03CC, foldFull: [ 0x03CC ] }
  , { code: 0x038E, lower: [], title: [], upper: [], fold: 0x03CD, foldFull: [ 0x03CD ] }
  , { code: 0x038F, lower: [], title: [], upper: [], fold: 0x03CE, foldFull: [ 0x03CE ] }
  , { code: 0x0390, lower: [ 0x0390 ], title: [ 0x0399, 0x0308, 0x0301 ], upper: [ 0x0399, 0x0308, 0x0301 ], fold: 0, foldFull: [ 0x03B9, 0x0308, 0x0301 ] }
  , { code: 0x0390, lower: [ 0x0390 ], title: [ 0x0399, 0x0308, 0x0301 ], upper: [ 0x0399, 0x0308, 0x0301 ], fold: 0, foldFull: [ 0x03B9, 0x0308, 0x0301 ] }
  , { code: 0x0391, lower: [], title: [], upper: [], fold: 0x03B1, foldFull: [ 0x03B1 ] }
  , { code: 0x0392, lower: [], title: [], upper: [], fold: 0x03B2, foldFull: [ 0x03B2 ] }
  , { code: 0x0393, lower: [], title: [], upper: [], fold: 0x03B3, foldFull: [ 0x03B3 ] }
  , { code: 0x0394, lower: [], title: [], upper: [], fold: 0x03B4, foldFull: [ 0x03B4 ] }
  , { code: 0x0395, lower: [], title: [], upper: [], fold: 0x03B5, foldFull: [ 0x03B5 ] }
  , { code: 0x0396, lower: [], title: [], upper: [], fold: 0x03B6, foldFull: [ 0x03B6 ] }
  , { code: 0x0397, lower: [], title: [], upper: [], fold: 0x03B7, foldFull: [ 0x03B7 ] }
  , { code: 0x0398, lower: [], title: [], upper: [], fold: 0x03B8, foldFull: [ 0x03B8 ] }
  , { code: 0x0399, lower: [], title: [], upper: [], fold: 0x03B9, foldFull: [ 0x03B9 ] }
  , { code: 0x039A, lower: [], title: [], upper: [], fold: 0x03BA, foldFull: [ 0x03BA ] }
  , { code: 0x039B, lower: [], title: [], upper: [], fold: 0x03BB, foldFull: [ 0x03BB ] }
  , { code: 0x039C, lower: [], title: [], upper: [], fold: 0x03BC, foldFull: [ 0x03BC ] }
  , { code: 0x039D, lower: [], title: [], upper: [], fold: 0x03BD, foldFull: [ 0x03BD ] }
  , { code: 0x039E, lower: [], title: [], upper: [], fold: 0x03BE, foldFull: [ 0x03BE ] }
  , { code: 0x039F, lower: [], title: [], upper: [], fold: 0x03BF, foldFull: [ 0x03BF ] }
  , { code: 0x03A0, lower: [], title: [], upper: [], fold: 0x03C0, foldFull: [ 0x03C0 ] }
  , { code: 0x03A1, lower: [], title: [], upper: [], fold: 0x03C1, foldFull: [ 0x03C1 ] }
  , { code: 0x03A3, lower: [], title: [], upper: [], fold: 0x03C3, foldFull: [ 0x03C3 ] }
  , { code: 0x03A4, lower: [], title: [], upper: [], fold: 0x03C4, foldFull: [ 0x03C4 ] }
  , { code: 0x03A5, lower: [], title: [], upper: [], fold: 0x03C5, foldFull: [ 0x03C5 ] }
  , { code: 0x03A6, lower: [], title: [], upper: [], fold: 0x03C6, foldFull: [ 0x03C6 ] }
  , { code: 0x03A7, lower: [], title: [], upper: [], fold: 0x03C7, foldFull: [ 0x03C7 ] }
  , { code: 0x03A8, lower: [], title: [], upper: [], fold: 0x03C8, foldFull: [ 0x03C8 ] }
  , { code: 0x03A9, lower: [], title: [], upper: [], fold: 0x03C9, foldFull: [ 0x03C9 ] }
  , { code: 0x03AA, lower: [], title: [], upper: [], fold: 0x03CA, foldFull: [ 0x03CA ] }
  , { code: 0x03AB, lower: [], title: [], upper: [], fold: 0x03CB, foldFull: [ 0x03CB ] }
  , { code: 0x03B0, lower: [ 0x03B0 ], title: [ 0x03A5, 0x0308, 0x0301 ], upper: [ 0x03A5, 0x0308, 0x0301 ], fold: 0, foldFull: [ 0x03C5, 0x0308, 0x0301 ] }
  , { code: 0x03B0, lower: [ 0x03B0 ], title: [ 0x03A5, 0x0308, 0x0301 ], upper: [ 0x03A5, 0x0308, 0x0301 ], fold: 0, foldFull: [ 0x03C5, 0x0308, 0x0301 ] }
  , { code: 0x03C2, lower: [], title: [], upper: [], fold: 0x03C3, foldFull: [ 0x03C3 ] }
  , { code: 0x03CF, lower: [], title: [], upper: [], fold: 0x03D7, foldFull: [ 0x03D7 ] }
  , { code: 0x03D0, lower: [], title: [], upper: [], fold: 0x03B2, foldFull: [ 0x03B2 ] }
  , { code: 0x03D1, lower: [], title: [], upper: [], fold: 0x03B8, foldFull: [ 0x03B8 ] }
  , { code: 0x03D5, lower: [], title: [], upper: [], fold: 0x03C6, foldFull: [ 0x03C6 ] }
  , { code: 0x03D6, lower: [], title: [], upper: [], fold: 0x03C0, foldFull: [ 0x03C0 ] }
  , { code: 0x03D8, lower: [], title: [], upper: [], fold: 0x03D9, foldFull: [ 0x03D9 ] }
  , { code: 0x03DA, lower: [], title: [], upper: [], fold: 0x03DB, foldFull: [ 0x03DB ] }
  , { code: 0x03DC, lower: [], title: [], upper: [], fold: 0x03DD, foldFull: [ 0x03DD ] }
  , { code: 0x03DE, lower: [], title: [], upper: [], fold: 0x03DF, foldFull: [ 0x03DF ] }
  , { code: 0x03E0, lower: [], title: [], upper: [], fold: 0x03E1, foldFull: [ 0x03E1 ] }
  , { code: 0x03E2, lower: [], title: [], upper: [], fold: 0x03E3, foldFull: [ 0x03E3 ] }
  , { code: 0x03E4, lower: [], title: [], upper: [], fold: 0x03E5, foldFull: [ 0x03E5 ] }
  , { code: 0x03E6, lower: [], title: [], upper: [], fold: 0x03E7, foldFull: [ 0x03E7 ] }
  , { code: 0x03E8, lower: [], title: [], upper: [], fold: 0x03E9, foldFull: [ 0x03E9 ] }
  , { code: 0x03EA, lower: [], title: [], upper: [], fold: 0x03EB, foldFull: [ 0x03EB ] }
  , { code: 0x03EC, lower: [], title: [], upper: [], fold: 0x03ED, foldFull: [ 0x03ED ] }
  , { code: 0x03EE, lower: [], title: [], upper: [], fold: 0x03EF, foldFull: [ 0x03EF ] }
  , { code: 0x03F0, lower: [], title: [], upper: [], fold: 0x03BA, foldFull: [ 0x03BA ] }
  , { code: 0x03F1, lower: [], title: [], upper: [], fold: 0x03C1, foldFull: [ 0x03C1 ] }
  , { code: 0x03F4, lower: [], title: [], upper: [], fold: 0x03B8, foldFull: [ 0x03B8 ] }
  , { code: 0x03F5, lower: [], title: [], upper: [], fold: 0x03B5, foldFull: [ 0x03B5 ] }
  , { code: 0x03F7, lower: [], title: [], upper: [], fold: 0x03F8, foldFull: [ 0x03F8 ] }
  , { code: 0x03F9, lower: [], title: [], upper: [], fold: 0x03F2, foldFull: [ 0x03F2 ] }
  , { code: 0x03FA, lower: [], title: [], upper: [], fold: 0x03FB, foldFull: [ 0x03FB ] }
  , { code: 0x03FD, lower: [], title: [], upper: [], fold: 0x037B, foldFull: [ 0x037B ] }
  , { code: 0x03FE, lower: [], title: [], upper: [], fold: 0x037C, foldFull: [ 0x037C ] }
  , { code: 0x03FF, lower: [], title: [], upper: [], fold: 0x037D, foldFull: [ 0x037D ] }
  , { code: 0x0400, lower: [], title: [], upper: [], fold: 0x0450, foldFull: [ 0x0450 ] }
  , { code: 0x0401, lower: [], title: [], upper: [], fold: 0x0451, foldFull: [ 0x0451 ] }
  , { code: 0x0402, lower: [], title: [], upper: [], fold: 0x0452, foldFull: [ 0x0452 ] }
  , { code: 0x0403, lower: [], title: [], upper: [], fold: 0x0453, foldFull: [ 0x0453 ] }
  , { code: 0x0404, lower: [], title: [], upper: [], fold: 0x0454, foldFull: [ 0x0454 ] }
  , { code: 0x0405, lower: [], title: [], upper: [], fold: 0x0455, foldFull: [ 0x0455 ] }
  , { code: 0x0406, lower: [], title: [], upper: [], fold: 0x0456, foldFull: [ 0x0456 ] }
  , { code: 0x0407, lower: [], title: [], upper: [], fold: 0x0457, foldFull: [ 0x0457 ] }
  , { code: 0x0408, lower: [], title: [], upper: [], fold: 0x0458, foldFull: [ 0x0458 ] }
  , { code: 0x0409, lower: [], title: [], upper: [], fold: 0x0459, foldFull: [ 0x0459 ] }
  , { code: 0x040A, lower: [], title: [], upper: [], fold: 0x045A, foldFull: [ 0x045A ] }
  , { code: 0x040B, lower: [], title: [], upper: [], fold: 0x045B, foldFull: [ 0x045B ] }
  , { code: 0x040C, lower: [], title: [], upper: [], fold: 0x045C, foldFull: [ 0x045C ] }
  , { code: 0x040D, lower: [], title: [], upper: [], fold: 0x045D, foldFull: [ 0x045D ] }
  , { code: 0x040E, lower: [], title: [], upper: [], fold: 0x045E, foldFull: [ 0x045E ] }
  , { code: 0x040F, lower: [], title: [], upper: [], fold: 0x045F, foldFull: [ 0x045F ] }
  , { code: 0x0410, lower: [], title: [], upper: [], fold: 0x0430, foldFull: [ 0x0430 ] }
  , { code: 0x0411, lower: [], title: [], upper: [], fold: 0x0431, foldFull: [ 0x0431 ] }
  , { code: 0x0412, lower: [], title: [], upper: [], fold: 0x0432, foldFull: [ 0x0432 ] }
  , { code: 0x0413, lower: [], title: [], upper: [], fold: 0x0433, foldFull: [ 0x0433 ] }
  , { code: 0x0414, lower: [], title: [], upper: [], fold: 0x0434, foldFull: [ 0x0434 ] }
  , { code: 0x0415, lower: [], title: [], upper: [], fold: 0x0435, foldFull: [ 0x0435 ] }
  , { code: 0x0416, lower: [], title: [], upper: [], fold: 0x0436, foldFull: [ 0x0436 ] }
  , { code: 0x0417, lower: [], title: [], upper: [], fold: 0x0437, foldFull: [ 0x0437 ] }
  , { code: 0x0418, lower: [], title: [], upper: [], fold: 0x0438, foldFull: [ 0x0438 ] }
  , { code: 0x0419, lower: [], title: [], upper: [], fold: 0x0439, foldFull: [ 0x0439 ] }
  , { code: 0x041A, lower: [], title: [], upper: [], fold: 0x043A, foldFull: [ 0x043A ] }
  , { code: 0x041B, lower: [], title: [], upper: [], fold: 0x043B, foldFull: [ 0x043B ] }
  , { code: 0x041C, lower: [], title: [], upper: [], fold: 0x043C, foldFull: [ 0x043C ] }
  , { code: 0x041D, lower: [], title: [], upper: [], fold: 0x043D, foldFull: [ 0x043D ] }
  , { code: 0x041E, lower: [], title: [], upper: [], fold: 0x043E, foldFull: [ 0x043E ] }
  , { code: 0x041F, lower: [], title: [], upper: [], fold: 0x043F, foldFull: [ 0x043F ] }
  , { code: 0x0420, lower: [], title: [], upper: [], fold: 0x0440, foldFull: [ 0x0440 ] }
  , { code: 0x0421, lower: [], title: [], upper: [], fold: 0x0441, foldFull: [ 0x0441 ] }
  , { code: 0x0422, lower: [], title: [], upper: [], fold: 0x0442, foldFull: [ 0x0442 ] }
  , { code: 0x0423, lower: [], title: [], upper: [], fold: 0x0443, foldFull: [ 0x0443 ] }
  , { code: 0x0424, lower: [], title: [], upper: [], fold: 0x0444, foldFull: [ 0x0444 ] }
  , { code: 0x0425, lower: [], title: [], upper: [], fold: 0x0445, foldFull: [ 0x0445 ] }
  , { code: 0x0426, lower: [], title: [], upper: [], fold: 0x0446, foldFull: [ 0x0446 ] }
  , { code: 0x0427, lower: [], title: [], upper: [], fold: 0x0447, foldFull: [ 0x0447 ] }
  , { code: 0x0428, lower: [], title: [], upper: [], fold: 0x0448, foldFull: [ 0x0448 ] }
  , { code: 0x0429, lower: [], title: [], upper: [], fold: 0x0449, foldFull: [ 0x0449 ] }
  , { code: 0x042A, lower: [], title: [], upper: [], fold: 0x044A, foldFull: [ 0x044A ] }
  , { code: 0x042B, lower: [], title: [], upper: [], fold: 0x044B, foldFull: [ 0x044B ] }
  , { code: 0x042C, lower: [], title: [], upper: [], fold: 0x044C, foldFull: [ 0x044C ] }
  , { code: 0x042D, lower: [], title: [], upper: [], fold: 0x044D, foldFull: [ 0x044D ] }
  , { code: 0x042E, lower: [], title: [], upper: [], fold: 0x044E, foldFull: [ 0x044E ] }
  , { code: 0x042F, lower: [], title: [], upper: [], fold: 0x044F, foldFull: [ 0x044F ] }
  , { code: 0x0460, lower: [], title: [], upper: [], fold: 0x0461, foldFull: [ 0x0461 ] }
  , { code: 0x0462, lower: [], title: [], upper: [], fold: 0x0463, foldFull: [ 0x0463 ] }
  , { code: 0x0464, lower: [], title: [], upper: [], fold: 0x0465, foldFull: [ 0x0465 ] }
  , { code: 0x0466, lower: [], title: [], upper: [], fold: 0x0467, foldFull: [ 0x0467 ] }
  , { code: 0x0468, lower: [], title: [], upper: [], fold: 0x0469, foldFull: [ 0x0469 ] }
  , { code: 0x046A, lower: [], title: [], upper: [], fold: 0x046B, foldFull: [ 0x046B ] }
  , { code: 0x046C, lower: [], title: [], upper: [], fold: 0x046D, foldFull: [ 0x046D ] }
  , { code: 0x046E, lower: [], title: [], upper: [], fold: 0x046F, foldFull: [ 0x046F ] }
  , { code: 0x0470, lower: [], title: [], upper: [], fold: 0x0471, foldFull: [ 0x0471 ] }
  , { code: 0x0472, lower: [], title: [], upper: [], fold: 0x0473, foldFull: [ 0x0473 ] }
  , { code: 0x0474, lower: [], title: [], upper: [], fold: 0x0475, foldFull: [ 0x0475 ] }
  , { code: 0x0476, lower: [], title: [], upper: [], fold: 0x0477, foldFull: [ 0x0477 ] }
  , { code: 0x0478, lower: [], title: [], upper: [], fold: 0x0479, foldFull: [ 0x0479 ] }
  , { code: 0x047A, lower: [], title: [], upper: [], fold: 0x047B, foldFull: [ 0x047B ] }
  , { code: 0x047C, lower: [], title: [], upper: [], fold: 0x047D, foldFull: [ 0x047D ] }
  , { code: 0x047E, lower: [], title: [], upper: [], fold: 0x047F, foldFull: [ 0x047F ] }
  , { code: 0x0480, lower: [], title: [], upper: [], fold: 0x0481, foldFull: [ 0x0481 ] }
  , { code: 0x048A, lower: [], title: [], upper: [], fold: 0x048B, foldFull: [ 0x048B ] }
  , { code: 0x048C, lower: [], title: [], upper: [], fold: 0x048D, foldFull: [ 0x048D ] }
  , { code: 0x048E, lower: [], title: [], upper: [], fold: 0x048F, foldFull: [ 0x048F ] }
  , { code: 0x0490, lower: [], title: [], upper: [], fold: 0x0491, foldFull: [ 0x0491 ] }
  , { code: 0x0492, lower: [], title: [], upper: [], fold: 0x0493, foldFull: [ 0x0493 ] }
  , { code: 0x0494, lower: [], title: [], upper: [], fold: 0x0495, foldFull: [ 0x0495 ] }
  , { code: 0x0496, lower: [], title: [], upper: [], fold: 0x0497, foldFull: [ 0x0497 ] }
  , { code: 0x0498, lower: [], title: [], upper: [], fold: 0x0499, foldFull: [ 0x0499 ] }
  , { code: 0x049A, lower: [], title: [], upper: [], fold: 0x049B, foldFull: [ 0x049B ] }
  , { code: 0x049C, lower: [], title: [], upper: [], fold: 0x049D, foldFull: [ 0x049D ] }
  , { code: 0x049E, lower: [], title: [], upper: [], fold: 0x049F, foldFull: [ 0x049F ] }
  , { code: 0x04A0, lower: [], title: [], upper: [], fold: 0x04A1, foldFull: [ 0x04A1 ] }
  , { code: 0x04A2, lower: [], title: [], upper: [], fold: 0x04A3, foldFull: [ 0x04A3 ] }
  , { code: 0x04A4, lower: [], title: [], upper: [], fold: 0x04A5, foldFull: [ 0x04A5 ] }
  , { code: 0x04A6, lower: [], title: [], upper: [], fold: 0x04A7, foldFull: [ 0x04A7 ] }
  , { code: 0x04A8, lower: [], title: [], upper: [], fold: 0x04A9, foldFull: [ 0x04A9 ] }
  , { code: 0x04AA, lower: [], title: [], upper: [], fold: 0x04AB, foldFull: [ 0x04AB ] }
  , { code: 0x04AC, lower: [], title: [], upper: [], fold: 0x04AD, foldFull: [ 0x04AD ] }
  , { code: 0x04AE, lower: [], title: [], upper: [], fold: 0x04AF, foldFull: [ 0x04AF ] }
  , { code: 0x04B0, lower: [], title: [], upper: [], fold: 0x04B1, foldFull: [ 0x04B1 ] }
  , { code: 0x04B2, lower: [], title: [], upper: [], fold: 0x04B3, foldFull: [ 0x04B3 ] }
  , { code: 0x04B4, lower: [], title: [], upper: [], fold: 0x04B5, foldFull: [ 0x04B5 ] }
  , { code: 0x04B6, lower: [], title: [], upper: [], fold: 0x04B7, foldFull: [ 0x04B7 ] }
  , { code: 0x04B8, lower: [], title: [], upper: [], fold: 0x04B9, foldFull: [ 0x04B9 ] }
  , { code: 0x04BA, lower: [], title: [], upper: [], fold: 0x04BB, foldFull: [ 0x04BB ] }
  , { code: 0x04BC, lower: [], title: [], upper: [], fold: 0x04BD, foldFull: [ 0x04BD ] }
  , { code: 0x04BE, lower: [], title: [], upper: [], fold: 0x04BF, foldFull: [ 0x04BF ] }
  , { code: 0x04C0, lower: [], title: [], upper: [], fold: 0x04CF, foldFull: [ 0x04CF ] }
  , { code: 0x04C1, lower: [], title: [], upper: [], fold: 0x04C2, foldFull: [ 0x04C2 ] }
  , { code: 0x04C3, lower: [], title: [], upper: [], fold: 0x04C4, foldFull: [ 0x04C4 ] }
  , { code: 0x04C5, lower: [], title: [], upper: [], fold: 0x04C6, foldFull: [ 0x04C6 ] }
  , { code: 0x04C7, lower: [], title: [], upper: [], fold: 0x04C8, foldFull: [ 0x04C8 ] }
  , { code: 0x04C9, lower: [], title: [], upper: [], fold: 0x04CA, foldFull: [ 0x04CA ] }
  , { code: 0x04CB, lower: [], title: [], upper: [], fold: 0x04CC, foldFull: [ 0x04CC ] }
  , { code: 0x04CD, lower: [], title: [], upper: [], fold: 0x04CE, foldFull: [ 0x04CE ] }
  , { code: 0x04D0, lower: [], title: [], upper: [], fold: 0x04D1, foldFull: [ 0x04D1 ] }
  , { code: 0x04D2, lower: [], title: [], upper: [], fold: 0x04D3, foldFull: [ 0x04D3 ] }
  , { code: 0x04D4, lower: [], title: [], upper: [], fold: 0x04D5, foldFull: [ 0x04D5 ] }
  , { code: 0x04D6, lower: [], title: [], upper: [], fold: 0x04D7, foldFull: [ 0x04D7 ] }
  , { code: 0x04D8, lower: [], title: [], upper: [], fold: 0x04D9, foldFull: [ 0x04D9 ] }
  , { code: 0x04DA, lower: [], title: [], upper: [], fold: 0x04DB, foldFull: [ 0x04DB ] }
  , { code: 0x04DC, lower: [], title: [], upper: [], fold: 0x04DD, foldFull: [ 0x04DD ] }
  , { code: 0x04DE, lower: [], title: [], upper: [], fold: 0x04DF, foldFull: [ 0x04DF ] }
  , { code: 0x04E0, lower: [], title: [], upper: [], fold: 0x04E1, foldFull: [ 0x04E1 ] }
  , { code: 0x04E2, lower: [], title: [], upper: [], fold: 0x04E3, foldFull: [ 0x04E3 ] }
  , { code: 0x04E4, lower: [], title: [], upper: [], fold: 0x04E5, foldFull: [ 0x04E5 ] }
  , { code: 0x04E6, lower: [], title: [], upper: [], fold: 0x04E7, foldFull: [ 0x04E7 ] }
  , { code: 0x04E8, lower: [], title: [], upper: [], fold: 0x04E9, foldFull: [ 0x04E9 ] }
  , { code: 0x04EA, lower: [], title: [], upper: [], fold: 0x04EB, foldFull: [ 0x04EB ] }
  , { code: 0x04EC, lower: [], title: [], upper: [], fold: 0x04ED, foldFull: [ 0x04ED ] }
  , { code: 0x04EE, lower: [], title: [], upper: [], fold: 0x04EF, foldFull: [ 0x04EF ] }
  , { code: 0x04F0, lower: [], title: [], upper: [], fold: 0x04F1, foldFull: [ 0x04F1 ] }
  , { code: 0x04F2, lower: [], title: [], upper: [], fold: 0x04F3, foldFull: [ 0x04F3 ] }
  , { code: 0x04F4, lower: [], title: [], upper: [], fold: 0x04F5, foldFull: [ 0x04F5 ] }
  , { code: 0x04F6, lower: [], title: [], upper: [], fold: 0x04F7, foldFull: [ 0x04F7 ] }
  , { code: 0x04F8, lower: [], title: [], upper: [], fold: 0x04F9, foldFull: [ 0x04F9 ] }
  , { code: 0x04FA, lower: [], title: [], upper: [], fold: 0x04FB, foldFull: [ 0x04FB ] }
  , { code: 0x04FC, lower: [], title: [], upper: [], fold: 0x04FD, foldFull: [ 0x04FD ] }
  , { code: 0x04FE, lower: [], title: [], upper: [], fold: 0x04FF, foldFull: [ 0x04FF ] }
  , { code: 0x0500, lower: [], title: [], upper: [], fold: 0x0501, foldFull: [ 0x0501 ] }
  , { code: 0x0502, lower: [], title: [], upper: [], fold: 0x0503, foldFull: [ 0x0503 ] }
  , { code: 0x0504, lower: [], title: [], upper: [], fold: 0x0505, foldFull: [ 0x0505 ] }
  , { code: 0x0506, lower: [], title: [], upper: [], fold: 0x0507, foldFull: [ 0x0507 ] }
  , { code: 0x0508, lower: [], title: [], upper: [], fold: 0x0509, foldFull: [ 0x0509 ] }
  , { code: 0x050A, lower: [], title: [], upper: [], fold: 0x050B, foldFull: [ 0x050B ] }
  , { code: 0x050C, lower: [], title: [], upper: [], fold: 0x050D, foldFull: [ 0x050D ] }
  , { code: 0x050E, lower: [], title: [], upper: [], fold: 0x050F, foldFull: [ 0x050F ] }
  , { code: 0x0510, lower: [], title: [], upper: [], fold: 0x0511, foldFull: [ 0x0511 ] }
  , { code: 0x0512, lower: [], title: [], upper: [], fold: 0x0513, foldFull: [ 0x0513 ] }
  , { code: 0x0514, lower: [], title: [], upper: [], fold: 0x0515, foldFull: [ 0x0515 ] }
  , { code: 0x0516, lower: [], title: [], upper: [], fold: 0x0517, foldFull: [ 0x0517 ] }
  , { code: 0x0518, lower: [], title: [], upper: [], fold: 0x0519, foldFull: [ 0x0519 ] }
  , { code: 0x051A, lower: [], title: [], upper: [], fold: 0x051B, foldFull: [ 0x051B ] }
  , { code: 0x051C, lower: [], title: [], upper: [], fold: 0x051D, foldFull: [ 0x051D ] }
  , { code: 0x051E, lower: [], title: [], upper: [], fold: 0x051F, foldFull: [ 0x051F ] }
  , { code: 0x0520, lower: [], title: [], upper: [], fold: 0x0521, foldFull: [ 0x0521 ] }
  , { code: 0x0522, lower: [], title: [], upper: [], fold: 0x0523, foldFull: [ 0x0523 ] }
  , { code: 0x0524, lower: [], title: [], upper: [], fold: 0x0525, foldFull: [ 0x0525 ] }
  , { code: 0x0526, lower: [], title: [], upper: [], fold: 0x0527, foldFull: [ 0x0527 ] }
  , { code: 0x0528, lower: [], title: [], upper: [], fold: 0x0529, foldFull: [ 0x0529 ] }
  , { code: 0x052A, lower: [], title: [], upper: [], fold: 0x052B, foldFull: [ 0x052B ] }
  , { code: 0x052C, lower: [], title: [], upper: [], fold: 0x052D, foldFull: [ 0x052D ] }
  , { code: 0x052E, lower: [], title: [], upper: [], fold: 0x052F, foldFull: [ 0x052F ] }
  , { code: 0x0531, lower: [], title: [], upper: [], fold: 0x0561, foldFull: [ 0x0561 ] }
  , { code: 0x0532, lower: [], title: [], upper: [], fold: 0x0562, foldFull: [ 0x0562 ] }
  , { code: 0x0533, lower: [], title: [], upper: [], fold: 0x0563, foldFull: [ 0x0563 ] }
  , { code: 0x0534, lower: [], title: [], upper: [], fold: 0x0564, foldFull: [ 0x0564 ] }
  , { code: 0x0535, lower: [], title: [], upper: [], fold: 0x0565, foldFull: [ 0x0565 ] }
  , { code: 0x0536, lower: [], title: [], upper: [], fold: 0x0566, foldFull: [ 0x0566 ] }
  , { code: 0x0537, lower: [], title: [], upper: [], fold: 0x0567, foldFull: [ 0x0567 ] }
  , { code: 0x0538, lower: [], title: [], upper: [], fold: 0x0568, foldFull: [ 0x0568 ] }
  , { code: 0x0539, lower: [], title: [], upper: [], fold: 0x0569, foldFull: [ 0x0569 ] }
  , { code: 0x053A, lower: [], title: [], upper: [], fold: 0x056A, foldFull: [ 0x056A ] }
  , { code: 0x053B, lower: [], title: [], upper: [], fold: 0x056B, foldFull: [ 0x056B ] }
  , { code: 0x053C, lower: [], title: [], upper: [], fold: 0x056C, foldFull: [ 0x056C ] }
  , { code: 0x053D, lower: [], title: [], upper: [], fold: 0x056D, foldFull: [ 0x056D ] }
  , { code: 0x053E, lower: [], title: [], upper: [], fold: 0x056E, foldFull: [ 0x056E ] }
  , { code: 0x053F, lower: [], title: [], upper: [], fold: 0x056F, foldFull: [ 0x056F ] }
  , { code: 0x0540, lower: [], title: [], upper: [], fold: 0x0570, foldFull: [ 0x0570 ] }
  , { code: 0x0541, lower: [], title: [], upper: [], fold: 0x0571, foldFull: [ 0x0571 ] }
  , { code: 0x0542, lower: [], title: [], upper: [], fold: 0x0572, foldFull: [ 0x0572 ] }
  , { code: 0x0543, lower: [], title: [], upper: [], fold: 0x0573, foldFull: [ 0x0573 ] }
  , { code: 0x0544, lower: [], title: [], upper: [], fold: 0x0574, foldFull: [ 0x0574 ] }
  , { code: 0x0545, lower: [], title: [], upper: [], fold: 0x0575, foldFull: [ 0x0575 ] }
  , { code: 0x0546, lower: [], title: [], upper: [], fold: 0x0576, foldFull: [ 0x0576 ] }
  , { code: 0x0547, lower: [], title: [], upper: [], fold: 0x0577, foldFull: [ 0x0577 ] }
  , { code: 0x0548, lower: [], title: [], upper: [], fold: 0x0578, foldFull: [ 0x0578 ] }
  , { code: 0x0549, lower: [], title: [], upper: [], fold: 0x0579, foldFull: [ 0x0579 ] }
  , { code: 0x054A, lower: [], title: [], upper: [], fold: 0x057A, foldFull: [ 0x057A ] }
  , { code: 0x054B, lower: [], title: [], upper: [], fold: 0x057B, foldFull: [ 0x057B ] }
  , { code: 0x054C, lower: [], title: [], upper: [], fold: 0x057C, foldFull: [ 0x057C ] }
  , { code: 0x054D, lower: [], title: [], upper: [], fold: 0x057D, foldFull: [ 0x057D ] }
  , { code: 0x054E, lower: [], title: [], upper: [], fold: 0x057E, foldFull: [ 0x057E ] }
  , { code: 0x054F, lower: [], title: [], upper: [], fold: 0x057F, foldFull: [ 0x057F ] }
  , { code: 0x0550, lower: [], title: [], upper: [], fold: 0x0580, foldFull: [ 0x0580 ] }
  , { code: 0x0551, lower: [], title: [], upper: [], fold: 0x0581, foldFull: [ 0x0581 ] }
  , { code: 0x0552, lower: [], title: [], upper: [], fold: 0x0582, foldFull: [ 0x0582 ] }
  , { code: 0x0553, lower: [], title: [], upper: [], fold: 0x0583, foldFull: [ 0x0583 ] }
  , { code: 0x0554, lower: [], title: [], upper: [], fold: 0x0584, foldFull: [ 0x0584 ] }
  , { code: 0x0555, lower: [], title: [], upper: [], fold: 0x0585, foldFull: [ 0x0585 ] }
  , { code: 0x0556, lower: [], title: [], upper: [], fold: 0x0586, foldFull: [ 0x0586 ] }
  , { code: 0x0587, lower: [ 0x0587 ], title: [ 0x0535, 0x0582 ], upper: [ 0x0535, 0x0552 ], fold: 0, foldFull: [ 0x0565, 0x0582 ] }
  , { code: 0x0587, lower: [ 0x0587 ], title: [ 0x0535, 0x0582 ], upper: [ 0x0535, 0x0552 ], fold: 0, foldFull: [ 0x0565, 0x0582 ] }
  , { code: 0x10A0, lower: [], title: [], upper: [], fold: 0x2D00, foldFull: [ 0x2D00 ] }
  , { code: 0x10A1, lower: [], title: [], upper: [], fold: 0x2D01, foldFull: [ 0x2D01 ] }
  , { code: 0x10A2, lower: [], title: [], upper: [], fold: 0x2D02, foldFull: [ 0x2D02 ] }
  , { code: 0x10A3, lower: [], title: [], upper: [], fold: 0x2D03, foldFull: [ 0x2D03 ] }
  , { code: 0x10A4, lower: [], title: [], upper: [], fold: 0x2D04, foldFull: [ 0x2D04 ] }
  , { code: 0x10A5, lower: [], title: [], upper: [], fold: 0x2D05, foldFull: [ 0x2D05 ] }
  , { code: 0x10A6, lower: [], title: [], upper: [], fold: 0x2D06, foldFull: [ 0x2D06 ] }
  , { code: 0x10A7, lower: [], title: [], upper: [], fold: 0x2D07, foldFull: [ 0x2D07 ] }
  , { code: 0x10A8, lower: [], title: [], upper: [], fold: 0x2D08, foldFull: [ 0x2D08 ] }
  , { code: 0x10A9, lower: [], title: [], upper: [], fold: 0x2D09, foldFull: [ 0x2D09 ] }
  , { code: 0x10AA, lower: [], title: [], upper: [], fold: 0x2D0A, foldFull: [ 0x2D0A ] }
  , { code: 0x10AB, lower: [], title: [], upper: [], fold: 0x2D0B, foldFull: [ 0x2D0B ] }
  , { code: 0x10AC, lower: [], title: [], upper: [], fold: 0x2D0C, foldFull: [ 0x2D0C ] }
  , { code: 0x10AD, lower: [], title: [], upper: [], fold: 0x2D0D, foldFull: [ 0x2D0D ] }
  , { code: 0x10AE, lower: [], title: [], upper: [], fold: 0x2D0E, foldFull: [ 0x2D0E ] }
  , { code: 0x10AF, lower: [], title: [], upper: [], fold: 0x2D0F, foldFull: [ 0x2D0F ] }
  , { code: 0x10B0, lower: [], title: [], upper: [], fold: 0x2D10, foldFull: [ 0x2D10 ] }
  , { code: 0x10B1, lower: [], title: [], upper: [], fold: 0x2D11, foldFull: [ 0x2D11 ] }
  , { code: 0x10B2, lower: [], title: [], upper: [], fold: 0x2D12, foldFull: [ 0x2D12 ] }
  , { code: 0x10B3, lower: [], title: [], upper: [], fold: 0x2D13, foldFull: [ 0x2D13 ] }
  , { code: 0x10B4, lower: [], title: [], upper: [], fold: 0x2D14, foldFull: [ 0x2D14 ] }
  , { code: 0x10B5, lower: [], title: [], upper: [], fold: 0x2D15, foldFull: [ 0x2D15 ] }
  , { code: 0x10B6, lower: [], title: [], upper: [], fold: 0x2D16, foldFull: [ 0x2D16 ] }
  , { code: 0x10B7, lower: [], title: [], upper: [], fold: 0x2D17, foldFull: [ 0x2D17 ] }
  , { code: 0x10B8, lower: [], title: [], upper: [], fold: 0x2D18, foldFull: [ 0x2D18 ] }
  , { code: 0x10B9, lower: [], title: [], upper: [], fold: 0x2D19, foldFull: [ 0x2D19 ] }
  , { code: 0x10BA, lower: [], title: [], upper: [], fold: 0x2D1A, foldFull: [ 0x2D1A ] }
  , { code: 0x10BB, lower: [], title: [], upper: [], fold: 0x2D1B, foldFull: [ 0x2D1B ] }
  , { code: 0x10BC, lower: [], title: [], upper: [], fold: 0x2D1C, foldFull: [ 0x2D1C ] }
  , { code: 0x10BD, lower: [], title: [], upper: [], fold: 0x2D1D, foldFull: [ 0x2D1D ] }
  , { code: 0x10BE, lower: [], title: [], upper: [], fold: 0x2D1E, foldFull: [ 0x2D1E ] }
  , { code: 0x10BF, lower: [], title: [], upper: [], fold: 0x2D1F, foldFull: [ 0x2D1F ] }
  , { code: 0x10C0, lower: [], title: [], upper: [], fold: 0x2D20, foldFull: [ 0x2D20 ] }
  , { code: 0x10C1, lower: [], title: [], upper: [], fold: 0x2D21, foldFull: [ 0x2D21 ] }
  , { code: 0x10C2, lower: [], title: [], upper: [], fold: 0x2D22, foldFull: [ 0x2D22 ] }
  , { code: 0x10C3, lower: [], title: [], upper: [], fold: 0x2D23, foldFull: [ 0x2D23 ] }
  , { code: 0x10C4, lower: [], title: [], upper: [], fold: 0x2D24, foldFull: [ 0x2D24 ] }
  , { code: 0x10C5, lower: [], title: [], upper: [], fold: 0x2D25, foldFull: [ 0x2D25 ] }
  , { code: 0x10C7, lower: [], title: [], upper: [], fold: 0x2D27, foldFull: [ 0x2D27 ] }
  , { code: 0x10CD, lower: [], title: [], upper: [], fold: 0x2D2D, foldFull: [ 0x2D2D ] }
  , { code: 0x13F8, lower: [], title: [], upper: [], fold: 0x13F0, foldFull: [ 0x13F0 ] }
  , { code: 0x13F9, lower: [], title: [], upper: [], fold: 0x13F1, foldFull: [ 0x13F1 ] }
  , { code: 0x13FA, lower: [], title: [], upper: [], fold: 0x13F2, foldFull: [ 0x13F2 ] }
  , { code: 0x13FB, lower: [], title: [], upper: [], fold: 0x13F3, foldFull: [ 0x13F3 ] }
  , { code: 0x13FC, lower: [], title: [], upper: [], fold: 0x13F4, foldFull: [ 0x13F4 ] }
  , { code: 0x13FD, lower: [], title: [], upper: [], fold: 0x13F5, foldFull: [ 0x13F5 ] }
  , { code: 0x1C80, lower: [], title: [], upper: [], fold: 0x0432, foldFull: [ 0x0432 ] }
  , { code: 0x1C81, lower: [], title: [], upper: [], fold: 0x0434, foldFull: [ 0x0434 ] }
  , { code: 0x1C82, lower: [], title: [], upper: [], fold: 0x043E, foldFull: [ 0x043E ] }
  , { code: 0x1C83, lower: [], title: [], upper: [], fold: 0x0441, foldFull: [ 0x0441 ] }
  , { code: 0x1C84, lower: [], title: [], upper: [], fold: 0x0442, foldFull: [ 0x0442 ] }
  , { code: 0x1C85, lower: [], title: [], upper: [], fold: 0x0442, foldFull: [ 0x0442 ] }
  , { code: 0x1C86, lower: [], title: [], upper: [], fold: 0x044A, foldFull: [ 0x044A ] }
  , { code: 0x1C87, lower: [], title: [], upper: [], fold: 0x0463, foldFull: [ 0x0463 ] }
  , { code: 0x1C88, lower: [], title: [], upper: [], fold: 0xA64B, foldFull: [ 0xA64B ] }
  , { code: 0x1C90, lower: [], title: [], upper: [], fold: 0x10D0, foldFull: [ 0x10D0 ] }
  , { code: 0x1C91, lower: [], title: [], upper: [], fold: 0x10D1, foldFull: [ 0x10D1 ] }
  , { code: 0x1C92, lower: [], title: [], upper: [], fold: 0x10D2, foldFull: [ 0x10D2 ] }
  , { code: 0x1C93, lower: [], title: [], upper: [], fold: 0x10D3, foldFull: [ 0x10D3 ] }
  , { code: 0x1C94, lower: [], title: [], upper: [], fold: 0x10D4, foldFull: [ 0x10D4 ] }
  , { code: 0x1C95, lower: [], title: [], upper: [], fold: 0x10D5, foldFull: [ 0x10D5 ] }
  , { code: 0x1C96, lower: [], title: [], upper: [], fold: 0x10D6, foldFull: [ 0x10D6 ] }
  , { code: 0x1C97, lower: [], title: [], upper: [], fold: 0x10D7, foldFull: [ 0x10D7 ] }
  , { code: 0x1C98, lower: [], title: [], upper: [], fold: 0x10D8, foldFull: [ 0x10D8 ] }
  , { code: 0x1C99, lower: [], title: [], upper: [], fold: 0x10D9, foldFull: [ 0x10D9 ] }
  , { code: 0x1C9A, lower: [], title: [], upper: [], fold: 0x10DA, foldFull: [ 0x10DA ] }
  , { code: 0x1C9B, lower: [], title: [], upper: [], fold: 0x10DB, foldFull: [ 0x10DB ] }
  , { code: 0x1C9C, lower: [], title: [], upper: [], fold: 0x10DC, foldFull: [ 0x10DC ] }
  , { code: 0x1C9D, lower: [], title: [], upper: [], fold: 0x10DD, foldFull: [ 0x10DD ] }
  , { code: 0x1C9E, lower: [], title: [], upper: [], fold: 0x10DE, foldFull: [ 0x10DE ] }
  , { code: 0x1C9F, lower: [], title: [], upper: [], fold: 0x10DF, foldFull: [ 0x10DF ] }
  , { code: 0x1CA0, lower: [], title: [], upper: [], fold: 0x10E0, foldFull: [ 0x10E0 ] }
  , { code: 0x1CA1, lower: [], title: [], upper: [], fold: 0x10E1, foldFull: [ 0x10E1 ] }
  , { code: 0x1CA2, lower: [], title: [], upper: [], fold: 0x10E2, foldFull: [ 0x10E2 ] }
  , { code: 0x1CA3, lower: [], title: [], upper: [], fold: 0x10E3, foldFull: [ 0x10E3 ] }
  , { code: 0x1CA4, lower: [], title: [], upper: [], fold: 0x10E4, foldFull: [ 0x10E4 ] }
  , { code: 0x1CA5, lower: [], title: [], upper: [], fold: 0x10E5, foldFull: [ 0x10E5 ] }
  , { code: 0x1CA6, lower: [], title: [], upper: [], fold: 0x10E6, foldFull: [ 0x10E6 ] }
  , { code: 0x1CA7, lower: [], title: [], upper: [], fold: 0x10E7, foldFull: [ 0x10E7 ] }
  , { code: 0x1CA8, lower: [], title: [], upper: [], fold: 0x10E8, foldFull: [ 0x10E8 ] }
  , { code: 0x1CA9, lower: [], title: [], upper: [], fold: 0x10E9, foldFull: [ 0x10E9 ] }
  , { code: 0x1CAA, lower: [], title: [], upper: [], fold: 0x10EA, foldFull: [ 0x10EA ] }
  , { code: 0x1CAB, lower: [], title: [], upper: [], fold: 0x10EB, foldFull: [ 0x10EB ] }
  , { code: 0x1CAC, lower: [], title: [], upper: [], fold: 0x10EC, foldFull: [ 0x10EC ] }
  , { code: 0x1CAD, lower: [], title: [], upper: [], fold: 0x10ED, foldFull: [ 0x10ED ] }
  , { code: 0x1CAE, lower: [], title: [], upper: [], fold: 0x10EE, foldFull: [ 0x10EE ] }
  , { code: 0x1CAF, lower: [], title: [], upper: [], fold: 0x10EF, foldFull: [ 0x10EF ] }
  , { code: 0x1CB0, lower: [], title: [], upper: [], fold: 0x10F0, foldFull: [ 0x10F0 ] }
  , { code: 0x1CB1, lower: [], title: [], upper: [], fold: 0x10F1, foldFull: [ 0x10F1 ] }
  , { code: 0x1CB2, lower: [], title: [], upper: [], fold: 0x10F2, foldFull: [ 0x10F2 ] }
  , { code: 0x1CB3, lower: [], title: [], upper: [], fold: 0x10F3, foldFull: [ 0x10F3 ] }
  , { code: 0x1CB4, lower: [], title: [], upper: [], fold: 0x10F4, foldFull: [ 0x10F4 ] }
  , { code: 0x1CB5, lower: [], title: [], upper: [], fold: 0x10F5, foldFull: [ 0x10F5 ] }
  , { code: 0x1CB6, lower: [], title: [], upper: [], fold: 0x10F6, foldFull: [ 0x10F6 ] }
  , { code: 0x1CB7, lower: [], title: [], upper: [], fold: 0x10F7, foldFull: [ 0x10F7 ] }
  , { code: 0x1CB8, lower: [], title: [], upper: [], fold: 0x10F8, foldFull: [ 0x10F8 ] }
  , { code: 0x1CB9, lower: [], title: [], upper: [], fold: 0x10F9, foldFull: [ 0x10F9 ] }
  , { code: 0x1CBA, lower: [], title: [], upper: [], fold: 0x10FA, foldFull: [ 0x10FA ] }
  , { code: 0x1CBD, lower: [], title: [], upper: [], fold: 0x10FD, foldFull: [ 0x10FD ] }
  , { code: 0x1CBE, lower: [], title: [], upper: [], fold: 0x10FE, foldFull: [ 0x10FE ] }
  , { code: 0x1CBF, lower: [], title: [], upper: [], fold: 0x10FF, foldFull: [ 0x10FF ] }
  , { code: 0x1E00, lower: [], title: [], upper: [], fold: 0x1E01, foldFull: [ 0x1E01 ] }
  , { code: 0x1E02, lower: [], title: [], upper: [], fold: 0x1E03, foldFull: [ 0x1E03 ] }
  , { code: 0x1E04, lower: [], title: [], upper: [], fold: 0x1E05, foldFull: [ 0x1E05 ] }
  , { code: 0x1E06, lower: [], title: [], upper: [], fold: 0x1E07, foldFull: [ 0x1E07 ] }
  , { code: 0x1E08, lower: [], title: [], upper: [], fold: 0x1E09, foldFull: [ 0x1E09 ] }
  , { code: 0x1E0A, lower: [], title: [], upper: [], fold: 0x1E0B, foldFull: [ 0x1E0B ] }
  , { code: 0x1E0C, lower: [], title: [], upper: [], fold: 0x1E0D, foldFull: [ 0x1E0D ] }
  , { code: 0x1E0E, lower: [], title: [], upper: [], fold: 0x1E0F, foldFull: [ 0x1E0F ] }
  , { code: 0x1E10, lower: [], title: [], upper: [], fold: 0x1E11, foldFull: [ 0x1E11 ] }
  , { code: 0x1E12, lower: [], title: [], upper: [], fold: 0x1E13, foldFull: [ 0x1E13 ] }
  , { code: 0x1E14, lower: [], title: [], upper: [], fold: 0x1E15, foldFull: [ 0x1E15 ] }
  , { code: 0x1E16, lower: [], title: [], upper: [], fold: 0x1E17, foldFull: [ 0x1E17 ] }
  , { code: 0x1E18, lower: [], title: [], upper: [], fold: 0x1E19, foldFull: [ 0x1E19 ] }
  , { code: 0x1E1A, lower: [], title: [], upper: [], fold: 0x1E1B, foldFull: [ 0x1E1B ] }
  , { code: 0x1E1C, lower: [], title: [], upper: [], fold: 0x1E1D, foldFull: [ 0x1E1D ] }
  , { code: 0x1E1E, lower: [], title: [], upper: [], fold: 0x1E1F, foldFull: [ 0x1E1F ] }
  , { code: 0x1E20, lower: [], title: [], upper: [], fold: 0x1E21, foldFull: [ 0x1E21 ] }
  , { code: 0x1E22, lower: [], title: [], upper: [], fold: 0x1E23, foldFull: [ 0x1E23 ] }
  , { code: 0x1E24, lower: [], title: [], upper: [], fold: 0x1E25, foldFull: [ 0x1E25 ] }
  , { code: 0x1E26, lower: [], title: [], upper: [], fold: 0x1E27, foldFull: [ 0x1E27 ] }
  , { code: 0x1E28, lower: [], title: [], upper: [], fold: 0x1E29, foldFull: [ 0x1E29 ] }
  , { code: 0x1E2A, lower: [], title: [], upper: [], fold: 0x1E2B, foldFull: [ 0x1E2B ] }
  , { code: 0x1E2C, lower: [], title: [], upper: [], fold: 0x1E2D, foldFull: [ 0x1E2D ] }
  , { code: 0x1E2E, lower: [], title: [], upper: [], fold: 0x1E2F, foldFull: [ 0x1E2F ] }
  , { code: 0x1E30, lower: [], title: [], upper: [], fold: 0x1E31, foldFull: [ 0x1E31 ] }
  , { code: 0x1E32, lower: [], title: [], upper: [], fold: 0x1E33, foldFull: [ 0x1E33 ] }
  , { code: 0x1E34, lower: [], title: [], upper: [], fold: 0x1E35, foldFull: [ 0x1E35 ] }
  , { code: 0x1E36, lower: [], title: [], upper: [], fold: 0x1E37, foldFull: [ 0x1E37 ] }
  , { code: 0x1E38, lower: [], title: [], upper: [], fold: 0x1E39, foldFull: [ 0x1E39 ] }
  , { code: 0x1E3A, lower: [], title: [], upper: [], fold: 0x1E3B, foldFull: [ 0x1E3B ] }
  , { code: 0x1E3C, lower: [], title: [], upper: [], fold: 0x1E3D, foldFull: [ 0x1E3D ] }
  , { code: 0x1E3E, lower: [], title: [], upper: [], fold: 0x1E3F, foldFull: [ 0x1E3F ] }
  , { code: 0x1E40, lower: [], title: [], upper: [], fold: 0x1E41, foldFull: [ 0x1E41 ] }
  , { code: 0x1E42, lower: [], title: [], upper: [], fold: 0x1E43, foldFull: [ 0x1E43 ] }
  , { code: 0x1E44, lower: [], title: [], upper: [], fold: 0x1E45, foldFull: [ 0x1E45 ] }
  , { code: 0x1E46, lower: [], title: [], upper: [], fold: 0x1E47, foldFull: [ 0x1E47 ] }
  , { code: 0x1E48, lower: [], title: [], upper: [], fold: 0x1E49, foldFull: [ 0x1E49 ] }
  , { code: 0x1E4A, lower: [], title: [], upper: [], fold: 0x1E4B, foldFull: [ 0x1E4B ] }
  , { code: 0x1E4C, lower: [], title: [], upper: [], fold: 0x1E4D, foldFull: [ 0x1E4D ] }
  , { code: 0x1E4E, lower: [], title: [], upper: [], fold: 0x1E4F, foldFull: [ 0x1E4F ] }
  , { code: 0x1E50, lower: [], title: [], upper: [], fold: 0x1E51, foldFull: [ 0x1E51 ] }
  , { code: 0x1E52, lower: [], title: [], upper: [], fold: 0x1E53, foldFull: [ 0x1E53 ] }
  , { code: 0x1E54, lower: [], title: [], upper: [], fold: 0x1E55, foldFull: [ 0x1E55 ] }
  , { code: 0x1E56, lower: [], title: [], upper: [], fold: 0x1E57, foldFull: [ 0x1E57 ] }
  , { code: 0x1E58, lower: [], title: [], upper: [], fold: 0x1E59, foldFull: [ 0x1E59 ] }
  , { code: 0x1E5A, lower: [], title: [], upper: [], fold: 0x1E5B, foldFull: [ 0x1E5B ] }
  , { code: 0x1E5C, lower: [], title: [], upper: [], fold: 0x1E5D, foldFull: [ 0x1E5D ] }
  , { code: 0x1E5E, lower: [], title: [], upper: [], fold: 0x1E5F, foldFull: [ 0x1E5F ] }
  , { code: 0x1E60, lower: [], title: [], upper: [], fold: 0x1E61, foldFull: [ 0x1E61 ] }
  , { code: 0x1E62, lower: [], title: [], upper: [], fold: 0x1E63, foldFull: [ 0x1E63 ] }
  , { code: 0x1E64, lower: [], title: [], upper: [], fold: 0x1E65, foldFull: [ 0x1E65 ] }
  , { code: 0x1E66, lower: [], title: [], upper: [], fold: 0x1E67, foldFull: [ 0x1E67 ] }
  , { code: 0x1E68, lower: [], title: [], upper: [], fold: 0x1E69, foldFull: [ 0x1E69 ] }
  , { code: 0x1E6A, lower: [], title: [], upper: [], fold: 0x1E6B, foldFull: [ 0x1E6B ] }
  , { code: 0x1E6C, lower: [], title: [], upper: [], fold: 0x1E6D, foldFull: [ 0x1E6D ] }
  , { code: 0x1E6E, lower: [], title: [], upper: [], fold: 0x1E6F, foldFull: [ 0x1E6F ] }
  , { code: 0x1E70, lower: [], title: [], upper: [], fold: 0x1E71, foldFull: [ 0x1E71 ] }
  , { code: 0x1E72, lower: [], title: [], upper: [], fold: 0x1E73, foldFull: [ 0x1E73 ] }
  , { code: 0x1E74, lower: [], title: [], upper: [], fold: 0x1E75, foldFull: [ 0x1E75 ] }
  , { code: 0x1E76, lower: [], title: [], upper: [], fold: 0x1E77, foldFull: [ 0x1E77 ] }
  , { code: 0x1E78, lower: [], title: [], upper: [], fold: 0x1E79, foldFull: [ 0x1E79 ] }
  , { code: 0x1E7A, lower: [], title: [], upper: [], fold: 0x1E7B, foldFull: [ 0x1E7B ] }
  , { code: 0x1E7C, lower: [], title: [], upper: [], fold: 0x1E7D, foldFull: [ 0x1E7D ] }
  , { code: 0x1E7E, lower: [], title: [], upper: [], fold: 0x1E7F, foldFull: [ 0x1E7F ] }
  , { code: 0x1E80, lower: [], title: [], upper: [], fold: 0x1E81, foldFull: [ 0x1E81 ] }
  , { code: 0x1E82, lower: [], title: [], upper: [], fold: 0x1E83, foldFull: [ 0x1E83 ] }
  , { code: 0x1E84, lower: [], title: [], upper: [], fold: 0x1E85, foldFull: [ 0x1E85 ] }
  , { code: 0x1E86, lower: [], title: [], upper: [], fold: 0x1E87, foldFull: [ 0x1E87 ] }
  , { code: 0x1E88, lower: [], title: [], upper: [], fold: 0x1E89, foldFull: [ 0x1E89 ] }
  , { code: 0x1E8A, lower: [], title: [], upper: [], fold: 0x1E8B, foldFull: [ 0x1E8B ] }
  , { code: 0x1E8C, lower: [], title: [], upper: [], fold: 0x1E8D, foldFull: [ 0x1E8D ] }
  , { code: 0x1E8E, lower: [], title: [], upper: [], fold: 0x1E8F, foldFull: [ 0x1E8F ] }
  , { code: 0x1E90, lower: [], title: [], upper: [], fold: 0x1E91, foldFull: [ 0x1E91 ] }
  , { code: 0x1E92, lower: [], title: [], upper: [], fold: 0x1E93, foldFull: [ 0x1E93 ] }
  , { code: 0x1E94, lower: [], title: [], upper: [], fold: 0x1E95, foldFull: [ 0x1E95 ] }
  , { code: 0x1E96, lower: [ 0x1E96 ], title: [ 0x0048, 0x0331 ], upper: [ 0x0048, 0x0331 ], fold: 0, foldFull: [ 0x0068, 0x0331 ] }
  , { code: 0x1E96, lower: [ 0x1E96 ], title: [ 0x0048, 0x0331 ], upper: [ 0x0048, 0x0331 ], fold: 0, foldFull: [ 0x0068, 0x0331 ] }
  , { code: 0x1E97, lower: [ 0x1E97 ], title: [ 0x0054, 0x0308 ], upper: [ 0x0054, 0x0308 ], fold: 0, foldFull: [ 0x0074, 0x0308 ] }
  , { code: 0x1E97, lower: [ 0x1E97 ], title: [ 0x0054, 0x0308 ], upper: [ 0x0054, 0x0308 ], fold: 0, foldFull: [ 0x0074, 0x0308 ] }
  , { code: 0x1E98, lower: [ 0x1E98 ], title: [ 0x0057, 0x030A ], upper: [ 0x0057, 0x030A ], fold: 0, foldFull: [ 0x0077, 0x030A ] }
  , { code: 0x1E98, lower: [ 0x1E98 ], title: [ 0x0057, 0x030A ], upper: [ 0x0057, 0x030A ], fold: 0, foldFull: [ 0x0077, 0x030A ] }
  , { code: 0x1E99, lower: [ 0x1E99 ], title: [ 0x0059, 0x030A ], upper: [ 0x0059, 0x030A ], fold: 0, foldFull: [ 0x0079, 0x030A ] }
  , { code: 0x1E99, lower: [ 0x1E99 ], title: [ 0x0059, 0x030A ], upper: [ 0x0059, 0x030A ], fold: 0, foldFull: [ 0x0079, 0x030A ] }
  , { code: 0x1E9A, lower: [ 0x1E9A ], title: [ 0x0041, 0x02BE ], upper: [ 0x0041, 0x02BE ], fold: 0, foldFull: [ 0x0061, 0x02BE ] }
  , { code: 0x1E9A, lower: [ 0x1E9A ], title: [ 0x0041, 0x02BE ], upper: [ 0x0041, 0x02BE ], fold: 0, foldFull: [ 0x0061, 0x02BE ] }
  , { code: 0x1E9B, lower: [], title: [], upper: [], fold: 0x1E61, foldFull: [ 0x1E61 ] }
  , { code: 0x1E9E, lower: [], title: [], upper: [], fold: 0x00DF, foldFull: [ 0x0073, 0x0073 ] }
  , { code: 0x1EA0, lower: [], title: [], upper: [], fold: 0x1EA1, foldFull: [ 0x1EA1 ] }
  , { code: 0x1EA2, lower: [], title: [], upper: [], fold: 0x1EA3, foldFull: [ 0x1EA3 ] }
  , { code: 0x1EA4, lower: [], title: [], upper: [], fold: 0x1EA5, foldFull: [ 0x1EA5 ] }
  , { code: 0x1EA6, lower: [], title: [], upper: [], fold: 0x1EA7, foldFull: [ 0x1EA7 ] }
  , { code: 0x1EA8, lower: [], title: [], upper: [], fold: 0x1EA9, foldFull: [ 0x1EA9 ] }
  , { code: 0x1EAA, lower: [], title: [], upper: [], fold: 0x1EAB, foldFull: [ 0x1EAB ] }
  , { code: 0x1EAC, lower: [], title: [], upper: [], fold: 0x1EAD, foldFull: [ 0x1EAD ] }
  , { code: 0x1EAE, lower: [], title: [], upper: [], fold: 0x1EAF, foldFull: [ 0x1EAF ] }
  , { code: 0x1EB0, lower: [], title: [], upper: [], fold: 0x1EB1, foldFull: [ 0x1EB1 ] }
  , { code: 0x1EB2, lower: [], title: [], upper: [], fold: 0x1EB3, foldFull: [ 0x1EB3 ] }
  , { code: 0x1EB4, lower: [], title: [], upper: [], fold: 0x1EB5, foldFull: [ 0x1EB5 ] }
  , { code: 0x1EB6, lower: [], title: [], upper: [], fold: 0x1EB7, foldFull: [ 0x1EB7 ] }
  , { code: 0x1EB8, lower: [], title: [], upper: [], fold: 0x1EB9, foldFull: [ 0x1EB9 ] }
  , { code: 0x1EBA, lower: [], title: [], upper: [], fold: 0x1EBB, foldFull: [ 0x1EBB ] }
  , { code: 0x1EBC, lower: [], title: [], upper: [], fold: 0x1EBD, foldFull: [ 0x1EBD ] }
  , { code: 0x1EBE, lower: [], title: [], upper: [], fold: 0x1EBF, foldFull: [ 0x1EBF ] }
  , { code: 0x1EC0, lower: [], title: [], upper: [], fold: 0x1EC1, foldFull: [ 0x1EC1 ] }
  , { code: 0x1EC2, lower: [], title: [], upper: [], fold: 0x1EC3, foldFull: [ 0x1EC3 ] }
  , { code: 0x1EC4, lower: [], title: [], upper: [], fold: 0x1EC5, foldFull: [ 0x1EC5 ] }
  , { code: 0x1EC6, lower: [], title: [], upper: [], fold: 0x1EC7, foldFull: [ 0x1EC7 ] }
  , { code: 0x1EC8, lower: [], title: [], upper: [], fold: 0x1EC9, foldFull: [ 0x1EC9 ] }
  , { code: 0x1ECA, lower: [], title: [], upper: [], fold: 0x1ECB, foldFull: [ 0x1ECB ] }
  , { code: 0x1ECC, lower: [], title: [], upper: [], fold: 0x1ECD, foldFull: [ 0x1ECD ] }
  , { code: 0x1ECE, lower: [], title: [], upper: [], fold: 0x1ECF, foldFull: [ 0x1ECF ] }
  , { code: 0x1ED0, lower: [], title: [], upper: [], fold: 0x1ED1, foldFull: [ 0x1ED1 ] }
  , { code: 0x1ED2, lower: [], title: [], upper: [], fold: 0x1ED3, foldFull: [ 0x1ED3 ] }
  , { code: 0x1ED4, lower: [], title: [], upper: [], fold: 0x1ED5, foldFull: [ 0x1ED5 ] }
  , { code: 0x1ED6, lower: [], title: [], upper: [], fold: 0x1ED7, foldFull: [ 0x1ED7 ] }
  , { code: 0x1ED8, lower: [], title: [], upper: [], fold: 0x1ED9, foldFull: [ 0x1ED9 ] }
  , { code: 0x1EDA, lower: [], title: [], upper: [], fold: 0x1EDB, foldFull: [ 0x1EDB ] }
  , { code: 0x1EDC, lower: [], title: [], upper: [], fold: 0x1EDD, foldFull: [ 0x1EDD ] }
  , { code: 0x1EDE, lower: [], title: [], upper: [], fold: 0x1EDF, foldFull: [ 0x1EDF ] }
  , { code: 0x1EE0, lower: [], title: [], upper: [], fold: 0x1EE1, foldFull: [ 0x1EE1 ] }
  , { code: 0x1EE2, lower: [], title: [], upper: [], fold: 0x1EE3, foldFull: [ 0x1EE3 ] }
  , { code: 0x1EE4, lower: [], title: [], upper: [], fold: 0x1EE5, foldFull: [ 0x1EE5 ] }
  , { code: 0x1EE6, lower: [], title: [], upper: [], fold: 0x1EE7, foldFull: [ 0x1EE7 ] }
  , { code: 0x1EE8, lower: [], title: [], upper: [], fold: 0x1EE9, foldFull: [ 0x1EE9 ] }
  , { code: 0x1EEA, lower: [], title: [], upper: [], fold: 0x1EEB, foldFull: [ 0x1EEB ] }
  , { code: 0x1EEC, lower: [], title: [], upper: [], fold: 0x1EED, foldFull: [ 0x1EED ] }
  , { code: 0x1EEE, lower: [], title: [], upper: [], fold: 0x1EEF, foldFull: [ 0x1EEF ] }
  , { code: 0x1EF0, lower: [], title: [], upper: [], fold: 0x1EF1, foldFull: [ 0x1EF1 ] }
  , { code: 0x1EF2, lower: [], title: [], upper: [], fold: 0x1EF3, foldFull: [ 0x1EF3 ] }
  , { code: 0x1EF4, lower: [], title: [], upper: [], fold: 0x1EF5, foldFull: [ 0x1EF5 ] }
  , { code: 0x1EF6, lower: [], title: [], upper: [], fold: 0x1EF7, foldFull: [ 0x1EF7 ] }
  , { code: 0x1EF8, lower: [], title: [], upper: [], fold: 0x1EF9, foldFull: [ 0x1EF9 ] }
  , { code: 0x1EFA, lower: [], title: [], upper: [], fold: 0x1EFB, foldFull: [ 0x1EFB ] }
  , { code: 0x1EFC, lower: [], title: [], upper: [], fold: 0x1EFD, foldFull: [ 0x1EFD ] }
  , { code: 0x1EFE, lower: [], title: [], upper: [], fold: 0x1EFF, foldFull: [ 0x1EFF ] }
  , { code: 0x1F08, lower: [], title: [], upper: [], fold: 0x1F00, foldFull: [ 0x1F00 ] }
  , { code: 0x1F09, lower: [], title: [], upper: [], fold: 0x1F01, foldFull: [ 0x1F01 ] }
  , { code: 0x1F0A, lower: [], title: [], upper: [], fold: 0x1F02, foldFull: [ 0x1F02 ] }
  , { code: 0x1F0B, lower: [], title: [], upper: [], fold: 0x1F03, foldFull: [ 0x1F03 ] }
  , { code: 0x1F0C, lower: [], title: [], upper: [], fold: 0x1F04, foldFull: [ 0x1F04 ] }
  , { code: 0x1F0D, lower: [], title: [], upper: [], fold: 0x1F05, foldFull: [ 0x1F05 ] }
  , { code: 0x1F0E, lower: [], title: [], upper: [], fold: 0x1F06, foldFull: [ 0x1F06 ] }
  , { code: 0x1F0F, lower: [], title: [], upper: [], fold: 0x1F07, foldFull: [ 0x1F07 ] }
  , { code: 0x1F18, lower: [], title: [], upper: [], fold: 0x1F10, foldFull: [ 0x1F10 ] }
  , { code: 0x1F19, lower: [], title: [], upper: [], fold: 0x1F11, foldFull: [ 0x1F11 ] }
  , { code: 0x1F1A, lower: [], title: [], upper: [], fold: 0x1F12, foldFull: [ 0x1F12 ] }
  , { code: 0x1F1B, lower: [], title: [], upper: [], fold: 0x1F13, foldFull: [ 0x1F13 ] }
  , { code: 0x1F1C, lower: [], title: [], upper: [], fold: 0x1F14, foldFull: [ 0x1F14 ] }
  , { code: 0x1F1D, lower: [], title: [], upper: [], fold: 0x1F15, foldFull: [ 0x1F15 ] }
  , { code: 0x1F28, lower: [], title: [], upper: [], fold: 0x1F20, foldFull: [ 0x1F20 ] }
  , { code: 0x1F29, lower: [], title: [], upper: [], fold: 0x1F21, foldFull: [ 0x1F21 ] }
  , { code: 0x1F2A, lower: [], title: [], upper: [], fold: 0x1F22, foldFull: [ 0x1F22 ] }
  , { code: 0x1F2B, lower: [], title: [], upper: [], fold: 0x1F23, foldFull: [ 0x1F23 ] }
  , { code: 0x1F2C, lower: [], title: [], upper: [], fold: 0x1F24, foldFull: [ 0x1F24 ] }
  , { code: 0x1F2D, lower: [], title: [], upper: [], fold: 0x1F25, foldFull: [ 0x1F25 ] }
  , { code: 0x1F2E, lower: [], title: [], upper: [], fold: 0x1F26, foldFull: [ 0x1F26 ] }
  , { code: 0x1F2F, lower: [], title: [], upper: [], fold: 0x1F27, foldFull: [ 0x1F27 ] }
  , { code: 0x1F38, lower: [], title: [], upper: [], fold: 0x1F30, foldFull: [ 0x1F30 ] }
  , { code: 0x1F39, lower: [], title: [], upper: [], fold: 0x1F31, foldFull: [ 0x1F31 ] }
  , { code: 0x1F3A, lower: [], title: [], upper: [], fold: 0x1F32, foldFull: [ 0x1F32 ] }
  , { code: 0x1F3B, lower: [], title: [], upper: [], fold: 0x1F33, foldFull: [ 0x1F33 ] }
  , { code: 0x1F3C, lower: [], title: [], upper: [], fold: 0x1F34, foldFull: [ 0x1F34 ] }
  , { code: 0x1F3D, lower: [], title: [], upper: [], fold: 0x1F35, foldFull: [ 0x1F35 ] }
  , { code: 0x1F3E, lower: [], title: [], upper: [], fold: 0x1F36, foldFull: [ 0x1F36 ] }
  , { code: 0x1F3F, lower: [], title: [], upper: [], fold: 0x1F37, foldFull: [ 0x1F37 ] }
  , { code: 0x1F48, lower: [], title: [], upper: [], fold: 0x1F40, foldFull: [ 0x1F40 ] }
  , { code: 0x1F49, lower: [], title: [], upper: [], fold: 0x1F41, foldFull: [ 0x1F41 ] }
  , { code: 0x1F4A, lower: [], title: [], upper: [], fold: 0x1F42, foldFull: [ 0x1F42 ] }
  , { code: 0x1F4B, lower: [], title: [], upper: [], fold: 0x1F43, foldFull: [ 0x1F43 ] }
  , { code: 0x1F4C, lower: [], title: [], upper: [], fold: 0x1F44, foldFull: [ 0x1F44 ] }
  , { code: 0x1F4D, lower: [], title: [], upper: [], fold: 0x1F45, foldFull: [ 0x1F45 ] }
  , { code: 0x1F50, lower: [ 0x1F50 ], title: [ 0x03A5, 0x0313 ], upper: [ 0x03A5, 0x0313 ], fold: 0, foldFull: [ 0x03C5, 0x0313 ] }
  , { code: 0x1F50, lower: [ 0x1F50 ], title: [ 0x03A5, 0x0313 ], upper: [ 0x03A5, 0x0313 ], fold: 0, foldFull: [ 0x03C5, 0x0313 ] }
  , { code: 0x1F52, lower: [ 0x1F52 ], title: [ 0x03A5, 0x0313, 0x0300 ], upper: [ 0x03A5, 0x0313, 0x0300 ], fold: 0, foldFull: [ 0x03C5, 0x0313, 0x0300 ] }
  , { code: 0x1F52, lower: [ 0x1F52 ], title: [ 0x03A5, 0x0313, 0x0300 ], upper: [ 0x03A5, 0x0313, 0x0300 ], fold: 0, foldFull: [ 0x03C5, 0x0313, 0x0300 ] }
  , { code: 0x1F54, lower: [ 0x1F54 ], title: [ 0x03A5, 0x0313, 0x0301 ], upper: [ 0x03A5, 0x0313, 0x0301 ], fold: 0, foldFull: [ 0x03C5, 0x0313, 0x0301 ] }
  , { code: 0x1F54, lower: [ 0x1F54 ], title: [ 0x03A5, 0x0313, 0x0301 ], upper: [ 0x03A5, 0x0313, 0x0301 ], fold: 0, foldFull: [ 0x03C5, 0x0313, 0x0301 ] }
  , { code: 0x1F56, lower: [ 0x1F56 ], title: [ 0x03A5, 0x0313, 0x0342 ], upper: [ 0x03A5, 0x0313, 0x0342 ], fold: 0, foldFull: [ 0x03C5, 0x0313, 0x0342 ] }
  , { code: 0x1F56, lower: [ 0x1F56 ], title: [ 0x03A5, 0x0313, 0x0342 ], upper: [ 0x03A5, 0x0313, 0x0342 ], fold: 0, foldFull: [ 0x03C5, 0x0313, 0x0342 ] }
  , { code: 0x1F59, lower: [], title: [], upper: [], fold: 0x1F51, foldFull: [ 0x1F51 ] }
  , { code: 0x1F5B, lower: [], title: [], upper: [], fold: 0x1F53, foldFull: [ 0x1F53 ] }
  , { code: 0x1F5D, lower: [], title: [], upper: [], fold: 0x1F55, foldFull: [ 0x1F55 ] }
  , { code: 0x1F5F, lower: [], title: [], upper: [], fold: 0x1F57, foldFull: [ 0x1F57 ] }
  , { code: 0x1F68, lower: [], title: [], upper: [], fold: 0x1F60, foldFull: [ 0x1F60 ] }
  , { code: 0x1F69, lower: [], title: [], upper: [], fold: 0x1F61, foldFull: [ 0x1F61 ] }
  , { code: 0x1F6A, lower: [], title: [], upper: [], fold: 0x1F62, foldFull: [ 0x1F62 ] }
  , { code: 0x1F6B, lower: [], title: [], upper: [], fold: 0x1F63, foldFull: [ 0x1F63 ] }
  , { code: 0x1F6C, lower: [], title: [], upper: [], fold: 0x1F64, foldFull: [ 0x1F64 ] }
  , { code: 0x1F6D, lower: [], title: [], upper: [], fold: 0x1F65, foldFull: [ 0x1F65 ] }
  , { code: 0x1F6E, lower: [], title: [], upper: [], fold: 0x1F66, foldFull: [ 0x1F66 ] }
  , { code: 0x1F6F, lower: [], title: [], upper: [], fold: 0x1F67, foldFull: [ 0x1F67 ] }
  , { code: 0x1F80, lower: [ 0x1F80 ], title: [ 0x1F88 ], upper: [ 0x1F08, 0x0399 ], fold: 0, foldFull: [ 0x1F00, 0x03B9 ] }
  , { code: 0x1F80, lower: [ 0x1F80 ], title: [ 0x1F88 ], upper: [ 0x1F08, 0x0399 ], fold: 0, foldFull: [ 0x1F00, 0x03B9 ] }
  , { code: 0x1F81, lower: [ 0x1F81 ], title: [ 0x1F89 ], upper: [ 0x1F09, 0x0399 ], fold: 0, foldFull: [ 0x1F01, 0x03B9 ] }
  , { code: 0x1F81, lower: [ 0x1F81 ], title: [ 0x1F89 ], upper: [ 0x1F09, 0x0399 ], fold: 0, foldFull: [ 0x1F01, 0x03B9 ] }
  , { code: 0x1F82, lower: [ 0x1F82 ], title: [ 0x1F8A ], upper: [ 0x1F0A, 0x0399 ], fold: 0, foldFull: [ 0x1F02, 0x03B9 ] }
  , { code: 0x1F82, lower: [ 0x1F82 ], title: [ 0x1F8A ], upper: [ 0x1F0A, 0x0399 ], fold: 0, foldFull: [ 0x1F02, 0x03B9 ] }
  , { code: 0x1F83, lower: [ 0x1F83 ], title: [ 0x1F8B ], upper: [ 0x1F0B, 0x0399 ], fold: 0, foldFull: [ 0x1F03, 0x03B9 ] }
  , { code: 0x1F83, lower: [ 0x1F83 ], title: [ 0x1F8B ], upper: [ 0x1F0B, 0x0399 ], fold: 0, foldFull: [ 0x1F03, 0x03B9 ] }
  , { code: 0x1F84, lower: [ 0x1F84 ], title: [ 0x1F8C ], upper: [ 0x1F0C, 0x0399 ], fold: 0, foldFull: [ 0x1F04, 0x03B9 ] }
  , { code: 0x1F84, lower: [ 0x1F84 ], title: [ 0x1F8C ], upper: [ 0x1F0C, 0x0399 ], fold: 0, foldFull: [ 0x1F04, 0x03B9 ] }
  , { code: 0x1F85, lower: [ 0x1F85 ], title: [ 0x1F8D ], upper: [ 0x1F0D, 0x0399 ], fold: 0, foldFull: [ 0x1F05, 0x03B9 ] }
  , { code: 0x1F85, lower: [ 0x1F85 ], title: [ 0x1F8D ], upper: [ 0x1F0D, 0x0399 ], fold: 0, foldFull: [ 0x1F05, 0x03B9 ] }
  , { code: 0x1F86, lower: [ 0x1F86 ], title: [ 0x1F8E ], upper: [ 0x1F0E, 0x0399 ], fold: 0, foldFull: [ 0x1F06, 0x03B9 ] }
  , { code: 0x1F86, lower: [ 0x1F86 ], title: [ 0x1F8E ], upper: [ 0x1F0E, 0x0399 ], fold: 0, foldFull: [ 0x1F06, 0x03B9 ] }
  , { code: 0x1F87, lower: [ 0x1F87 ], title: [ 0x1F8F ], upper: [ 0x1F0F, 0x0399 ], fold: 0, foldFull: [ 0x1F07, 0x03B9 ] }
  , { code: 0x1F87, lower: [ 0x1F87 ], title: [ 0x1F8F ], upper: [ 0x1F0F, 0x0399 ], fold: 0, foldFull: [ 0x1F07, 0x03B9 ] }
  , { code: 0x1F88, lower: [ 0x1F80 ], title: [ 0x1F88 ], upper: [ 0x1F08, 0x0399 ], fold: 0x1F80, foldFull: [ 0x1F00, 0x03B9 ] }
  , { code: 0x1F88, lower: [ 0x1F80 ], title: [ 0x1F88 ], upper: [ 0x1F08, 0x0399 ], fold: 0x1F80, foldFull: [ 0x1F00, 0x03B9 ] }
  , { code: 0x1F89, lower: [ 0x1F81 ], title: [ 0x1F89 ], upper: [ 0x1F09, 0x0399 ], fold: 0x1F81, foldFull: [ 0x1F01, 0x03B9 ] }
  , { code: 0x1F89, lower: [ 0x1F81 ], title: [ 0x1F89 ], upper: [ 0x1F09, 0x0399 ], fold: 0x1F81, foldFull: [ 0x1F01, 0x03B9 ] }
  , { code: 0x1F8A, lower: [ 0x1F82 ], title: [ 0x1F8A ], upper: [ 0x1F0A, 0x0399 ], fold: 0x1F82, foldFull: [ 0x1F02, 0x03B9 ] }
  , { code: 0x1F8A, lower: [ 0x1F82 ], title: [ 0x1F8A ], upper: [ 0x1F0A, 0x0399 ], fold: 0x1F82, foldFull: [ 0x1F02, 0x03B9 ] }
  , { code: 0x1F8B, lower: [ 0x1F83 ], title: [ 0x1F8B ], upper: [ 0x1F0B, 0x0399 ], fold: 0x1F83, foldFull: [ 0x1F03, 0x03B9 ] }
  , { code: 0x1F8B, lower: [ 0x1F83 ], title: [ 0x1F8B ], upper: [ 0x1F0B, 0x0399 ], fold: 0x1F83, foldFull: [ 0x1F03, 0x03B9 ] }
  , { code: 0x1F8C, lower: [ 0x1F84 ], title: [ 0x1F8C ], upper: [ 0x1F0C, 0x0399 ], fold: 0x1F84, foldFull: [ 0x1F04, 0x03B9 ] }
  , { code: 0x1F8C, lower: [ 0x1F84 ], title: [ 0x1F8C ], upper: [ 0x1F0C, 0x0399 ], fold: 0x1F84, foldFull: [ 0x1F04, 0x03B9 ] }
  , { code: 0x1F8D, lower: [ 0x1F85 ], title: [ 0x1F8D ], upper: [ 0x1F0D, 0x0399 ], fold: 0x1F85, foldFull: [ 0x1F05, 0x03B9 ] }
  , { code: 0x1F8D, lower: [ 0x1F85 ], title: [ 0x1F8D ], upper: [ 0x1F0D, 0x0399 ], fold: 0x1F85, foldFull: [ 0x1F05, 0x03B9 ] }
  , { code: 0x1F8E, lower: [ 0x1F86 ], title: [ 0x1F8E ], upper: [ 0x1F0E, 0x0399 ], fold: 0x1F86, foldFull: [ 0x1F06, 0x03B9 ] }
  , { code: 0x1F8E, lower: [ 0x1F86 ], title: [ 0x1F8E ], upper: [ 0x1F0E, 0x0399 ], fold: 0x1F86, foldFull: [ 0x1F06, 0x03B9 ] }
  , { code: 0x1F8F, lower: [ 0x1F87 ], title: [ 0x1F8F ], upper: [ 0x1F0F, 0x0399 ], fold: 0x1F87, foldFull: [ 0x1F07, 0x03B9 ] }
  , { code: 0x1F8F, lower: [ 0x1F87 ], title: [ 0x1F8F ], upper: [ 0x1F0F, 0x0399 ], fold: 0x1F87, foldFull: [ 0x1F07, 0x03B9 ] }
  , { code: 0x1F90, lower: [ 0x1F90 ], title: [ 0x1F98 ], upper: [ 0x1F28, 0x0399 ], fold: 0, foldFull: [ 0x1F20, 0x03B9 ] }
  , { code: 0x1F90, lower: [ 0x1F90 ], title: [ 0x1F98 ], upper: [ 0x1F28, 0x0399 ], fold: 0, foldFull: [ 0x1F20, 0x03B9 ] }
  , { code: 0x1F91, lower: [ 0x1F91 ], title: [ 0x1F99 ], upper: [ 0x1F29, 0x0399 ], fold: 0, foldFull: [ 0x1F21, 0x03B9 ] }
  , { code: 0x1F91, lower: [ 0x1F91 ], title: [ 0x1F99 ], upper: [ 0x1F29, 0x0399 ], fold: 0, foldFull: [ 0x1F21, 0x03B9 ] }
  , { code: 0x1F92, lower: [ 0x1F92 ], title: [ 0x1F9A ], upper: [ 0x1F2A, 0x0399 ], fold: 0, foldFull: [ 0x1F22, 0x03B9 ] }
  , { code: 0x1F92, lower: [ 0x1F92 ], title: [ 0x1F9A ], upper: [ 0x1F2A, 0x0399 ], fold: 0, foldFull: [ 0x1F22, 0x03B9 ] }
  , { code: 0x1F93, lower: [ 0x1F93 ], title: [ 0x1F9B ], upper: [ 0x1F2B, 0x0399 ], fold: 0, foldFull: [ 0x1F23, 0x03B9 ] }
  , { code: 0x1F93, lower: [ 0x1F93 ], title: [ 0x1F9B ], upper: [ 0x1F2B, 0x0399 ], fold: 0, foldFull: [ 0x1F23, 0x03B9 ] }
  , { code: 0x1F94, lower: [ 0x1F94 ], title: [ 0x1F9C ], upper: [ 0x1F2C, 0x0399 ], fold: 0, foldFull: [ 0x1F24, 0x03B9 ] }
  , { code: 0x1F94, lower: [ 0x1F94 ], title: [ 0x1F9C ], upper: [ 0x1F2C, 0x0399 ], fold: 0, foldFull: [ 0x1F24, 0x03B9 ] }
  , { code: 0x1F95, lower: [ 0x1F95 ], title: [ 0x1F9D ], upper: [ 0x1F2D, 0x0399 ], fold: 0, foldFull: [ 0x1F25, 0x03B9 ] }
  , { code: 0x1F95, lower: [ 0x1F95 ], title: [ 0x1F9D ], upper: [ 0x1F2D, 0x0399 ], fold: 0, foldFull: [ 0x1F25, 0x03B9 ] }
  , { code: 0x1F96, lower: [ 0x1F96 ], title: [ 0x1F9E ], upper: [ 0x1F2E, 0x0399 ], fold: 0, foldFull: [ 0x1F26, 0x03B9 ] }
  , { code: 0x1F96, lower: [ 0x1F96 ], title: [ 0x1F9E ], upper: [ 0x1F2E, 0x0399 ], fold: 0, foldFull: [ 0x1F26, 0x03B9 ] }
  , { code: 0x1F97, lower: [ 0x1F97 ], title: [ 0x1F9F ], upper: [ 0x1F2F, 0x0399 ], fold: 0, foldFull: [ 0x1F27, 0x03B9 ] }
  , { code: 0x1F97, lower: [ 0x1F97 ], title: [ 0x1F9F ], upper: [ 0x1F2F, 0x0399 ], fold: 0, foldFull: [ 0x1F27, 0x03B9 ] }
  , { code: 0x1F98, lower: [ 0x1F90 ], title: [ 0x1F98 ], upper: [ 0x1F28, 0x0399 ], fold: 0x1F90, foldFull: [ 0x1F20, 0x03B9 ] }
  , { code: 0x1F98, lower: [ 0x1F90 ], title: [ 0x1F98 ], upper: [ 0x1F28, 0x0399 ], fold: 0x1F90, foldFull: [ 0x1F20, 0x03B9 ] }
  , { code: 0x1F99, lower: [ 0x1F91 ], title: [ 0x1F99 ], upper: [ 0x1F29, 0x0399 ], fold: 0x1F91, foldFull: [ 0x1F21, 0x03B9 ] }
  , { code: 0x1F99, lower: [ 0x1F91 ], title: [ 0x1F99 ], upper: [ 0x1F29, 0x0399 ], fold: 0x1F91, foldFull: [ 0x1F21, 0x03B9 ] }
  , { code: 0x1F9A, lower: [ 0x1F92 ], title: [ 0x1F9A ], upper: [ 0x1F2A, 0x0399 ], fold: 0x1F92, foldFull: [ 0x1F22, 0x03B9 ] }
  , { code: 0x1F9A, lower: [ 0x1F92 ], title: [ 0x1F9A ], upper: [ 0x1F2A, 0x0399 ], fold: 0x1F92, foldFull: [ 0x1F22, 0x03B9 ] }
  , { code: 0x1F9B, lower: [ 0x1F93 ], title: [ 0x1F9B ], upper: [ 0x1F2B, 0x0399 ], fold: 0x1F93, foldFull: [ 0x1F23, 0x03B9 ] }
  , { code: 0x1F9B, lower: [ 0x1F93 ], title: [ 0x1F9B ], upper: [ 0x1F2B, 0x0399 ], fold: 0x1F93, foldFull: [ 0x1F23, 0x03B9 ] }
  , { code: 0x1F9C, lower: [ 0x1F94 ], title: [ 0x1F9C ], upper: [ 0x1F2C, 0x0399 ], fold: 0x1F94, foldFull: [ 0x1F24, 0x03B9 ] }
  , { code: 0x1F9C, lower: [ 0x1F94 ], title: [ 0x1F9C ], upper: [ 0x1F2C, 0x0399 ], fold: 0x1F94, foldFull: [ 0x1F24, 0x03B9 ] }
  , { code: 0x1F9D, lower: [ 0x1F95 ], title: [ 0x1F9D ], upper: [ 0x1F2D, 0x0399 ], fold: 0x1F95, foldFull: [ 0x1F25, 0x03B9 ] }
  , { code: 0x1F9D, lower: [ 0x1F95 ], title: [ 0x1F9D ], upper: [ 0x1F2D, 0x0399 ], fold: 0x1F95, foldFull: [ 0x1F25, 0x03B9 ] }
  , { code: 0x1F9E, lower: [ 0x1F96 ], title: [ 0x1F9E ], upper: [ 0x1F2E, 0x0399 ], fold: 0x1F96, foldFull: [ 0x1F26, 0x03B9 ] }
  , { code: 0x1F9E, lower: [ 0x1F96 ], title: [ 0x1F9E ], upper: [ 0x1F2E, 0x0399 ], fold: 0x1F96, foldFull: [ 0x1F26, 0x03B9 ] }
  , { code: 0x1F9F, lower: [ 0x1F97 ], title: [ 0x1F9F ], upper: [ 0x1F2F, 0x0399 ], fold: 0x1F97, foldFull: [ 0x1F27, 0x03B9 ] }
  , { code: 0x1F9F, lower: [ 0x1F97 ], title: [ 0x1F9F ], upper: [ 0x1F2F, 0x0399 ], fold: 0x1F97, foldFull: [ 0x1F27, 0x03B9 ] }
  , { code: 0x1FA0, lower: [ 0x1FA0 ], title: [ 0x1FA8 ], upper: [ 0x1F68, 0x0399 ], fold: 0, foldFull: [ 0x1F60, 0x03B9 ] }
  , { code: 0x1FA0, lower: [ 0x1FA0 ], title: [ 0x1FA8 ], upper: [ 0x1F68, 0x0399 ], fold: 0, foldFull: [ 0x1F60, 0x03B9 ] }
  , { code: 0x1FA1, lower: [ 0x1FA1 ], title: [ 0x1FA9 ], upper: [ 0x1F69, 0x0399 ], fold: 0, foldFull: [ 0x1F61, 0x03B9 ] }
  , { code: 0x1FA1, lower: [ 0x1FA1 ], title: [ 0x1FA9 ], upper: [ 0x1F69, 0x0399 ], fold: 0, foldFull: [ 0x1F61, 0x03B9 ] }
  , { code: 0x1FA2, lower: [ 0x1FA2 ], title: [ 0x1FAA ], upper: [ 0x1F6A, 0x0399 ], fold: 0, foldFull: [ 0x1F62, 0x03B9 ] }
  , { code: 0x1FA2, lower: [ 0x1FA2 ], title: [ 0x1FAA ], upper: [ 0x1F6A, 0x0399 ], fold: 0, foldFull: [ 0x1F62, 0x03B9 ] }
  , { code: 0x1FA3, lower: [ 0x1FA3 ], title: [ 0x1FAB ], upper: [ 0x1F6B, 0x0399 ], fold: 0, foldFull: [ 0x1F63, 0x03B9 ] }
  , { code: 0x1FA3, lower: [ 0x1FA3 ], title: [ 0x1FAB ], upper: [ 0x1F6B, 0x0399 ], fold: 0, foldFull: [ 0x1F63, 0x03B9 ] }
  , { code: 0x1FA4, lower: [ 0x1FA4 ], title: [ 0x1FAC ], upper: [ 0x1F6C, 0x0399 ], fold: 0, foldFull: [ 0x1F64, 0x03B9 ] }
  , { code: 0x1FA4, lower: [ 0x1FA4 ], title: [ 0x1FAC ], upper: [ 0x1F6C, 0x0399 ], fold: 0, foldFull: [ 0x1F64, 0x03B9 ] }
  , { code: 0x1FA5, lower: [ 0x1FA5 ], title: [ 0x1FAD ], upper: [ 0x1F6D, 0x0399 ], fold: 0, foldFull: [ 0x1F65, 0x03B9 ] }
  , { code: 0x1FA5, lower: [ 0x1FA5 ], title: [ 0x1FAD ], upper: [ 0x1F6D, 0x0399 ], fold: 0, foldFull: [ 0x1F65, 0x03B9 ] }
  , { code: 0x1FA6, lower: [ 0x1FA6 ], title: [ 0x1FAE ], upper: [ 0x1F6E, 0x0399 ], fold: 0, foldFull: [ 0x1F66, 0x03B9 ] }
  , { code: 0x1FA6, lower: [ 0x1FA6 ], title: [ 0x1FAE ], upper: [ 0x1F6E, 0x0399 ], fold: 0, foldFull: [ 0x1F66, 0x03B9 ] }
  , { code: 0x1FA7, lower: [ 0x1FA7 ], title: [ 0x1FAF ], upper: [ 0x1F6F, 0x0399 ], fold: 0, foldFull: [ 0x1F67, 0x03B9 ] }
  , { code: 0x1FA7, lower: [ 0x1FA7 ], title: [ 0x1FAF ], upper: [ 0x1F6F, 0x0399 ], fold: 0, foldFull: [ 0x1F67, 0x03B9 ] }
  , { code: 0x1FA8, lower: [ 0x1FA0 ], title: [ 0x1FA8 ], upper: [ 0x1F68, 0x0399 ], fold: 0x1FA0, foldFull: [ 0x1F60, 0x03B9 ] }
  , { code: 0x1FA8, lower: [ 0x1FA0 ], title: [ 0x1FA8 ], upper: [ 0x1F68, 0x0399 ], fold: 0x1FA0, foldFull: [ 0x1F60, 0x03B9 ] }
  , { code: 0x1FA9, lower: [ 0x1FA1 ], title: [ 0x1FA9 ], upper: [ 0x1F69, 0x0399 ], fold: 0x1FA1, foldFull: [ 0x1F61, 0x03B9 ] }
  , { code: 0x1FA9, lower: [ 0x1FA1 ], title: [ 0x1FA9 ], upper: [ 0x1F69, 0x0399 ], fold: 0x1FA1, foldFull: [ 0x1F61, 0x03B9 ] }
  , { code: 0x1FAA, lower: [ 0x1FA2 ], title: [ 0x1FAA ], upper: [ 0x1F6A, 0x0399 ], fold: 0x1FA2, foldFull: [ 0x1F62, 0x03B9 ] }
  , { code: 0x1FAA, lower: [ 0x1FA2 ], title: [ 0x1FAA ], upper: [ 0x1F6A, 0x0399 ], fold: 0x1FA2, foldFull: [ 0x1F62, 0x03B9 ] }
  , { code: 0x1FAB, lower: [ 0x1FA3 ], title: [ 0x1FAB ], upper: [ 0x1F6B, 0x0399 ], fold: 0x1FA3, foldFull: [ 0x1F63, 0x03B9 ] }
  , { code: 0x1FAB, lower: [ 0x1FA3 ], title: [ 0x1FAB ], upper: [ 0x1F6B, 0x0399 ], fold: 0x1FA3, foldFull: [ 0x1F63, 0x03B9 ] }
  , { code: 0x1FAC, lower: [ 0x1FA4 ], title: [ 0x1FAC ], upper: [ 0x1F6C, 0x0399 ], fold: 0x1FA4, foldFull: [ 0x1F64, 0x03B9 ] }
  , { code: 0x1FAC, lower: [ 0x1FA4 ], title: [ 0x1FAC ], upper: [ 0x1F6C, 0x0399 ], fold: 0x1FA4, foldFull: [ 0x1F64, 0x03B9 ] }
  , { code: 0x1FAD, lower: [ 0x1FA5 ], title: [ 0x1FAD ], upper: [ 0x1F6D, 0x0399 ], fold: 0x1FA5, foldFull: [ 0x1F65, 0x03B9 ] }
  , { code: 0x1FAD, lower: [ 0x1FA5 ], title: [ 0x1FAD ], upper: [ 0x1F6D, 0x0399 ], fold: 0x1FA5, foldFull: [ 0x1F65, 0x03B9 ] }
  , { code: 0x1FAE, lower: [ 0x1FA6 ], title: [ 0x1FAE ], upper: [ 0x1F6E, 0x0399 ], fold: 0x1FA6, foldFull: [ 0x1F66, 0x03B9 ] }
  , { code: 0x1FAE, lower: [ 0x1FA6 ], title: [ 0x1FAE ], upper: [ 0x1F6E, 0x0399 ], fold: 0x1FA6, foldFull: [ 0x1F66, 0x03B9 ] }
  , { code: 0x1FAF, lower: [ 0x1FA7 ], title: [ 0x1FAF ], upper: [ 0x1F6F, 0x0399 ], fold: 0x1FA7, foldFull: [ 0x1F67, 0x03B9 ] }
  , { code: 0x1FAF, lower: [ 0x1FA7 ], title: [ 0x1FAF ], upper: [ 0x1F6F, 0x0399 ], fold: 0x1FA7, foldFull: [ 0x1F67, 0x03B9 ] }
  , { code: 0x1FB2, lower: [ 0x1FB2 ], title: [ 0x1FBA, 0x0345 ], upper: [ 0x1FBA, 0x0399 ], fold: 0, foldFull: [ 0x1F70, 0x03B9 ] }
  , { code: 0x1FB2, lower: [ 0x1FB2 ], title: [ 0x1FBA, 0x0345 ], upper: [ 0x1FBA, 0x0399 ], fold: 0, foldFull: [ 0x1F70, 0x03B9 ] }
  , { code: 0x1FB3, lower: [ 0x1FB3 ], title: [ 0x1FBC ], upper: [ 0x0391, 0x0399 ], fold: 0, foldFull: [ 0x03B1, 0x03B9 ] }
  , { code: 0x1FB3, lower: [ 0x1FB3 ], title: [ 0x1FBC ], upper: [ 0x0391, 0x0399 ], fold: 0, foldFull: [ 0x03B1, 0x03B9 ] }
  , { code: 0x1FB4, lower: [ 0x1FB4 ], title: [ 0x0386, 0x0345 ], upper: [ 0x0386, 0x0399 ], fold: 0, foldFull: [ 0x03AC, 0x03B9 ] }
  , { code: 0x1FB4, lower: [ 0x1FB4 ], title: [ 0x0386, 0x0345 ], upper: [ 0x0386, 0x0399 ], fold: 0, foldFull: [ 0x03AC, 0x03B9 ] }
  , { code: 0x1FB6, lower: [ 0x1FB6 ], title: [ 0x0391, 0x0342 ], upper: [ 0x0391, 0x0342 ], fold: 0, foldFull: [ 0x03B1, 0x0342 ] }
  , { code: 0x1FB6, lower: [ 0x1FB6 ], title: [ 0x0391, 0x0342 ], upper: [ 0x0391, 0x0342 ], fold: 0, foldFull: [ 0x03B1, 0x0342 ] }
  , { code: 0x1FB7, lower: [ 0x1FB7 ], title: [ 0x0391, 0x0342, 0x0345 ], upper: [ 0x0391, 0x0342, 0x0399 ], fold: 0, foldFull: [ 0x03B1, 0x0342, 0x03B9 ] }
  , { code: 0x1FB7, lower: [ 0x1FB7 ], title: [ 0x0391, 0x0342, 0x0345 ], upper: [ 0x0391, 0x0342, 0x0399 ], fold: 0, foldFull: [ 0x03B1, 0x0342, 0x03B9 ] }
  , { code: 0x1FB8, lower: [], title: [], upper: [], fold: 0x1FB0, foldFull: [ 0x1FB0 ] }
  , { code: 0x1FB9, lower: [], title: [], upper: [], fold: 0x1FB1, foldFull: [ 0x1FB1 ] }
  , { code: 0x1FBA, lower: [], title: [], upper: [], fold: 0x1F70, foldFull: [ 0x1F70 ] }
  , { code: 0x1FBB, lower: [], title: [], upper: [], fold: 0x1F71, foldFull: [ 0x1F71 ] }
  , { code: 0x1FBC, lower: [ 0x1FB3 ], title: [ 0x1FBC ], upper: [ 0x0391, 0x0399 ], fold: 0x1FB3, foldFull: [ 0x03B1, 0x03B9 ] }
  , { code: 0x1FBC, lower: [ 0x1FB3 ], title: [ 0x1FBC ], upper: [ 0x0391, 0x0399 ], fold: 0x1FB3, foldFull: [ 0x03B1, 0x03B9 ] }
  , { code: 0x1FBE, lower: [], title: [], upper: [], fold: 0x03B9, foldFull: [ 0x03B9 ] }
  , { code: 0x1FC2, lower: [ 0x1FC2 ], title: [ 0x1FCA, 0x0345 ], upper: [ 0x1FCA, 0x0399 ], fold: 0, foldFull: [ 0x1F74, 0x03B9 ] }
  , { code: 0x1FC2, lower: [ 0x1FC2 ], title: [ 0x1FCA, 0x0345 ], upper: [ 0x1FCA, 0x0399 ], fold: 0, foldFull: [ 0x1F74, 0x03B9 ] }
  , { code: 0x1FC3, lower: [ 0x1FC3 ], title: [ 0x1FCC ], upper: [ 0x0397, 0x0399 ], fold: 0, foldFull: [ 0x03B7, 0x03B9 ] }
  , { code: 0x1FC3, lower: [ 0x1FC3 ], title: [ 0x1FCC ], upper: [ 0x0397, 0x0399 ], fold: 0, foldFull: [ 0x03B7, 0x03B9 ] }
  , { code: 0x1FC4, lower: [ 0x1FC4 ], title: [ 0x0389, 0x0345 ], upper: [ 0x0389, 0x0399 ], fold: 0, foldFull: [ 0x03AE, 0x03B9 ] }
  , { code: 0x1FC4, lower: [ 0x1FC4 ], title: [ 0x0389, 0x0345 ], upper: [ 0x0389, 0x0399 ], fold: 0, foldFull: [ 0x03AE, 0x03B9 ] }
  , { code: 0x1FC6, lower: [ 0x1FC6 ], title: [ 0x0397, 0x0342 ], upper: [ 0x0397, 0x0342 ], fold: 0, foldFull: [ 0x03B7, 0x0342 ] }
  , { code: 0x1FC6, lower: [ 0x1FC6 ], title: [ 0x0397, 0x0342 ], upper: [ 0x0397, 0x0342 ], fold: 0, foldFull: [ 0x03B7, 0x0342 ] }
  , { code: 0x1FC7, lower: [ 0x1FC7 ], title: [ 0x0397, 0x0342, 0x0345 ], upper: [ 0x0397, 0x0342, 0x0399 ], fold: 0, foldFull: [ 0x03B7, 0x0342, 0x03B9 ] }
  , { code: 0x1FC7, lower: [ 0x1FC7 ], title: [ 0x0397, 0x0342, 0x0345 ], upper: [ 0x0397, 0x0342, 0x0399 ], fold: 0, foldFull: [ 0x03B7, 0x0342, 0x03B9 ] }
  , { code: 0x1FC8, lower: [], title: [], upper: [], fold: 0x1F72, foldFull: [ 0x1F72 ] }
  , { code: 0x1FC9, lower: [], title: [], upper: [], fold: 0x1F73, foldFull: [ 0x1F73 ] }
  , { code: 0x1FCA, lower: [], title: [], upper: [], fold: 0x1F74, foldFull: [ 0x1F74 ] }
  , { code: 0x1FCB, lower: [], title: [], upper: [], fold: 0x1F75, foldFull: [ 0x1F75 ] }
  , { code: 0x1FCC, lower: [ 0x1FC3 ], title: [ 0x1FCC ], upper: [ 0x0397, 0x0399 ], fold: 0x1FC3, foldFull: [ 0x03B7, 0x03B9 ] }
  , { code: 0x1FCC, lower: [ 0x1FC3 ], title: [ 0x1FCC ], upper: [ 0x0397, 0x0399 ], fold: 0x1FC3, foldFull: [ 0x03B7, 0x03B9 ] }
  , { code: 0x1FD2, lower: [ 0x1FD2 ], title: [ 0x0399, 0x0308, 0x0300 ], upper: [ 0x0399, 0x0308, 0x0300 ], fold: 0, foldFull: [ 0x03B9, 0x0308, 0x0300 ] }
  , { code: 0x1FD2, lower: [ 0x1FD2 ], title: [ 0x0399, 0x0308, 0x0300 ], upper: [ 0x0399, 0x0308, 0x0300 ], fold: 0, foldFull: [ 0x03B9, 0x0308, 0x0300 ] }
  , { code: 0x1FD3, lower: [ 0x1FD3 ], title: [ 0x0399, 0x0308, 0x0301 ], upper: [ 0x0399, 0x0308, 0x0301 ], fold: 0, foldFull: [ 0x03B9, 0x0308, 0x0301 ] }
  , { code: 0x1FD3, lower: [ 0x1FD3 ], title: [ 0x0399, 0x0308, 0x0301 ], upper: [ 0x0399, 0x0308, 0x0301 ], fold: 0, foldFull: [ 0x03B9, 0x0308, 0x0301 ] }
  , { code: 0x1FD6, lower: [ 0x1FD6 ], title: [ 0x0399, 0x0342 ], upper: [ 0x0399, 0x0342 ], fold: 0, foldFull: [ 0x03B9, 0x0342 ] }
  , { code: 0x1FD6, lower: [ 0x1FD6 ], title: [ 0x0399, 0x0342 ], upper: [ 0x0399, 0x0342 ], fold: 0, foldFull: [ 0x03B9, 0x0342 ] }
  , { code: 0x1FD7, lower: [ 0x1FD7 ], title: [ 0x0399, 0x0308, 0x0342 ], upper: [ 0x0399, 0x0308, 0x0342 ], fold: 0, foldFull: [ 0x03B9, 0x0308, 0x0342 ] }
  , { code: 0x1FD7, lower: [ 0x1FD7 ], title: [ 0x0399, 0x0308, 0x0342 ], upper: [ 0x0399, 0x0308, 0x0342 ], fold: 0, foldFull: [ 0x03B9, 0x0308, 0x0342 ] }
  , { code: 0x1FD8, lower: [], title: [], upper: [], fold: 0x1FD0, foldFull: [ 0x1FD0 ] }
  , { code: 0x1FD9, lower: [], title: [], upper: [], fold: 0x1FD1, foldFull: [ 0x1FD1 ] }
  , { code: 0x1FDA, lower: [], title: [], upper: [], fold: 0x1F76, foldFull: [ 0x1F76 ] }
  , { code: 0x1FDB, lower: [], title: [], upper: [], fold: 0x1F77, foldFull: [ 0x1F77 ] }
  , { code: 0x1FE2, lower: [ 0x1FE2 ], title: [ 0x03A5, 0x0308, 0x0300 ], upper: [ 0x03A5, 0x0308, 0x0300 ], fold: 0, foldFull: [ 0x03C5, 0x0308, 0x0300 ] }
  , { code: 0x1FE2, lower: [ 0x1FE2 ], title: [ 0x03A5, 0x0308, 0x0300 ], upper: [ 0x03A5, 0x0308, 0x0300 ], fold: 0, foldFull: [ 0x03C5, 0x0308, 0x0300 ] }
  , { code: 0x1FE3, lower: [ 0x1FE3 ], title: [ 0x03A5, 0x0308, 0x0301 ], upper: [ 0x03A5, 0x0308, 0x0301 ], fold: 0, foldFull: [ 0x03C5, 0x0308, 0x0301 ] }
  , { code: 0x1FE3, lower: [ 0x1FE3 ], title: [ 0x03A5, 0x0308, 0x0301 ], upper: [ 0x03A5, 0x0308, 0x0301 ], fold: 0, foldFull: [ 0x03C5, 0x0308, 0x0301 ] }
  , { code: 0x1FE4, lower: [ 0x1FE4 ], title: [ 0x03A1, 0x0313 ], upper: [ 0x03A1, 0x0313 ], fold: 0, foldFull: [ 0x03C1, 0x0313 ] }
  , { code: 0x1FE4, lower: [ 0x1FE4 ], title: [ 0x03A1, 0x0313 ], upper: [ 0x03A1, 0x0313 ], fold: 0, foldFull: [ 0x03C1, 0x0313 ] }
  , { code: 0x1FE6, lower: [ 0x1FE6 ], title: [ 0x03A5, 0x0342 ], upper: [ 0x03A5, 0x0342 ], fold: 0, foldFull: [ 0x03C5, 0x0342 ] }
  , { code: 0x1FE6, lower: [ 0x1FE6 ], title: [ 0x03A5, 0x0342 ], upper: [ 0x03A5, 0x0342 ], fold: 0, foldFull: [ 0x03C5, 0x0342 ] }
  , { code: 0x1FE7, lower: [ 0x1FE7 ], title: [ 0x03A5, 0x0308, 0x0342 ], upper: [ 0x03A5, 0x0308, 0x0342 ], fold: 0, foldFull: [ 0x03C5, 0x0308, 0x0342 ] }
  , { code: 0x1FE7, lower: [ 0x1FE7 ], title: [ 0x03A5, 0x0308, 0x0342 ], upper: [ 0x03A5, 0x0308, 0x0342 ], fold: 0, foldFull: [ 0x03C5, 0x0308, 0x0342 ] }
  , { code: 0x1FE8, lower: [], title: [], upper: [], fold: 0x1FE0, foldFull: [ 0x1FE0 ] }
  , { code: 0x1FE9, lower: [], title: [], upper: [], fold: 0x1FE1, foldFull: [ 0x1FE1 ] }
  , { code: 0x1FEA, lower: [], title: [], upper: [], fold: 0x1F7A, foldFull: [ 0x1F7A ] }
  , { code: 0x1FEB, lower: [], title: [], upper: [], fold: 0x1F7B, foldFull: [ 0x1F7B ] }
  , { code: 0x1FEC, lower: [], title: [], upper: [], fold: 0x1FE5, foldFull: [ 0x1FE5 ] }
  , { code: 0x1FF2, lower: [ 0x1FF2 ], title: [ 0x1FFA, 0x0345 ], upper: [ 0x1FFA, 0x0399 ], fold: 0, foldFull: [ 0x1F7C, 0x03B9 ] }
  , { code: 0x1FF2, lower: [ 0x1FF2 ], title: [ 0x1FFA, 0x0345 ], upper: [ 0x1FFA, 0x0399 ], fold: 0, foldFull: [ 0x1F7C, 0x03B9 ] }
  , { code: 0x1FF3, lower: [ 0x1FF3 ], title: [ 0x1FFC ], upper: [ 0x03A9, 0x0399 ], fold: 0, foldFull: [ 0x03C9, 0x03B9 ] }
  , { code: 0x1FF3, lower: [ 0x1FF3 ], title: [ 0x1FFC ], upper: [ 0x03A9, 0x0399 ], fold: 0, foldFull: [ 0x03C9, 0x03B9 ] }
  , { code: 0x1FF4, lower: [ 0x1FF4 ], title: [ 0x038F, 0x0345 ], upper: [ 0x038F, 0x0399 ], fold: 0, foldFull: [ 0x03CE, 0x03B9 ] }
  , { code: 0x1FF4, lower: [ 0x1FF4 ], title: [ 0x038F, 0x0345 ], upper: [ 0x038F, 0x0399 ], fold: 0, foldFull: [ 0x03CE, 0x03B9 ] }
  , { code: 0x1FF6, lower: [ 0x1FF6 ], title: [ 0x03A9, 0x0342 ], upper: [ 0x03A9, 0x0342 ], fold: 0, foldFull: [ 0x03C9, 0x0342 ] }
  , { code: 0x1FF6, lower: [ 0x1FF6 ], title: [ 0x03A9, 0x0342 ], upper: [ 0x03A9, 0x0342 ], fold: 0, foldFull: [ 0x03C9, 0x0342 ] }
  , { code: 0x1FF7, lower: [ 0x1FF7 ], title: [ 0x03A9, 0x0342, 0x0345 ], upper: [ 0x03A9, 0x0342, 0x0399 ], fold: 0, foldFull: [ 0x03C9, 0x0342, 0x03B9 ] }
  , { code: 0x1FF7, lower: [ 0x1FF7 ], title: [ 0x03A9, 0x0342, 0x0345 ], upper: [ 0x03A9, 0x0342, 0x0399 ], fold: 0, foldFull: [ 0x03C9, 0x0342, 0x03B9 ] }
  , { code: 0x1FF8, lower: [], title: [], upper: [], fold: 0x1F78, foldFull: [ 0x1F78 ] }
  , { code: 0x1FF9, lower: [], title: [], upper: [], fold: 0x1F79, foldFull: [ 0x1F79 ] }
  , { code: 0x1FFA, lower: [], title: [], upper: [], fold: 0x1F7C, foldFull: [ 0x1F7C ] }
  , { code: 0x1FFB, lower: [], title: [], upper: [], fold: 0x1F7D, foldFull: [ 0x1F7D ] }
  , { code: 0x1FFC, lower: [ 0x1FF3 ], title: [ 0x1FFC ], upper: [ 0x03A9, 0x0399 ], fold: 0x1FF3, foldFull: [ 0x03C9, 0x03B9 ] }
  , { code: 0x1FFC, lower: [ 0x1FF3 ], title: [ 0x1FFC ], upper: [ 0x03A9, 0x0399 ], fold: 0x1FF3, foldFull: [ 0x03C9, 0x03B9 ] }
  , { code: 0x2126, lower: [], title: [], upper: [], fold: 0x03C9, foldFull: [ 0x03C9 ] }
  , { code: 0x212A, lower: [], title: [], upper: [], fold: 0x006B, foldFull: [ 0x006B ] }
  , { code: 0x212B, lower: [], title: [], upper: [], fold: 0x00E5, foldFull: [ 0x00E5 ] }
  , { code: 0x2132, lower: [], title: [], upper: [], fold: 0x214E, foldFull: [ 0x214E ] }
  , { code: 0x2160, lower: [], title: [], upper: [], fold: 0x2170, foldFull: [ 0x2170 ] }
  , { code: 0x2161, lower: [], title: [], upper: [], fold: 0x2171, foldFull: [ 0x2171 ] }
  , { code: 0x2162, lower: [], title: [], upper: [], fold: 0x2172, foldFull: [ 0x2172 ] }
  , { code: 0x2163, lower: [], title: [], upper: [], fold: 0x2173, foldFull: [ 0x2173 ] }
  , { code: 0x2164, lower: [], title: [], upper: [], fold: 0x2174, foldFull: [ 0x2174 ] }
  , { code: 0x2165, lower: [], title: [], upper: [], fold: 0x2175, foldFull: [ 0x2175 ] }
  , { code: 0x2166, lower: [], title: [], upper: [], fold: 0x2176, foldFull: [ 0x2176 ] }
  , { code: 0x2167, lower: [], title: [], upper: [], fold: 0x2177, foldFull: [ 0x2177 ] }
  , { code: 0x2168, lower: [], title: [], upper: [], fold: 0x2178, foldFull: [ 0x2178 ] }
  , { code: 0x2169, lower: [], title: [], upper: [], fold: 0x2179, foldFull: [ 0x2179 ] }
  , { code: 0x216A, lower: [], title: [], upper: [], fold: 0x217A, foldFull: [ 0x217A ] }
  , { code: 0x216B, lower: [], title: [], upper: [], fold: 0x217B, foldFull: [ 0x217B ] }
  , { code: 0x216C, lower: [], title: [], upper: [], fold: 0x217C, foldFull: [ 0x217C ] }
  , { code: 0x216D, lower: [], title: [], upper: [], fold: 0x217D, foldFull: [ 0x217D ] }
  , { code: 0x216E, lower: [], title: [], upper: [], fold: 0x217E, foldFull: [ 0x217E ] }
  , { code: 0x216F, lower: [], title: [], upper: [], fold: 0x217F, foldFull: [ 0x217F ] }
  , { code: 0x2183, lower: [], title: [], upper: [], fold: 0x2184, foldFull: [ 0x2184 ] }
  , { code: 0x24B6, lower: [], title: [], upper: [], fold: 0x24D0, foldFull: [ 0x24D0 ] }
  , { code: 0x24B7, lower: [], title: [], upper: [], fold: 0x24D1, foldFull: [ 0x24D1 ] }
  , { code: 0x24B8, lower: [], title: [], upper: [], fold: 0x24D2, foldFull: [ 0x24D2 ] }
  , { code: 0x24B9, lower: [], title: [], upper: [], fold: 0x24D3, foldFull: [ 0x24D3 ] }
  , { code: 0x24BA, lower: [], title: [], upper: [], fold: 0x24D4, foldFull: [ 0x24D4 ] }
  , { code: 0x24BB, lower: [], title: [], upper: [], fold: 0x24D5, foldFull: [ 0x24D5 ] }
  , { code: 0x24BC, lower: [], title: [], upper: [], fold: 0x24D6, foldFull: [ 0x24D6 ] }
  , { code: 0x24BD, lower: [], title: [], upper: [], fold: 0x24D7, foldFull: [ 0x24D7 ] }
  , { code: 0x24BE, lower: [], title: [], upper: [], fold: 0x24D8, foldFull: [ 0x24D8 ] }
  , { code: 0x24BF, lower: [], title: [], upper: [], fold: 0x24D9, foldFull: [ 0x24D9 ] }
  , { code: 0x24C0, lower: [], title: [], upper: [], fold: 0x24DA, foldFull: [ 0x24DA ] }
  , { code: 0x24C1, lower: [], title: [], upper: [], fold: 0x24DB, foldFull: [ 0x24DB ] }
  , { code: 0x24C2, lower: [], title: [], upper: [], fold: 0x24DC, foldFull: [ 0x24DC ] }
  , { code: 0x24C3, lower: [], title: [], upper: [], fold: 0x24DD, foldFull: [ 0x24DD ] }
  , { code: 0x24C4, lower: [], title: [], upper: [], fold: 0x24DE, foldFull: [ 0x24DE ] }
  , { code: 0x24C5, lower: [], title: [], upper: [], fold: 0x24DF, foldFull: [ 0x24DF ] }
  , { code: 0x24C6, lower: [], title: [], upper: [], fold: 0x24E0, foldFull: [ 0x24E0 ] }
  , { code: 0x24C7, lower: [], title: [], upper: [], fold: 0x24E1, foldFull: [ 0x24E1 ] }
  , { code: 0x24C8, lower: [], title: [], upper: [], fold: 0x24E2, foldFull: [ 0x24E2 ] }
  , { code: 0x24C9, lower: [], title: [], upper: [], fold: 0x24E3, foldFull: [ 0x24E3 ] }
  , { code: 0x24CA, lower: [], title: [], upper: [], fold: 0x24E4, foldFull: [ 0x24E4 ] }
  , { code: 0x24CB, lower: [], title: [], upper: [], fold: 0x24E5, foldFull: [ 0x24E5 ] }
  , { code: 0x24CC, lower: [], title: [], upper: [], fold: 0x24E6, foldFull: [ 0x24E6 ] }
  , { code: 0x24CD, lower: [], title: [], upper: [], fold: 0x24E7, foldFull: [ 0x24E7 ] }
  , { code: 0x24CE, lower: [], title: [], upper: [], fold: 0x24E8, foldFull: [ 0x24E8 ] }
  , { code: 0x24CF, lower: [], title: [], upper: [], fold: 0x24E9, foldFull: [ 0x24E9 ] }
  , { code: 0x2C00, lower: [], title: [], upper: [], fold: 0x2C30, foldFull: [ 0x2C30 ] }
  , { code: 0x2C01, lower: [], title: [], upper: [], fold: 0x2C31, foldFull: [ 0x2C31 ] }
  , { code: 0x2C02, lower: [], title: [], upper: [], fold: 0x2C32, foldFull: [ 0x2C32 ] }
  , { code: 0x2C03, lower: [], title: [], upper: [], fold: 0x2C33, foldFull: [ 0x2C33 ] }
  , { code: 0x2C04, lower: [], title: [], upper: [], fold: 0x2C34, foldFull: [ 0x2C34 ] }
  , { code: 0x2C05, lower: [], title: [], upper: [], fold: 0x2C35, foldFull: [ 0x2C35 ] }
  , { code: 0x2C06, lower: [], title: [], upper: [], fold: 0x2C36, foldFull: [ 0x2C36 ] }
  , { code: 0x2C07, lower: [], title: [], upper: [], fold: 0x2C37, foldFull: [ 0x2C37 ] }
  , { code: 0x2C08, lower: [], title: [], upper: [], fold: 0x2C38, foldFull: [ 0x2C38 ] }
  , { code: 0x2C09, lower: [], title: [], upper: [], fold: 0x2C39, foldFull: [ 0x2C39 ] }
  , { code: 0x2C0A, lower: [], title: [], upper: [], fold: 0x2C3A, foldFull: [ 0x2C3A ] }
  , { code: 0x2C0B, lower: [], title: [], upper: [], fold: 0x2C3B, foldFull: [ 0x2C3B ] }
  , { code: 0x2C0C, lower: [], title: [], upper: [], fold: 0x2C3C, foldFull: [ 0x2C3C ] }
  , { code: 0x2C0D, lower: [], title: [], upper: [], fold: 0x2C3D, foldFull: [ 0x2C3D ] }
  , { code: 0x2C0E, lower: [], title: [], upper: [], fold: 0x2C3E, foldFull: [ 0x2C3E ] }
  , { code: 0x2C0F, lower: [], title: [], upper: [], fold: 0x2C3F, foldFull: [ 0x2C3F ] }
  , { code: 0x2C10, lower: [], title: [], upper: [], fold: 0x2C40, foldFull: [ 0x2C40 ] }
  , { code: 0x2C11, lower: [], title: [], upper: [], fold: 0x2C41, foldFull: [ 0x2C41 ] }
  , { code: 0x2C12, lower: [], title: [], upper: [], fold: 0x2C42, foldFull: [ 0x2C42 ] }
  , { code: 0x2C13, lower: [], title: [], upper: [], fold: 0x2C43, foldFull: [ 0x2C43 ] }
  , { code: 0x2C14, lower: [], title: [], upper: [], fold: 0x2C44, foldFull: [ 0x2C44 ] }
  , { code: 0x2C15, lower: [], title: [], upper: [], fold: 0x2C45, foldFull: [ 0x2C45 ] }
  , { code: 0x2C16, lower: [], title: [], upper: [], fold: 0x2C46, foldFull: [ 0x2C46 ] }
  , { code: 0x2C17, lower: [], title: [], upper: [], fold: 0x2C47, foldFull: [ 0x2C47 ] }
  , { code: 0x2C18, lower: [], title: [], upper: [], fold: 0x2C48, foldFull: [ 0x2C48 ] }
  , { code: 0x2C19, lower: [], title: [], upper: [], fold: 0x2C49, foldFull: [ 0x2C49 ] }
  , { code: 0x2C1A, lower: [], title: [], upper: [], fold: 0x2C4A, foldFull: [ 0x2C4A ] }
  , { code: 0x2C1B, lower: [], title: [], upper: [], fold: 0x2C4B, foldFull: [ 0x2C4B ] }
  , { code: 0x2C1C, lower: [], title: [], upper: [], fold: 0x2C4C, foldFull: [ 0x2C4C ] }
  , { code: 0x2C1D, lower: [], title: [], upper: [], fold: 0x2C4D, foldFull: [ 0x2C4D ] }
  , { code: 0x2C1E, lower: [], title: [], upper: [], fold: 0x2C4E, foldFull: [ 0x2C4E ] }
  , { code: 0x2C1F, lower: [], title: [], upper: [], fold: 0x2C4F, foldFull: [ 0x2C4F ] }
  , { code: 0x2C20, lower: [], title: [], upper: [], fold: 0x2C50, foldFull: [ 0x2C50 ] }
  , { code: 0x2C21, lower: [], title: [], upper: [], fold: 0x2C51, foldFull: [ 0x2C51 ] }
  , { code: 0x2C22, lower: [], title: [], upper: [], fold: 0x2C52, foldFull: [ 0x2C52 ] }
  , { code: 0x2C23, lower: [], title: [], upper: [], fold: 0x2C53, foldFull: [ 0x2C53 ] }
  , { code: 0x2C24, lower: [], title: [], upper: [], fold: 0x2C54, foldFull: [ 0x2C54 ] }
  , { code: 0x2C25, lower: [], title: [], upper: [], fold: 0x2C55, foldFull: [ 0x2C55 ] }
  , { code: 0x2C26, lower: [], title: [], upper: [], fold: 0x2C56, foldFull: [ 0x2C56 ] }
  , { code: 0x2C27, lower: [], title: [], upper: [], fold: 0x2C57, foldFull: [ 0x2C57 ] }
  , { code: 0x2C28, lower: [], title: [], upper: [], fold: 0x2C58, foldFull: [ 0x2C58 ] }
  , { code: 0x2C29, lower: [], title: [], upper: [], fold: 0x2C59, foldFull: [ 0x2C59 ] }
  , { code: 0x2C2A, lower: [], title: [], upper: [], fold: 0x2C5A, foldFull: [ 0x2C5A ] }
  , { code: 0x2C2B, lower: [], title: [], upper: [], fold: 0x2C5B, foldFull: [ 0x2C5B ] }
  , { code: 0x2C2C, lower: [], title: [], upper: [], fold: 0x2C5C, foldFull: [ 0x2C5C ] }
  , { code: 0x2C2D, lower: [], title: [], upper: [], fold: 0x2C5D, foldFull: [ 0x2C5D ] }
  , { code: 0x2C2E, lower: [], title: [], upper: [], fold: 0x2C5E, foldFull: [ 0x2C5E ] }
  , { code: 0x2C60, lower: [], title: [], upper: [], fold: 0x2C61, foldFull: [ 0x2C61 ] }
  , { code: 0x2C62, lower: [], title: [], upper: [], fold: 0x026B, foldFull: [ 0x026B ] }
  , { code: 0x2C63, lower: [], title: [], upper: [], fold: 0x1D7D, foldFull: [ 0x1D7D ] }
  , { code: 0x2C64, lower: [], title: [], upper: [], fold: 0x027D, foldFull: [ 0x027D ] }
  , { code: 0x2C67, lower: [], title: [], upper: [], fold: 0x2C68, foldFull: [ 0x2C68 ] }
  , { code: 0x2C69, lower: [], title: [], upper: [], fold: 0x2C6A, foldFull: [ 0x2C6A ] }
  , { code: 0x2C6B, lower: [], title: [], upper: [], fold: 0x2C6C, foldFull: [ 0x2C6C ] }
  , { code: 0x2C6D, lower: [], title: [], upper: [], fold: 0x0251, foldFull: [ 0x0251 ] }
  , { code: 0x2C6E, lower: [], title: [], upper: [], fold: 0x0271, foldFull: [ 0x0271 ] }
  , { code: 0x2C6F, lower: [], title: [], upper: [], fold: 0x0250, foldFull: [ 0x0250 ] }
  , { code: 0x2C70, lower: [], title: [], upper: [], fold: 0x0252, foldFull: [ 0x0252 ] }
  , { code: 0x2C72, lower: [], title: [], upper: [], fold: 0x2C73, foldFull: [ 0x2C73 ] }
  , { code: 0x2C75, lower: [], title: [], upper: [], fold: 0x2C76, foldFull: [ 0x2C76 ] }
  , { code: 0x2C7E, lower: [], title: [], upper: [], fold: 0x023F, foldFull: [ 0x023F ] }
  , { code: 0x2C7F, lower: [], title: [], upper: [], fold: 0x0240, foldFull: [ 0x0240 ] }
  , { code: 0x2C80, lower: [], title: [], upper: [], fold: 0x2C81, foldFull: [ 0x2C81 ] }
  , { code: 0x2C82, lower: [], title: [], upper: [], fold: 0x2C83, foldFull: [ 0x2C83 ] }
  , { code: 0x2C84, lower: [], title: [], upper: [], fold: 0x2C85, foldFull: [ 0x2C85 ] }
  , { code: 0x2C86, lower: [], title: [], upper: [], fold: 0x2C87, foldFull: [ 0x2C87 ] }
  , { code: 0x2C88, lower: [], title: [], upper: [], fold: 0x2C89, foldFull: [ 0x2C89 ] }
  , { code: 0x2C8A, lower: [], title: [], upper: [], fold: 0x2C8B, foldFull: [ 0x2C8B ] }
  , { code: 0x2C8C, lower: [], title: [], upper: [], fold: 0x2C8D, foldFull: [ 0x2C8D ] }
  , { code: 0x2C8E, lower: [], title: [], upper: [], fold: 0x2C8F, foldFull: [ 0x2C8F ] }
  , { code: 0x2C90, lower: [], title: [], upper: [], fold: 0x2C91, foldFull: [ 0x2C91 ] }
  , { code: 0x2C92, lower: [], title: [], upper: [], fold: 0x2C93, foldFull: [ 0x2C93 ] }
  , { code: 0x2C94, lower: [], title: [], upper: [], fold: 0x2C95, foldFull: [ 0x2C95 ] }
  , { code: 0x2C96, lower: [], title: [], upper: [], fold: 0x2C97, foldFull: [ 0x2C97 ] }
  , { code: 0x2C98, lower: [], title: [], upper: [], fold: 0x2C99, foldFull: [ 0x2C99 ] }
  , { code: 0x2C9A, lower: [], title: [], upper: [], fold: 0x2C9B, foldFull: [ 0x2C9B ] }
  , { code: 0x2C9C, lower: [], title: [], upper: [], fold: 0x2C9D, foldFull: [ 0x2C9D ] }
  , { code: 0x2C9E, lower: [], title: [], upper: [], fold: 0x2C9F, foldFull: [ 0x2C9F ] }
  , { code: 0x2CA0, lower: [], title: [], upper: [], fold: 0x2CA1, foldFull: [ 0x2CA1 ] }
  , { code: 0x2CA2, lower: [], title: [], upper: [], fold: 0x2CA3, foldFull: [ 0x2CA3 ] }
  , { code: 0x2CA4, lower: [], title: [], upper: [], fold: 0x2CA5, foldFull: [ 0x2CA5 ] }
  , { code: 0x2CA6, lower: [], title: [], upper: [], fold: 0x2CA7, foldFull: [ 0x2CA7 ] }
  , { code: 0x2CA8, lower: [], title: [], upper: [], fold: 0x2CA9, foldFull: [ 0x2CA9 ] }
  , { code: 0x2CAA, lower: [], title: [], upper: [], fold: 0x2CAB, foldFull: [ 0x2CAB ] }
  , { code: 0x2CAC, lower: [], title: [], upper: [], fold: 0x2CAD, foldFull: [ 0x2CAD ] }
  , { code: 0x2CAE, lower: [], title: [], upper: [], fold: 0x2CAF, foldFull: [ 0x2CAF ] }
  , { code: 0x2CB0, lower: [], title: [], upper: [], fold: 0x2CB1, foldFull: [ 0x2CB1 ] }
  , { code: 0x2CB2, lower: [], title: [], upper: [], fold: 0x2CB3, foldFull: [ 0x2CB3 ] }
  , { code: 0x2CB4, lower: [], title: [], upper: [], fold: 0x2CB5, foldFull: [ 0x2CB5 ] }
  , { code: 0x2CB6, lower: [], title: [], upper: [], fold: 0x2CB7, foldFull: [ 0x2CB7 ] }
  , { code: 0x2CB8, lower: [], title: [], upper: [], fold: 0x2CB9, foldFull: [ 0x2CB9 ] }
  , { code: 0x2CBA, lower: [], title: [], upper: [], fold: 0x2CBB, foldFull: [ 0x2CBB ] }
  , { code: 0x2CBC, lower: [], title: [], upper: [], fold: 0x2CBD, foldFull: [ 0x2CBD ] }
  , { code: 0x2CBE, lower: [], title: [], upper: [], fold: 0x2CBF, foldFull: [ 0x2CBF ] }
  , { code: 0x2CC0, lower: [], title: [], upper: [], fold: 0x2CC1, foldFull: [ 0x2CC1 ] }
  , { code: 0x2CC2, lower: [], title: [], upper: [], fold: 0x2CC3, foldFull: [ 0x2CC3 ] }
  , { code: 0x2CC4, lower: [], title: [], upper: [], fold: 0x2CC5, foldFull: [ 0x2CC5 ] }
  , { code: 0x2CC6, lower: [], title: [], upper: [], fold: 0x2CC7, foldFull: [ 0x2CC7 ] }
  , { code: 0x2CC8, lower: [], title: [], upper: [], fold: 0x2CC9, foldFull: [ 0x2CC9 ] }
  , { code: 0x2CCA, lower: [], title: [], upper: [], fold: 0x2CCB, foldFull: [ 0x2CCB ] }
  , { code: 0x2CCC, lower: [], title: [], upper: [], fold: 0x2CCD, foldFull: [ 0x2CCD ] }
  , { code: 0x2CCE, lower: [], title: [], upper: [], fold: 0x2CCF, foldFull: [ 0x2CCF ] }
  , { code: 0x2CD0, lower: [], title: [], upper: [], fold: 0x2CD1, foldFull: [ 0x2CD1 ] }
  , { code: 0x2CD2, lower: [], title: [], upper: [], fold: 0x2CD3, foldFull: [ 0x2CD3 ] }
  , { code: 0x2CD4, lower: [], title: [], upper: [], fold: 0x2CD5, foldFull: [ 0x2CD5 ] }
  , { code: 0x2CD6, lower: [], title: [], upper: [], fold: 0x2CD7, foldFull: [ 0x2CD7 ] }
  , { code: 0x2CD8, lower: [], title: [], upper: [], fold: 0x2CD9, foldFull: [ 0x2CD9 ] }
  , { code: 0x2CDA, lower: [], title: [], upper: [], fold: 0x2CDB, foldFull: [ 0x2CDB ] }
  , { code: 0x2CDC, lower: [], title: [], upper: [], fold: 0x2CDD, foldFull: [ 0x2CDD ] }
  , { code: 0x2CDE, lower: [], title: [], upper: [], fold: 0x2CDF, foldFull: [ 0x2CDF ] }
  , { code: 0x2CE0, lower: [], title: [], upper: [], fold: 0x2CE1, foldFull: [ 0x2CE1 ] }
  , { code: 0x2CE2, lower: [], title: [], upper: [], fold: 0x2CE3, foldFull: [ 0x2CE3 ] }
  , { code: 0x2CEB, lower: [], title: [], upper: [], fold: 0x2CEC, foldFull: [ 0x2CEC ] }
  , { code: 0x2CED, lower: [], title: [], upper: [], fold: 0x2CEE, foldFull: [ 0x2CEE ] }
  , { code: 0x2CF2, lower: [], title: [], upper: [], fold: 0x2CF3, foldFull: [ 0x2CF3 ] }
  , { code: 0xA640, lower: [], title: [], upper: [], fold: 0xA641, foldFull: [ 0xA641 ] }
  , { code: 0xA642, lower: [], title: [], upper: [], fold: 0xA643, foldFull: [ 0xA643 ] }
  , { code: 0xA644, lower: [], title: [], upper: [], fold: 0xA645, foldFull: [ 0xA645 ] }
  , { code: 0xA646, lower: [], title: [], upper: [], fold: 0xA647, foldFull: [ 0xA647 ] }
  , { code: 0xA648, lower: [], title: [], upper: [], fold: 0xA649, foldFull: [ 0xA649 ] }
  , { code: 0xA64A, lower: [], title: [], upper: [], fold: 0xA64B, foldFull: [ 0xA64B ] }
  , { code: 0xA64C, lower: [], title: [], upper: [], fold: 0xA64D, foldFull: [ 0xA64D ] }
  , { code: 0xA64E, lower: [], title: [], upper: [], fold: 0xA64F, foldFull: [ 0xA64F ] }
  , { code: 0xA650, lower: [], title: [], upper: [], fold: 0xA651, foldFull: [ 0xA651 ] }
  , { code: 0xA652, lower: [], title: [], upper: [], fold: 0xA653, foldFull: [ 0xA653 ] }
  , { code: 0xA654, lower: [], title: [], upper: [], fold: 0xA655, foldFull: [ 0xA655 ] }
  , { code: 0xA656, lower: [], title: [], upper: [], fold: 0xA657, foldFull: [ 0xA657 ] }
  , { code: 0xA658, lower: [], title: [], upper: [], fold: 0xA659, foldFull: [ 0xA659 ] }
  , { code: 0xA65A, lower: [], title: [], upper: [], fold: 0xA65B, foldFull: [ 0xA65B ] }
  , { code: 0xA65C, lower: [], title: [], upper: [], fold: 0xA65D, foldFull: [ 0xA65D ] }
  , { code: 0xA65E, lower: [], title: [], upper: [], fold: 0xA65F, foldFull: [ 0xA65F ] }
  , { code: 0xA660, lower: [], title: [], upper: [], fold: 0xA661, foldFull: [ 0xA661 ] }
  , { code: 0xA662, lower: [], title: [], upper: [], fold: 0xA663, foldFull: [ 0xA663 ] }
  , { code: 0xA664, lower: [], title: [], upper: [], fold: 0xA665, foldFull: [ 0xA665 ] }
  , { code: 0xA666, lower: [], title: [], upper: [], fold: 0xA667, foldFull: [ 0xA667 ] }
  , { code: 0xA668, lower: [], title: [], upper: [], fold: 0xA669, foldFull: [ 0xA669 ] }
  , { code: 0xA66A, lower: [], title: [], upper: [], fold: 0xA66B, foldFull: [ 0xA66B ] }
  , { code: 0xA66C, lower: [], title: [], upper: [], fold: 0xA66D, foldFull: [ 0xA66D ] }
  , { code: 0xA680, lower: [], title: [], upper: [], fold: 0xA681, foldFull: [ 0xA681 ] }
  , { code: 0xA682, lower: [], title: [], upper: [], fold: 0xA683, foldFull: [ 0xA683 ] }
  , { code: 0xA684, lower: [], title: [], upper: [], fold: 0xA685, foldFull: [ 0xA685 ] }
  , { code: 0xA686, lower: [], title: [], upper: [], fold: 0xA687, foldFull: [ 0xA687 ] }
  , { code: 0xA688, lower: [], title: [], upper: [], fold: 0xA689, foldFull: [ 0xA689 ] }
  , { code: 0xA68A, lower: [], title: [], upper: [], fold: 0xA68B, foldFull: [ 0xA68B ] }
  , { code: 0xA68C, lower: [], title: [], upper: [], fold: 0xA68D, foldFull: [ 0xA68D ] }
  , { code: 0xA68E, lower: [], title: [], upper: [], fold: 0xA68F, foldFull: [ 0xA68F ] }
  , { code: 0xA690, lower: [], title: [], upper: [], fold: 0xA691, foldFull: [ 0xA691 ] }
  , { code: 0xA692, lower: [], title: [], upper: [], fold: 0xA693, foldFull: [ 0xA693 ] }
  , { code: 0xA694, lower: [], title: [], upper: [], fold: 0xA695, foldFull: [ 0xA695 ] }
  , { code: 0xA696, lower: [], title: [], upper: [], fold: 0xA697, foldFull: [ 0xA697 ] }
  , { code: 0xA698, lower: [], title: [], upper: [], fold: 0xA699, foldFull: [ 0xA699 ] }
  , { code: 0xA69A, lower: [], title: [], upper: [], fold: 0xA69B, foldFull: [ 0xA69B ] }
  , { code: 0xA722, lower: [], title: [], upper: [], fold: 0xA723, foldFull: [ 0xA723 ] }
  , { code: 0xA724, lower: [], title: [], upper: [], fold: 0xA725, foldFull: [ 0xA725 ] }
  , { code: 0xA726, lower: [], title: [], upper: [], fold: 0xA727, foldFull: [ 0xA727 ] }
  , { code: 0xA728, lower: [], title: [], upper: [], fold: 0xA729, foldFull: [ 0xA729 ] }
  , { code: 0xA72A, lower: [], title: [], upper: [], fold: 0xA72B, foldFull: [ 0xA72B ] }
  , { code: 0xA72C, lower: [], title: [], upper: [], fold: 0xA72D, foldFull: [ 0xA72D ] }
  , { code: 0xA72E, lower: [], title: [], upper: [], fold: 0xA72F, foldFull: [ 0xA72F ] }
  , { code: 0xA732, lower: [], title: [], upper: [], fold: 0xA733, foldFull: [ 0xA733 ] }
  , { code: 0xA734, lower: [], title: [], upper: [], fold: 0xA735, foldFull: [ 0xA735 ] }
  , { code: 0xA736, lower: [], title: [], upper: [], fold: 0xA737, foldFull: [ 0xA737 ] }
  , { code: 0xA738, lower: [], title: [], upper: [], fold: 0xA739, foldFull: [ 0xA739 ] }
  , { code: 0xA73A, lower: [], title: [], upper: [], fold: 0xA73B, foldFull: [ 0xA73B ] }
  , { code: 0xA73C, lower: [], title: [], upper: [], fold: 0xA73D, foldFull: [ 0xA73D ] }
  , { code: 0xA73E, lower: [], title: [], upper: [], fold: 0xA73F, foldFull: [ 0xA73F ] }
  , { code: 0xA740, lower: [], title: [], upper: [], fold: 0xA741, foldFull: [ 0xA741 ] }
  , { code: 0xA742, lower: [], title: [], upper: [], fold: 0xA743, foldFull: [ 0xA743 ] }
  , { code: 0xA744, lower: [], title: [], upper: [], fold: 0xA745, foldFull: [ 0xA745 ] }
  , { code: 0xA746, lower: [], title: [], upper: [], fold: 0xA747, foldFull: [ 0xA747 ] }
  , { code: 0xA748, lower: [], title: [], upper: [], fold: 0xA749, foldFull: [ 0xA749 ] }
  , { code: 0xA74A, lower: [], title: [], upper: [], fold: 0xA74B, foldFull: [ 0xA74B ] }
  , { code: 0xA74C, lower: [], title: [], upper: [], fold: 0xA74D, foldFull: [ 0xA74D ] }
  , { code: 0xA74E, lower: [], title: [], upper: [], fold: 0xA74F, foldFull: [ 0xA74F ] }
  , { code: 0xA750, lower: [], title: [], upper: [], fold: 0xA751, foldFull: [ 0xA751 ] }
  , { code: 0xA752, lower: [], title: [], upper: [], fold: 0xA753, foldFull: [ 0xA753 ] }
  , { code: 0xA754, lower: [], title: [], upper: [], fold: 0xA755, foldFull: [ 0xA755 ] }
  , { code: 0xA756, lower: [], title: [], upper: [], fold: 0xA757, foldFull: [ 0xA757 ] }
  , { code: 0xA758, lower: [], title: [], upper: [], fold: 0xA759, foldFull: [ 0xA759 ] }
  , { code: 0xA75A, lower: [], title: [], upper: [], fold: 0xA75B, foldFull: [ 0xA75B ] }
  , { code: 0xA75C, lower: [], title: [], upper: [], fold: 0xA75D, foldFull: [ 0xA75D ] }
  , { code: 0xA75E, lower: [], title: [], upper: [], fold: 0xA75F, foldFull: [ 0xA75F ] }
  , { code: 0xA760, lower: [], title: [], upper: [], fold: 0xA761, foldFull: [ 0xA761 ] }
  , { code: 0xA762, lower: [], title: [], upper: [], fold: 0xA763, foldFull: [ 0xA763 ] }
  , { code: 0xA764, lower: [], title: [], upper: [], fold: 0xA765, foldFull: [ 0xA765 ] }
  , { code: 0xA766, lower: [], title: [], upper: [], fold: 0xA767, foldFull: [ 0xA767 ] }
  , { code: 0xA768, lower: [], title: [], upper: [], fold: 0xA769, foldFull: [ 0xA769 ] }
  , { code: 0xA76A, lower: [], title: [], upper: [], fold: 0xA76B, foldFull: [ 0xA76B ] }
  , { code: 0xA76C, lower: [], title: [], upper: [], fold: 0xA76D, foldFull: [ 0xA76D ] }
  , { code: 0xA76E, lower: [], title: [], upper: [], fold: 0xA76F, foldFull: [ 0xA76F ] }
  , { code: 0xA779, lower: [], title: [], upper: [], fold: 0xA77A, foldFull: [ 0xA77A ] }
  , { code: 0xA77B, lower: [], title: [], upper: [], fold: 0xA77C, foldFull: [ 0xA77C ] }
  , { code: 0xA77D, lower: [], title: [], upper: [], fold: 0x1D79, foldFull: [ 0x1D79 ] }
  , { code: 0xA77E, lower: [], title: [], upper: [], fold: 0xA77F, foldFull: [ 0xA77F ] }
  , { code: 0xA780, lower: [], title: [], upper: [], fold: 0xA781, foldFull: [ 0xA781 ] }
  , { code: 0xA782, lower: [], title: [], upper: [], fold: 0xA783, foldFull: [ 0xA783 ] }
  , { code: 0xA784, lower: [], title: [], upper: [], fold: 0xA785, foldFull: [ 0xA785 ] }
  , { code: 0xA786, lower: [], title: [], upper: [], fold: 0xA787, foldFull: [ 0xA787 ] }
  , { code: 0xA78B, lower: [], title: [], upper: [], fold: 0xA78C, foldFull: [ 0xA78C ] }
  , { code: 0xA78D, lower: [], title: [], upper: [], fold: 0x0265, foldFull: [ 0x0265 ] }
  , { code: 0xA790, lower: [], title: [], upper: [], fold: 0xA791, foldFull: [ 0xA791 ] }
  , { code: 0xA792, lower: [], title: [], upper: [], fold: 0xA793, foldFull: [ 0xA793 ] }
  , { code: 0xA796, lower: [], title: [], upper: [], fold: 0xA797, foldFull: [ 0xA797 ] }
  , { code: 0xA798, lower: [], title: [], upper: [], fold: 0xA799, foldFull: [ 0xA799 ] }
  , { code: 0xA79A, lower: [], title: [], upper: [], fold: 0xA79B, foldFull: [ 0xA79B ] }
  , { code: 0xA79C, lower: [], title: [], upper: [], fold: 0xA79D, foldFull: [ 0xA79D ] }
  , { code: 0xA79E, lower: [], title: [], upper: [], fold: 0xA79F, foldFull: [ 0xA79F ] }
  , { code: 0xA7A0, lower: [], title: [], upper: [], fold: 0xA7A1, foldFull: [ 0xA7A1 ] }
  , { code: 0xA7A2, lower: [], title: [], upper: [], fold: 0xA7A3, foldFull: [ 0xA7A3 ] }
  , { code: 0xA7A4, lower: [], title: [], upper: [], fold: 0xA7A5, foldFull: [ 0xA7A5 ] }
  , { code: 0xA7A6, lower: [], title: [], upper: [], fold: 0xA7A7, foldFull: [ 0xA7A7 ] }
  , { code: 0xA7A8, lower: [], title: [], upper: [], fold: 0xA7A9, foldFull: [ 0xA7A9 ] }
  , { code: 0xA7AA, lower: [], title: [], upper: [], fold: 0x0266, foldFull: [ 0x0266 ] }
  , { code: 0xA7AB, lower: [], title: [], upper: [], fold: 0x025C, foldFull: [ 0x025C ] }
  , { code: 0xA7AC, lower: [], title: [], upper: [], fold: 0x0261, foldFull: [ 0x0261 ] }
  , { code: 0xA7AD, lower: [], title: [], upper: [], fold: 0x026C, foldFull: [ 0x026C ] }
  , { code: 0xA7AE, lower: [], title: [], upper: [], fold: 0x026A, foldFull: [ 0x026A ] }
  , { code: 0xA7B0, lower: [], title: [], upper: [], fold: 0x029E, foldFull: [ 0x029E ] }
  , { code: 0xA7B1, lower: [], title: [], upper: [], fold: 0x0287, foldFull: [ 0x0287 ] }
  , { code: 0xA7B2, lower: [], title: [], upper: [], fold: 0x029D, foldFull: [ 0x029D ] }
  , { code: 0xA7B3, lower: [], title: [], upper: [], fold: 0xAB53, foldFull: [ 0xAB53 ] }
  , { code: 0xA7B4, lower: [], title: [], upper: [], fold: 0xA7B5, foldFull: [ 0xA7B5 ] }
  , { code: 0xA7B6, lower: [], title: [], upper: [], fold: 0xA7B7, foldFull: [ 0xA7B7 ] }
  , { code: 0xA7B8, lower: [], title: [], upper: [], fold: 0xA7B9, foldFull: [ 0xA7B9 ] }
  , { code: 0xA7BA, lower: [], title: [], upper: [], fold: 0xA7BB, foldFull: [ 0xA7BB ] }
  , { code: 0xA7BC, lower: [], title: [], upper: [], fold: 0xA7BD, foldFull: [ 0xA7BD ] }
  , { code: 0xA7BE, lower: [], title: [], upper: [], fold: 0xA7BF, foldFull: [ 0xA7BF ] }
  , { code: 0xA7C2, lower: [], title: [], upper: [], fold: 0xA7C3, foldFull: [ 0xA7C3 ] }
  , { code: 0xA7C4, lower: [], title: [], upper: [], fold: 0xA794, foldFull: [ 0xA794 ] }
  , { code: 0xA7C5, lower: [], title: [], upper: [], fold: 0x0282, foldFull: [ 0x0282 ] }
  , { code: 0xA7C6, lower: [], title: [], upper: [], fold: 0x1D8E, foldFull: [ 0x1D8E ] }
  , { code: 0xA7C7, lower: [], title: [], upper: [], fold: 0xA7C8, foldFull: [ 0xA7C8 ] }
  , { code: 0xA7C9, lower: [], title: [], upper: [], fold: 0xA7CA, foldFull: [ 0xA7CA ] }
  , { code: 0xA7F5, lower: [], title: [], upper: [], fold: 0xA7F6, foldFull: [ 0xA7F6 ] }
  , { code: 0xAB70, lower: [], title: [], upper: [], fold: 0x13A0, foldFull: [ 0x13A0 ] }
  , { code: 0xAB71, lower: [], title: [], upper: [], fold: 0x13A1, foldFull: [ 0x13A1 ] }
  , { code: 0xAB72, lower: [], title: [], upper: [], fold: 0x13A2, foldFull: [ 0x13A2 ] }
  , { code: 0xAB73, lower: [], title: [], upper: [], fold: 0x13A3, foldFull: [ 0x13A3 ] }
  , { code: 0xAB74, lower: [], title: [], upper: [], fold: 0x13A4, foldFull: [ 0x13A4 ] }
  , { code: 0xAB75, lower: [], title: [], upper: [], fold: 0x13A5, foldFull: [ 0x13A5 ] }
  , { code: 0xAB76, lower: [], title: [], upper: [], fold: 0x13A6, foldFull: [ 0x13A6 ] }
  , { code: 0xAB77, lower: [], title: [], upper: [], fold: 0x13A7, foldFull: [ 0x13A7 ] }
  , { code: 0xAB78, lower: [], title: [], upper: [], fold: 0x13A8, foldFull: [ 0x13A8 ] }
  , { code: 0xAB79, lower: [], title: [], upper: [], fold: 0x13A9, foldFull: [ 0x13A9 ] }
  , { code: 0xAB7A, lower: [], title: [], upper: [], fold: 0x13AA, foldFull: [ 0x13AA ] }
  , { code: 0xAB7B, lower: [], title: [], upper: [], fold: 0x13AB, foldFull: [ 0x13AB ] }
  , { code: 0xAB7C, lower: [], title: [], upper: [], fold: 0x13AC, foldFull: [ 0x13AC ] }
  , { code: 0xAB7D, lower: [], title: [], upper: [], fold: 0x13AD, foldFull: [ 0x13AD ] }
  , { code: 0xAB7E, lower: [], title: [], upper: [], fold: 0x13AE, foldFull: [ 0x13AE ] }
  , { code: 0xAB7F, lower: [], title: [], upper: [], fold: 0x13AF, foldFull: [ 0x13AF ] }
  , { code: 0xAB80, lower: [], title: [], upper: [], fold: 0x13B0, foldFull: [ 0x13B0 ] }
  , { code: 0xAB81, lower: [], title: [], upper: [], fold: 0x13B1, foldFull: [ 0x13B1 ] }
  , { code: 0xAB82, lower: [], title: [], upper: [], fold: 0x13B2, foldFull: [ 0x13B2 ] }
  , { code: 0xAB83, lower: [], title: [], upper: [], fold: 0x13B3, foldFull: [ 0x13B3 ] }
  , { code: 0xAB84, lower: [], title: [], upper: [], fold: 0x13B4, foldFull: [ 0x13B4 ] }
  , { code: 0xAB85, lower: [], title: [], upper: [], fold: 0x13B5, foldFull: [ 0x13B5 ] }
  , { code: 0xAB86, lower: [], title: [], upper: [], fold: 0x13B6, foldFull: [ 0x13B6 ] }
  , { code: 0xAB87, lower: [], title: [], upper: [], fold: 0x13B7, foldFull: [ 0x13B7 ] }
  , { code: 0xAB88, lower: [], title: [], upper: [], fold: 0x13B8, foldFull: [ 0x13B8 ] }
  , { code: 0xAB89, lower: [], title: [], upper: [], fold: 0x13B9, foldFull: [ 0x13B9 ] }
  , { code: 0xAB8A, lower: [], title: [], upper: [], fold: 0x13BA, foldFull: [ 0x13BA ] }
  , { code: 0xAB8B, lower: [], title: [], upper: [], fold: 0x13BB, foldFull: [ 0x13BB ] }
  , { code: 0xAB8C, lower: [], title: [], upper: [], fold: 0x13BC, foldFull: [ 0x13BC ] }
  , { code: 0xAB8D, lower: [], title: [], upper: [], fold: 0x13BD, foldFull: [ 0x13BD ] }
  , { code: 0xAB8E, lower: [], title: [], upper: [], fold: 0x13BE, foldFull: [ 0x13BE ] }
  , { code: 0xAB8F, lower: [], title: [], upper: [], fold: 0x13BF, foldFull: [ 0x13BF ] }
  , { code: 0xAB90, lower: [], title: [], upper: [], fold: 0x13C0, foldFull: [ 0x13C0 ] }
  , { code: 0xAB91, lower: [], title: [], upper: [], fold: 0x13C1, foldFull: [ 0x13C1 ] }
  , { code: 0xAB92, lower: [], title: [], upper: [], fold: 0x13C2, foldFull: [ 0x13C2 ] }
  , { code: 0xAB93, lower: [], title: [], upper: [], fold: 0x13C3, foldFull: [ 0x13C3 ] }
  , { code: 0xAB94, lower: [], title: [], upper: [], fold: 0x13C4, foldFull: [ 0x13C4 ] }
  , { code: 0xAB95, lower: [], title: [], upper: [], fold: 0x13C5, foldFull: [ 0x13C5 ] }
  , { code: 0xAB96, lower: [], title: [], upper: [], fold: 0x13C6, foldFull: [ 0x13C6 ] }
  , { code: 0xAB97, lower: [], title: [], upper: [], fold: 0x13C7, foldFull: [ 0x13C7 ] }
  , { code: 0xAB98, lower: [], title: [], upper: [], fold: 0x13C8, foldFull: [ 0x13C8 ] }
  , { code: 0xAB99, lower: [], title: [], upper: [], fold: 0x13C9, foldFull: [ 0x13C9 ] }
  , { code: 0xAB9A, lower: [], title: [], upper: [], fold: 0x13CA, foldFull: [ 0x13CA ] }
  , { code: 0xAB9B, lower: [], title: [], upper: [], fold: 0x13CB, foldFull: [ 0x13CB ] }
  , { code: 0xAB9C, lower: [], title: [], upper: [], fold: 0x13CC, foldFull: [ 0x13CC ] }
  , { code: 0xAB9D, lower: [], title: [], upper: [], fold: 0x13CD, foldFull: [ 0x13CD ] }
  , { code: 0xAB9E, lower: [], title: [], upper: [], fold: 0x13CE, foldFull: [ 0x13CE ] }
  , { code: 0xAB9F, lower: [], title: [], upper: [], fold: 0x13CF, foldFull: [ 0x13CF ] }
  , { code: 0xABA0, lower: [], title: [], upper: [], fold: 0x13D0, foldFull: [ 0x13D0 ] }
  , { code: 0xABA1, lower: [], title: [], upper: [], fold: 0x13D1, foldFull: [ 0x13D1 ] }
  , { code: 0xABA2, lower: [], title: [], upper: [], fold: 0x13D2, foldFull: [ 0x13D2 ] }
  , { code: 0xABA3, lower: [], title: [], upper: [], fold: 0x13D3, foldFull: [ 0x13D3 ] }
  , { code: 0xABA4, lower: [], title: [], upper: [], fold: 0x13D4, foldFull: [ 0x13D4 ] }
  , { code: 0xABA5, lower: [], title: [], upper: [], fold: 0x13D5, foldFull: [ 0x13D5 ] }
  , { code: 0xABA6, lower: [], title: [], upper: [], fold: 0x13D6, foldFull: [ 0x13D6 ] }
  , { code: 0xABA7, lower: [], title: [], upper: [], fold: 0x13D7, foldFull: [ 0x13D7 ] }
  , { code: 0xABA8, lower: [], title: [], upper: [], fold: 0x13D8, foldFull: [ 0x13D8 ] }
  , { code: 0xABA9, lower: [], title: [], upper: [], fold: 0x13D9, foldFull: [ 0x13D9 ] }
  , { code: 0xABAA, lower: [], title: [], upper: [], fold: 0x13DA, foldFull: [ 0x13DA ] }
  , { code: 0xABAB, lower: [], title: [], upper: [], fold: 0x13DB, foldFull: [ 0x13DB ] }
  , { code: 0xABAC, lower: [], title: [], upper: [], fold: 0x13DC, foldFull: [ 0x13DC ] }
  , { code: 0xABAD, lower: [], title: [], upper: [], fold: 0x13DD, foldFull: [ 0x13DD ] }
  , { code: 0xABAE, lower: [], title: [], upper: [], fold: 0x13DE, foldFull: [ 0x13DE ] }
  , { code: 0xABAF, lower: [], title: [], upper: [], fold: 0x13DF, foldFull: [ 0x13DF ] }
  , { code: 0xABB0, lower: [], title: [], upper: [], fold: 0x13E0, foldFull: [ 0x13E0 ] }
  , { code: 0xABB1, lower: [], title: [], upper: [], fold: 0x13E1, foldFull: [ 0x13E1 ] }
  , { code: 0xABB2, lower: [], title: [], upper: [], fold: 0x13E2, foldFull: [ 0x13E2 ] }
  , { code: 0xABB3, lower: [], title: [], upper: [], fold: 0x13E3, foldFull: [ 0x13E3 ] }
  , { code: 0xABB4, lower: [], title: [], upper: [], fold: 0x13E4, foldFull: [ 0x13E4 ] }
  , { code: 0xABB5, lower: [], title: [], upper: [], fold: 0x13E5, foldFull: [ 0x13E5 ] }
  , { code: 0xABB6, lower: [], title: [], upper: [], fold: 0x13E6, foldFull: [ 0x13E6 ] }
  , { code: 0xABB7, lower: [], title: [], upper: [], fold: 0x13E7, foldFull: [ 0x13E7 ] }
  , { code: 0xABB8, lower: [], title: [], upper: [], fold: 0x13E8, foldFull: [ 0x13E8 ] }
  , { code: 0xABB9, lower: [], title: [], upper: [], fold: 0x13E9, foldFull: [ 0x13E9 ] }
  , { code: 0xABBA, lower: [], title: [], upper: [], fold: 0x13EA, foldFull: [ 0x13EA ] }
  , { code: 0xABBB, lower: [], title: [], upper: [], fold: 0x13EB, foldFull: [ 0x13EB ] }
  , { code: 0xABBC, lower: [], title: [], upper: [], fold: 0x13EC, foldFull: [ 0x13EC ] }
  , { code: 0xABBD, lower: [], title: [], upper: [], fold: 0x13ED, foldFull: [ 0x13ED ] }
  , { code: 0xABBE, lower: [], title: [], upper: [], fold: 0x13EE, foldFull: [ 0x13EE ] }
  , { code: 0xABBF, lower: [], title: [], upper: [], fold: 0x13EF, foldFull: [ 0x13EF ] }
  , { code: 0xFB00, lower: [ 0xFB00 ], title: [ 0x0046, 0x0066 ], upper: [ 0x0046, 0x0046 ], fold: 0, foldFull: [ 0x0066, 0x0066 ] }
  , { code: 0xFB00, lower: [ 0xFB00 ], title: [ 0x0046, 0x0066 ], upper: [ 0x0046, 0x0046 ], fold: 0, foldFull: [ 0x0066, 0x0066 ] }
  , { code: 0xFB01, lower: [ 0xFB01 ], title: [ 0x0046, 0x0069 ], upper: [ 0x0046, 0x0049 ], fold: 0, foldFull: [ 0x0066, 0x0069 ] }
  , { code: 0xFB01, lower: [ 0xFB01 ], title: [ 0x0046, 0x0069 ], upper: [ 0x0046, 0x0049 ], fold: 0, foldFull: [ 0x0066, 0x0069 ] }
  , { code: 0xFB02, lower: [ 0xFB02 ], title: [ 0x0046, 0x006C ], upper: [ 0x0046, 0x004C ], fold: 0, foldFull: [ 0x0066, 0x006C ] }
  , { code: 0xFB02, lower: [ 0xFB02 ], title: [ 0x0046, 0x006C ], upper: [ 0x0046, 0x004C ], fold: 0, foldFull: [ 0x0066, 0x006C ] }
  , { code: 0xFB03, lower: [ 0xFB03 ], title: [ 0x0046, 0x0066, 0x0069 ], upper: [ 0x0046, 0x0046, 0x0049 ], fold: 0, foldFull: [ 0x0066, 0x0066, 0x0069 ] }
  , { code: 0xFB03, lower: [ 0xFB03 ], title: [ 0x0046, 0x0066, 0x0069 ], upper: [ 0x0046, 0x0046, 0x0049 ], fold: 0, foldFull: [ 0x0066, 0x0066, 0x0069 ] }
  , { code: 0xFB04, lower: [ 0xFB04 ], title: [ 0x0046, 0x0066, 0x006C ], upper: [ 0x0046, 0x0046, 0x004C ], fold: 0, foldFull: [ 0x0066, 0x0066, 0x006C ] }
  , { code: 0xFB04, lower: [ 0xFB04 ], title: [ 0x0046, 0x0066, 0x006C ], upper: [ 0x0046, 0x0046, 0x004C ], fold: 0, foldFull: [ 0x0066, 0x0066, 0x006C ] }
  , { code: 0xFB05, lower: [ 0xFB05 ], title: [ 0x0053, 0x0074 ], upper: [ 0x0053, 0x0054 ], fold: 0, foldFull: [ 0x0073, 0x0074 ] }
  , { code: 0xFB05, lower: [ 0xFB05 ], title: [ 0x0053, 0x0074 ], upper: [ 0x0053, 0x0054 ], fold: 0, foldFull: [ 0x0073, 0x0074 ] }
  , { code: 0xFB06, lower: [ 0xFB06 ], title: [ 0x0053, 0x0074 ], upper: [ 0x0053, 0x0054 ], fold: 0, foldFull: [ 0x0073, 0x0074 ] }
  , { code: 0xFB06, lower: [ 0xFB06 ], title: [ 0x0053, 0x0074 ], upper: [ 0x0053, 0x0054 ], fold: 0, foldFull: [ 0x0073, 0x0074 ] }
  , { code: 0xFB13, lower: [ 0xFB13 ], title: [ 0x0544, 0x0576 ], upper: [ 0x0544, 0x0546 ], fold: 0, foldFull: [ 0x0574, 0x0576 ] }
  , { code: 0xFB13, lower: [ 0xFB13 ], title: [ 0x0544, 0x0576 ], upper: [ 0x0544, 0x0546 ], fold: 0, foldFull: [ 0x0574, 0x0576 ] }
  , { code: 0xFB14, lower: [ 0xFB14 ], title: [ 0x0544, 0x0565 ], upper: [ 0x0544, 0x0535 ], fold: 0, foldFull: [ 0x0574, 0x0565 ] }
  , { code: 0xFB14, lower: [ 0xFB14 ], title: [ 0x0544, 0x0565 ], upper: [ 0x0544, 0x0535 ], fold: 0, foldFull: [ 0x0574, 0x0565 ] }
  , { code: 0xFB15, lower: [ 0xFB15 ], title: [ 0x0544, 0x056B ], upper: [ 0x0544, 0x053B ], fold: 0, foldFull: [ 0x0574, 0x056B ] }
  , { code: 0xFB15, lower: [ 0xFB15 ], title: [ 0x0544, 0x056B ], upper: [ 0x0544, 0x053B ], fold: 0, foldFull: [ 0x0574, 0x056B ] }
  , { code: 0xFB16, lower: [ 0xFB16 ], title: [ 0x054E, 0x0576 ], upper: [ 0x054E, 0x0546 ], fold: 0, foldFull: [ 0x057E, 0x0576 ] }
  , { code: 0xFB16, lower: [ 0xFB16 ], title: [ 0x054E, 0x0576 ], upper: [ 0x054E, 0x0546 ], fold: 0, foldFull: [ 0x057E, 0x0576 ] }
  , { code: 0xFB17, lower: [ 0xFB17 ], title: [ 0x0544, 0x056D ], upper: [ 0x0544, 0x053D ], fold: 0, foldFull: [ 0x0574, 0x056D ] }
  , { code: 0xFB17, lower: [ 0xFB17 ], title: [ 0x0544, 0x056D ], upper: [ 0x0544, 0x053D ], fold: 0, foldFull: [ 0x0574, 0x056D ] }
  , { code: 0xFF21, lower: [], title: [], upper: [], fold: 0xFF41, foldFull: [ 0xFF41 ] }
  , { code: 0xFF22, lower: [], title: [], upper: [], fold: 0xFF42, foldFull: [ 0xFF42 ] }
  , { code: 0xFF23, lower: [], title: [], upper: [], fold: 0xFF43, foldFull: [ 0xFF43 ] }
  , { code: 0xFF24, lower: [], title: [], upper: [], fold: 0xFF44, foldFull: [ 0xFF44 ] }
  , { code: 0xFF25, lower: [], title: [], upper: [], fold: 0xFF45, foldFull: [ 0xFF45 ] }
  , { code: 0xFF26, lower: [], title: [], upper: [], fold: 0xFF46, foldFull: [ 0xFF46 ] }
  , { code: 0xFF27, lower: [], title: [], upper: [], fold: 0xFF47, foldFull: [ 0xFF47 ] }
  , { code: 0xFF28, lower: [], title: [], upper: [], fold: 0xFF48, foldFull: [ 0xFF48 ] }
  , { code: 0xFF29, lower: [], title: [], upper: [], fold: 0xFF49, foldFull: [ 0xFF49 ] }
  , { code: 0xFF2A, lower: [], title: [], upper: [], fold: 0xFF4A, foldFull: [ 0xFF4A ] }
  , { code: 0xFF2B, lower: [], title: [], upper: [], fold: 0xFF4B, foldFull: [ 0xFF4B ] }
  , { code: 0xFF2C, lower: [], title: [], upper: [], fold: 0xFF4C, foldFull: [ 0xFF4C ] }
  , { code: 0xFF2D, lower: [], title: [], upper: [], fold: 0xFF4D, foldFull: [ 0xFF4D ] }
  , { code: 0xFF2E, lower: [], title: [], upper: [], fold: 0xFF4E, foldFull: [ 0xFF4E ] }
  , { code: 0xFF2F, lower: [], title: [], upper: [], fold: 0xFF4F, foldFull: [ 0xFF4F ] }
  , { code: 0xFF30, lower: [], title: [], upper: [], fold: 0xFF50, foldFull: [ 0xFF50 ] }
  , { code: 0xFF31, lower: [], title: [], upper: [], fold: 0xFF51, foldFull: [ 0xFF51 ] }
  , { code: 0xFF32, lower: [], title: [], upper: [], fold: 0xFF52, foldFull: [ 0xFF52 ] }
  , { code: 0xFF33, lower: [], title: [], upper: [], fold: 0xFF53, foldFull: [ 0xFF53 ] }
  , { code: 0xFF34, lower: [], title: [], upper: [], fold: 0xFF54, foldFull: [ 0xFF54 ] }
  , { code: 0xFF35, lower: [], title: [], upper: [], fold: 0xFF55, foldFull: [ 0xFF55 ] }
  , { code: 0xFF36, lower: [], title: [], upper: [], fold: 0xFF56, foldFull: [ 0xFF56 ] }
  , { code: 0xFF37, lower: [], title: [], upper: [], fold: 0xFF57, foldFull: [ 0xFF57 ] }
  , { code: 0xFF38, lower: [], title: [], upper: [], fold: 0xFF58, foldFull: [ 0xFF58 ] }
  , { code: 0xFF39, lower: [], title: [], upper: [], fold: 0xFF59, foldFull: [ 0xFF59 ] }
  , { code: 0xFF3A, lower: [], title: [], upper: [], fold: 0xFF5A, foldFull: [ 0xFF5A ] }
  , { code: 0x10400, lower: [], title: [], upper: [], fold: 0x10428, foldFull: [ 0x10428 ] }
  , { code: 0x10401, lower: [], title: [], upper: [], fold: 0x10429, foldFull: [ 0x10429 ] }
  , { code: 0x10402, lower: [], title: [], upper: [], fold: 0x1042A, foldFull: [ 0x1042A ] }
  , { code: 0x10403, lower: [], title: [], upper: [], fold: 0x1042B, foldFull: [ 0x1042B ] }
  , { code: 0x10404, lower: [], title: [], upper: [], fold: 0x1042C, foldFull: [ 0x1042C ] }
  , { code: 0x10405, lower: [], title: [], upper: [], fold: 0x1042D, foldFull: [ 0x1042D ] }
  , { code: 0x10406, lower: [], title: [], upper: [], fold: 0x1042E, foldFull: [ 0x1042E ] }
  , { code: 0x10407, lower: [], title: [], upper: [], fold: 0x1042F, foldFull: [ 0x1042F ] }
  , { code: 0x10408, lower: [], title: [], upper: [], fold: 0x10430, foldFull: [ 0x10430 ] }
  , { code: 0x10409, lower: [], title: [], upper: [], fold: 0x10431, foldFull: [ 0x10431 ] }
  , { code: 0x1040A, lower: [], title: [], upper: [], fold: 0x10432, foldFull: [ 0x10432 ] }
  , { code: 0x1040B, lower: [], title: [], upper: [], fold: 0x10433, foldFull: [ 0x10433 ] }
  , { code: 0x1040C, lower: [], title: [], upper: [], fold: 0x10434, foldFull: [ 0x10434 ] }
  , { code: 0x1040D, lower: [], title: [], upper: [], fold: 0x10435, foldFull: [ 0x10435 ] }
  , { code: 0x1040E, lower: [], title: [], upper: [], fold: 0x10436, foldFull: [ 0x10436 ] }
  , { code: 0x1040F, lower: [], title: [], upper: [], fold: 0x10437, foldFull: [ 0x10437 ] }
  , { code: 0x10410, lower: [], title: [], upper: [], fold: 0x10438, foldFull: [ 0x10438 ] }
  , { code: 0x10411, lower: [], title: [], upper: [], fold: 0x10439, foldFull: [ 0x10439 ] }
  , { code: 0x10412, lower: [], title: [], upper: [], fold: 0x1043A, foldFull: [ 0x1043A ] }
  , { code: 0x10413, lower: [], title: [], upper: [], fold: 0x1043B, foldFull: [ 0x1043B ] }
  , { code: 0x10414, lower: [], title: [], upper: [], fold: 0x1043C, foldFull: [ 0x1043C ] }
  , { code: 0x10415, lower: [], title: [], upper: [], fold: 0x1043D, foldFull: [ 0x1043D ] }
  , { code: 0x10416, lower: [], title: [], upper: [], fold: 0x1043E, foldFull: [ 0x1043E ] }
  , { code: 0x10417, lower: [], title: [], upper: [], fold: 0x1043F, foldFull: [ 0x1043F ] }
  , { code: 0x10418, lower: [], title: [], upper: [], fold: 0x10440, foldFull: [ 0x10440 ] }
  , { code: 0x10419, lower: [], title: [], upper: [], fold: 0x10441, foldFull: [ 0x10441 ] }
  , { code: 0x1041A, lower: [], title: [], upper: [], fold: 0x10442, foldFull: [ 0x10442 ] }
  , { code: 0x1041B, lower: [], title: [], upper: [], fold: 0x10443, foldFull: [ 0x10443 ] }
  , { code: 0x1041C, lower: [], title: [], upper: [], fold: 0x10444, foldFull: [ 0x10444 ] }
  , { code: 0x1041D, lower: [], title: [], upper: [], fold: 0x10445, foldFull: [ 0x10445 ] }
  , { code: 0x1041E, lower: [], title: [], upper: [], fold: 0x10446, foldFull: [ 0x10446 ] }
  , { code: 0x1041F, lower: [], title: [], upper: [], fold: 0x10447, foldFull: [ 0x10447 ] }
  , { code: 0x10420, lower: [], title: [], upper: [], fold: 0x10448, foldFull: [ 0x10448 ] }
  , { code: 0x10421, lower: [], title: [], upper: [], fold: 0x10449, foldFull: [ 0x10449 ] }
  , { code: 0x10422, lower: [], title: [], upper: [], fold: 0x1044A, foldFull: [ 0x1044A ] }
  , { code: 0x10423, lower: [], title: [], upper: [], fold: 0x1044B, foldFull: [ 0x1044B ] }
  , { code: 0x10424, lower: [], title: [], upper: [], fold: 0x1044C, foldFull: [ 0x1044C ] }
  , { code: 0x10425, lower: [], title: [], upper: [], fold: 0x1044D, foldFull: [ 0x1044D ] }
  , { code: 0x10426, lower: [], title: [], upper: [], fold: 0x1044E, foldFull: [ 0x1044E ] }
  , { code: 0x10427, lower: [], title: [], upper: [], fold: 0x1044F, foldFull: [ 0x1044F ] }
  , { code: 0x104B0, lower: [], title: [], upper: [], fold: 0x104D8, foldFull: [ 0x104D8 ] }
  , { code: 0x104B1, lower: [], title: [], upper: [], fold: 0x104D9, foldFull: [ 0x104D9 ] }
  , { code: 0x104B2, lower: [], title: [], upper: [], fold: 0x104DA, foldFull: [ 0x104DA ] }
  , { code: 0x104B3, lower: [], title: [], upper: [], fold: 0x104DB, foldFull: [ 0x104DB ] }
  , { code: 0x104B4, lower: [], title: [], upper: [], fold: 0x104DC, foldFull: [ 0x104DC ] }
  , { code: 0x104B5, lower: [], title: [], upper: [], fold: 0x104DD, foldFull: [ 0x104DD ] }
  , { code: 0x104B6, lower: [], title: [], upper: [], fold: 0x104DE, foldFull: [ 0x104DE ] }
  , { code: 0x104B7, lower: [], title: [], upper: [], fold: 0x104DF, foldFull: [ 0x104DF ] }
  , { code: 0x104B8, lower: [], title: [], upper: [], fold: 0x104E0, foldFull: [ 0x104E0 ] }
  , { code: 0x104B9, lower: [], title: [], upper: [], fold: 0x104E1, foldFull: [ 0x104E1 ] }
  , { code: 0x104BA, lower: [], title: [], upper: [], fold: 0x104E2, foldFull: [ 0x104E2 ] }
  , { code: 0x104BB, lower: [], title: [], upper: [], fold: 0x104E3, foldFull: [ 0x104E3 ] }
  , { code: 0x104BC, lower: [], title: [], upper: [], fold: 0x104E4, foldFull: [ 0x104E4 ] }
  , { code: 0x104BD, lower: [], title: [], upper: [], fold: 0x104E5, foldFull: [ 0x104E5 ] }
  , { code: 0x104BE, lower: [], title: [], upper: [], fold: 0x104E6, foldFull: [ 0x104E6 ] }
  , { code: 0x104BF, lower: [], title: [], upper: [], fold: 0x104E7, foldFull: [ 0x104E7 ] }
  , { code: 0x104C0, lower: [], title: [], upper: [], fold: 0x104E8, foldFull: [ 0x104E8 ] }
  , { code: 0x104C1, lower: [], title: [], upper: [], fold: 0x104E9, foldFull: [ 0x104E9 ] }
  , { code: 0x104C2, lower: [], title: [], upper: [], fold: 0x104EA, foldFull: [ 0x104EA ] }
  , { code: 0x104C3, lower: [], title: [], upper: [], fold: 0x104EB, foldFull: [ 0x104EB ] }
  , { code: 0x104C4, lower: [], title: [], upper: [], fold: 0x104EC, foldFull: [ 0x104EC ] }
  , { code: 0x104C5, lower: [], title: [], upper: [], fold: 0x104ED, foldFull: [ 0x104ED ] }
  , { code: 0x104C6, lower: [], title: [], upper: [], fold: 0x104EE, foldFull: [ 0x104EE ] }
  , { code: 0x104C7, lower: [], title: [], upper: [], fold: 0x104EF, foldFull: [ 0x104EF ] }
  , { code: 0x104C8, lower: [], title: [], upper: [], fold: 0x104F0, foldFull: [ 0x104F0 ] }
  , { code: 0x104C9, lower: [], title: [], upper: [], fold: 0x104F1, foldFull: [ 0x104F1 ] }
  , { code: 0x104CA, lower: [], title: [], upper: [], fold: 0x104F2, foldFull: [ 0x104F2 ] }
  , { code: 0x104CB, lower: [], title: [], upper: [], fold: 0x104F3, foldFull: [ 0x104F3 ] }
  , { code: 0x104CC, lower: [], title: [], upper: [], fold: 0x104F4, foldFull: [ 0x104F4 ] }
  , { code: 0x104CD, lower: [], title: [], upper: [], fold: 0x104F5, foldFull: [ 0x104F5 ] }
  , { code: 0x104CE, lower: [], title: [], upper: [], fold: 0x104F6, foldFull: [ 0x104F6 ] }
  , { code: 0x104CF, lower: [], title: [], upper: [], fold: 0x104F7, foldFull: [ 0x104F7 ] }
  , { code: 0x104D0, lower: [], title: [], upper: [], fold: 0x104F8, foldFull: [ 0x104F8 ] }
  , { code: 0x104D1, lower: [], title: [], upper: [], fold: 0x104F9, foldFull: [ 0x104F9 ] }
  , { code: 0x104D2, lower: [], title: [], upper: [], fold: 0x104FA, foldFull: [ 0x104FA ] }
  , { code: 0x104D3, lower: [], title: [], upper: [], fold: 0x104FB, foldFull: [ 0x104FB ] }
  , { code: 0x10C80, lower: [], title: [], upper: [], fold: 0x10CC0, foldFull: [ 0x10CC0 ] }
  , { code: 0x10C81, lower: [], title: [], upper: [], fold: 0x10CC1, foldFull: [ 0x10CC1 ] }
  , { code: 0x10C82, lower: [], title: [], upper: [], fold: 0x10CC2, foldFull: [ 0x10CC2 ] }
  , { code: 0x10C83, lower: [], title: [], upper: [], fold: 0x10CC3, foldFull: [ 0x10CC3 ] }
  , { code: 0x10C84, lower: [], title: [], upper: [], fold: 0x10CC4, foldFull: [ 0x10CC4 ] }
  , { code: 0x10C85, lower: [], title: [], upper: [], fold: 0x10CC5, foldFull: [ 0x10CC5 ] }
  , { code: 0x10C86, lower: [], title: [], upper: [], fold: 0x10CC6, foldFull: [ 0x10CC6 ] }
  , { code: 0x10C87, lower: [], title: [], upper: [], fold: 0x10CC7, foldFull: [ 0x10CC7 ] }
  , { code: 0x10C88, lower: [], title: [], upper: [], fold: 0x10CC8, foldFull: [ 0x10CC8 ] }
  , { code: 0x10C89, lower: [], title: [], upper: [], fold: 0x10CC9, foldFull: [ 0x10CC9 ] }
  , { code: 0x10C8A, lower: [], title: [], upper: [], fold: 0x10CCA, foldFull: [ 0x10CCA ] }
  , { code: 0x10C8B, lower: [], title: [], upper: [], fold: 0x10CCB, foldFull: [ 0x10CCB ] }
  , { code: 0x10C8C, lower: [], title: [], upper: [], fold: 0x10CCC, foldFull: [ 0x10CCC ] }
  , { code: 0x10C8D, lower: [], title: [], upper: [], fold: 0x10CCD, foldFull: [ 0x10CCD ] }
  , { code: 0x10C8E, lower: [], title: [], upper: [], fold: 0x10CCE, foldFull: [ 0x10CCE ] }
  , { code: 0x10C8F, lower: [], title: [], upper: [], fold: 0x10CCF, foldFull: [ 0x10CCF ] }
  , { code: 0x10C90, lower: [], title: [], upper: [], fold: 0x10CD0, foldFull: [ 0x10CD0 ] }
  , { code: 0x10C91, lower: [], title: [], upper: [], fold: 0x10CD1, foldFull: [ 0x10CD1 ] }
  , { code: 0x10C92, lower: [], title: [], upper: [], fold: 0x10CD2, foldFull: [ 0x10CD2 ] }
  , { code: 0x10C93, lower: [], title: [], upper: [], fold: 0x10CD3, foldFull: [ 0x10CD3 ] }
  , { code: 0x10C94, lower: [], title: [], upper: [], fold: 0x10CD4, foldFull: [ 0x10CD4 ] }
  , { code: 0x10C95, lower: [], title: [], upper: [], fold: 0x10CD5, foldFull: [ 0x10CD5 ] }
  , { code: 0x10C96, lower: [], title: [], upper: [], fold: 0x10CD6, foldFull: [ 0x10CD6 ] }
  , { code: 0x10C97, lower: [], title: [], upper: [], fold: 0x10CD7, foldFull: [ 0x10CD7 ] }
  , { code: 0x10C98, lower: [], title: [], upper: [], fold: 0x10CD8, foldFull: [ 0x10CD8 ] }
  , { code: 0x10C99, lower: [], title: [], upper: [], fold: 0x10CD9, foldFull: [ 0x10CD9 ] }
  , { code: 0x10C9A, lower: [], title: [], upper: [], fold: 0x10CDA, foldFull: [ 0x10CDA ] }
  , { code: 0x10C9B, lower: [], title: [], upper: [], fold: 0x10CDB, foldFull: [ 0x10CDB ] }
  , { code: 0x10C9C, lower: [], title: [], upper: [], fold: 0x10CDC, foldFull: [ 0x10CDC ] }
  , { code: 0x10C9D, lower: [], title: [], upper: [], fold: 0x10CDD, foldFull: [ 0x10CDD ] }
  , { code: 0x10C9E, lower: [], title: [], upper: [], fold: 0x10CDE, foldFull: [ 0x10CDE ] }
  , { code: 0x10C9F, lower: [], title: [], upper: [], fold: 0x10CDF, foldFull: [ 0x10CDF ] }
  , { code: 0x10CA0, lower: [], title: [], upper: [], fold: 0x10CE0, foldFull: [ 0x10CE0 ] }
  , { code: 0x10CA1, lower: [], title: [], upper: [], fold: 0x10CE1, foldFull: [ 0x10CE1 ] }
  , { code: 0x10CA2, lower: [], title: [], upper: [], fold: 0x10CE2, foldFull: [ 0x10CE2 ] }
  , { code: 0x10CA3, lower: [], title: [], upper: [], fold: 0x10CE3, foldFull: [ 0x10CE3 ] }
  , { code: 0x10CA4, lower: [], title: [], upper: [], fold: 0x10CE4, foldFull: [ 0x10CE4 ] }
  , { code: 0x10CA5, lower: [], title: [], upper: [], fold: 0x10CE5, foldFull: [ 0x10CE5 ] }
  , { code: 0x10CA6, lower: [], title: [], upper: [], fold: 0x10CE6, foldFull: [ 0x10CE6 ] }
  , { code: 0x10CA7, lower: [], title: [], upper: [], fold: 0x10CE7, foldFull: [ 0x10CE7 ] }
  , { code: 0x10CA8, lower: [], title: [], upper: [], fold: 0x10CE8, foldFull: [ 0x10CE8 ] }
  , { code: 0x10CA9, lower: [], title: [], upper: [], fold: 0x10CE9, foldFull: [ 0x10CE9 ] }
  , { code: 0x10CAA, lower: [], title: [], upper: [], fold: 0x10CEA, foldFull: [ 0x10CEA ] }
  , { code: 0x10CAB, lower: [], title: [], upper: [], fold: 0x10CEB, foldFull: [ 0x10CEB ] }
  , { code: 0x10CAC, lower: [], title: [], upper: [], fold: 0x10CEC, foldFull: [ 0x10CEC ] }
  , { code: 0x10CAD, lower: [], title: [], upper: [], fold: 0x10CED, foldFull: [ 0x10CED ] }
  , { code: 0x10CAE, lower: [], title: [], upper: [], fold: 0x10CEE, foldFull: [ 0x10CEE ] }
  , { code: 0x10CAF, lower: [], title: [], upper: [], fold: 0x10CEF, foldFull: [ 0x10CEF ] }
  , { code: 0x10CB0, lower: [], title: [], upper: [], fold: 0x10CF0, foldFull: [ 0x10CF0 ] }
  , { code: 0x10CB1, lower: [], title: [], upper: [], fold: 0x10CF1, foldFull: [ 0x10CF1 ] }
  , { code: 0x10CB2, lower: [], title: [], upper: [], fold: 0x10CF2, foldFull: [ 0x10CF2 ] }
  , { code: 0x118A0, lower: [], title: [], upper: [], fold: 0x118C0, foldFull: [ 0x118C0 ] }
  , { code: 0x118A1, lower: [], title: [], upper: [], fold: 0x118C1, foldFull: [ 0x118C1 ] }
  , { code: 0x118A2, lower: [], title: [], upper: [], fold: 0x118C2, foldFull: [ 0x118C2 ] }
  , { code: 0x118A3, lower: [], title: [], upper: [], fold: 0x118C3, foldFull: [ 0x118C3 ] }
  , { code: 0x118A4, lower: [], title: [], upper: [], fold: 0x118C4, foldFull: [ 0x118C4 ] }
  , { code: 0x118A5, lower: [], title: [], upper: [], fold: 0x118C5, foldFull: [ 0x118C5 ] }
  , { code: 0x118A6, lower: [], title: [], upper: [], fold: 0x118C6, foldFull: [ 0x118C6 ] }
  , { code: 0x118A7, lower: [], title: [], upper: [], fold: 0x118C7, foldFull: [ 0x118C7 ] }
  , { code: 0x118A8, lower: [], title: [], upper: [], fold: 0x118C8, foldFull: [ 0x118C8 ] }
  , { code: 0x118A9, lower: [], title: [], upper: [], fold: 0x118C9, foldFull: [ 0x118C9 ] }
  , { code: 0x118AA, lower: [], title: [], upper: [], fold: 0x118CA, foldFull: [ 0x118CA ] }
  , { code: 0x118AB, lower: [], title: [], upper: [], fold: 0x118CB, foldFull: [ 0x118CB ] }
  , { code: 0x118AC, lower: [], title: [], upper: [], fold: 0x118CC, foldFull: [ 0x118CC ] }
  , { code: 0x118AD, lower: [], title: [], upper: [], fold: 0x118CD, foldFull: [ 0x118CD ] }
  , { code: 0x118AE, lower: [], title: [], upper: [], fold: 0x118CE, foldFull: [ 0x118CE ] }
  , { code: 0x118AF, lower: [], title: [], upper: [], fold: 0x118CF, foldFull: [ 0x118CF ] }
  , { code: 0x118B0, lower: [], title: [], upper: [], fold: 0x118D0, foldFull: [ 0x118D0 ] }
  , { code: 0x118B1, lower: [], title: [], upper: [], fold: 0x118D1, foldFull: [ 0x118D1 ] }
  , { code: 0x118B2, lower: [], title: [], upper: [], fold: 0x118D2, foldFull: [ 0x118D2 ] }
  , { code: 0x118B3, lower: [], title: [], upper: [], fold: 0x118D3, foldFull: [ 0x118D3 ] }
  , { code: 0x118B4, lower: [], title: [], upper: [], fold: 0x118D4, foldFull: [ 0x118D4 ] }
  , { code: 0x118B5, lower: [], title: [], upper: [], fold: 0x118D5, foldFull: [ 0x118D5 ] }
  , { code: 0x118B6, lower: [], title: [], upper: [], fold: 0x118D6, foldFull: [ 0x118D6 ] }
  , { code: 0x118B7, lower: [], title: [], upper: [], fold: 0x118D7, foldFull: [ 0x118D7 ] }
  , { code: 0x118B8, lower: [], title: [], upper: [], fold: 0x118D8, foldFull: [ 0x118D8 ] }
  , { code: 0x118B9, lower: [], title: [], upper: [], fold: 0x118D9, foldFull: [ 0x118D9 ] }
  , { code: 0x118BA, lower: [], title: [], upper: [], fold: 0x118DA, foldFull: [ 0x118DA ] }
  , { code: 0x118BB, lower: [], title: [], upper: [], fold: 0x118DB, foldFull: [ 0x118DB ] }
  , { code: 0x118BC, lower: [], title: [], upper: [], fold: 0x118DC, foldFull: [ 0x118DC ] }
  , { code: 0x118BD, lower: [], title: [], upper: [], fold: 0x118DD, foldFull: [ 0x118DD ] }
  , { code: 0x118BE, lower: [], title: [], upper: [], fold: 0x118DE, foldFull: [ 0x118DE ] }
  , { code: 0x118BF, lower: [], title: [], upper: [], fold: 0x118DF, foldFull: [ 0x118DF ] }
  , { code: 0x16E40, lower: [], title: [], upper: [], fold: 0x16E60, foldFull: [ 0x16E60 ] }
  , { code: 0x16E41, lower: [], title: [], upper: [], fold: 0x16E61, foldFull: [ 0x16E61 ] }
  , { code: 0x16E42, lower: [], title: [], upper: [], fold: 0x16E62, foldFull: [ 0x16E62 ] }
  , { code: 0x16E43, lower: [], title: [], upper: [], fold: 0x16E63, foldFull: [ 0x16E63 ] }
  , { code: 0x16E44, lower: [], title: [], upper: [], fold: 0x16E64, foldFull: [ 0x16E64 ] }
  , { code: 0x16E45, lower: [], title: [], upper: [], fold: 0x16E65, foldFull: [ 0x16E65 ] }
  , { code: 0x16E46, lower: [], title: [], upper: [], fold: 0x16E66, foldFull: [ 0x16E66 ] }
  , { code: 0x16E47, lower: [], title: [], upper: [], fold: 0x16E67, foldFull: [ 0x16E67 ] }
  , { code: 0x16E48, lower: [], title: [], upper: [], fold: 0x16E68, foldFull: [ 0x16E68 ] }
  , { code: 0x16E49, lower: [], title: [], upper: [], fold: 0x16E69, foldFull: [ 0x16E69 ] }
  , { code: 0x16E4A, lower: [], title: [], upper: [], fold: 0x16E6A, foldFull: [ 0x16E6A ] }
  , { code: 0x16E4B, lower: [], title: [], upper: [], fold: 0x16E6B, foldFull: [ 0x16E6B ] }
  , { code: 0x16E4C, lower: [], title: [], upper: [], fold: 0x16E6C, foldFull: [ 0x16E6C ] }
  , { code: 0x16E4D, lower: [], title: [], upper: [], fold: 0x16E6D, foldFull: [ 0x16E6D ] }
  , { code: 0x16E4E, lower: [], title: [], upper: [], fold: 0x16E6E, foldFull: [ 0x16E6E ] }
  , { code: 0x16E4F, lower: [], title: [], upper: [], fold: 0x16E6F, foldFull: [ 0x16E6F ] }
  , { code: 0x16E50, lower: [], title: [], upper: [], fold: 0x16E70, foldFull: [ 0x16E70 ] }
  , { code: 0x16E51, lower: [], title: [], upper: [], fold: 0x16E71, foldFull: [ 0x16E71 ] }
  , { code: 0x16E52, lower: [], title: [], upper: [], fold: 0x16E72, foldFull: [ 0x16E72 ] }
  , { code: 0x16E53, lower: [], title: [], upper: [], fold: 0x16E73, foldFull: [ 0x16E73 ] }
  , { code: 0x16E54, lower: [], title: [], upper: [], fold: 0x16E74, foldFull: [ 0x16E74 ] }
  , { code: 0x16E55, lower: [], title: [], upper: [], fold: 0x16E75, foldFull: [ 0x16E75 ] }
  , { code: 0x16E56, lower: [], title: [], upper: [], fold: 0x16E76, foldFull: [ 0x16E76 ] }
  , { code: 0x16E57, lower: [], title: [], upper: [], fold: 0x16E77, foldFull: [ 0x16E77 ] }
  , { code: 0x16E58, lower: [], title: [], upper: [], fold: 0x16E78, foldFull: [ 0x16E78 ] }
  , { code: 0x16E59, lower: [], title: [], upper: [], fold: 0x16E79, foldFull: [ 0x16E79 ] }
  , { code: 0x16E5A, lower: [], title: [], upper: [], fold: 0x16E7A, foldFull: [ 0x16E7A ] }
  , { code: 0x16E5B, lower: [], title: [], upper: [], fold: 0x16E7B, foldFull: [ 0x16E7B ] }
  , { code: 0x16E5C, lower: [], title: [], upper: [], fold: 0x16E7C, foldFull: [ 0x16E7C ] }
  , { code: 0x16E5D, lower: [], title: [], upper: [], fold: 0x16E7D, foldFull: [ 0x16E7D ] }
  , { code: 0x16E5E, lower: [], title: [], upper: [], fold: 0x16E7E, foldFull: [ 0x16E7E ] }
  , { code: 0x16E5F, lower: [], title: [], upper: [], fold: 0x16E7F, foldFull: [ 0x16E7F ] }
  , { code: 0x1E900, lower: [], title: [], upper: [], fold: 0x1E922, foldFull: [ 0x1E922 ] }
  , { code: 0x1E901, lower: [], title: [], upper: [], fold: 0x1E923, foldFull: [ 0x1E923 ] }
  , { code: 0x1E902, lower: [], title: [], upper: [], fold: 0x1E924, foldFull: [ 0x1E924 ] }
  , { code: 0x1E903, lower: [], title: [], upper: [], fold: 0x1E925, foldFull: [ 0x1E925 ] }
  , { code: 0x1E904, lower: [], title: [], upper: [], fold: 0x1E926, foldFull: [ 0x1E926 ] }
  , { code: 0x1E905, lower: [], title: [], upper: [], fold: 0x1E927, foldFull: [ 0x1E927 ] }
  , { code: 0x1E906, lower: [], title: [], upper: [], fold: 0x1E928, foldFull: [ 0x1E928 ] }
  , { code: 0x1E907, lower: [], title: [], upper: [], fold: 0x1E929, foldFull: [ 0x1E929 ] }
  , { code: 0x1E908, lower: [], title: [], upper: [], fold: 0x1E92A, foldFull: [ 0x1E92A ] }
  , { code: 0x1E909, lower: [], title: [], upper: [], fold: 0x1E92B, foldFull: [ 0x1E92B ] }
  , { code: 0x1E90A, lower: [], title: [], upper: [], fold: 0x1E92C, foldFull: [ 0x1E92C ] }
  , { code: 0x1E90B, lower: [], title: [], upper: [], fold: 0x1E92D, foldFull: [ 0x1E92D ] }
  , { code: 0x1E90C, lower: [], title: [], upper: [], fold: 0x1E92E, foldFull: [ 0x1E92E ] }
  , { code: 0x1E90D, lower: [], title: [], upper: [], fold: 0x1E92F, foldFull: [ 0x1E92F ] }
  , { code: 0x1E90E, lower: [], title: [], upper: [], fold: 0x1E930, foldFull: [ 0x1E930 ] }
  , { code: 0x1E90F, lower: [], title: [], upper: [], fold: 0x1E931, foldFull: [ 0x1E931 ] }
  , { code: 0x1E910, lower: [], title: [], upper: [], fold: 0x1E932, foldFull: [ 0x1E932 ] }
  , { code: 0x1E911, lower: [], title: [], upper: [], fold: 0x1E933, foldFull: [ 0x1E933 ] }
  , { code: 0x1E912, lower: [], title: [], upper: [], fold: 0x1E934, foldFull: [ 0x1E934 ] }
  , { code: 0x1E913, lower: [], title: [], upper: [], fold: 0x1E935, foldFull: [ 0x1E935 ] }
  , { code: 0x1E914, lower: [], title: [], upper: [], fold: 0x1E936, foldFull: [ 0x1E936 ] }
  , { code: 0x1E915, lower: [], title: [], upper: [], fold: 0x1E937, foldFull: [ 0x1E937 ] }
  , { code: 0x1E916, lower: [], title: [], upper: [], fold: 0x1E938, foldFull: [ 0x1E938 ] }
  , { code: 0x1E917, lower: [], title: [], upper: [], fold: 0x1E939, foldFull: [ 0x1E939 ] }
  , { code: 0x1E918, lower: [], title: [], upper: [], fold: 0x1E93A, foldFull: [ 0x1E93A ] }
  , { code: 0x1E919, lower: [], title: [], upper: [], fold: 0x1E93B, foldFull: [ 0x1E93B ] }
  , { code: 0x1E91A, lower: [], title: [], upper: [], fold: 0x1E93C, foldFull: [ 0x1E93C ] }
  , { code: 0x1E91B, lower: [], title: [], upper: [], fold: 0x1E93D, foldFull: [ 0x1E93D ] }
  , { code: 0x1E91C, lower: [], title: [], upper: [], fold: 0x1E93E, foldFull: [ 0x1E93E ] }
  , { code: 0x1E91D, lower: [], title: [], upper: [], fold: 0x1E93F, foldFull: [ 0x1E93F ] }
  , { code: 0x1E91E, lower: [], title: [], upper: [], fold: 0x1E940, foldFull: [ 0x1E940 ] }
  , { code: 0x1E91F, lower: [], title: [], upper: [], fold: 0x1E941, foldFull: [ 0x1E941 ] }
  , { code: 0x1E920, lower: [], title: [], upper: [], fold: 0x1E942, foldFull: [ 0x1E942 ] }
  , { code: 0x1E921, lower: [], title: [], upper: [], fold: 0x1E943, foldFull: [ 0x1E943 ] }
  ]

zeroRec :: Int -> CaseRec
zeroRec code = { code, lower: [], title: [], upper: [], fold: 0, foldFull: [] }

recCmp :: CaseRec -> CaseRec -> Ordering
recCmp { code } { code: code' } = compare code code'

findRule :: Int -> CaseRec
findRule code = case bsearch (zeroRec code) rules (Array.length rules) recCmp of
  Nothing -> zeroRec code
  Just r -> r

fold :: Int -> Int
fold code =
  let
    folded = (findRule code).fold
  in
    if folded == 0 then uTowlower code else folded

foldFull :: Int -> Array Int
foldFull code =
  let
    folded = (findRule code).foldFull
  in
    if Array.null folded then [ uTowlower code ] else folded

lower :: Int -> Array Int
lower code =
  let
    lowered = (findRule code).lower
  in
    if Array.null lowered then [ uTowlower code ] else lowered

title :: Int -> Array Int
title code =
  let
    titled = (findRule code).title
  in
    if Array.null titled then [ uTowtitle code ] else titled

upper :: Int -> Array Int
upper code =
  let
    uppered = (findRule code).upper
  in
    if Array.null uppered then [ uTowupper code ] else uppered
