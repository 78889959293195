export function data_(e) {
  return e.data;
}

export function origin(e) {
  return e.origin;
}

export function lastEventId(e) {
  return e.lastEventId;
}
