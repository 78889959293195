module Gargantext.Components.Notifications where

import Control.Monad.Except.Trans (runExceptT)
import Data.Array as A
import Data.Either (Either(..))
import Data.Eq.Generic (genericEq)
import Data.FoldableWithIndex (foldlWithIndex, foldMapWithIndex)
import Data.Generic.Rep (class Generic)
import Data.Hashable (class Hashable, hash)
import Data.HashMap as HM
import Data.Maybe (Maybe(..), fromMaybe, isJust)
import Data.Show.Generic (genericShow)
import Data.Traversable (for, traverse)
import Data.Tuple (Tuple(..))
import Effect (Effect)
import Effect.Ref as Ref
import Effect.Timer (setTimeout)
import Effect.Var (($=))
import Effect.Var as Var
import Foreign as F
import Gargantext.Components.Notifications.Types
import Gargantext.Sessions.Types (Session(..))
import Gargantext.Types as GT
import Gargantext.Utils (protocol)
import Gargantext.Utils.Reactix as R2
import Prelude
import Reactix as R
import Simple.JSON as JSON
import Web.Socket.Event.MessageEvent as ME
import WebSocket as WS


here :: R2.Here
here = R2.here "Gargantext.Components.Notifications"

insertCallback :: State -> Topic -> UUID -> Callback -> State
insertCallback (State state@{ callbacks }) topic uuid cb =
  State $ state { callbacks = HM.alter alterCallbacksHM topic callbacks }
  where
    alterCallbacksHM :: Maybe CallbacksHM -> Maybe CallbacksHM
    alterCallbacksHM Nothing = Just $ HM.singleton uuid cb
    alterCallbacksHM (Just hm) = Just $ HM.insert uuid cb hm

removeCallback :: State -> Topic -> UUID -> State
removeCallback (State state@{ callbacks }) topic uuid =
  State $ state { callbacks = HM.alter alterCallbacksHM topic callbacks }
  where
    alterCallbacksHM :: Maybe CallbacksHM -> Maybe CallbacksHM
    alterCallbacksHM Nothing = Nothing
    alterCallbacksHM (Just hm) = Just $ HM.delete uuid hm

-- | Execute all callbacks for a given Notification
callNotification :: State -> Notification -> Effect Unit
callNotification (State { callbacks }) (Notification topic message) = do
  -- here.log2 "[callTopic] topic" topic
  -- here.log2 "[callTopic] callbacks" (HM.values callbacks)
  -- here.log2 "[callTopic] topicCallbacks" (HM.values topicCallbacks)
  _ <- for (HM.values topicCallbacks) $ \cb -> do
    cb message
  pure unit
  where
    topicCallbacks :: CallbacksHM
    topicCallbacks = fromMaybe HM.empty $ HM.lookup topic callbacks



isConnected :: WSNotification -> Effect Boolean
isConnected (WSNotification { connection }) =do
  mConn <- Ref.read connection
  mRs <- traverse (\(WS.Connection conn) -> do
      rs <- Var.get conn.readyState
      pure rs -- $ rs == WS.Open
           ) mConn
  pure $ mRs == Just WS.Open
  -- mConn <- AVar.tryRead connection
  -- case mConn of
  --   Nothing -> pure false
  --   Just conn -> do
-- isConnected (WSNotification { connection: Just (WS.Connection conn) }) = do
--   rs <- Var.get conn.readyState
--   pure $ rs == WS.Open

send :: forall a. (JSON.WriteForeign a) => WSNotification -> a -> Effect Unit
-- send (WSNotification { connection: Nothing }) _ = pure unit
-- send (WSNotification { connection: Just (WS.Connection conn) }) d =
send (WSNotification { connection }) d = do
  mConn <- Ref.read connection
  void $ traverse (\(WS.Connection conn) -> do
                      conn.send (WS.Message $ JSON.writeJSON d)
                      ) mConn

-- alterState :: WSNotification -> (State -> State) -> Effect Unit
-- alterState (WSNotification ws') stateCb = do
--   let state = ws' .. "state"
--   -- here.log2 "[alterState] state" state
--   void $ pure $ (ws' .= "state") (stateCb state)

alterState :: WSNotification -> (State -> State) -> Effect Unit
alterState (WSNotification ws') stateCb = do
  Ref.modify_ stateCb ws'.state
    

allSubscriptions :: State -> Array (Tuple Topic (Tuple UUID Callback))
allSubscriptions (State { callbacks }) =
  foldMapWithIndex outerFold callbacks
  where
    innerFold :: Topic -> UUID -> Callback -> Array (Tuple Topic (Tuple UUID Callback))
    innerFold topic uuid cb = [Tuple topic (Tuple uuid cb)]
    outerFold :: Topic -> CallbacksHM -> Array (Tuple Topic (Tuple UUID Callback))
    outerFold topic uuidCb = foldMapWithIndex (innerFold topic) uuidCb

allSubscriptionsWS :: WSNotification -> Effect (Array (Tuple Topic (Tuple UUID Callback)))
allSubscriptionsWS (WSNotification ws') = do
  state <- Ref.read ws'.state
  pure $ allSubscriptions state


performAction :: WSNotification -> Action -> Effect Unit
performAction ws (InsertCallback topic uuid cb) = do
  let subscription = WSSubscribe topic
  alterState ws (\s -> insertCallback s topic uuid cb)
  connected <- isConnected ws
  if connected
    then
      send ws subscription
    else do
      pure unit
      -- void $ pure $ (ws' .= "state") (insertCallback ws'.state topic uuid cb)
      -- WSNotification $ ws' { state = insertCallback ws'.state topic uuid cb }
performAction ws (RemoveCallback topic uuid) = do
  let subscription = WSUnsubscribe topic
  alterState ws (\s -> removeCallback s topic uuid)
  connected <- isConnected ws
  if connected
    then
      send ws subscription
    else do
      pure unit
      -- void $ pure $ (ws' .= "state") (removeCallback ws'.state topic uuid)
      -- WSNotification $ ws' { state = removeCallback ws'.state topic uuid }
performAction (WSNotification ws') (Call notification) = do
  state <- Ref.read ws'.state
  -- here.log2 "[performAction Call] state" state
  callNotification state notification


-- | Correctly choose between "ws" and "wss" protocols based on what
-- | is the current window location
wsProtocol :: Effect String
wsProtocol = do
  proto <- protocol
  pure (if proto == "http:" then "ws" else "wss")


-- | Main WebSockets connect functionality. Handles incoming messages,
-- | authorizes user (if logged in), tries to reconnect when the
-- | connection drops
connect :: WSNotification -> String -> (Maybe Session) -> Effect Unit
connect ws@(WSNotification ws') url session = do
  mConn <- Ref.read ws'.connection
  case mConn of
    Just _conn -> pure unit
    Nothing -> do
      connection@(WS.Connection conn) <- WS.newWebSocket (WS.URL url) []
      Ref.write (Just connection) ws'.connection
      
      let onmessage me = do
            s <- runExceptT $ F.readString (ME.data_ me)
            case s of
              Left err -> do
                here.log2 "[connect] data received is not a string - was expecting a JSON string!" err
              Right s' -> do
                let parsed = JSON.readJSON s' :: JSON.E Notification
                case parsed of
                  Left err -> do
                    here.log2 "[connect] Can't parse message" err
                  Right n@(Notification topic _message) -> do
                    -- here.log2 "[connect] notification" topic
                    performAction ws (Call n)
                  -- Right parsed' -> do
                  --   here.log2 "[connect] onmessage, F.readString" parsed'
                
      conn.onopen $= (\_ -> do
                         -- authorize user first
                         here.log2 "[connect] session" session
                         case session of
                           Just (Session { token }) ->
                             send ws $ WSAuthorize token
                           Nothing -> pure unit
                         -- send pending subscriptions
                         allSubs <- allSubscriptionsWS ws
                         void $ for allSubs $ \(Tuple topic _) -> do
                           let subscription = WSSubscribe topic
                           here.log2 "[connect] pending subscription" subscription
                           send ws subscription)

      conn.onclose $= (\_ -> do
                          Ref.write Nothing ws'.connection
                          void $ setTimeout 1000 $ do
                            connect ws url session
                      )
        
      conn.onmessage $= onmessage

      pure unit

mkWSNotification :: String -> Effect WSNotification
mkWSNotification url = do
  ws <- emptyWSNotification
  connect ws url Nothing
  pure ws

-- TODO useLoaderWithUpdate { loader, path, render, topic }
-- topic can be used to bind with a reload state for a component.
-- If a (Notification topic) arrives, we can bump up reload state
