-- |
-- The present module has been ported from Haskell to PureScript
-- by Nicolas Pouillard for the Gargantext projet.
--
-- Original Haskell code:
--   Copyright      : (c) 2010 Daniel Fischer
--   Licence        : BSD3
--   Maintainer     : Daniel Fischer <daniel.is.fischer@googlemail.com>
--
-- Simultaneous search for multiple patterns in a 'String'
-- using the Karp-Rabin algorithm.
--
-- A description of the algorithm for a single pattern can be found at
-- <http://www-igm.univ-mlv.fr/~lecroq/string/node5.html#SECTION0050>.
module Data.String.Search.KarpRabin (
                                    -- $overview

                                    -- ** Caution
                                    -- $caution

                                    -- * Function
                                    indicesOfAny
                                  , indicesOfAnyHashStruct
                                  , indicesOfAnyLegacy
                                  , fromCodePoint
                                  , Base
                                  , universalBase
                                  , Hash
                                  , HashStruct
                                  , hashStruct
                                  , RollingHash
                                  , mkRollingHash
                                  , hashU64
                                  , rehashU64
                                  ) where


import Data.Array as A
import Data.Array ((..))
import Data.Enum (fromEnum)
import Data.Foldable (class Foldable, minimum, foldl)
import Data.FunctorWithIndex (mapWithIndex)
import Data.Generic.Rep (class Generic)
import Data.List as L
import Data.Map as M
import Data.Maybe (Maybe(..), isJust, fromJust)
import Data.Show.Generic (genericShow)
import Data.String as S
import Data.String (CodePoint)
import Data.String.Search.Utils (slidingWindow)
import Data.Tuple (Tuple(..), snd)
import Data.UInt64 (UInt64, unsafeFromInt)  -- shl
-- import Debug (trace)
import Partial.Unsafe (unsafePartial)
import Prelude


type Base = UInt64
type Hash = UInt64


-- | Base that we will use in Karp-Rabin
universalBase :: Base
universalBase = unsafeFromInt 256


fromCodePoint :: CodePoint -> Base
fromCodePoint c = unsafeFromInt (fromEnum c)


-- Rolling hash implementation, see
-- https://en.wikipedia.org/wiki/Rabin%E2%80%93Karp_algorithm
newtype RollingHash = RollingHash {
    base    :: Base
  , len     :: Int
  , basePowLen :: Base  -- pow base len % modulus (stored for performance reasons)
  }

derive instance Generic RollingHash _
instance Show RollingHash where
  show = genericShow

mkRollingHash :: Base -> Int -> RollingHash
mkRollingHash base len = RollingHash { base
                                       -- , modulus
                                     , len
                                     , basePowLen }
  where
    -- basePowLen = foldl (\acc _l -> (acc*base) `mod` modulus) (unsafeFromInt 1) (1..(len - 1))
    basePowLen = foldl (\acc _l -> (acc*base)) (unsafeFromInt 1) (1..(len - 1))


-- | NOTE: xs must be of length RollingHash.len
hashU64 :: RollingHash -> Array Base -> Hash
hashU64 rh@(RollingHash { base }) xs =
  -- foldl (\acc x -> (((acc*base) `mod` modulus) + x) `mod` modulus) (unsafeFromInt 0) xs
  foldl (\acc x -> rehashU64 rh acc (unsafeFromInt 0) x) (unsafeFromInt 0) xs

-- | In this function we use the fact that UIn64 rolls automatically
-- | after 18446744073709551615ul.rehashU64 :: RollingHash -> Hash ->
-- | Base -> Base -> Hash
rehashU64 (RollingHash { base, basePowLen }) h old new =
  (h - old*basePowLen)*base + new


-- | This struct is for performance reasons.
type HashStruct = {
    hash       :: String -> Hash
  , hashMap    :: M.Map Hash (Array Int)
  , hLen       :: Int
  , pats       :: Array String
  , rehash     :: Hash -> CodePoint -> CodePoint -> Hash
  , rehashChar :: Hash -> Char -> Char -> Hash
  }

-- | This is a temporary structure, computed from given patterns, to
-- | make the function more performant (suppose we want to match the
-- | same patterns many times for a given string: we need to compute
-- | `hash` etc only once for these patterns).
hashStruct :: Array String -> HashStruct
hashStruct pats = { hash, hashMap, hLen, pats, rehash, rehashChar }
  where
    hLen = minimum1 32 (S.length <$> pats)

    rh = mkRollingHash universalBase hLen

    hash :: String -> Hash
    hash = hashU64 rh <<< map fromCodePoint <<< S.toCodePointArray <<< S.take hLen

    rehash :: Hash -> CodePoint -> CodePoint -> Hash
    rehash h o n = rehashU64 rh h (fromCodePoint o) (fromCodePoint n)

    rehashChar :: Hash -> Char -> Char -> Hash
    rehashChar h o n = rehash h (S.codePointFromChar o) (S.codePointFromChar n)
    
    hashMap :: M.Map Hash (Array Int)
    hashMap =
      M.fromFoldableWith (flip (<>))
                         (mapWithIndex (\i a -> Tuple (hash a) [i]) pats)



-- $overview
--
-- The Karp-Rabin algorithm works by calculating a hash of the pattern and
-- comparing that hash with the hash of a slice of the target string with
-- the same length as the pattern. If the hashes are equal, the slice of the
-- target is compared to the pattern byte for byte (since the hash
-- function generally isn't injective).
--
-- For a single pattern, this tends to be more efficient than the na&#239;ve
-- algorithm, but it cannot compete with algorithms like
-- Knuth-Morris-Pratt or Boyer-Moore.
--
-- However, the algorithm can be generalised to search for multiple patterns
-- simultaneously. If the shortest pattern has length @k@, hash the prefix of
-- length @k@ of all patterns and compare the hash of the target's slices of
-- length @k@ to them. If there's a match, check whether the slice is part
-- of an occurrence of the corresponding pattern.
--
-- With a hash-function that
--
--   * allows to compute the hash of one slice in constant time from the hash
--     of the previous slice, the new and the dropped character, and
--
--   * produces few spurious matches,
--
-- searching for occurrences of any of @n@ patterns has a best-case complexity
-- of /O/(@targetLength@ * @lookup n@). The worst-case complexity is
-- /O/(@targetLength@ * @lookup n@ * @sum patternLengths@), the average is
-- not much worse than the best case.
--
-- The functions in this module store the hashes of the patterns in an
-- 'Map', so the lookup is /O/(@log n@). Re-hashing is done in constant
-- time and spurious matches of the hashes /should be/ sufficiently rare.
-- The maximal length of the prefixes to be hashed is 32.

-- $caution
--
-- Unfortunately, the constant factors are high, so these functions are slow.
-- Unless the number of patterns to search for is high (larger than 50 at
-- least), repeated search for single patterns using Boyer-Moore or DFA and
-- manual merging of the indices is faster. /Much/ faster for less than 40
-- or so patterns.
--
-- In summary, this module is more of an interesting curiosity than anything
-- else.

-- | @'indicesOfAny'@ finds all occurrences of any of several non-empty patterns
--   in a strict target string. If no non-empty patterns are given,
--   the result is an empty array. Otherwise the result array contains
--   the pairs of all indices where any of the (non-empty) patterns start
--   and the array of all patterns starting at that index, the patterns being
--   represented by their (zero-based) position in the pattern array.
--   Empty patterns are filtered out before processing begins.
indicesOfAny :: Array String                  -- ^ Array of non-empty patterns
             -> String                        -- ^ String to search
             -> Array (Tuple Int (Array Int)) -- ^ Array of matches
indicesOfAny pats = if A.null nepats then const []
                    else strictMatcher $ hashStruct nepats
  where
    nepats = A.filter (not <<< S.null) pats


indicesOfAnyHashStruct :: HashStruct
                       -> String
                       -> Array (Tuple Int (Array Int))
indicesOfAnyHashStruct hs = if A.null nepats then const []
                            else strictMatcher hs
  where
    nepats = A.filter (not <<< S.null) hs.pats

------------------------------------------------------------------------------
--                                 Workers                                 --
------------------------------------------------------------------------------

-- rehash' :: UInt -> UInt -> UInt -> CodePoint -> CodePoint -> UInt
-- rehash' shDi out h o n =
--     (h `shl` shDi - (fromCodePoint o `shl` out)) + fromCodePoint n

minimum1 :: forall a f. Ord a => Foldable f => a -> f a -> a
minimum1 a fa =
  case minimum fa of
    Nothing -> a
    Just b  -> min a b

type GoAcc = Tuple Hash (Array (Tuple Int (Array Int)))

strictMatcher :: HashStruct -> String -> Array (Tuple Int (Array Int))
strictMatcher { hash, hashMap, hLen, pats, rehash } = unsafePartial search
  where
    search :: Partial => String -> Array (Tuple Int (Array Int))
    search str =
      if strLen < hLen then []
      else snd <$> foldl go (Tuple shash []) $ A.zip (0 .. maxIdx) window
          where
            -- | Sliding window of substrings. Note that we need to
            -- | add the last one.
            window :: Array (Array CodePoint)
            window = slidingWindow arr (hLen + 1) <> [A.slice maxIdx (maxIdx + hLen) arr]
            strLen = S.length str
            maxIdx = strLen - hLen
            arr = S.toCodePointArray str
            shash :: Hash
            shash = hash str
            go :: GoAcc -> Tuple Int (Array CodePoint) -> GoAcc
            go (Tuple h acc) (Tuple idx arr') =
              let rehashed = rehash h (fromJust $ A.head arr') (fromJust $ A.last arr')
                  -- _ = if A.elem " abd" pats then
                  --        trace ("[strictMatcher] " <> show { h
                  --                                          , hashMap
                  --                                          , acc
                  --                                          , idx
                  --                                          , arr': S.fromCodePointArray arr'
                  --                                          , arrHeadLast: S.fromCodePointArray [fromJust $ A.head arr', fromJust $ A.last arr']
                  --                                          , lookup: M.lookup h hashMap }) \_ -> unit
                  --     else
                  --       unit
                  el = case M.lookup h hashMap of
                    Nothing -> Nothing
                    Just ps ->
                      let str' = S.drop idx str
                          okay bs = isJust (S.stripPrefix (S.Pattern bs) str')
                          -- _ = trace ("[strictMatcher] " <> show { pats
                          --                                       , ps
                          --                                       , oks: (\x -> okay (A.unsafeIndex pats x)) <$> ps}) \_ -> unit
                      in
                      case A.filter (\x -> okay (A.unsafeIndex pats x)) ps of
                        [] -> Nothing
                        qs -> Just (Tuple idx qs)
                  acc' = case el of
                    Nothing -> acc
                    Just el' -> A.snoc acc el'
              in
               Tuple rehashed acc'


-- LEGACY STUFF

indicesOfAnyLegacy :: Array String                  -- ^ Array of non-empty patterns
                   -> String                        -- ^ String to search
                   -> Array (Tuple Int (Array Int)) -- ^ Array of matches
indicesOfAnyLegacy pats = if A.null nepats then const []
                          else strictMatcherLegacy $ hashStruct nepats
      where
        nepats = A.filter (not <<< S.null) pats

-- | This function works correctly, however it has recursion error
-- | problems inside `search`.
strictMatcherLegacy :: HashStruct -> String -> Array (Tuple Int (Array Int))
strictMatcherLegacy { hash, hashMap, hLen, pats, rehash } = unsafePartial search
  where
    search :: Partial => String -> Array (Tuple Int (Array Int))
    search str = if strLen < hLen then []
                 else A.fromFoldable (go 0 shash)
      where
        arr = S.toCodePointArray str
        shash :: Hash
        shash = hash str
        strLen = S.length str
        maxIdx = strLen - hLen

        strAt i = A.unsafeIndex arr i

        go :: Int -> Hash -> L.List (Tuple Int (Array Int))
        go sI h =
          let hd = strAt sI
              isEnd = sI == maxIdx
          in
           case M.lookup h hashMap of
             Nothing ->
               if isEnd then L.Nil
               else go (sI + 1) (rehash h hd (strAt (sI + hLen)))
             Just ps ->
               let rst = S.drop sI str
                   more = if isEnd then L.Nil
                          else go (sI + 1) (rehash h hd (strAt (sI + hLen)))
                   okay bs =
                     isJust (S.stripPrefix (S.Pattern bs) rst)
               in case A.filter (\x -> okay (A.unsafeIndex pats x)) ps of
                 [] -> more
                 qs -> Tuple sI qs L.: more

--            go sI h =
--              case M.lookup h hashMap of
--                Nothing ->
--                  if sI == maxIdx
--                    then L.Nil
--                    else go (sI + 1) (rehash h (strAt sI) (strAt (sI + hLen)))
--                Just ps ->
--                  let rst = S.drop sI str
--                      hd  = strAt sI
--                      more = if sI == maxIdx then L.Nil else
--                                go (sI + 1) (rehash h hd (strAt (sI + hLen)))
--                      okay bs =
--                        isJust (S.stripPrefix (S.Pattern bs) rst)
--                  in case A.filter (\x -> okay (A.unsafeIndex pats x)) ps of
--                           [] -> more
--                           qs -> Tuple sI qs L.: more
