module Gargantext.Components.PhyloExplorer.DetailsTab
  ( detailsTab
  ) where

import Gargantext.Prelude

import Gargantext.Components.PhyloExplorer.JSON (Cluster(..), TimeUnit(..))
import Gargantext.Components.PhyloExplorer.Store as PhyloStore
import Gargantext.Components.PhyloExplorer.Types (PhyloConfig(..), PhyloData(..))
import Gargantext.Utils (nbsp)
import Gargantext.Utils.Reactix as R2
import Reactix as R
import Reactix.DOM.HTML as H

here :: R2.Here
here = R2.here "Gargantext.Components.PhyloExplorer.SideBar.DetailsTab"

detailsTab :: R2.Leaf ( key :: String )
detailsTab = R2.leaf detailsTabCpt


detailsTabCpt :: R.Component ( key :: String )
detailsTabCpt = here.component "" cpt where
  cpt _ _ = do
    -- | States
    -- |
    store <- PhyloStore.use

    (PhyloData o) <- R2.useLive' store.phyloData
    (PhyloConfig c) <- R2.useLive' store.phyloConfig

    -- | Render
    -- |
    pure $

      H.div
      { className: "phylo-details-tab" }
      [
        -- Counters
        H.ul
        { className: "phylo-details-tab__counter" }
        [
          detailsCount o.nbDocs "docs"
        ,
          detailsCount o.nbFoundations "foundations"
        ,
          detailsCount o.nbPeriods "periods"
        ]
      ,
        H.ul
        { className: "phylo-details-tab__counter" }
        [
          detailsCount o.nbTerms "terms"
        ,
          detailsCount o.nbGroups "groups"
        ,
          detailsCount o.nbBranches "branches"
        ]
      ,
        H.hr
        { className: "phylo-details-tab__delimiter" }
      ,
        -- Link description
        H.a
        { className: "phylo-details-tab__link"
        , href: "http://maps.gargantext.org/unpublished_maps_phylo/vaccines_countries/documentation.html"
        , target: "_blank" }
        [
          H.text "How the phylomemy was built?"
        ]
      ,
        H.hr 
        { className: "phylo-details-tab__delimiter" }
      , 
        -- Phylo params
        H.ul
        { className: "phylo-details-tab__counter" }
        [ detailsParams c.levelOfObservation "Level of observation"
        , detailsTimeUnit c.timeUnit
        , detailsClique c.clique 
        , detailsParams c.proximity "Proximity"
        , detailsParams c.synchrony "Synchrony"
        , detailsParams c.minBranch "Minimum Branch Size"
        ]
      ]

detailsCount :: Int -> String -> R.Element
detailsCount value label =
  H.li
  { className: "phylo-details-tab__counter__item" }
  [
    H.span
    { className: "phylo-details-tab__counter__value" }
    [
      H.text $ show value
    ]
  ,
    H.span
    { className: "phylo-details-tab__counter__label "}
    [
      H.text $ nbsp 1 <> label
    ]
  ]

detailsParams :: forall a. (Show a) => a -> String -> R.Element
detailsParams value label = 
  H.li 
  { className: "phylo-details-tab__params__item"} 
  [
    H.span 
    { className: "phylo-details-tab__params__label"} 
    [ 
      H.text $ label <> ":"
    ]
  ,
    H.span
    { className: "phylo-details-tab__params__value"}
    [ H.text $ show value]
  ]

detailsClique :: Cluster -> R.Element
detailsClique c =
  R.fragment [
    H.li 
    { className: "phylo-details-tab__params__item"} 
    [ H.span 
      { className: "phylo-details-tab__params__label"} 
      [ H.text "Topic detection strategy" ]
    ]
  ,
    H.ul 
    { className: "phylo-details-tab__counter" } 
    (parseClique c)
  ]  
  where
    parseClique :: Cluster -> Array R.Element
    parseClique (Fis { _fis_support, _fis_size }) =
      [
          detailsParams "Fis" "Algorithm"
        ,
          detailsParams _fis_support "Support"
        ,
          detailsParams _fis_size "Size"
      ]
    parseClique (MaxClique { _mcl_size, _mcl_threshold, _mcl_filter}) =
      [ 
        detailsParams "MaxClique" "Algorithm"
      ,
        detailsParams _mcl_size "Size"
      ,
        detailsParams _mcl_threshold "Threshold"
      ,
        detailsParams _mcl_filter "Filter"
      ]

detailsTimeUnit :: TimeUnit -> R.Element
detailsTimeUnit t =
  R.fragment [
    H.li 
    { className: "phylo-details-tab__params__item" }
    [ H.span 
      { className: "phylo-details-tab__params__label" }
      [ H.text "Inter-temporal Matching" ]
    ]
  ,
    H.ul 
    { className: "phylo-details-tab__counter" } 
    (parseTimeUnit t)
  ]
  where
    parseTimeUnit :: TimeUnit -> Array R.Element
    parseTimeUnit (Epoch {_epoch_period, _epoch_step, _epoch_matchingFrame}) =
      [
        detailsParams "Epoch" "Time unit"
      ,
        detailsParams _epoch_period "Period"
      ,
        detailsParams _epoch_step "Step"
      ,
        detailsParams _epoch_matchingFrame "Matching frame"
      ] 
    parseTimeUnit (Year {_year_period, _year_step, _year_matchingFrame}) = 
      [
        detailsParams "Year" "Time unit"
      ,
        detailsParams _year_period "Period"
      ,
        detailsParams _year_step "Step"
      ,
        detailsParams _year_matchingFrame "Matching frame"
      ]
    parseTimeUnit (Month {_month_period, _month_step, _month_matchingFrame}) = 
      [
        detailsParams "Month" "Time unit"
      ,
        detailsParams _month_period "Period"
      ,
        detailsParams _month_step "Step"
      ,
        detailsParams _month_matchingFrame "Matching frame"
      ]
    parseTimeUnit (Week {_week_period, _week_step, _week_matchingFrame}) =
      [
        detailsParams "Week" "Time unit"
      ,
        detailsParams _week_period "Period"
      ,
        detailsParams _week_step "Step"
      ,
        detailsParams _week_matchingFrame "Matching frame"
      ] 
    parseTimeUnit (Day {_day_period, _day_step, _day_matchingFrame}) = 
      [
        detailsParams "Day" "Time unit"
      ,
        detailsParams _day_period "Period"
      ,
        detailsParams _day_step "Step"
      ,
        detailsParams _day_matchingFrame "Matching frame"
      ]
