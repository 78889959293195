module Gargantext.Hooks.Sigmax.ForceAtlas2 where

-- FFI for force atlas2: https://graphology.github.io/standard-library/layout-forceatlas2.html

import Effect (Effect)
import Effect.Timer (setTimeout)
import Effect.Uncurried (EffectFn1, EffectFn2, runEffectFn1, runEffectFn2)
import FFI.Simple ((..))
import Gargantext.Hooks.Sigmax.Graphology as Graphology
import Prelude


-- | Type representing the web worker.
foreign import data FA2Layout :: Type

graph :: FA2Layout -> Graphology.Graph
graph s = s .. "graph" :: Graphology.Graph

-- TODO inferSettings
-- TODO init with settings
foreign import _init :: forall settings. EffectFn2 Graphology.Graph settings FA2Layout
foreign import _start :: EffectFn1 FA2Layout Unit
foreign import _stop :: EffectFn1 FA2Layout Unit
foreign import _kill :: EffectFn1 FA2Layout Unit
foreign import _isRunning :: EffectFn1 FA2Layout Boolean

init :: forall settings. Graphology.Graph -> settings -> Effect FA2Layout
init = runEffectFn2 _init

start :: FA2Layout -> Effect Unit
start = runEffectFn1 _start

stop :: FA2Layout -> Effect Unit
stop = runEffectFn1 _stop

kill :: FA2Layout -> Effect Unit
kill = runEffectFn1 _kill

isRunning :: FA2Layout -> Effect Boolean
isRunning = runEffectFn1 _isRunning

-- TODO?
restart :: FA2Layout -> Effect Unit
restart fa2 = do
  stop fa2
  _ <- setTimeout 100 $ do
    start fa2
  pure unit

refresh :: FA2Layout -> Effect Unit
refresh f = do
  isRunning' <- isRunning f
  if isRunning' then
    pure unit
  else do
    _ <- setTimeout 100 $ do
      restart f
      _ <- setTimeout 100 $ stop f
      pure unit
    pure unit
