module Data.String.Search.Utils where

import Data.Array
import Prelude

slidingWindow :: forall a. Array a -> Int -> Array (Array a)
slidingWindow lst len =
  let diff = length lst - len
  in
   if diff < 0 then []
   else (\idx -> slice idx (idx + len) lst) <$> 0 .. diff
