{
  "name": "Gargantext",
  "version": "0.0.7.3.6",
  "scripts": {
    "build": "spago build",
    "bundle": "spago bundle --module Main --outfile dist/bundle.min.js --minify --source-maps",
    "css": "$npm_execpath run css-themes",
    "css-themes": "$npm_execpath run css-default-theme && $npm_execpath run css-dark-theme && $npm_execpath run css-darkster-theme && $npm_execpath run css-greyson-theme && $npm_execpath run css-herbie-theme && $npm_execpath run css-monotony-theme",
    "css-default-theme": "sass src/sass/themes/default.scss:dist/styles/bootstrap-default.css",
    "css-dark-theme": "cp node_modules/bootstrap-dark/src/bootstrap-dark.css dist/styles/bootstrap-dark.css",
    "css-darkster-theme": "sass src/sass/themes/darkster.scss:dist/styles/bootstrap-darkster.css",
    "css-greyson-theme": "sass src/sass/themes/greyson.scss:dist/styles/bootstrap-greyson.css",
    "css-herbie-theme": "sass src/sass/themes/herbie.scss:dist/styles/bootstrap-herbie.css",
    "css-monotony-theme": "sass src/sass/themes/monotony.scss:dist/styles/bootstrap-monotony.css",
    "docs": "spago docs",
    "repl": "spago repl",
    "server": "serve -l 8008 dist",
    "server-ssl": "ssl-serve --ssl dist",
    "test": "spago test"
  },
  "dependencies": {
    "@fontsource/crete-round": "~5.0.12",
    "@fontsource/montserrat": "~5.0.17",
    "@fontsource/mulish": "^5.0.16",
    "@fontsource/nunito": "^5.0.17",
    "@fontsource/open-sans": "~5.0.25",
    "@fontsource/oswald": "~5.0.18",
    "@popperjs/core": "~2.9.2",
    "@urql/core": "~2.3.3",
    "aes-js": "~3.1.1",
    "base-x": "~3.0.2",
    "bootstrap": "~4.6.0",
    "bootstrap-dark": "~1.0.3",
    "buffer": "~6.0.3",
    "create-react-class": "~15.6.3",
    "crypto": "~1.0.1",
    "d3": "~7.6.1",
    "debounce": "^2.0.0",
    "debouncing": "^22.7.25",
    "echarts": "~5.1.2",
    "echarts-for-react": "~3.0.1",
    "graphology": "~0.25.1",
    "graphology-communities-louvain": "~2.0.1",
    "graphology-layout-forceatlas2": "~0.9.2",
    "graphology-layout-noverlap": "~0.4.2",
    "graphology-operators": "~1.6.0",
    "graphql": "~15.6.1",
    "graphql-ws": "~5.5.0",
    "highlightjs": "~9.16.2",
    "immer": "~9.0.5",
    "isomorphic-unfetch": "~3.1.0",
    "markdown-it": "~13.0.1",
    "minify": "^11.3.0",
    "prop-types": "~15.6.2",
    "react": "~18.2.0",
    "react-awesome-popover": "~6.1.1",
    "react-bootstrap": "~1.5.2",
    "react-dom": "~18.2.0",
    "react-tooltip": "~4.2.8",
    "secp256k1": "~4.0.2",
    "sigma": "~2.4.0",
    "twgl.js": "~5.0.4",
    "use-debounce": "^10.0.0",
    "uuid": "8.3.2"
  },
  "devDependencies": {
    "@babel/core": "~7.24.5",
    "@babel/preset-env": "~7.24.5",
    "@babel/preset-react": "~7.24.1",
    "@getgauge/cli": "~1.4.0",
    "esbuild": "~0.21.1",
    "parcel": "~2.8.2",
    "react-testing-library": "~8.0.1",
    "sass": "~1.77.0",
    "serve": "~14.2.3",
    "ssl-serve": "~6.5.8",
    "taiko": "latest",
    "vscode-languageserver": "~7.0.0",
    "xhr2": "~0.2.1"
  },
  "optionalDependencies": {
    "purescript-language-server": "~0.17.1"
  }
}
