module Gargantext.Components.Forest.Breadcrumb
  ( breadcrumbView
  , component
  )
  where

import Data.Array as A
import Data.Either (Either(..), either)
import Data.Int (fromString)
import Data.Maybe (Maybe(..), fromMaybe, maybe)
import Data.String (Pattern(..), split)
import Gargantext.Components.App.Store as Store
import Gargantext.Components.Bootstrap as B
import Gargantext.Components.GraphQL.Endpoints (getBreadcrumb, getNodeChildren, getNodeParent)
import Gargantext.Components.GraphQL.Tree (BreadcrumbInfo, TreeNode)
import Gargantext.Config.REST (AffRESTError)
import Gargantext.Ends (Backend(..))
import Gargantext.Hooks.Loader (useLoader)
import Gargantext.Routes (AppRoute(..), appPath, nodeTypeAppRoute)
import Gargantext.Sessions.Types
import Gargantext.Types (NodeType, SessionId)
import Gargantext.Types as GT
import Gargantext.Utils as GU
import Gargantext.Utils.Reactix as R2
import Prelude
import Reactix as R
import Reactix.DOM.HTML as H
import Toestand as T



here :: R2.Here
here = R2.here "Gargantext.Components.Forest.Breadcrumb"


component :: R2.Leaf ()
component = R2.leaf componentCpt
componentCpt :: R.Component ()
componentCpt = here.component "breadcrumb" cpt where
  cpt { } _ = do
    { route, session } <- Store.use

    -- | States
    -- |
    route' <- T.useLive T.unequal route
    session' <- T.useLive T.unequal session
    -- R.provideContext SessionContext.context session'

    -- | Effects
    -- |
    -- url <- R.unsafeHooksEffect GU.href

    -- | Behaviors
    -- |
    -- let
      -- currentUrl = url
      -- fragment = Fragment.toString $ Fragment.fromString "http://localhost:8008/#/corpus/user1@localhost:8008/112"
      -- fragment = Fragment.toString $ Fragment.fromString url
      -- currentNodeId = fromMaybe 0 $ fromString $ getLastUrlElement url

    
    case session' of 
      Nothing -> pure $ H.div {} []
      Just session'' -> do
        
        -- url <- R.unsafeHooksEffect GU.href
        -- let nodeId = fromMaybe 0 $ fromString $ getLastUrlElement url
        -- breadcrumbData <- R2.useLayoutEffect1' $ getBreadcrumb session' currentNodeId

        pure $
          -- breadcrumb layout
          H.nav { className: "breadcrumb-wrapper bg-light"
                , "aria-label": "breadcrumb" }
          [
            H.ol { className: "breadcrumb text-small" }
            [
              H.li { className: "breadcrumb-item" }
              [
                H.a { href: "/" } 
                [ H.span { className: "" }
                  [ 
                    B.icon { name: "home" }
                  ]
                , H.text "Home" 
                ]
              ]
            ,
              breadcrumbView { format: "default"
                             , route: route'
                             , session: session''
                             , openTreeNodes: true
                             }
            ]
          -- ,
          --   H.nav 
          --   { className: "breadcrumb-wrapper bg-light"
          --   , "aria-label": "breadcrumb" }
          --   [
          --     H.ol { className: "breadcrumb text-small" }
          --     [
          --       H.li { className: "breadcrumb-item" }
          --       [
          --         H.a { href: "/" } 
          --         [ H.span { className: "" }
          --           [ 
          --             B.icon { name: "home" }
          --           ]
          --         , H.text "Home" 
          --         ]
          --       ]
          --     , H.li { className: "breadcrum-item" }
          --       [
          --         H.span {}
          --         [
          --           H.text $ show session' <> " - " <> show currentNodeId
          --           -- H.text $ show breadcrumbData
          --         ]
          --       ]
          --     , 
          --       H.li { className: "breadcrumb-item" }
          --       [
          --         H.a { href: "/" } 
          --         [ H.span { className: "" }
          --           [ 
          --             B.icon { name: "folder-open-o" } 
          --           ]
          --         , H.text "parent folder" 
          --         ]
          --       ]
          --     , 
          --       H.li { className: "breadcrumb-item" }
          --       [ H.span { className: "active-page" }
          --         [ H.span { className: "" }
          --           [ 
          --             B.icon { name: "book" } 
          --           ]
          --         , H.text "current node"
          --         ]
          --       ]
          --     ]
          --   ]
          ]

type BreadcrumbViewProps =
  ( format        :: String
  , route         :: AppRoute
  , session       :: Session
  , openTreeNodes :: Boolean
  )


breadcrumbView :: R2.Leaf BreadcrumbViewProps
breadcrumbView = R2.leaf breadcrumbViewCpt
breadcrumbViewCpt :: R.Component BreadcrumbViewProps
breadcrumbViewCpt = R2.hereComponent here "breadcrumbViewCpt" hCpt where
  hCpt hp { format, route, session, openTreeNodes } _ = do

    useLoader { errorHandler: Nothing
              , herePrefix: hp
              , loader: loadBreadcrumbData
              , path: { route
                      , session
                      }
              , render: \items -> breadcrumbViewMain { format: format
                                                     , items
                                                     , session
                                                       --  , reload: reload
                                                     , openTreeNodes
                                                     } [] 
              }

type BreadcrumbViewMainProps =
  ( format        :: String
  , items         :: BreadcrumbInfo
  , session       :: Session
  -- , reload        :: T.Box T2.Reload
  , openTreeNodes :: Boolean
  )

breadcrumbViewMain :: R2.Component BreadcrumbViewMainProps
breadcrumbViewMain = R.createElement breadcrumbViewMainCpt
breadcrumbViewMainCpt :: R.Component BreadcrumbViewMainProps
breadcrumbViewMainCpt = here.component "breadcrumbViewMainCpt" cpt where
  cpt { items: { parents }, session, format, openTreeNodes } _ = do
    
    -- session' <- T.useLive T.unequal session
    let items = makeBreadcrumbElements parents session format openTreeNodes

    -- case session of 
    --   Nothing -> pure $ H.div {} []
    --   Just (session) -> do

    pure $ 
      R.fragment items


  makeBreadcrumbElements :: Array TreeNode -> Session -> String -> Boolean -> Array R.Element
  makeBreadcrumbElements items' session format openTreeNodes = makeBreadcrumbElementsMap <$> items' where
    makeBreadcrumbElementsMap :: TreeNode -> R.Element
    makeBreadcrumbElementsMap node = breadcrumbItem { linkId: node.id
                                                    , linkNodeType: node.node_type
                                                    , nodeType: node.node_type
                                                    , session
                                                    , text: node.name
                                                    -- , reload: props.reload
                                                    -- , style: FolderUp
                                                    , format: format
                                                    , openTreeNodes: openTreeNodes
                                                    }

type BreadcrumbItemProps =
  ( linkNodeType  :: NodeType
  , linkId        :: Int
  , nodeType      :: NodeType
  -- , reload        :: T.Box T2.Reload
  , session       :: Session
  -- , style         :: FolderStyle
  , text          :: String
  , format        :: String
  , openTreeNodes :: Boolean
  )

breadcrumbItem :: R2.Leaf BreadcrumbItemProps
breadcrumbItem = R2.leaf breadcrumbItemCpt
breadcrumbItemCpt :: R.Component BreadcrumbItemProps
breadcrumbItemCpt = here.component "breadcrumbItemCpt" cpt where
  cpt { linkId
      , linkNodeType
      , nodeType
      , session
      , text
        -- , reload
        -- , style
      , format
      , openTreeNodes
      } _ = do

    boxes@{ forestOpen } <- Store.use
    boxes@{ route } <- Store.use

    let sid = sessionId session
    let rootId = treeId session
    
    let currentNodeIdFromUrl = mkNodeId session linkId
    if openTreeNodes then
      R.unsafeHooksEffect $ T.modify_ (openNodesInsert (currentNodeIdFromUrl)) forestOpen
    else
      pure unit



    pure $ 
      if format == "text"
      then
                
        H.span { className: "node-path-item" }
        [
          if nodeType == GT.NodeUser
          then
            H.text ""
          else
            H.text $ " / " <> text <> ""
        ]

      else
      
        H.li { className: "breadcrumb-item" }
        [
          if nodeType `A.elem` [ GT.FolderPrivate, GT.FolderPublic, GT.FolderShared, GT.NodeUser ]
          then
            H.span { className: "" }
            [
              H.span { className: "" }
              [ 
                B.icon
                { className: ""
                , name: GT.getIcon nodeType true
                }
              ]
            , 
              if nodeType == GT.NodeUser
              then
                H.text $ getUserText session
              else
                H.text text
            
            , H.span { className: "text-small" }
              [ 
              if nodeType == GT.NodeUser
              then
                H.text $ " (" <> getInstanceText session <> ")"
              else
                H.text ""
              ]
          
            ]

          else
            H.a { className: ""
                , href: "/#/" <> createNodeUrl linkId rootId linkNodeType sid 
                } 
            [ 
              H.span { className: "" }
              [ 
                -- B.icon { name: "folder-open-o" } 
                B.icon
                { className: ""
                , name: GT.getIcon nodeType true
                }
              ]
            , 
              H.text text 
            ]
        ]

  createNodeUrl :: Int -> Int -> NodeType -> SessionId -> String
  createNodeUrl lId _rootId nType sId
    -- | rootId == lId  = appPath Home
    | otherwise      = appPath $ getFolderPath nType sId lId
  
  getFolderPath :: GT.NodeType -> GT.SessionId -> Int -> AppRoute
  getFolderPath nodeType sid nodeId = fromMaybe Home $ nodeTypeAppRoute nodeType sid nodeId


treeId :: Session -> Int
treeId (Session {treeId: tId}) = tId

getInstanceText :: Session -> String
getInstanceText (Session { backend }) = cleanBackendUrl backend

getUserText :: Session -> String
getUserText (Session { username }) = username
  -- getLastUrlElement $ fromMaybe "" $ A.head $ split (Pattern "@") str



type LoadRawProps =
  (
    nodeId  :: Int
  , session :: Session
  -- , reload :: T.Box T2.Reload
  )

loadBreadcrumbDataRaw :: Record LoadRawProps -> AffRESTError BreadcrumbInfo
loadBreadcrumbDataRaw { nodeId, session } = getBreadcrumb session nodeId


type LoadProps =
  ( route   :: AppRoute
  , session :: Session )

loadBreadcrumbData :: Record LoadProps -> AffRESTError BreadcrumbInfo
loadBreadcrumbData { route: Annuaire _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: ContactPage _s nodeId annuaireId, session } = do
  loadBreadcrumbDataRaw { nodeId: annuaireId, session }
loadBreadcrumbData { route: Corpus _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: CorpusCode _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: CorpusDocument _s corpusId listId documentId, session } = do
  texts <- getNodeChildren session corpusId GT.NodeTexts
  let docId = maybe corpusId _.id $ either (const Nothing) A.head texts
  loadBreadcrumbDataRaw { nodeId: docId, session }
loadBreadcrumbData { route: Dashboard _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: Document _s listId documentId, session } = do
  corpora <- getNodeParent session listId GT.Corpus
  let nodeId = maybe listId _.id $ either (const Nothing) A.head corpora
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: Folder _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: FolderPrivate _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: FolderPublic _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: FolderShared _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: Lists _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: NodeTexts _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: PGraphExplorer _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: PhyloExplorer _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: RouteFile _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: RouteFrameCalc _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: RouteFrameCode _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: RouteFrameVisio _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: RouteFrameWrite _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: Team _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: TreeFlat _s nodeId _q, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: UserPage _s nodeId, session } = do
  loadBreadcrumbDataRaw { nodeId, session }
loadBreadcrumbData { route: ForgotPassword _s } = do
  pure $ Right { parents: [] }
loadBreadcrumbData { route: Home } = do
  pure $ Right { parents: [] }
loadBreadcrumbData { route: Login } = do
  pure $ Right { parents: [] }
loadBreadcrumbData { route: Share _t _i} = do
  pure $ Right { parents: []}
