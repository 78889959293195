-----------------------------------------------------------
-- This is an automatically generated file: do not edit
-- Generated by ubconfc at Wed Nov 24 21:40:34 EST 2021
-----------------------------------------------------------

module Data.CodePoint.Unicode.Internal where

import Prelude

import Data.Array as Array
import Data.Int (floor, toNumber)
import Data.Maybe (Maybe(..), isJust)
import Partial.Unsafe (unsafePartial)

-- Unicode general categories, listed in the same order as in the Unicode
-- standard. This must be the same order as in GHC.Unicode.
data UnicodeCategory
  = NUMCAT_LU -- Letter, Uppercase
  | NUMCAT_LL -- Letter, Lowercase
  | NUMCAT_LT -- Letter, Titlecase
  | NUMCAT_LM -- Letter, Modifier
  | NUMCAT_LO -- Letter, Other
  | NUMCAT_MN -- Mark, Non-Spacing
  | NUMCAT_MC -- Mark, Spacing Combining
  | NUMCAT_ME -- Mark, Enclosing
  | NUMCAT_ND -- Number, Decimal
  | NUMCAT_NL -- Number, Letter
  | NUMCAT_NO -- Number, Other
  | NUMCAT_PC -- Punctuation, Connector
  | NUMCAT_PD -- Punctuation, Dash
  | NUMCAT_PS -- Punctuation, Open
  | NUMCAT_PE -- Punctuation, Close
  | NUMCAT_PI -- Punctuation, Initial quote
  | NUMCAT_PF -- Punctuation, Final quote
  | NUMCAT_PO -- Punctuation, Other
  | NUMCAT_SM -- Symbol, Math
  | NUMCAT_SC -- Symbol, Currency
  | NUMCAT_SK -- Symbol, Modifier
  | NUMCAT_SO -- Symbol, Other
  | NUMCAT_ZS -- Separator, Space
  | NUMCAT_ZL -- Separator, Line
  | NUMCAT_ZP -- Separator, Paragraph
  | NUMCAT_CC -- Other, Control
  | NUMCAT_CF -- Other, Format
  | NUMCAT_CS -- Other, Surrogate
  | NUMCAT_CO -- Other, Private Use
  | NUMCAT_CN -- Other, Not Assigned

instance showUnicodeCategory :: Show UnicodeCategory where
  show NUMCAT_LU = "NUMCAT_LU"
  show NUMCAT_LL = "NUMCAT_LL"
  show NUMCAT_LT = "NUMCAT_LT"
  show NUMCAT_LM = "NUMCAT_LM"
  show NUMCAT_LO = "NUMCAT_LO"
  show NUMCAT_MN = "NUMCAT_MN"
  show NUMCAT_MC = "NUMCAT_MC"
  show NUMCAT_ME = "NUMCAT_ME"
  show NUMCAT_ND = "NUMCAT_ND"
  show NUMCAT_NL = "NUMCAT_NL"
  show NUMCAT_NO = "NUMCAT_NO"
  show NUMCAT_PC = "NUMCAT_PC"
  show NUMCAT_PD = "NUMCAT_PD"
  show NUMCAT_PS = "NUMCAT_PS"
  show NUMCAT_PE = "NUMCAT_PE"
  show NUMCAT_PI = "NUMCAT_PI"
  show NUMCAT_PF = "NUMCAT_PF"
  show NUMCAT_PO = "NUMCAT_PO"
  show NUMCAT_SM = "NUMCAT_SM"
  show NUMCAT_SC = "NUMCAT_SC"
  show NUMCAT_SK = "NUMCAT_SK"
  show NUMCAT_SO = "NUMCAT_SO"
  show NUMCAT_ZS = "NUMCAT_ZS"
  show NUMCAT_ZL = "NUMCAT_ZL"
  show NUMCAT_ZP = "NUMCAT_ZP"
  show NUMCAT_CC = "NUMCAT_CC"
  show NUMCAT_CF = "NUMCAT_CF"
  show NUMCAT_CS = "NUMCAT_CS"
  show NUMCAT_CO = "NUMCAT_CO"
  show NUMCAT_CN = "NUMCAT_CN"

newtype ConversionRule = ConversionRule
  { category :: Int
  , unicodeCat :: UnicodeCategory
  , possible :: Int
  , updist :: Int
  , lowdist :: Int
  , titledist :: Int
  }

instance showConversionRule :: Show ConversionRule where
  show (ConversionRule rule) = "ConversionRule { category: " <> show rule.category
    <> ", unicodeCat: "
    <> show rule.unicodeCat
    <> ", possible: "
    <> show rule.possible
    <> ", updist: "
    <> show rule.updist
    <> ", lowdist: "
    <> show rule.lowdist
    <> ", titledist: "
    <> show rule.titledist
    <> " }"

newtype CharBlock = CharBlock
  { start :: Int
  , length :: Int
  , convRule :: ConversionRule
  }

instance showCharBlock :: Show CharBlock where
  show (CharBlock rule) = "CharBlock { start: " <> show rule.start
    <> ", length: "
    <> show rule.length
    <> ", convRule: "
    <> show rule.convRule
    <> " }"

gencatPF :: Int
gencatPF = 262144

gencatSM :: Int
gencatSM = 64

gencatSO :: Int
gencatSO = 8192

gencatPI :: Int
gencatPI = 32768

gencatMC :: Int
gencatMC = 8388608

gencatCO :: Int
gencatCO = 268435456

gencatME :: Int
gencatME = 4194304

gencatPO :: Int
gencatPO = 4

gencatCS :: Int
gencatCS = 134217728

gencatPS :: Int
gencatPS = 16

gencatMN :: Int
gencatMN = 2097152

gencatZL :: Int
gencatZL = 33554432

gencatZP :: Int
gencatZP = 67108864

gencatZS :: Int
gencatZS = 2

gencatLL :: Int
gencatLL = 4096

gencatLM :: Int
gencatLM = 1048576

gencatLO :: Int
gencatLO = 16384

gencatND :: Int
gencatND = 256

gencatLT :: Int
gencatLT = 524288

gencatSC :: Int
gencatSC = 8

gencatLU :: Int
gencatLU = 512

gencatNL :: Int
gencatNL = 16777216

gencatCC :: Int
gencatCC = 1

gencatNO :: Int
gencatNO = 131072

gencatCF :: Int
gencatCF = 65536

gencatPC :: Int
gencatPC = 2048

gencatSK :: Int
gencatSK = 1024

gencatPD :: Int
gencatPD = 128

gencatPE :: Int
gencatPE = 32

maxUniChar :: Int
maxUniChar = 1114109

numBlocks :: Int
numBlocks = 3396

numConvBlocks :: Int
numConvBlocks = 1332

numSpaceBlocks :: Int
numSpaceBlocks = 7

numLat1Blocks :: Int
numLat1Blocks = 63

numRules :: Int
numRules = 205

rule201 :: ConversionRule
rule201 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 40, titledist: 0 }

rule188 :: ConversionRule
rule188 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -42315, titledist: 0 }

rule63 :: ConversionRule
rule63 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 10782, lowdist: 0, titledist: 10782 }

rule148 :: ConversionRule
rule148 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 128, lowdist: 0, titledist: 128 }

rule184 :: ConversionRule
rule184 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -42280, titledist: 0 }

rule74 :: ConversionRule
rule74 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -209, lowdist: 0, titledist: -209 }

rule194 :: ConversionRule
rule194 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -48, titledist: 0 }

rule22 :: ConversionRule
rule22 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 1, titledist: 0 }

rule192 :: ConversionRule
rule192 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -42261, titledist: 0 }

rule150 :: ConversionRule
rule150 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 126, lowdist: 0, titledist: 126 }

rule45 :: ConversionRule
rule45 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 219, titledist: 0 }

rule183 :: ConversionRule
rule183 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -35332, titledist: 0 }

rule115 :: ConversionRule
rule115 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -60, titledist: 0 }

rule110 :: ConversionRule
rule110 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -8, lowdist: 0, titledist: -8 }

rule44 :: ConversionRule
rule44 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 217, titledist: 0 }

rule81 :: ConversionRule
rule81 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 10727, lowdist: 0, titledist: 10727 }

rule165 :: ConversionRule
rule165 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -8262, titledist: 0 }

rule40 :: ConversionRule
rule40 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 213, titledist: 0 }

rule42 :: ConversionRule
rule42 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 214, titledist: 0 }

rule75 :: ConversionRule
rule75 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -211, lowdist: 0, titledist: -211 }

rule29 :: ConversionRule
rule29 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 210, titledist: 0 }

rule32 :: ConversionRule
rule32 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 79, titledist: 0 }

rule37 :: ConversionRule
rule37 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 211, titledist: 0 }

rule111 :: ConversionRule
rule111 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -86, lowdist: 0, titledist: -86 }

rule86 :: ConversionRule
rule86 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -217, lowdist: 0, titledist: -217 }

rule5 :: ConversionRule
rule5 = ConversionRule { category: gencatPE, unicodeCat: NUMCAT_PE, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule123 :: ConversionRule
rule123 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -48, lowdist: 0, titledist: -48 }

rule73 :: ConversionRule
rule73 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 42308, lowdist: 0, titledist: 42308 }

rule164 :: ConversionRule
rule164 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -8383, titledist: 0 }

rule167 :: ConversionRule
rule167 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -28, lowdist: 0, titledist: -28 }

rule130 :: ConversionRule
rule130 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -6253, lowdist: 0, titledist: -6253 }

rule113 :: ConversionRule
rule113 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 7, lowdist: 0, titledist: 7 }

rule102 :: ConversionRule
rule102 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -64, lowdist: 0, titledist: -64 }

rule61 :: ConversionRule
rule61 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 10783, lowdist: 0, titledist: 10783 }

rule89 :: ConversionRule
rule89 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 42261, lowdist: 0, titledist: 42261 }

rule62 :: ConversionRule
rule62 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 10780, lowdist: 0, titledist: 10780 }

rule112 :: ConversionRule
rule112 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -80, lowdist: 0, titledist: -80 }

rule105 :: ConversionRule
rule105 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -62, lowdist: 0, titledist: -62 }

rule60 :: ConversionRule
rule60 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 71, titledist: 0 }

rule51 :: ConversionRule
rule51 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -97, titledist: 0 }

rule96 :: ConversionRule
rule96 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 37, titledist: 0 }

rule95 :: ConversionRule
rule95 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 38, titledist: 0 }

rule145 :: ConversionRule
rule145 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 74, lowdist: 0, titledist: 74 }

rule133 :: ConversionRule
rule133 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -6243, lowdist: 0, titledist: -6243 }

rule203 :: ConversionRule
rule203 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 34, titledist: 0 }

rule200 :: ConversionRule
rule200 = ConversionRule { category: gencatCO, unicodeCat: NUMCAT_CO, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule202 :: ConversionRule
rule202 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -40, lowdist: 0, titledist: -40 }

rule28 :: ConversionRule
rule28 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 195, lowdist: 0, titledist: 195 }

rule84 :: ConversionRule
rule84 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 42282, lowdist: 0, titledist: 42282 }

rule180 :: ConversionRule
rule180 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -10782, titledist: 0 }

rule179 :: ConversionRule
rule179 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -10783, titledist: 0 }

rule195 :: ConversionRule
rule195 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -42307, titledist: 0 }

rule193 :: ConversionRule
rule193 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 928, titledist: 0 }

rule9 :: ConversionRule
rule9 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 32, titledist: 0 }

rule181 :: ConversionRule
rule181 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -10815, titledist: 0 }

rule160 :: ConversionRule
rule160 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -126, titledist: 0 }

rule4 :: ConversionRule
rule4 = ConversionRule { category: gencatPS, unicodeCat: NUMCAT_PS, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule155 :: ConversionRule
rule155 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -7205, lowdist: 0, titledist: -7205 }

rule69 :: ConversionRule
rule69 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 42319, lowdist: 0, titledist: 42319 }

rule177 :: ConversionRule
rule177 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -10780, titledist: 0 }

rule154 :: ConversionRule
rule154 = ConversionRule { category: gencatLT, unicodeCat: NUMCAT_LT, possible: 1, updist: 0, lowdist: -9, titledist: 0 }

rule175 :: ConversionRule
rule175 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -10795, lowdist: 0, titledist: -10795 }

rule25 :: ConversionRule
rule25 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -232, lowdist: 0, titledist: -232 }

rule27 :: ConversionRule
rule27 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -300, lowdist: 0, titledist: -300 }

rule127 :: ConversionRule
rule127 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 38864, titledist: 0 }

rule16 :: ConversionRule
rule16 = ConversionRule { category: gencatCF, unicodeCat: NUMCAT_CF, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule78 :: ConversionRule
rule78 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 10749, lowdist: 0, titledist: 10749 }

rule144 :: ConversionRule
rule144 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -8, titledist: 0 }

rule26 :: ConversionRule
rule26 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -121, titledist: 0 }

rule149 :: ConversionRule
rule149 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 112, lowdist: 0, titledist: 112 }

rule41 :: ConversionRule
rule41 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 130, lowdist: 0, titledist: 130 }

rule90 :: ConversionRule
rule90 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 42258, lowdist: 0, titledist: 42258 }

rule31 :: ConversionRule
rule31 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 205, titledist: 0 }

rule198 :: ConversionRule
rule198 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -38864, lowdist: 0, titledist: -38864 }

rule129 :: ConversionRule
rule129 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -6254, lowdist: 0, titledist: -6254 }

rule30 :: ConversionRule
rule30 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 206, titledist: 0 }

rule71 :: ConversionRule
rule71 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -207, lowdist: 0, titledist: -207 }

rule3 :: ConversionRule
rule3 = ConversionRule { category: gencatSC, unicodeCat: NUMCAT_SC, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule157 :: ConversionRule
rule157 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -100, titledist: 0 }

rule24 :: ConversionRule
rule24 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -199, titledist: 0 }

rule33 :: ConversionRule
rule33 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 202, titledist: 0 }

rule34 :: ConversionRule
rule34 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 203, titledist: 0 }

rule50 :: ConversionRule
rule50 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -79, lowdist: 0, titledist: -79 }

rule176 :: ConversionRule
rule176 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -10792, lowdist: 0, titledist: -10792 }

rule58 :: ConversionRule
rule58 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -195, titledist: 0 }

rule67 :: ConversionRule
rule67 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -202, lowdist: 0, titledist: -202 }

rule80 :: ConversionRule
rule80 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -214, lowdist: 0, titledist: -214 }

rule141 :: ConversionRule
rule141 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -59, lowdist: 0, titledist: -59 }

rule131 :: ConversionRule
rule131 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -6244, lowdist: 0, titledist: -6244 }

rule57 :: ConversionRule
rule57 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 10815, lowdist: 0, titledist: 10815 }

rule106 :: ConversionRule
rule106 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -57, lowdist: 0, titledist: -57 }

rule94 :: ConversionRule
rule94 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 116, titledist: 0 }

rule118 :: ConversionRule
rule118 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 80, titledist: 0 }

rule109 :: ConversionRule
rule109 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -54, lowdist: 0, titledist: -54 }

rule72 :: ConversionRule
rule72 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 42280, lowdist: 0, titledist: 42280 }

rule163 :: ConversionRule
rule163 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -7517, titledist: 0 }

rule161 :: ConversionRule
rule161 = ConversionRule { category: gencatZL, unicodeCat: NUMCAT_ZL, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule14 :: ConversionRule
rule14 = ConversionRule { category: gencatLO, unicodeCat: NUMCAT_LO, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule100 :: ConversionRule
rule100 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -37, lowdist: 0, titledist: -37 }

rule204 :: ConversionRule
rule204 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -34, lowdist: 0, titledist: -34 }

rule187 :: ConversionRule
rule187 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -42319, titledist: 0 }

rule143 :: ConversionRule
rule143 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 8, lowdist: 0, titledist: 8 }

rule138 :: ConversionRule
rule138 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 35332, lowdist: 0, titledist: 35332 }

rule12 :: ConversionRule
rule12 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -32, lowdist: 0, titledist: -32 }

rule93 :: ConversionRule
rule93 = ConversionRule { category: gencatMN, unicodeCat: NUMCAT_MN, possible: 1, updist: 84, lowdist: 0, titledist: 84 }

rule199 :: ConversionRule
rule199 = ConversionRule { category: gencatCS, unicodeCat: NUMCAT_CS, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule17 :: ConversionRule
rule17 = ConversionRule { category: gencatNO, unicodeCat: NUMCAT_NO, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule156 :: ConversionRule
rule156 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -86, titledist: 0 }

rule169 :: ConversionRule
rule169 = ConversionRule { category: gencatNL, unicodeCat: NUMCAT_NL, possible: 1, updist: -16, lowdist: 0, titledist: -16 }

rule64 :: ConversionRule
rule64 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -210, lowdist: 0, titledist: -210 }

rule83 :: ConversionRule
rule83 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 42307, lowdist: 0, titledist: 42307 }

rule2 :: ConversionRule
rule2 = ConversionRule { category: gencatPO, unicodeCat: NUMCAT_PO, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule92 :: ConversionRule
rule92 = ConversionRule { category: gencatMN, unicodeCat: NUMCAT_MN, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule39 :: ConversionRule
rule39 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 163, lowdist: 0, titledist: 163 }

rule185 :: ConversionRule
rule185 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 48, lowdist: 0, titledist: 48 }

rule107 :: ConversionRule
rule107 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule43 :: ConversionRule
rule43 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 218, titledist: 0 }

rule53 :: ConversionRule
rule53 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -130, titledist: 0 }

rule91 :: ConversionRule
rule91 = ConversionRule { category: gencatLM, unicodeCat: NUMCAT_LM, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule140 :: ConversionRule
rule140 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 35384, lowdist: 0, titledist: 35384 }

rule158 :: ConversionRule
rule158 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -112, titledist: 0 }

rule132 :: ConversionRule
rule132 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -6242, lowdist: 0, titledist: -6242 }

rule142 :: ConversionRule
rule142 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -7615, titledist: 0 }

rule21 :: ConversionRule
rule21 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 121, lowdist: 0, titledist: 121 }

rule125 :: ConversionRule
rule125 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 7264, titledist: 0 }

rule119 :: ConversionRule
rule119 = ConversionRule { category: gencatME, unicodeCat: NUMCAT_ME, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule13 :: ConversionRule
rule13 = ConversionRule { category: gencatSO, unicodeCat: NUMCAT_SO, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule19 :: ConversionRule
rule19 = ConversionRule { category: gencatPF, unicodeCat: NUMCAT_PF, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule147 :: ConversionRule
rule147 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 100, lowdist: 0, titledist: 100 }

rule49 :: ConversionRule
rule49 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -2, lowdist: 0, titledist: -1 }

rule85 :: ConversionRule
rule85 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -69, lowdist: 0, titledist: -69 }

rule20 :: ConversionRule
rule20 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule135 :: ConversionRule
rule135 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -6181, lowdist: 0, titledist: -6181 }

rule70 :: ConversionRule
rule70 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 42315, lowdist: 0, titledist: 42315 }

rule170 :: ConversionRule
rule170 = ConversionRule { category: gencatSO, unicodeCat: NUMCAT_SO, possible: 1, updist: 0, lowdist: 26, titledist: 0 }

rule196 :: ConversionRule
rule196 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -35384, titledist: 0 }

rule108 :: ConversionRule
rule108 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -47, lowdist: 0, titledist: -47 }

rule66 :: ConversionRule
rule66 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -205, lowdist: 0, titledist: -205 }

rule162 :: ConversionRule
rule162 = ConversionRule { category: gencatZP, unicodeCat: NUMCAT_ZP, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule128 :: ConversionRule
rule128 = ConversionRule { category: gencatNL, unicodeCat: NUMCAT_NL, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule8 :: ConversionRule
rule8 = ConversionRule { category: gencatND, unicodeCat: NUMCAT_ND, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule103 :: ConversionRule
rule103 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -63, lowdist: 0, titledist: -63 }

rule77 :: ConversionRule
rule77 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 42305, lowdist: 0, titledist: 42305 }

rule124 :: ConversionRule
rule124 = ConversionRule { category: gencatMC, unicodeCat: NUMCAT_MC, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule6 :: ConversionRule
rule6 = ConversionRule { category: gencatSM, unicodeCat: NUMCAT_SM, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule7 :: ConversionRule
rule7 = ConversionRule { category: gencatPD, unicodeCat: NUMCAT_PD, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule55 :: ConversionRule
rule55 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -163, titledist: 0 }

rule54 :: ConversionRule
rule54 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 10795, titledist: 0 }

rule186 :: ConversionRule
rule186 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -42308, titledist: 0 }

rule146 :: ConversionRule
rule146 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 86, lowdist: 0, titledist: 86 }

rule134 :: ConversionRule
rule134 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -6236, lowdist: 0, titledist: -6236 }

rule65 :: ConversionRule
rule65 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -206, lowdist: 0, titledist: -206 }

rule82 :: ConversionRule
rule82 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -218, lowdist: 0, titledist: -218 }

rule56 :: ConversionRule
rule56 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 10792, titledist: 0 }

rule190 :: ConversionRule
rule190 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -42258, titledist: 0 }

rule159 :: ConversionRule
rule159 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -128, titledist: 0 }

rule153 :: ConversionRule
rule153 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -74, titledist: 0 }

rule152 :: ConversionRule
rule152 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 9, lowdist: 0, titledist: 9 }

rule120 :: ConversionRule
rule120 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 15, titledist: 0 }

rule189 :: ConversionRule
rule189 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -42305, titledist: 0 }

rule48 :: ConversionRule
rule48 = ConversionRule { category: gencatLT, unicodeCat: NUMCAT_LT, possible: 1, updist: -1, lowdist: 1, titledist: 0 }

rule52 :: ConversionRule
rule52 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -56, titledist: 0 }

rule197 :: ConversionRule
rule197 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -928, lowdist: 0, titledist: -928 }

rule178 :: ConversionRule
rule178 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -10749, titledist: 0 }

rule79 :: ConversionRule
rule79 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -213, lowdist: 0, titledist: -213 }

rule11 :: ConversionRule
rule11 = ConversionRule { category: gencatPC, unicodeCat: NUMCAT_PC, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule151 :: ConversionRule
rule151 = ConversionRule { category: gencatLT, unicodeCat: NUMCAT_LT, possible: 1, updist: 0, lowdist: -8, titledist: 0 }

rule38 :: ConversionRule
rule38 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 209, titledist: 0 }

rule18 :: ConversionRule
rule18 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 743, lowdist: 0, titledist: 743 }

rule174 :: ConversionRule
rule174 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -10727, titledist: 0 }

rule36 :: ConversionRule
rule36 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 97, lowdist: 0, titledist: 97 }

rule46 :: ConversionRule
rule46 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 56, lowdist: 0, titledist: 56 }

rule88 :: ConversionRule
rule88 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -219, lowdist: 0, titledist: -219 }

rule10 :: ConversionRule
rule10 = ConversionRule { category: gencatSK, unicodeCat: NUMCAT_SK, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule35 :: ConversionRule
rule35 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 207, titledist: 0 }

rule172 :: ConversionRule
rule172 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -10743, titledist: 0 }

rule117 :: ConversionRule
rule117 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -7, titledist: 0 }

rule47 :: ConversionRule
rule47 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 2, titledist: 1 }

rule182 :: ConversionRule
rule182 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -7264, lowdist: 0, titledist: -7264 }

rule76 :: ConversionRule
rule76 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 10743, lowdist: 0, titledist: 10743 }

rule136 :: ConversionRule
rule136 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 35266, lowdist: 0, titledist: 35266 }

rule59 :: ConversionRule
rule59 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 69, titledist: 0 }

rule116 :: ConversionRule
rule116 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -96, lowdist: 0, titledist: -96 }

rule173 :: ConversionRule
rule173 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -3814, titledist: 0 }

rule15 :: ConversionRule
rule15 = ConversionRule { category: gencatPI, unicodeCat: NUMCAT_PI, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule122 :: ConversionRule
rule122 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 48, titledist: 0 }

rule99 :: ConversionRule
rule99 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -38, lowdist: 0, titledist: -38 }

rule168 :: ConversionRule
rule168 = ConversionRule { category: gencatNL, unicodeCat: NUMCAT_NL, possible: 1, updist: 0, lowdist: 16, titledist: 0 }

rule126 :: ConversionRule
rule126 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 3008, lowdist: 0, titledist: 0 }

rule98 :: ConversionRule
rule98 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 63, titledist: 0 }

rule87 :: ConversionRule
rule87 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -71, lowdist: 0, titledist: -71 }

rule97 :: ConversionRule
rule97 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 64, titledist: 0 }

rule114 :: ConversionRule
rule114 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -116, lowdist: 0, titledist: -116 }

rule171 :: ConversionRule
rule171 = ConversionRule { category: gencatSO, unicodeCat: NUMCAT_SO, possible: 1, updist: -26, lowdist: 0, titledist: -26 }

rule121 :: ConversionRule
rule121 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -15, lowdist: 0, titledist: -15 }

rule166 :: ConversionRule
rule166 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 28, titledist: 0 }

rule101 :: ConversionRule
rule101 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -31, lowdist: 0, titledist: -31 }

rule139 :: ConversionRule
rule139 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: 3814, lowdist: 0, titledist: 3814 }

rule104 :: ConversionRule
rule104 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: 8, titledist: 0 }

rule23 :: ConversionRule
rule23 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -1, lowdist: 0, titledist: -1 }

rule68 :: ConversionRule
rule68 = ConversionRule { category: gencatLL, unicodeCat: NUMCAT_LL, possible: 1, updist: -203, lowdist: 0, titledist: -203 }

rule191 :: ConversionRule
rule191 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -42282, titledist: 0 }

rule0 :: ConversionRule
rule0 = ConversionRule { category: gencatCC, unicodeCat: NUMCAT_CC, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule1 :: ConversionRule
rule1 = ConversionRule { category: gencatZS, unicodeCat: NUMCAT_ZS, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

rule137 :: ConversionRule
rule137 = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_LU, possible: 1, updist: 0, lowdist: -3008, titledist: 0 }

allchars :: Array CharBlock
allchars =
  [ CharBlock { start: 0, length: 32, convRule: rule0 }
  , CharBlock { start: 32, length: 1, convRule: rule1 }
  , CharBlock { start: 33, length: 3, convRule: rule2 }
  , CharBlock { start: 36, length: 1, convRule: rule3 }
  , CharBlock { start: 37, length: 3, convRule: rule2 }
  , CharBlock { start: 40, length: 1, convRule: rule4 }
  , CharBlock { start: 41, length: 1, convRule: rule5 }
  , CharBlock { start: 42, length: 1, convRule: rule2 }
  , CharBlock { start: 43, length: 1, convRule: rule6 }
  , CharBlock { start: 44, length: 1, convRule: rule2 }
  , CharBlock { start: 45, length: 1, convRule: rule7 }
  , CharBlock { start: 46, length: 2, convRule: rule2 }
  , CharBlock { start: 48, length: 10, convRule: rule8 }
  , CharBlock { start: 58, length: 2, convRule: rule2 }
  , CharBlock { start: 60, length: 3, convRule: rule6 }
  , CharBlock { start: 63, length: 2, convRule: rule2 }
  , CharBlock { start: 65, length: 26, convRule: rule9 }
  , CharBlock { start: 91, length: 1, convRule: rule4 }
  , CharBlock { start: 92, length: 1, convRule: rule2 }
  , CharBlock { start: 93, length: 1, convRule: rule5 }
  , CharBlock { start: 94, length: 1, convRule: rule10 }
  , CharBlock { start: 95, length: 1, convRule: rule11 }
  , CharBlock { start: 96, length: 1, convRule: rule10 }
  , CharBlock { start: 97, length: 26, convRule: rule12 }
  , CharBlock { start: 123, length: 1, convRule: rule4 }
  , CharBlock { start: 124, length: 1, convRule: rule6 }
  , CharBlock { start: 125, length: 1, convRule: rule5 }
  , CharBlock { start: 126, length: 1, convRule: rule6 }
  , CharBlock { start: 127, length: 33, convRule: rule0 }
  , CharBlock { start: 160, length: 1, convRule: rule1 }
  , CharBlock { start: 161, length: 1, convRule: rule2 }
  , CharBlock { start: 162, length: 4, convRule: rule3 }
  , CharBlock { start: 166, length: 1, convRule: rule13 }
  , CharBlock { start: 167, length: 1, convRule: rule2 }
  , CharBlock { start: 168, length: 1, convRule: rule10 }
  , CharBlock { start: 169, length: 1, convRule: rule13 }
  , CharBlock { start: 170, length: 1, convRule: rule14 }
  , CharBlock { start: 171, length: 1, convRule: rule15 }
  , CharBlock { start: 172, length: 1, convRule: rule6 }
  , CharBlock { start: 173, length: 1, convRule: rule16 }
  , CharBlock { start: 174, length: 1, convRule: rule13 }
  , CharBlock { start: 175, length: 1, convRule: rule10 }
  , CharBlock { start: 176, length: 1, convRule: rule13 }
  , CharBlock { start: 177, length: 1, convRule: rule6 }
  , CharBlock { start: 178, length: 2, convRule: rule17 }
  , CharBlock { start: 180, length: 1, convRule: rule10 }
  , CharBlock { start: 181, length: 1, convRule: rule18 }
  , CharBlock { start: 182, length: 2, convRule: rule2 }
  , CharBlock { start: 184, length: 1, convRule: rule10 }
  , CharBlock { start: 185, length: 1, convRule: rule17 }
  , CharBlock { start: 186, length: 1, convRule: rule14 }
  , CharBlock { start: 187, length: 1, convRule: rule19 }
  , CharBlock { start: 188, length: 3, convRule: rule17 }
  , CharBlock { start: 191, length: 1, convRule: rule2 }
  , CharBlock { start: 192, length: 23, convRule: rule9 }
  , CharBlock { start: 215, length: 1, convRule: rule6 }
  , CharBlock { start: 216, length: 7, convRule: rule9 }
  , CharBlock { start: 223, length: 1, convRule: rule20 }
  , CharBlock { start: 224, length: 23, convRule: rule12 }
  , CharBlock { start: 247, length: 1, convRule: rule6 }
  , CharBlock { start: 248, length: 7, convRule: rule12 }
  , CharBlock { start: 255, length: 1, convRule: rule21 }
  , CharBlock { start: 256, length: 1, convRule: rule22 }
  , CharBlock { start: 257, length: 1, convRule: rule23 }
  , CharBlock { start: 258, length: 1, convRule: rule22 }
  , CharBlock { start: 259, length: 1, convRule: rule23 }
  , CharBlock { start: 260, length: 1, convRule: rule22 }
  , CharBlock { start: 261, length: 1, convRule: rule23 }
  , CharBlock { start: 262, length: 1, convRule: rule22 }
  , CharBlock { start: 263, length: 1, convRule: rule23 }
  , CharBlock { start: 264, length: 1, convRule: rule22 }
  , CharBlock { start: 265, length: 1, convRule: rule23 }
  , CharBlock { start: 266, length: 1, convRule: rule22 }
  , CharBlock { start: 267, length: 1, convRule: rule23 }
  , CharBlock { start: 268, length: 1, convRule: rule22 }
  , CharBlock { start: 269, length: 1, convRule: rule23 }
  , CharBlock { start: 270, length: 1, convRule: rule22 }
  , CharBlock { start: 271, length: 1, convRule: rule23 }
  , CharBlock { start: 272, length: 1, convRule: rule22 }
  , CharBlock { start: 273, length: 1, convRule: rule23 }
  , CharBlock { start: 274, length: 1, convRule: rule22 }
  , CharBlock { start: 275, length: 1, convRule: rule23 }
  , CharBlock { start: 276, length: 1, convRule: rule22 }
  , CharBlock { start: 277, length: 1, convRule: rule23 }
  , CharBlock { start: 278, length: 1, convRule: rule22 }
  , CharBlock { start: 279, length: 1, convRule: rule23 }
  , CharBlock { start: 280, length: 1, convRule: rule22 }
  , CharBlock { start: 281, length: 1, convRule: rule23 }
  , CharBlock { start: 282, length: 1, convRule: rule22 }
  , CharBlock { start: 283, length: 1, convRule: rule23 }
  , CharBlock { start: 284, length: 1, convRule: rule22 }
  , CharBlock { start: 285, length: 1, convRule: rule23 }
  , CharBlock { start: 286, length: 1, convRule: rule22 }
  , CharBlock { start: 287, length: 1, convRule: rule23 }
  , CharBlock { start: 288, length: 1, convRule: rule22 }
  , CharBlock { start: 289, length: 1, convRule: rule23 }
  , CharBlock { start: 290, length: 1, convRule: rule22 }
  , CharBlock { start: 291, length: 1, convRule: rule23 }
  , CharBlock { start: 292, length: 1, convRule: rule22 }
  , CharBlock { start: 293, length: 1, convRule: rule23 }
  , CharBlock { start: 294, length: 1, convRule: rule22 }
  , CharBlock { start: 295, length: 1, convRule: rule23 }
  , CharBlock { start: 296, length: 1, convRule: rule22 }
  , CharBlock { start: 297, length: 1, convRule: rule23 }
  , CharBlock { start: 298, length: 1, convRule: rule22 }
  , CharBlock { start: 299, length: 1, convRule: rule23 }
  , CharBlock { start: 300, length: 1, convRule: rule22 }
  , CharBlock { start: 301, length: 1, convRule: rule23 }
  , CharBlock { start: 302, length: 1, convRule: rule22 }
  , CharBlock { start: 303, length: 1, convRule: rule23 }
  , CharBlock { start: 304, length: 1, convRule: rule24 }
  , CharBlock { start: 305, length: 1, convRule: rule25 }
  , CharBlock { start: 306, length: 1, convRule: rule22 }
  , CharBlock { start: 307, length: 1, convRule: rule23 }
  , CharBlock { start: 308, length: 1, convRule: rule22 }
  , CharBlock { start: 309, length: 1, convRule: rule23 }
  , CharBlock { start: 310, length: 1, convRule: rule22 }
  , CharBlock { start: 311, length: 1, convRule: rule23 }
  , CharBlock { start: 312, length: 1, convRule: rule20 }
  , CharBlock { start: 313, length: 1, convRule: rule22 }
  , CharBlock { start: 314, length: 1, convRule: rule23 }
  , CharBlock { start: 315, length: 1, convRule: rule22 }
  , CharBlock { start: 316, length: 1, convRule: rule23 }
  , CharBlock { start: 317, length: 1, convRule: rule22 }
  , CharBlock { start: 318, length: 1, convRule: rule23 }
  , CharBlock { start: 319, length: 1, convRule: rule22 }
  , CharBlock { start: 320, length: 1, convRule: rule23 }
  , CharBlock { start: 321, length: 1, convRule: rule22 }
  , CharBlock { start: 322, length: 1, convRule: rule23 }
  , CharBlock { start: 323, length: 1, convRule: rule22 }
  , CharBlock { start: 324, length: 1, convRule: rule23 }
  , CharBlock { start: 325, length: 1, convRule: rule22 }
  , CharBlock { start: 326, length: 1, convRule: rule23 }
  , CharBlock { start: 327, length: 1, convRule: rule22 }
  , CharBlock { start: 328, length: 1, convRule: rule23 }
  , CharBlock { start: 329, length: 1, convRule: rule20 }
  , CharBlock { start: 330, length: 1, convRule: rule22 }
  , CharBlock { start: 331, length: 1, convRule: rule23 }
  , CharBlock { start: 332, length: 1, convRule: rule22 }
  , CharBlock { start: 333, length: 1, convRule: rule23 }
  , CharBlock { start: 334, length: 1, convRule: rule22 }
  , CharBlock { start: 335, length: 1, convRule: rule23 }
  , CharBlock { start: 336, length: 1, convRule: rule22 }
  , CharBlock { start: 337, length: 1, convRule: rule23 }
  , CharBlock { start: 338, length: 1, convRule: rule22 }
  , CharBlock { start: 339, length: 1, convRule: rule23 }
  , CharBlock { start: 340, length: 1, convRule: rule22 }
  , CharBlock { start: 341, length: 1, convRule: rule23 }
  , CharBlock { start: 342, length: 1, convRule: rule22 }
  , CharBlock { start: 343, length: 1, convRule: rule23 }
  , CharBlock { start: 344, length: 1, convRule: rule22 }
  , CharBlock { start: 345, length: 1, convRule: rule23 }
  , CharBlock { start: 346, length: 1, convRule: rule22 }
  , CharBlock { start: 347, length: 1, convRule: rule23 }
  , CharBlock { start: 348, length: 1, convRule: rule22 }
  , CharBlock { start: 349, length: 1, convRule: rule23 }
  , CharBlock { start: 350, length: 1, convRule: rule22 }
  , CharBlock { start: 351, length: 1, convRule: rule23 }
  , CharBlock { start: 352, length: 1, convRule: rule22 }
  , CharBlock { start: 353, length: 1, convRule: rule23 }
  , CharBlock { start: 354, length: 1, convRule: rule22 }
  , CharBlock { start: 355, length: 1, convRule: rule23 }
  , CharBlock { start: 356, length: 1, convRule: rule22 }
  , CharBlock { start: 357, length: 1, convRule: rule23 }
  , CharBlock { start: 358, length: 1, convRule: rule22 }
  , CharBlock { start: 359, length: 1, convRule: rule23 }
  , CharBlock { start: 360, length: 1, convRule: rule22 }
  , CharBlock { start: 361, length: 1, convRule: rule23 }
  , CharBlock { start: 362, length: 1, convRule: rule22 }
  , CharBlock { start: 363, length: 1, convRule: rule23 }
  , CharBlock { start: 364, length: 1, convRule: rule22 }
  , CharBlock { start: 365, length: 1, convRule: rule23 }
  , CharBlock { start: 366, length: 1, convRule: rule22 }
  , CharBlock { start: 367, length: 1, convRule: rule23 }
  , CharBlock { start: 368, length: 1, convRule: rule22 }
  , CharBlock { start: 369, length: 1, convRule: rule23 }
  , CharBlock { start: 370, length: 1, convRule: rule22 }
  , CharBlock { start: 371, length: 1, convRule: rule23 }
  , CharBlock { start: 372, length: 1, convRule: rule22 }
  , CharBlock { start: 373, length: 1, convRule: rule23 }
  , CharBlock { start: 374, length: 1, convRule: rule22 }
  , CharBlock { start: 375, length: 1, convRule: rule23 }
  , CharBlock { start: 376, length: 1, convRule: rule26 }
  , CharBlock { start: 377, length: 1, convRule: rule22 }
  , CharBlock { start: 378, length: 1, convRule: rule23 }
  , CharBlock { start: 379, length: 1, convRule: rule22 }
  , CharBlock { start: 380, length: 1, convRule: rule23 }
  , CharBlock { start: 381, length: 1, convRule: rule22 }
  , CharBlock { start: 382, length: 1, convRule: rule23 }
  , CharBlock { start: 383, length: 1, convRule: rule27 }
  , CharBlock { start: 384, length: 1, convRule: rule28 }
  , CharBlock { start: 385, length: 1, convRule: rule29 }
  , CharBlock { start: 386, length: 1, convRule: rule22 }
  , CharBlock { start: 387, length: 1, convRule: rule23 }
  , CharBlock { start: 388, length: 1, convRule: rule22 }
  , CharBlock { start: 389, length: 1, convRule: rule23 }
  , CharBlock { start: 390, length: 1, convRule: rule30 }
  , CharBlock { start: 391, length: 1, convRule: rule22 }
  , CharBlock { start: 392, length: 1, convRule: rule23 }
  , CharBlock { start: 393, length: 2, convRule: rule31 }
  , CharBlock { start: 395, length: 1, convRule: rule22 }
  , CharBlock { start: 396, length: 1, convRule: rule23 }
  , CharBlock { start: 397, length: 1, convRule: rule20 }
  , CharBlock { start: 398, length: 1, convRule: rule32 }
  , CharBlock { start: 399, length: 1, convRule: rule33 }
  , CharBlock { start: 400, length: 1, convRule: rule34 }
  , CharBlock { start: 401, length: 1, convRule: rule22 }
  , CharBlock { start: 402, length: 1, convRule: rule23 }
  , CharBlock { start: 403, length: 1, convRule: rule31 }
  , CharBlock { start: 404, length: 1, convRule: rule35 }
  , CharBlock { start: 405, length: 1, convRule: rule36 }
  , CharBlock { start: 406, length: 1, convRule: rule37 }
  , CharBlock { start: 407, length: 1, convRule: rule38 }
  , CharBlock { start: 408, length: 1, convRule: rule22 }
  , CharBlock { start: 409, length: 1, convRule: rule23 }
  , CharBlock { start: 410, length: 1, convRule: rule39 }
  , CharBlock { start: 411, length: 1, convRule: rule20 }
  , CharBlock { start: 412, length: 1, convRule: rule37 }
  , CharBlock { start: 413, length: 1, convRule: rule40 }
  , CharBlock { start: 414, length: 1, convRule: rule41 }
  , CharBlock { start: 415, length: 1, convRule: rule42 }
  , CharBlock { start: 416, length: 1, convRule: rule22 }
  , CharBlock { start: 417, length: 1, convRule: rule23 }
  , CharBlock { start: 418, length: 1, convRule: rule22 }
  , CharBlock { start: 419, length: 1, convRule: rule23 }
  , CharBlock { start: 420, length: 1, convRule: rule22 }
  , CharBlock { start: 421, length: 1, convRule: rule23 }
  , CharBlock { start: 422, length: 1, convRule: rule43 }
  , CharBlock { start: 423, length: 1, convRule: rule22 }
  , CharBlock { start: 424, length: 1, convRule: rule23 }
  , CharBlock { start: 425, length: 1, convRule: rule43 }
  , CharBlock { start: 426, length: 2, convRule: rule20 }
  , CharBlock { start: 428, length: 1, convRule: rule22 }
  , CharBlock { start: 429, length: 1, convRule: rule23 }
  , CharBlock { start: 430, length: 1, convRule: rule43 }
  , CharBlock { start: 431, length: 1, convRule: rule22 }
  , CharBlock { start: 432, length: 1, convRule: rule23 }
  , CharBlock { start: 433, length: 2, convRule: rule44 }
  , CharBlock { start: 435, length: 1, convRule: rule22 }
  , CharBlock { start: 436, length: 1, convRule: rule23 }
  , CharBlock { start: 437, length: 1, convRule: rule22 }
  , CharBlock { start: 438, length: 1, convRule: rule23 }
  , CharBlock { start: 439, length: 1, convRule: rule45 }
  , CharBlock { start: 440, length: 1, convRule: rule22 }
  , CharBlock { start: 441, length: 1, convRule: rule23 }
  , CharBlock { start: 442, length: 1, convRule: rule20 }
  , CharBlock { start: 443, length: 1, convRule: rule14 }
  , CharBlock { start: 444, length: 1, convRule: rule22 }
  , CharBlock { start: 445, length: 1, convRule: rule23 }
  , CharBlock { start: 446, length: 1, convRule: rule20 }
  , CharBlock { start: 447, length: 1, convRule: rule46 }
  , CharBlock { start: 448, length: 4, convRule: rule14 }
  , CharBlock { start: 452, length: 1, convRule: rule47 }
  , CharBlock { start: 453, length: 1, convRule: rule48 }
  , CharBlock { start: 454, length: 1, convRule: rule49 }
  , CharBlock { start: 455, length: 1, convRule: rule47 }
  , CharBlock { start: 456, length: 1, convRule: rule48 }
  , CharBlock { start: 457, length: 1, convRule: rule49 }
  , CharBlock { start: 458, length: 1, convRule: rule47 }
  , CharBlock { start: 459, length: 1, convRule: rule48 }
  , CharBlock { start: 460, length: 1, convRule: rule49 }
  , CharBlock { start: 461, length: 1, convRule: rule22 }
  , CharBlock { start: 462, length: 1, convRule: rule23 }
  , CharBlock { start: 463, length: 1, convRule: rule22 }
  , CharBlock { start: 464, length: 1, convRule: rule23 }
  , CharBlock { start: 465, length: 1, convRule: rule22 }
  , CharBlock { start: 466, length: 1, convRule: rule23 }
  , CharBlock { start: 467, length: 1, convRule: rule22 }
  , CharBlock { start: 468, length: 1, convRule: rule23 }
  , CharBlock { start: 469, length: 1, convRule: rule22 }
  , CharBlock { start: 470, length: 1, convRule: rule23 }
  , CharBlock { start: 471, length: 1, convRule: rule22 }
  , CharBlock { start: 472, length: 1, convRule: rule23 }
  , CharBlock { start: 473, length: 1, convRule: rule22 }
  , CharBlock { start: 474, length: 1, convRule: rule23 }
  , CharBlock { start: 475, length: 1, convRule: rule22 }
  , CharBlock { start: 476, length: 1, convRule: rule23 }
  , CharBlock { start: 477, length: 1, convRule: rule50 }
  , CharBlock { start: 478, length: 1, convRule: rule22 }
  , CharBlock { start: 479, length: 1, convRule: rule23 }
  , CharBlock { start: 480, length: 1, convRule: rule22 }
  , CharBlock { start: 481, length: 1, convRule: rule23 }
  , CharBlock { start: 482, length: 1, convRule: rule22 }
  , CharBlock { start: 483, length: 1, convRule: rule23 }
  , CharBlock { start: 484, length: 1, convRule: rule22 }
  , CharBlock { start: 485, length: 1, convRule: rule23 }
  , CharBlock { start: 486, length: 1, convRule: rule22 }
  , CharBlock { start: 487, length: 1, convRule: rule23 }
  , CharBlock { start: 488, length: 1, convRule: rule22 }
  , CharBlock { start: 489, length: 1, convRule: rule23 }
  , CharBlock { start: 490, length: 1, convRule: rule22 }
  , CharBlock { start: 491, length: 1, convRule: rule23 }
  , CharBlock { start: 492, length: 1, convRule: rule22 }
  , CharBlock { start: 493, length: 1, convRule: rule23 }
  , CharBlock { start: 494, length: 1, convRule: rule22 }
  , CharBlock { start: 495, length: 1, convRule: rule23 }
  , CharBlock { start: 496, length: 1, convRule: rule20 }
  , CharBlock { start: 497, length: 1, convRule: rule47 }
  , CharBlock { start: 498, length: 1, convRule: rule48 }
  , CharBlock { start: 499, length: 1, convRule: rule49 }
  , CharBlock { start: 500, length: 1, convRule: rule22 }
  , CharBlock { start: 501, length: 1, convRule: rule23 }
  , CharBlock { start: 502, length: 1, convRule: rule51 }
  , CharBlock { start: 503, length: 1, convRule: rule52 }
  , CharBlock { start: 504, length: 1, convRule: rule22 }
  , CharBlock { start: 505, length: 1, convRule: rule23 }
  , CharBlock { start: 506, length: 1, convRule: rule22 }
  , CharBlock { start: 507, length: 1, convRule: rule23 }
  , CharBlock { start: 508, length: 1, convRule: rule22 }
  , CharBlock { start: 509, length: 1, convRule: rule23 }
  , CharBlock { start: 510, length: 1, convRule: rule22 }
  , CharBlock { start: 511, length: 1, convRule: rule23 }
  , CharBlock { start: 512, length: 1, convRule: rule22 }
  , CharBlock { start: 513, length: 1, convRule: rule23 }
  , CharBlock { start: 514, length: 1, convRule: rule22 }
  , CharBlock { start: 515, length: 1, convRule: rule23 }
  , CharBlock { start: 516, length: 1, convRule: rule22 }
  , CharBlock { start: 517, length: 1, convRule: rule23 }
  , CharBlock { start: 518, length: 1, convRule: rule22 }
  , CharBlock { start: 519, length: 1, convRule: rule23 }
  , CharBlock { start: 520, length: 1, convRule: rule22 }
  , CharBlock { start: 521, length: 1, convRule: rule23 }
  , CharBlock { start: 522, length: 1, convRule: rule22 }
  , CharBlock { start: 523, length: 1, convRule: rule23 }
  , CharBlock { start: 524, length: 1, convRule: rule22 }
  , CharBlock { start: 525, length: 1, convRule: rule23 }
  , CharBlock { start: 526, length: 1, convRule: rule22 }
  , CharBlock { start: 527, length: 1, convRule: rule23 }
  , CharBlock { start: 528, length: 1, convRule: rule22 }
  , CharBlock { start: 529, length: 1, convRule: rule23 }
  , CharBlock { start: 530, length: 1, convRule: rule22 }
  , CharBlock { start: 531, length: 1, convRule: rule23 }
  , CharBlock { start: 532, length: 1, convRule: rule22 }
  , CharBlock { start: 533, length: 1, convRule: rule23 }
  , CharBlock { start: 534, length: 1, convRule: rule22 }
  , CharBlock { start: 535, length: 1, convRule: rule23 }
  , CharBlock { start: 536, length: 1, convRule: rule22 }
  , CharBlock { start: 537, length: 1, convRule: rule23 }
  , CharBlock { start: 538, length: 1, convRule: rule22 }
  , CharBlock { start: 539, length: 1, convRule: rule23 }
  , CharBlock { start: 540, length: 1, convRule: rule22 }
  , CharBlock { start: 541, length: 1, convRule: rule23 }
  , CharBlock { start: 542, length: 1, convRule: rule22 }
  , CharBlock { start: 543, length: 1, convRule: rule23 }
  , CharBlock { start: 544, length: 1, convRule: rule53 }
  , CharBlock { start: 545, length: 1, convRule: rule20 }
  , CharBlock { start: 546, length: 1, convRule: rule22 }
  , CharBlock { start: 547, length: 1, convRule: rule23 }
  , CharBlock { start: 548, length: 1, convRule: rule22 }
  , CharBlock { start: 549, length: 1, convRule: rule23 }
  , CharBlock { start: 550, length: 1, convRule: rule22 }
  , CharBlock { start: 551, length: 1, convRule: rule23 }
  , CharBlock { start: 552, length: 1, convRule: rule22 }
  , CharBlock { start: 553, length: 1, convRule: rule23 }
  , CharBlock { start: 554, length: 1, convRule: rule22 }
  , CharBlock { start: 555, length: 1, convRule: rule23 }
  , CharBlock { start: 556, length: 1, convRule: rule22 }
  , CharBlock { start: 557, length: 1, convRule: rule23 }
  , CharBlock { start: 558, length: 1, convRule: rule22 }
  , CharBlock { start: 559, length: 1, convRule: rule23 }
  , CharBlock { start: 560, length: 1, convRule: rule22 }
  , CharBlock { start: 561, length: 1, convRule: rule23 }
  , CharBlock { start: 562, length: 1, convRule: rule22 }
  , CharBlock { start: 563, length: 1, convRule: rule23 }
  , CharBlock { start: 564, length: 6, convRule: rule20 }
  , CharBlock { start: 570, length: 1, convRule: rule54 }
  , CharBlock { start: 571, length: 1, convRule: rule22 }
  , CharBlock { start: 572, length: 1, convRule: rule23 }
  , CharBlock { start: 573, length: 1, convRule: rule55 }
  , CharBlock { start: 574, length: 1, convRule: rule56 }
  , CharBlock { start: 575, length: 2, convRule: rule57 }
  , CharBlock { start: 577, length: 1, convRule: rule22 }
  , CharBlock { start: 578, length: 1, convRule: rule23 }
  , CharBlock { start: 579, length: 1, convRule: rule58 }
  , CharBlock { start: 580, length: 1, convRule: rule59 }
  , CharBlock { start: 581, length: 1, convRule: rule60 }
  , CharBlock { start: 582, length: 1, convRule: rule22 }
  , CharBlock { start: 583, length: 1, convRule: rule23 }
  , CharBlock { start: 584, length: 1, convRule: rule22 }
  , CharBlock { start: 585, length: 1, convRule: rule23 }
  , CharBlock { start: 586, length: 1, convRule: rule22 }
  , CharBlock { start: 587, length: 1, convRule: rule23 }
  , CharBlock { start: 588, length: 1, convRule: rule22 }
  , CharBlock { start: 589, length: 1, convRule: rule23 }
  , CharBlock { start: 590, length: 1, convRule: rule22 }
  , CharBlock { start: 591, length: 1, convRule: rule23 }
  , CharBlock { start: 592, length: 1, convRule: rule61 }
  , CharBlock { start: 593, length: 1, convRule: rule62 }
  , CharBlock { start: 594, length: 1, convRule: rule63 }
  , CharBlock { start: 595, length: 1, convRule: rule64 }
  , CharBlock { start: 596, length: 1, convRule: rule65 }
  , CharBlock { start: 597, length: 1, convRule: rule20 }
  , CharBlock { start: 598, length: 2, convRule: rule66 }
  , CharBlock { start: 600, length: 1, convRule: rule20 }
  , CharBlock { start: 601, length: 1, convRule: rule67 }
  , CharBlock { start: 602, length: 1, convRule: rule20 }
  , CharBlock { start: 603, length: 1, convRule: rule68 }
  , CharBlock { start: 604, length: 1, convRule: rule69 }
  , CharBlock { start: 605, length: 3, convRule: rule20 }
  , CharBlock { start: 608, length: 1, convRule: rule66 }
  , CharBlock { start: 609, length: 1, convRule: rule70 }
  , CharBlock { start: 610, length: 1, convRule: rule20 }
  , CharBlock { start: 611, length: 1, convRule: rule71 }
  , CharBlock { start: 612, length: 1, convRule: rule20 }
  , CharBlock { start: 613, length: 1, convRule: rule72 }
  , CharBlock { start: 614, length: 1, convRule: rule73 }
  , CharBlock { start: 615, length: 1, convRule: rule20 }
  , CharBlock { start: 616, length: 1, convRule: rule74 }
  , CharBlock { start: 617, length: 1, convRule: rule75 }
  , CharBlock { start: 618, length: 1, convRule: rule73 }
  , CharBlock { start: 619, length: 1, convRule: rule76 }
  , CharBlock { start: 620, length: 1, convRule: rule77 }
  , CharBlock { start: 621, length: 2, convRule: rule20 }
  , CharBlock { start: 623, length: 1, convRule: rule75 }
  , CharBlock { start: 624, length: 1, convRule: rule20 }
  , CharBlock { start: 625, length: 1, convRule: rule78 }
  , CharBlock { start: 626, length: 1, convRule: rule79 }
  , CharBlock { start: 627, length: 2, convRule: rule20 }
  , CharBlock { start: 629, length: 1, convRule: rule80 }
  , CharBlock { start: 630, length: 7, convRule: rule20 }
  , CharBlock { start: 637, length: 1, convRule: rule81 }
  , CharBlock { start: 638, length: 2, convRule: rule20 }
  , CharBlock { start: 640, length: 1, convRule: rule82 }
  , CharBlock { start: 641, length: 1, convRule: rule20 }
  , CharBlock { start: 642, length: 1, convRule: rule83 }
  , CharBlock { start: 643, length: 1, convRule: rule82 }
  , CharBlock { start: 644, length: 3, convRule: rule20 }
  , CharBlock { start: 647, length: 1, convRule: rule84 }
  , CharBlock { start: 648, length: 1, convRule: rule82 }
  , CharBlock { start: 649, length: 1, convRule: rule85 }
  , CharBlock { start: 650, length: 2, convRule: rule86 }
  , CharBlock { start: 652, length: 1, convRule: rule87 }
  , CharBlock { start: 653, length: 5, convRule: rule20 }
  , CharBlock { start: 658, length: 1, convRule: rule88 }
  , CharBlock { start: 659, length: 1, convRule: rule20 }
  , CharBlock { start: 660, length: 1, convRule: rule14 }
  , CharBlock { start: 661, length: 8, convRule: rule20 }
  , CharBlock { start: 669, length: 1, convRule: rule89 }
  , CharBlock { start: 670, length: 1, convRule: rule90 }
  , CharBlock { start: 671, length: 17, convRule: rule20 }
  , CharBlock { start: 688, length: 18, convRule: rule91 }
  , CharBlock { start: 706, length: 4, convRule: rule10 }
  , CharBlock { start: 710, length: 12, convRule: rule91 }
  , CharBlock { start: 722, length: 14, convRule: rule10 }
  , CharBlock { start: 736, length: 5, convRule: rule91 }
  , CharBlock { start: 741, length: 7, convRule: rule10 }
  , CharBlock { start: 748, length: 1, convRule: rule91 }
  , CharBlock { start: 749, length: 1, convRule: rule10 }
  , CharBlock { start: 750, length: 1, convRule: rule91 }
  , CharBlock { start: 751, length: 17, convRule: rule10 }
  , CharBlock { start: 768, length: 69, convRule: rule92 }
  , CharBlock { start: 837, length: 1, convRule: rule93 }
  , CharBlock { start: 838, length: 42, convRule: rule92 }
  , CharBlock { start: 880, length: 1, convRule: rule22 }
  , CharBlock { start: 881, length: 1, convRule: rule23 }
  , CharBlock { start: 882, length: 1, convRule: rule22 }
  , CharBlock { start: 883, length: 1, convRule: rule23 }
  , CharBlock { start: 884, length: 1, convRule: rule91 }
  , CharBlock { start: 885, length: 1, convRule: rule10 }
  , CharBlock { start: 886, length: 1, convRule: rule22 }
  , CharBlock { start: 887, length: 1, convRule: rule23 }
  , CharBlock { start: 890, length: 1, convRule: rule91 }
  , CharBlock { start: 891, length: 3, convRule: rule41 }
  , CharBlock { start: 894, length: 1, convRule: rule2 }
  , CharBlock { start: 895, length: 1, convRule: rule94 }
  , CharBlock { start: 900, length: 2, convRule: rule10 }
  , CharBlock { start: 902, length: 1, convRule: rule95 }
  , CharBlock { start: 903, length: 1, convRule: rule2 }
  , CharBlock { start: 904, length: 3, convRule: rule96 }
  , CharBlock { start: 908, length: 1, convRule: rule97 }
  , CharBlock { start: 910, length: 2, convRule: rule98 }
  , CharBlock { start: 912, length: 1, convRule: rule20 }
  , CharBlock { start: 913, length: 17, convRule: rule9 }
  , CharBlock { start: 931, length: 9, convRule: rule9 }
  , CharBlock { start: 940, length: 1, convRule: rule99 }
  , CharBlock { start: 941, length: 3, convRule: rule100 }
  , CharBlock { start: 944, length: 1, convRule: rule20 }
  , CharBlock { start: 945, length: 17, convRule: rule12 }
  , CharBlock { start: 962, length: 1, convRule: rule101 }
  , CharBlock { start: 963, length: 9, convRule: rule12 }
  , CharBlock { start: 972, length: 1, convRule: rule102 }
  , CharBlock { start: 973, length: 2, convRule: rule103 }
  , CharBlock { start: 975, length: 1, convRule: rule104 }
  , CharBlock { start: 976, length: 1, convRule: rule105 }
  , CharBlock { start: 977, length: 1, convRule: rule106 }
  , CharBlock { start: 978, length: 3, convRule: rule107 }
  , CharBlock { start: 981, length: 1, convRule: rule108 }
  , CharBlock { start: 982, length: 1, convRule: rule109 }
  , CharBlock { start: 983, length: 1, convRule: rule110 }
  , CharBlock { start: 984, length: 1, convRule: rule22 }
  , CharBlock { start: 985, length: 1, convRule: rule23 }
  , CharBlock { start: 986, length: 1, convRule: rule22 }
  , CharBlock { start: 987, length: 1, convRule: rule23 }
  , CharBlock { start: 988, length: 1, convRule: rule22 }
  , CharBlock { start: 989, length: 1, convRule: rule23 }
  , CharBlock { start: 990, length: 1, convRule: rule22 }
  , CharBlock { start: 991, length: 1, convRule: rule23 }
  , CharBlock { start: 992, length: 1, convRule: rule22 }
  , CharBlock { start: 993, length: 1, convRule: rule23 }
  , CharBlock { start: 994, length: 1, convRule: rule22 }
  , CharBlock { start: 995, length: 1, convRule: rule23 }
  , CharBlock { start: 996, length: 1, convRule: rule22 }
  , CharBlock { start: 997, length: 1, convRule: rule23 }
  , CharBlock { start: 998, length: 1, convRule: rule22 }
  , CharBlock { start: 999, length: 1, convRule: rule23 }
  , CharBlock { start: 1000, length: 1, convRule: rule22 }
  , CharBlock { start: 1001, length: 1, convRule: rule23 }
  , CharBlock { start: 1002, length: 1, convRule: rule22 }
  , CharBlock { start: 1003, length: 1, convRule: rule23 }
  , CharBlock { start: 1004, length: 1, convRule: rule22 }
  , CharBlock { start: 1005, length: 1, convRule: rule23 }
  , CharBlock { start: 1006, length: 1, convRule: rule22 }
  , CharBlock { start: 1007, length: 1, convRule: rule23 }
  , CharBlock { start: 1008, length: 1, convRule: rule111 }
  , CharBlock { start: 1009, length: 1, convRule: rule112 }
  , CharBlock { start: 1010, length: 1, convRule: rule113 }
  , CharBlock { start: 1011, length: 1, convRule: rule114 }
  , CharBlock { start: 1012, length: 1, convRule: rule115 }
  , CharBlock { start: 1013, length: 1, convRule: rule116 }
  , CharBlock { start: 1014, length: 1, convRule: rule6 }
  , CharBlock { start: 1015, length: 1, convRule: rule22 }
  , CharBlock { start: 1016, length: 1, convRule: rule23 }
  , CharBlock { start: 1017, length: 1, convRule: rule117 }
  , CharBlock { start: 1018, length: 1, convRule: rule22 }
  , CharBlock { start: 1019, length: 1, convRule: rule23 }
  , CharBlock { start: 1020, length: 1, convRule: rule20 }
  , CharBlock { start: 1021, length: 3, convRule: rule53 }
  , CharBlock { start: 1024, length: 16, convRule: rule118 }
  , CharBlock { start: 1040, length: 32, convRule: rule9 }
  , CharBlock { start: 1072, length: 32, convRule: rule12 }
  , CharBlock { start: 1104, length: 16, convRule: rule112 }
  , CharBlock { start: 1120, length: 1, convRule: rule22 }
  , CharBlock { start: 1121, length: 1, convRule: rule23 }
  , CharBlock { start: 1122, length: 1, convRule: rule22 }
  , CharBlock { start: 1123, length: 1, convRule: rule23 }
  , CharBlock { start: 1124, length: 1, convRule: rule22 }
  , CharBlock { start: 1125, length: 1, convRule: rule23 }
  , CharBlock { start: 1126, length: 1, convRule: rule22 }
  , CharBlock { start: 1127, length: 1, convRule: rule23 }
  , CharBlock { start: 1128, length: 1, convRule: rule22 }
  , CharBlock { start: 1129, length: 1, convRule: rule23 }
  , CharBlock { start: 1130, length: 1, convRule: rule22 }
  , CharBlock { start: 1131, length: 1, convRule: rule23 }
  , CharBlock { start: 1132, length: 1, convRule: rule22 }
  , CharBlock { start: 1133, length: 1, convRule: rule23 }
  , CharBlock { start: 1134, length: 1, convRule: rule22 }
  , CharBlock { start: 1135, length: 1, convRule: rule23 }
  , CharBlock { start: 1136, length: 1, convRule: rule22 }
  , CharBlock { start: 1137, length: 1, convRule: rule23 }
  , CharBlock { start: 1138, length: 1, convRule: rule22 }
  , CharBlock { start: 1139, length: 1, convRule: rule23 }
  , CharBlock { start: 1140, length: 1, convRule: rule22 }
  , CharBlock { start: 1141, length: 1, convRule: rule23 }
  , CharBlock { start: 1142, length: 1, convRule: rule22 }
  , CharBlock { start: 1143, length: 1, convRule: rule23 }
  , CharBlock { start: 1144, length: 1, convRule: rule22 }
  , CharBlock { start: 1145, length: 1, convRule: rule23 }
  , CharBlock { start: 1146, length: 1, convRule: rule22 }
  , CharBlock { start: 1147, length: 1, convRule: rule23 }
  , CharBlock { start: 1148, length: 1, convRule: rule22 }
  , CharBlock { start: 1149, length: 1, convRule: rule23 }
  , CharBlock { start: 1150, length: 1, convRule: rule22 }
  , CharBlock { start: 1151, length: 1, convRule: rule23 }
  , CharBlock { start: 1152, length: 1, convRule: rule22 }
  , CharBlock { start: 1153, length: 1, convRule: rule23 }
  , CharBlock { start: 1154, length: 1, convRule: rule13 }
  , CharBlock { start: 1155, length: 5, convRule: rule92 }
  , CharBlock { start: 1160, length: 2, convRule: rule119 }
  , CharBlock { start: 1162, length: 1, convRule: rule22 }
  , CharBlock { start: 1163, length: 1, convRule: rule23 }
  , CharBlock { start: 1164, length: 1, convRule: rule22 }
  , CharBlock { start: 1165, length: 1, convRule: rule23 }
  , CharBlock { start: 1166, length: 1, convRule: rule22 }
  , CharBlock { start: 1167, length: 1, convRule: rule23 }
  , CharBlock { start: 1168, length: 1, convRule: rule22 }
  , CharBlock { start: 1169, length: 1, convRule: rule23 }
  , CharBlock { start: 1170, length: 1, convRule: rule22 }
  , CharBlock { start: 1171, length: 1, convRule: rule23 }
  , CharBlock { start: 1172, length: 1, convRule: rule22 }
  , CharBlock { start: 1173, length: 1, convRule: rule23 }
  , CharBlock { start: 1174, length: 1, convRule: rule22 }
  , CharBlock { start: 1175, length: 1, convRule: rule23 }
  , CharBlock { start: 1176, length: 1, convRule: rule22 }
  , CharBlock { start: 1177, length: 1, convRule: rule23 }
  , CharBlock { start: 1178, length: 1, convRule: rule22 }
  , CharBlock { start: 1179, length: 1, convRule: rule23 }
  , CharBlock { start: 1180, length: 1, convRule: rule22 }
  , CharBlock { start: 1181, length: 1, convRule: rule23 }
  , CharBlock { start: 1182, length: 1, convRule: rule22 }
  , CharBlock { start: 1183, length: 1, convRule: rule23 }
  , CharBlock { start: 1184, length: 1, convRule: rule22 }
  , CharBlock { start: 1185, length: 1, convRule: rule23 }
  , CharBlock { start: 1186, length: 1, convRule: rule22 }
  , CharBlock { start: 1187, length: 1, convRule: rule23 }
  , CharBlock { start: 1188, length: 1, convRule: rule22 }
  , CharBlock { start: 1189, length: 1, convRule: rule23 }
  , CharBlock { start: 1190, length: 1, convRule: rule22 }
  , CharBlock { start: 1191, length: 1, convRule: rule23 }
  , CharBlock { start: 1192, length: 1, convRule: rule22 }
  , CharBlock { start: 1193, length: 1, convRule: rule23 }
  , CharBlock { start: 1194, length: 1, convRule: rule22 }
  , CharBlock { start: 1195, length: 1, convRule: rule23 }
  , CharBlock { start: 1196, length: 1, convRule: rule22 }
  , CharBlock { start: 1197, length: 1, convRule: rule23 }
  , CharBlock { start: 1198, length: 1, convRule: rule22 }
  , CharBlock { start: 1199, length: 1, convRule: rule23 }
  , CharBlock { start: 1200, length: 1, convRule: rule22 }
  , CharBlock { start: 1201, length: 1, convRule: rule23 }
  , CharBlock { start: 1202, length: 1, convRule: rule22 }
  , CharBlock { start: 1203, length: 1, convRule: rule23 }
  , CharBlock { start: 1204, length: 1, convRule: rule22 }
  , CharBlock { start: 1205, length: 1, convRule: rule23 }
  , CharBlock { start: 1206, length: 1, convRule: rule22 }
  , CharBlock { start: 1207, length: 1, convRule: rule23 }
  , CharBlock { start: 1208, length: 1, convRule: rule22 }
  , CharBlock { start: 1209, length: 1, convRule: rule23 }
  , CharBlock { start: 1210, length: 1, convRule: rule22 }
  , CharBlock { start: 1211, length: 1, convRule: rule23 }
  , CharBlock { start: 1212, length: 1, convRule: rule22 }
  , CharBlock { start: 1213, length: 1, convRule: rule23 }
  , CharBlock { start: 1214, length: 1, convRule: rule22 }
  , CharBlock { start: 1215, length: 1, convRule: rule23 }
  , CharBlock { start: 1216, length: 1, convRule: rule120 }
  , CharBlock { start: 1217, length: 1, convRule: rule22 }
  , CharBlock { start: 1218, length: 1, convRule: rule23 }
  , CharBlock { start: 1219, length: 1, convRule: rule22 }
  , CharBlock { start: 1220, length: 1, convRule: rule23 }
  , CharBlock { start: 1221, length: 1, convRule: rule22 }
  , CharBlock { start: 1222, length: 1, convRule: rule23 }
  , CharBlock { start: 1223, length: 1, convRule: rule22 }
  , CharBlock { start: 1224, length: 1, convRule: rule23 }
  , CharBlock { start: 1225, length: 1, convRule: rule22 }
  , CharBlock { start: 1226, length: 1, convRule: rule23 }
  , CharBlock { start: 1227, length: 1, convRule: rule22 }
  , CharBlock { start: 1228, length: 1, convRule: rule23 }
  , CharBlock { start: 1229, length: 1, convRule: rule22 }
  , CharBlock { start: 1230, length: 1, convRule: rule23 }
  , CharBlock { start: 1231, length: 1, convRule: rule121 }
  , CharBlock { start: 1232, length: 1, convRule: rule22 }
  , CharBlock { start: 1233, length: 1, convRule: rule23 }
  , CharBlock { start: 1234, length: 1, convRule: rule22 }
  , CharBlock { start: 1235, length: 1, convRule: rule23 }
  , CharBlock { start: 1236, length: 1, convRule: rule22 }
  , CharBlock { start: 1237, length: 1, convRule: rule23 }
  , CharBlock { start: 1238, length: 1, convRule: rule22 }
  , CharBlock { start: 1239, length: 1, convRule: rule23 }
  , CharBlock { start: 1240, length: 1, convRule: rule22 }
  , CharBlock { start: 1241, length: 1, convRule: rule23 }
  , CharBlock { start: 1242, length: 1, convRule: rule22 }
  , CharBlock { start: 1243, length: 1, convRule: rule23 }
  , CharBlock { start: 1244, length: 1, convRule: rule22 }
  , CharBlock { start: 1245, length: 1, convRule: rule23 }
  , CharBlock { start: 1246, length: 1, convRule: rule22 }
  , CharBlock { start: 1247, length: 1, convRule: rule23 }
  , CharBlock { start: 1248, length: 1, convRule: rule22 }
  , CharBlock { start: 1249, length: 1, convRule: rule23 }
  , CharBlock { start: 1250, length: 1, convRule: rule22 }
  , CharBlock { start: 1251, length: 1, convRule: rule23 }
  , CharBlock { start: 1252, length: 1, convRule: rule22 }
  , CharBlock { start: 1253, length: 1, convRule: rule23 }
  , CharBlock { start: 1254, length: 1, convRule: rule22 }
  , CharBlock { start: 1255, length: 1, convRule: rule23 }
  , CharBlock { start: 1256, length: 1, convRule: rule22 }
  , CharBlock { start: 1257, length: 1, convRule: rule23 }
  , CharBlock { start: 1258, length: 1, convRule: rule22 }
  , CharBlock { start: 1259, length: 1, convRule: rule23 }
  , CharBlock { start: 1260, length: 1, convRule: rule22 }
  , CharBlock { start: 1261, length: 1, convRule: rule23 }
  , CharBlock { start: 1262, length: 1, convRule: rule22 }
  , CharBlock { start: 1263, length: 1, convRule: rule23 }
  , CharBlock { start: 1264, length: 1, convRule: rule22 }
  , CharBlock { start: 1265, length: 1, convRule: rule23 }
  , CharBlock { start: 1266, length: 1, convRule: rule22 }
  , CharBlock { start: 1267, length: 1, convRule: rule23 }
  , CharBlock { start: 1268, length: 1, convRule: rule22 }
  , CharBlock { start: 1269, length: 1, convRule: rule23 }
  , CharBlock { start: 1270, length: 1, convRule: rule22 }
  , CharBlock { start: 1271, length: 1, convRule: rule23 }
  , CharBlock { start: 1272, length: 1, convRule: rule22 }
  , CharBlock { start: 1273, length: 1, convRule: rule23 }
  , CharBlock { start: 1274, length: 1, convRule: rule22 }
  , CharBlock { start: 1275, length: 1, convRule: rule23 }
  , CharBlock { start: 1276, length: 1, convRule: rule22 }
  , CharBlock { start: 1277, length: 1, convRule: rule23 }
  , CharBlock { start: 1278, length: 1, convRule: rule22 }
  , CharBlock { start: 1279, length: 1, convRule: rule23 }
  , CharBlock { start: 1280, length: 1, convRule: rule22 }
  , CharBlock { start: 1281, length: 1, convRule: rule23 }
  , CharBlock { start: 1282, length: 1, convRule: rule22 }
  , CharBlock { start: 1283, length: 1, convRule: rule23 }
  , CharBlock { start: 1284, length: 1, convRule: rule22 }
  , CharBlock { start: 1285, length: 1, convRule: rule23 }
  , CharBlock { start: 1286, length: 1, convRule: rule22 }
  , CharBlock { start: 1287, length: 1, convRule: rule23 }
  , CharBlock { start: 1288, length: 1, convRule: rule22 }
  , CharBlock { start: 1289, length: 1, convRule: rule23 }
  , CharBlock { start: 1290, length: 1, convRule: rule22 }
  , CharBlock { start: 1291, length: 1, convRule: rule23 }
  , CharBlock { start: 1292, length: 1, convRule: rule22 }
  , CharBlock { start: 1293, length: 1, convRule: rule23 }
  , CharBlock { start: 1294, length: 1, convRule: rule22 }
  , CharBlock { start: 1295, length: 1, convRule: rule23 }
  , CharBlock { start: 1296, length: 1, convRule: rule22 }
  , CharBlock { start: 1297, length: 1, convRule: rule23 }
  , CharBlock { start: 1298, length: 1, convRule: rule22 }
  , CharBlock { start: 1299, length: 1, convRule: rule23 }
  , CharBlock { start: 1300, length: 1, convRule: rule22 }
  , CharBlock { start: 1301, length: 1, convRule: rule23 }
  , CharBlock { start: 1302, length: 1, convRule: rule22 }
  , CharBlock { start: 1303, length: 1, convRule: rule23 }
  , CharBlock { start: 1304, length: 1, convRule: rule22 }
  , CharBlock { start: 1305, length: 1, convRule: rule23 }
  , CharBlock { start: 1306, length: 1, convRule: rule22 }
  , CharBlock { start: 1307, length: 1, convRule: rule23 }
  , CharBlock { start: 1308, length: 1, convRule: rule22 }
  , CharBlock { start: 1309, length: 1, convRule: rule23 }
  , CharBlock { start: 1310, length: 1, convRule: rule22 }
  , CharBlock { start: 1311, length: 1, convRule: rule23 }
  , CharBlock { start: 1312, length: 1, convRule: rule22 }
  , CharBlock { start: 1313, length: 1, convRule: rule23 }
  , CharBlock { start: 1314, length: 1, convRule: rule22 }
  , CharBlock { start: 1315, length: 1, convRule: rule23 }
  , CharBlock { start: 1316, length: 1, convRule: rule22 }
  , CharBlock { start: 1317, length: 1, convRule: rule23 }
  , CharBlock { start: 1318, length: 1, convRule: rule22 }
  , CharBlock { start: 1319, length: 1, convRule: rule23 }
  , CharBlock { start: 1320, length: 1, convRule: rule22 }
  , CharBlock { start: 1321, length: 1, convRule: rule23 }
  , CharBlock { start: 1322, length: 1, convRule: rule22 }
  , CharBlock { start: 1323, length: 1, convRule: rule23 }
  , CharBlock { start: 1324, length: 1, convRule: rule22 }
  , CharBlock { start: 1325, length: 1, convRule: rule23 }
  , CharBlock { start: 1326, length: 1, convRule: rule22 }
  , CharBlock { start: 1327, length: 1, convRule: rule23 }
  , CharBlock { start: 1329, length: 38, convRule: rule122 }
  , CharBlock { start: 1369, length: 1, convRule: rule91 }
  , CharBlock { start: 1370, length: 6, convRule: rule2 }
  , CharBlock { start: 1376, length: 1, convRule: rule20 }
  , CharBlock { start: 1377, length: 38, convRule: rule123 }
  , CharBlock { start: 1415, length: 2, convRule: rule20 }
  , CharBlock { start: 1417, length: 1, convRule: rule2 }
  , CharBlock { start: 1418, length: 1, convRule: rule7 }
  , CharBlock { start: 1421, length: 2, convRule: rule13 }
  , CharBlock { start: 1423, length: 1, convRule: rule3 }
  , CharBlock { start: 1425, length: 45, convRule: rule92 }
  , CharBlock { start: 1470, length: 1, convRule: rule7 }
  , CharBlock { start: 1471, length: 1, convRule: rule92 }
  , CharBlock { start: 1472, length: 1, convRule: rule2 }
  , CharBlock { start: 1473, length: 2, convRule: rule92 }
  , CharBlock { start: 1475, length: 1, convRule: rule2 }
  , CharBlock { start: 1476, length: 2, convRule: rule92 }
  , CharBlock { start: 1478, length: 1, convRule: rule2 }
  , CharBlock { start: 1479, length: 1, convRule: rule92 }
  , CharBlock { start: 1488, length: 27, convRule: rule14 }
  , CharBlock { start: 1519, length: 4, convRule: rule14 }
  , CharBlock { start: 1523, length: 2, convRule: rule2 }
  , CharBlock { start: 1536, length: 6, convRule: rule16 }
  , CharBlock { start: 1542, length: 3, convRule: rule6 }
  , CharBlock { start: 1545, length: 2, convRule: rule2 }
  , CharBlock { start: 1547, length: 1, convRule: rule3 }
  , CharBlock { start: 1548, length: 2, convRule: rule2 }
  , CharBlock { start: 1550, length: 2, convRule: rule13 }
  , CharBlock { start: 1552, length: 11, convRule: rule92 }
  , CharBlock { start: 1563, length: 1, convRule: rule2 }
  , CharBlock { start: 1564, length: 1, convRule: rule16 }
  , CharBlock { start: 1566, length: 2, convRule: rule2 }
  , CharBlock { start: 1568, length: 32, convRule: rule14 }
  , CharBlock { start: 1600, length: 1, convRule: rule91 }
  , CharBlock { start: 1601, length: 10, convRule: rule14 }
  , CharBlock { start: 1611, length: 21, convRule: rule92 }
  , CharBlock { start: 1632, length: 10, convRule: rule8 }
  , CharBlock { start: 1642, length: 4, convRule: rule2 }
  , CharBlock { start: 1646, length: 2, convRule: rule14 }
  , CharBlock { start: 1648, length: 1, convRule: rule92 }
  , CharBlock { start: 1649, length: 99, convRule: rule14 }
  , CharBlock { start: 1748, length: 1, convRule: rule2 }
  , CharBlock { start: 1749, length: 1, convRule: rule14 }
  , CharBlock { start: 1750, length: 7, convRule: rule92 }
  , CharBlock { start: 1757, length: 1, convRule: rule16 }
  , CharBlock { start: 1758, length: 1, convRule: rule13 }
  , CharBlock { start: 1759, length: 6, convRule: rule92 }
  , CharBlock { start: 1765, length: 2, convRule: rule91 }
  , CharBlock { start: 1767, length: 2, convRule: rule92 }
  , CharBlock { start: 1769, length: 1, convRule: rule13 }
  , CharBlock { start: 1770, length: 4, convRule: rule92 }
  , CharBlock { start: 1774, length: 2, convRule: rule14 }
  , CharBlock { start: 1776, length: 10, convRule: rule8 }
  , CharBlock { start: 1786, length: 3, convRule: rule14 }
  , CharBlock { start: 1789, length: 2, convRule: rule13 }
  , CharBlock { start: 1791, length: 1, convRule: rule14 }
  , CharBlock { start: 1792, length: 14, convRule: rule2 }
  , CharBlock { start: 1807, length: 1, convRule: rule16 }
  , CharBlock { start: 1808, length: 1, convRule: rule14 }
  , CharBlock { start: 1809, length: 1, convRule: rule92 }
  , CharBlock { start: 1810, length: 30, convRule: rule14 }
  , CharBlock { start: 1840, length: 27, convRule: rule92 }
  , CharBlock { start: 1869, length: 89, convRule: rule14 }
  , CharBlock { start: 1958, length: 11, convRule: rule92 }
  , CharBlock { start: 1969, length: 1, convRule: rule14 }
  , CharBlock { start: 1984, length: 10, convRule: rule8 }
  , CharBlock { start: 1994, length: 33, convRule: rule14 }
  , CharBlock { start: 2027, length: 9, convRule: rule92 }
  , CharBlock { start: 2036, length: 2, convRule: rule91 }
  , CharBlock { start: 2038, length: 1, convRule: rule13 }
  , CharBlock { start: 2039, length: 3, convRule: rule2 }
  , CharBlock { start: 2042, length: 1, convRule: rule91 }
  , CharBlock { start: 2045, length: 1, convRule: rule92 }
  , CharBlock { start: 2046, length: 2, convRule: rule3 }
  , CharBlock { start: 2048, length: 22, convRule: rule14 }
  , CharBlock { start: 2070, length: 4, convRule: rule92 }
  , CharBlock { start: 2074, length: 1, convRule: rule91 }
  , CharBlock { start: 2075, length: 9, convRule: rule92 }
  , CharBlock { start: 2084, length: 1, convRule: rule91 }
  , CharBlock { start: 2085, length: 3, convRule: rule92 }
  , CharBlock { start: 2088, length: 1, convRule: rule91 }
  , CharBlock { start: 2089, length: 5, convRule: rule92 }
  , CharBlock { start: 2096, length: 15, convRule: rule2 }
  , CharBlock { start: 2112, length: 25, convRule: rule14 }
  , CharBlock { start: 2137, length: 3, convRule: rule92 }
  , CharBlock { start: 2142, length: 1, convRule: rule2 }
  , CharBlock { start: 2144, length: 11, convRule: rule14 }
  , CharBlock { start: 2208, length: 21, convRule: rule14 }
  , CharBlock { start: 2230, length: 18, convRule: rule14 }
  , CharBlock { start: 2259, length: 15, convRule: rule92 }
  , CharBlock { start: 2274, length: 1, convRule: rule16 }
  , CharBlock { start: 2275, length: 32, convRule: rule92 }
  , CharBlock { start: 2307, length: 1, convRule: rule124 }
  , CharBlock { start: 2308, length: 54, convRule: rule14 }
  , CharBlock { start: 2362, length: 1, convRule: rule92 }
  , CharBlock { start: 2363, length: 1, convRule: rule124 }
  , CharBlock { start: 2364, length: 1, convRule: rule92 }
  , CharBlock { start: 2365, length: 1, convRule: rule14 }
  , CharBlock { start: 2366, length: 3, convRule: rule124 }
  , CharBlock { start: 2369, length: 8, convRule: rule92 }
  , CharBlock { start: 2377, length: 4, convRule: rule124 }
  , CharBlock { start: 2381, length: 1, convRule: rule92 }
  , CharBlock { start: 2382, length: 2, convRule: rule124 }
  , CharBlock { start: 2384, length: 1, convRule: rule14 }
  , CharBlock { start: 2385, length: 7, convRule: rule92 }
  , CharBlock { start: 2392, length: 10, convRule: rule14 }
  , CharBlock { start: 2402, length: 2, convRule: rule92 }
  , CharBlock { start: 2404, length: 2, convRule: rule2 }
  , CharBlock { start: 2406, length: 10, convRule: rule8 }
  , CharBlock { start: 2416, length: 1, convRule: rule2 }
  , CharBlock { start: 2417, length: 1, convRule: rule91 }
  , CharBlock { start: 2418, length: 15, convRule: rule14 }
  , CharBlock { start: 2433, length: 1, convRule: rule92 }
  , CharBlock { start: 2434, length: 2, convRule: rule124 }
  , CharBlock { start: 2437, length: 8, convRule: rule14 }
  , CharBlock { start: 2447, length: 2, convRule: rule14 }
  , CharBlock { start: 2451, length: 22, convRule: rule14 }
  , CharBlock { start: 2474, length: 7, convRule: rule14 }
  , CharBlock { start: 2482, length: 1, convRule: rule14 }
  , CharBlock { start: 2486, length: 4, convRule: rule14 }
  , CharBlock { start: 2492, length: 1, convRule: rule92 }
  , CharBlock { start: 2493, length: 1, convRule: rule14 }
  , CharBlock { start: 2494, length: 3, convRule: rule124 }
  , CharBlock { start: 2497, length: 4, convRule: rule92 }
  , CharBlock { start: 2503, length: 2, convRule: rule124 }
  , CharBlock { start: 2507, length: 2, convRule: rule124 }
  , CharBlock { start: 2509, length: 1, convRule: rule92 }
  , CharBlock { start: 2510, length: 1, convRule: rule14 }
  , CharBlock { start: 2519, length: 1, convRule: rule124 }
  , CharBlock { start: 2524, length: 2, convRule: rule14 }
  , CharBlock { start: 2527, length: 3, convRule: rule14 }
  , CharBlock { start: 2530, length: 2, convRule: rule92 }
  , CharBlock { start: 2534, length: 10, convRule: rule8 }
  , CharBlock { start: 2544, length: 2, convRule: rule14 }
  , CharBlock { start: 2546, length: 2, convRule: rule3 }
  , CharBlock { start: 2548, length: 6, convRule: rule17 }
  , CharBlock { start: 2554, length: 1, convRule: rule13 }
  , CharBlock { start: 2555, length: 1, convRule: rule3 }
  , CharBlock { start: 2556, length: 1, convRule: rule14 }
  , CharBlock { start: 2557, length: 1, convRule: rule2 }
  , CharBlock { start: 2558, length: 1, convRule: rule92 }
  , CharBlock { start: 2561, length: 2, convRule: rule92 }
  , CharBlock { start: 2563, length: 1, convRule: rule124 }
  , CharBlock { start: 2565, length: 6, convRule: rule14 }
  , CharBlock { start: 2575, length: 2, convRule: rule14 }
  , CharBlock { start: 2579, length: 22, convRule: rule14 }
  , CharBlock { start: 2602, length: 7, convRule: rule14 }
  , CharBlock { start: 2610, length: 2, convRule: rule14 }
  , CharBlock { start: 2613, length: 2, convRule: rule14 }
  , CharBlock { start: 2616, length: 2, convRule: rule14 }
  , CharBlock { start: 2620, length: 1, convRule: rule92 }
  , CharBlock { start: 2622, length: 3, convRule: rule124 }
  , CharBlock { start: 2625, length: 2, convRule: rule92 }
  , CharBlock { start: 2631, length: 2, convRule: rule92 }
  , CharBlock { start: 2635, length: 3, convRule: rule92 }
  , CharBlock { start: 2641, length: 1, convRule: rule92 }
  , CharBlock { start: 2649, length: 4, convRule: rule14 }
  , CharBlock { start: 2654, length: 1, convRule: rule14 }
  , CharBlock { start: 2662, length: 10, convRule: rule8 }
  , CharBlock { start: 2672, length: 2, convRule: rule92 }
  , CharBlock { start: 2674, length: 3, convRule: rule14 }
  , CharBlock { start: 2677, length: 1, convRule: rule92 }
  , CharBlock { start: 2678, length: 1, convRule: rule2 }
  , CharBlock { start: 2689, length: 2, convRule: rule92 }
  , CharBlock { start: 2691, length: 1, convRule: rule124 }
  , CharBlock { start: 2693, length: 9, convRule: rule14 }
  , CharBlock { start: 2703, length: 3, convRule: rule14 }
  , CharBlock { start: 2707, length: 22, convRule: rule14 }
  , CharBlock { start: 2730, length: 7, convRule: rule14 }
  , CharBlock { start: 2738, length: 2, convRule: rule14 }
  , CharBlock { start: 2741, length: 5, convRule: rule14 }
  , CharBlock { start: 2748, length: 1, convRule: rule92 }
  , CharBlock { start: 2749, length: 1, convRule: rule14 }
  , CharBlock { start: 2750, length: 3, convRule: rule124 }
  , CharBlock { start: 2753, length: 5, convRule: rule92 }
  , CharBlock { start: 2759, length: 2, convRule: rule92 }
  , CharBlock { start: 2761, length: 1, convRule: rule124 }
  , CharBlock { start: 2763, length: 2, convRule: rule124 }
  , CharBlock { start: 2765, length: 1, convRule: rule92 }
  , CharBlock { start: 2768, length: 1, convRule: rule14 }
  , CharBlock { start: 2784, length: 2, convRule: rule14 }
  , CharBlock { start: 2786, length: 2, convRule: rule92 }
  , CharBlock { start: 2790, length: 10, convRule: rule8 }
  , CharBlock { start: 2800, length: 1, convRule: rule2 }
  , CharBlock { start: 2801, length: 1, convRule: rule3 }
  , CharBlock { start: 2809, length: 1, convRule: rule14 }
  , CharBlock { start: 2810, length: 6, convRule: rule92 }
  , CharBlock { start: 2817, length: 1, convRule: rule92 }
  , CharBlock { start: 2818, length: 2, convRule: rule124 }
  , CharBlock { start: 2821, length: 8, convRule: rule14 }
  , CharBlock { start: 2831, length: 2, convRule: rule14 }
  , CharBlock { start: 2835, length: 22, convRule: rule14 }
  , CharBlock { start: 2858, length: 7, convRule: rule14 }
  , CharBlock { start: 2866, length: 2, convRule: rule14 }
  , CharBlock { start: 2869, length: 5, convRule: rule14 }
  , CharBlock { start: 2876, length: 1, convRule: rule92 }
  , CharBlock { start: 2877, length: 1, convRule: rule14 }
  , CharBlock { start: 2878, length: 1, convRule: rule124 }
  , CharBlock { start: 2879, length: 1, convRule: rule92 }
  , CharBlock { start: 2880, length: 1, convRule: rule124 }
  , CharBlock { start: 2881, length: 4, convRule: rule92 }
  , CharBlock { start: 2887, length: 2, convRule: rule124 }
  , CharBlock { start: 2891, length: 2, convRule: rule124 }
  , CharBlock { start: 2893, length: 1, convRule: rule92 }
  , CharBlock { start: 2901, length: 2, convRule: rule92 }
  , CharBlock { start: 2903, length: 1, convRule: rule124 }
  , CharBlock { start: 2908, length: 2, convRule: rule14 }
  , CharBlock { start: 2911, length: 3, convRule: rule14 }
  , CharBlock { start: 2914, length: 2, convRule: rule92 }
  , CharBlock { start: 2918, length: 10, convRule: rule8 }
  , CharBlock { start: 2928, length: 1, convRule: rule13 }
  , CharBlock { start: 2929, length: 1, convRule: rule14 }
  , CharBlock { start: 2930, length: 6, convRule: rule17 }
  , CharBlock { start: 2946, length: 1, convRule: rule92 }
  , CharBlock { start: 2947, length: 1, convRule: rule14 }
  , CharBlock { start: 2949, length: 6, convRule: rule14 }
  , CharBlock { start: 2958, length: 3, convRule: rule14 }
  , CharBlock { start: 2962, length: 4, convRule: rule14 }
  , CharBlock { start: 2969, length: 2, convRule: rule14 }
  , CharBlock { start: 2972, length: 1, convRule: rule14 }
  , CharBlock { start: 2974, length: 2, convRule: rule14 }
  , CharBlock { start: 2979, length: 2, convRule: rule14 }
  , CharBlock { start: 2984, length: 3, convRule: rule14 }
  , CharBlock { start: 2990, length: 12, convRule: rule14 }
  , CharBlock { start: 3006, length: 2, convRule: rule124 }
  , CharBlock { start: 3008, length: 1, convRule: rule92 }
  , CharBlock { start: 3009, length: 2, convRule: rule124 }
  , CharBlock { start: 3014, length: 3, convRule: rule124 }
  , CharBlock { start: 3018, length: 3, convRule: rule124 }
  , CharBlock { start: 3021, length: 1, convRule: rule92 }
  , CharBlock { start: 3024, length: 1, convRule: rule14 }
  , CharBlock { start: 3031, length: 1, convRule: rule124 }
  , CharBlock { start: 3046, length: 10, convRule: rule8 }
  , CharBlock { start: 3056, length: 3, convRule: rule17 }
  , CharBlock { start: 3059, length: 6, convRule: rule13 }
  , CharBlock { start: 3065, length: 1, convRule: rule3 }
  , CharBlock { start: 3066, length: 1, convRule: rule13 }
  , CharBlock { start: 3072, length: 1, convRule: rule92 }
  , CharBlock { start: 3073, length: 3, convRule: rule124 }
  , CharBlock { start: 3076, length: 1, convRule: rule92 }
  , CharBlock { start: 3077, length: 8, convRule: rule14 }
  , CharBlock { start: 3086, length: 3, convRule: rule14 }
  , CharBlock { start: 3090, length: 23, convRule: rule14 }
  , CharBlock { start: 3114, length: 16, convRule: rule14 }
  , CharBlock { start: 3133, length: 1, convRule: rule14 }
  , CharBlock { start: 3134, length: 3, convRule: rule92 }
  , CharBlock { start: 3137, length: 4, convRule: rule124 }
  , CharBlock { start: 3142, length: 3, convRule: rule92 }
  , CharBlock { start: 3146, length: 4, convRule: rule92 }
  , CharBlock { start: 3157, length: 2, convRule: rule92 }
  , CharBlock { start: 3160, length: 3, convRule: rule14 }
  , CharBlock { start: 3168, length: 2, convRule: rule14 }
  , CharBlock { start: 3170, length: 2, convRule: rule92 }
  , CharBlock { start: 3174, length: 10, convRule: rule8 }
  , CharBlock { start: 3191, length: 1, convRule: rule2 }
  , CharBlock { start: 3192, length: 7, convRule: rule17 }
  , CharBlock { start: 3199, length: 1, convRule: rule13 }
  , CharBlock { start: 3200, length: 1, convRule: rule14 }
  , CharBlock { start: 3201, length: 1, convRule: rule92 }
  , CharBlock { start: 3202, length: 2, convRule: rule124 }
  , CharBlock { start: 3204, length: 1, convRule: rule2 }
  , CharBlock { start: 3205, length: 8, convRule: rule14 }
  , CharBlock { start: 3214, length: 3, convRule: rule14 }
  , CharBlock { start: 3218, length: 23, convRule: rule14 }
  , CharBlock { start: 3242, length: 10, convRule: rule14 }
  , CharBlock { start: 3253, length: 5, convRule: rule14 }
  , CharBlock { start: 3260, length: 1, convRule: rule92 }
  , CharBlock { start: 3261, length: 1, convRule: rule14 }
  , CharBlock { start: 3262, length: 1, convRule: rule124 }
  , CharBlock { start: 3263, length: 1, convRule: rule92 }
  , CharBlock { start: 3264, length: 5, convRule: rule124 }
  , CharBlock { start: 3270, length: 1, convRule: rule92 }
  , CharBlock { start: 3271, length: 2, convRule: rule124 }
  , CharBlock { start: 3274, length: 2, convRule: rule124 }
  , CharBlock { start: 3276, length: 2, convRule: rule92 }
  , CharBlock { start: 3285, length: 2, convRule: rule124 }
  , CharBlock { start: 3294, length: 1, convRule: rule14 }
  , CharBlock { start: 3296, length: 2, convRule: rule14 }
  , CharBlock { start: 3298, length: 2, convRule: rule92 }
  , CharBlock { start: 3302, length: 10, convRule: rule8 }
  , CharBlock { start: 3313, length: 2, convRule: rule14 }
  , CharBlock { start: 3328, length: 2, convRule: rule92 }
  , CharBlock { start: 3330, length: 2, convRule: rule124 }
  , CharBlock { start: 3332, length: 9, convRule: rule14 }
  , CharBlock { start: 3342, length: 3, convRule: rule14 }
  , CharBlock { start: 3346, length: 41, convRule: rule14 }
  , CharBlock { start: 3387, length: 2, convRule: rule92 }
  , CharBlock { start: 3389, length: 1, convRule: rule14 }
  , CharBlock { start: 3390, length: 3, convRule: rule124 }
  , CharBlock { start: 3393, length: 4, convRule: rule92 }
  , CharBlock { start: 3398, length: 3, convRule: rule124 }
  , CharBlock { start: 3402, length: 3, convRule: rule124 }
  , CharBlock { start: 3405, length: 1, convRule: rule92 }
  , CharBlock { start: 3406, length: 1, convRule: rule14 }
  , CharBlock { start: 3407, length: 1, convRule: rule13 }
  , CharBlock { start: 3412, length: 3, convRule: rule14 }
  , CharBlock { start: 3415, length: 1, convRule: rule124 }
  , CharBlock { start: 3416, length: 7, convRule: rule17 }
  , CharBlock { start: 3423, length: 3, convRule: rule14 }
  , CharBlock { start: 3426, length: 2, convRule: rule92 }
  , CharBlock { start: 3430, length: 10, convRule: rule8 }
  , CharBlock { start: 3440, length: 9, convRule: rule17 }
  , CharBlock { start: 3449, length: 1, convRule: rule13 }
  , CharBlock { start: 3450, length: 6, convRule: rule14 }
  , CharBlock { start: 3457, length: 1, convRule: rule92 }
  , CharBlock { start: 3458, length: 2, convRule: rule124 }
  , CharBlock { start: 3461, length: 18, convRule: rule14 }
  , CharBlock { start: 3482, length: 24, convRule: rule14 }
  , CharBlock { start: 3507, length: 9, convRule: rule14 }
  , CharBlock { start: 3517, length: 1, convRule: rule14 }
  , CharBlock { start: 3520, length: 7, convRule: rule14 }
  , CharBlock { start: 3530, length: 1, convRule: rule92 }
  , CharBlock { start: 3535, length: 3, convRule: rule124 }
  , CharBlock { start: 3538, length: 3, convRule: rule92 }
  , CharBlock { start: 3542, length: 1, convRule: rule92 }
  , CharBlock { start: 3544, length: 8, convRule: rule124 }
  , CharBlock { start: 3558, length: 10, convRule: rule8 }
  , CharBlock { start: 3570, length: 2, convRule: rule124 }
  , CharBlock { start: 3572, length: 1, convRule: rule2 }
  , CharBlock { start: 3585, length: 48, convRule: rule14 }
  , CharBlock { start: 3633, length: 1, convRule: rule92 }
  , CharBlock { start: 3634, length: 2, convRule: rule14 }
  , CharBlock { start: 3636, length: 7, convRule: rule92 }
  , CharBlock { start: 3647, length: 1, convRule: rule3 }
  , CharBlock { start: 3648, length: 6, convRule: rule14 }
  , CharBlock { start: 3654, length: 1, convRule: rule91 }
  , CharBlock { start: 3655, length: 8, convRule: rule92 }
  , CharBlock { start: 3663, length: 1, convRule: rule2 }
  , CharBlock { start: 3664, length: 10, convRule: rule8 }
  , CharBlock { start: 3674, length: 2, convRule: rule2 }
  , CharBlock { start: 3713, length: 2, convRule: rule14 }
  , CharBlock { start: 3716, length: 1, convRule: rule14 }
  , CharBlock { start: 3718, length: 5, convRule: rule14 }
  , CharBlock { start: 3724, length: 24, convRule: rule14 }
  , CharBlock { start: 3749, length: 1, convRule: rule14 }
  , CharBlock { start: 3751, length: 10, convRule: rule14 }
  , CharBlock { start: 3761, length: 1, convRule: rule92 }
  , CharBlock { start: 3762, length: 2, convRule: rule14 }
  , CharBlock { start: 3764, length: 9, convRule: rule92 }
  , CharBlock { start: 3773, length: 1, convRule: rule14 }
  , CharBlock { start: 3776, length: 5, convRule: rule14 }
  , CharBlock { start: 3782, length: 1, convRule: rule91 }
  , CharBlock { start: 3784, length: 6, convRule: rule92 }
  , CharBlock { start: 3792, length: 10, convRule: rule8 }
  , CharBlock { start: 3804, length: 4, convRule: rule14 }
  , CharBlock { start: 3840, length: 1, convRule: rule14 }
  , CharBlock { start: 3841, length: 3, convRule: rule13 }
  , CharBlock { start: 3844, length: 15, convRule: rule2 }
  , CharBlock { start: 3859, length: 1, convRule: rule13 }
  , CharBlock { start: 3860, length: 1, convRule: rule2 }
  , CharBlock { start: 3861, length: 3, convRule: rule13 }
  , CharBlock { start: 3864, length: 2, convRule: rule92 }
  , CharBlock { start: 3866, length: 6, convRule: rule13 }
  , CharBlock { start: 3872, length: 10, convRule: rule8 }
  , CharBlock { start: 3882, length: 10, convRule: rule17 }
  , CharBlock { start: 3892, length: 1, convRule: rule13 }
  , CharBlock { start: 3893, length: 1, convRule: rule92 }
  , CharBlock { start: 3894, length: 1, convRule: rule13 }
  , CharBlock { start: 3895, length: 1, convRule: rule92 }
  , CharBlock { start: 3896, length: 1, convRule: rule13 }
  , CharBlock { start: 3897, length: 1, convRule: rule92 }
  , CharBlock { start: 3898, length: 1, convRule: rule4 }
  , CharBlock { start: 3899, length: 1, convRule: rule5 }
  , CharBlock { start: 3900, length: 1, convRule: rule4 }
  , CharBlock { start: 3901, length: 1, convRule: rule5 }
  , CharBlock { start: 3902, length: 2, convRule: rule124 }
  , CharBlock { start: 3904, length: 8, convRule: rule14 }
  , CharBlock { start: 3913, length: 36, convRule: rule14 }
  , CharBlock { start: 3953, length: 14, convRule: rule92 }
  , CharBlock { start: 3967, length: 1, convRule: rule124 }
  , CharBlock { start: 3968, length: 5, convRule: rule92 }
  , CharBlock { start: 3973, length: 1, convRule: rule2 }
  , CharBlock { start: 3974, length: 2, convRule: rule92 }
  , CharBlock { start: 3976, length: 5, convRule: rule14 }
  , CharBlock { start: 3981, length: 11, convRule: rule92 }
  , CharBlock { start: 3993, length: 36, convRule: rule92 }
  , CharBlock { start: 4030, length: 8, convRule: rule13 }
  , CharBlock { start: 4038, length: 1, convRule: rule92 }
  , CharBlock { start: 4039, length: 6, convRule: rule13 }
  , CharBlock { start: 4046, length: 2, convRule: rule13 }
  , CharBlock { start: 4048, length: 5, convRule: rule2 }
  , CharBlock { start: 4053, length: 4, convRule: rule13 }
  , CharBlock { start: 4057, length: 2, convRule: rule2 }
  , CharBlock { start: 4096, length: 43, convRule: rule14 }
  , CharBlock { start: 4139, length: 2, convRule: rule124 }
  , CharBlock { start: 4141, length: 4, convRule: rule92 }
  , CharBlock { start: 4145, length: 1, convRule: rule124 }
  , CharBlock { start: 4146, length: 6, convRule: rule92 }
  , CharBlock { start: 4152, length: 1, convRule: rule124 }
  , CharBlock { start: 4153, length: 2, convRule: rule92 }
  , CharBlock { start: 4155, length: 2, convRule: rule124 }
  , CharBlock { start: 4157, length: 2, convRule: rule92 }
  , CharBlock { start: 4159, length: 1, convRule: rule14 }
  , CharBlock { start: 4160, length: 10, convRule: rule8 }
  , CharBlock { start: 4170, length: 6, convRule: rule2 }
  , CharBlock { start: 4176, length: 6, convRule: rule14 }
  , CharBlock { start: 4182, length: 2, convRule: rule124 }
  , CharBlock { start: 4184, length: 2, convRule: rule92 }
  , CharBlock { start: 4186, length: 4, convRule: rule14 }
  , CharBlock { start: 4190, length: 3, convRule: rule92 }
  , CharBlock { start: 4193, length: 1, convRule: rule14 }
  , CharBlock { start: 4194, length: 3, convRule: rule124 }
  , CharBlock { start: 4197, length: 2, convRule: rule14 }
  , CharBlock { start: 4199, length: 7, convRule: rule124 }
  , CharBlock { start: 4206, length: 3, convRule: rule14 }
  , CharBlock { start: 4209, length: 4, convRule: rule92 }
  , CharBlock { start: 4213, length: 13, convRule: rule14 }
  , CharBlock { start: 4226, length: 1, convRule: rule92 }
  , CharBlock { start: 4227, length: 2, convRule: rule124 }
  , CharBlock { start: 4229, length: 2, convRule: rule92 }
  , CharBlock { start: 4231, length: 6, convRule: rule124 }
  , CharBlock { start: 4237, length: 1, convRule: rule92 }
  , CharBlock { start: 4238, length: 1, convRule: rule14 }
  , CharBlock { start: 4239, length: 1, convRule: rule124 }
  , CharBlock { start: 4240, length: 10, convRule: rule8 }
  , CharBlock { start: 4250, length: 3, convRule: rule124 }
  , CharBlock { start: 4253, length: 1, convRule: rule92 }
  , CharBlock { start: 4254, length: 2, convRule: rule13 }
  , CharBlock { start: 4256, length: 38, convRule: rule125 }
  , CharBlock { start: 4295, length: 1, convRule: rule125 }
  , CharBlock { start: 4301, length: 1, convRule: rule125 }
  , CharBlock { start: 4304, length: 43, convRule: rule126 }
  , CharBlock { start: 4347, length: 1, convRule: rule2 }
  , CharBlock { start: 4348, length: 1, convRule: rule91 }
  , CharBlock { start: 4349, length: 3, convRule: rule126 }
  , CharBlock { start: 4352, length: 329, convRule: rule14 }
  , CharBlock { start: 4682, length: 4, convRule: rule14 }
  , CharBlock { start: 4688, length: 7, convRule: rule14 }
  , CharBlock { start: 4696, length: 1, convRule: rule14 }
  , CharBlock { start: 4698, length: 4, convRule: rule14 }
  , CharBlock { start: 4704, length: 41, convRule: rule14 }
  , CharBlock { start: 4746, length: 4, convRule: rule14 }
  , CharBlock { start: 4752, length: 33, convRule: rule14 }
  , CharBlock { start: 4786, length: 4, convRule: rule14 }
  , CharBlock { start: 4792, length: 7, convRule: rule14 }
  , CharBlock { start: 4800, length: 1, convRule: rule14 }
  , CharBlock { start: 4802, length: 4, convRule: rule14 }
  , CharBlock { start: 4808, length: 15, convRule: rule14 }
  , CharBlock { start: 4824, length: 57, convRule: rule14 }
  , CharBlock { start: 4882, length: 4, convRule: rule14 }
  , CharBlock { start: 4888, length: 67, convRule: rule14 }
  , CharBlock { start: 4957, length: 3, convRule: rule92 }
  , CharBlock { start: 4960, length: 9, convRule: rule2 }
  , CharBlock { start: 4969, length: 20, convRule: rule17 }
  , CharBlock { start: 4992, length: 16, convRule: rule14 }
  , CharBlock { start: 5008, length: 10, convRule: rule13 }
  , CharBlock { start: 5024, length: 80, convRule: rule127 }
  , CharBlock { start: 5104, length: 6, convRule: rule104 }
  , CharBlock { start: 5112, length: 6, convRule: rule110 }
  , CharBlock { start: 5120, length: 1, convRule: rule7 }
  , CharBlock { start: 5121, length: 620, convRule: rule14 }
  , CharBlock { start: 5741, length: 1, convRule: rule13 }
  , CharBlock { start: 5742, length: 1, convRule: rule2 }
  , CharBlock { start: 5743, length: 17, convRule: rule14 }
  , CharBlock { start: 5760, length: 1, convRule: rule1 }
  , CharBlock { start: 5761, length: 26, convRule: rule14 }
  , CharBlock { start: 5787, length: 1, convRule: rule4 }
  , CharBlock { start: 5788, length: 1, convRule: rule5 }
  , CharBlock { start: 5792, length: 75, convRule: rule14 }
  , CharBlock { start: 5867, length: 3, convRule: rule2 }
  , CharBlock { start: 5870, length: 3, convRule: rule128 }
  , CharBlock { start: 5873, length: 8, convRule: rule14 }
  , CharBlock { start: 5888, length: 13, convRule: rule14 }
  , CharBlock { start: 5902, length: 4, convRule: rule14 }
  , CharBlock { start: 5906, length: 3, convRule: rule92 }
  , CharBlock { start: 5920, length: 18, convRule: rule14 }
  , CharBlock { start: 5938, length: 3, convRule: rule92 }
  , CharBlock { start: 5941, length: 2, convRule: rule2 }
  , CharBlock { start: 5952, length: 18, convRule: rule14 }
  , CharBlock { start: 5970, length: 2, convRule: rule92 }
  , CharBlock { start: 5984, length: 13, convRule: rule14 }
  , CharBlock { start: 5998, length: 3, convRule: rule14 }
  , CharBlock { start: 6002, length: 2, convRule: rule92 }
  , CharBlock { start: 6016, length: 52, convRule: rule14 }
  , CharBlock { start: 6068, length: 2, convRule: rule92 }
  , CharBlock { start: 6070, length: 1, convRule: rule124 }
  , CharBlock { start: 6071, length: 7, convRule: rule92 }
  , CharBlock { start: 6078, length: 8, convRule: rule124 }
  , CharBlock { start: 6086, length: 1, convRule: rule92 }
  , CharBlock { start: 6087, length: 2, convRule: rule124 }
  , CharBlock { start: 6089, length: 11, convRule: rule92 }
  , CharBlock { start: 6100, length: 3, convRule: rule2 }
  , CharBlock { start: 6103, length: 1, convRule: rule91 }
  , CharBlock { start: 6104, length: 3, convRule: rule2 }
  , CharBlock { start: 6107, length: 1, convRule: rule3 }
  , CharBlock { start: 6108, length: 1, convRule: rule14 }
  , CharBlock { start: 6109, length: 1, convRule: rule92 }
  , CharBlock { start: 6112, length: 10, convRule: rule8 }
  , CharBlock { start: 6128, length: 10, convRule: rule17 }
  , CharBlock { start: 6144, length: 6, convRule: rule2 }
  , CharBlock { start: 6150, length: 1, convRule: rule7 }
  , CharBlock { start: 6151, length: 4, convRule: rule2 }
  , CharBlock { start: 6155, length: 3, convRule: rule92 }
  , CharBlock { start: 6158, length: 1, convRule: rule16 }
  , CharBlock { start: 6160, length: 10, convRule: rule8 }
  , CharBlock { start: 6176, length: 35, convRule: rule14 }
  , CharBlock { start: 6211, length: 1, convRule: rule91 }
  , CharBlock { start: 6212, length: 53, convRule: rule14 }
  , CharBlock { start: 6272, length: 5, convRule: rule14 }
  , CharBlock { start: 6277, length: 2, convRule: rule92 }
  , CharBlock { start: 6279, length: 34, convRule: rule14 }
  , CharBlock { start: 6313, length: 1, convRule: rule92 }
  , CharBlock { start: 6314, length: 1, convRule: rule14 }
  , CharBlock { start: 6320, length: 70, convRule: rule14 }
  , CharBlock { start: 6400, length: 31, convRule: rule14 }
  , CharBlock { start: 6432, length: 3, convRule: rule92 }
  , CharBlock { start: 6435, length: 4, convRule: rule124 }
  , CharBlock { start: 6439, length: 2, convRule: rule92 }
  , CharBlock { start: 6441, length: 3, convRule: rule124 }
  , CharBlock { start: 6448, length: 2, convRule: rule124 }
  , CharBlock { start: 6450, length: 1, convRule: rule92 }
  , CharBlock { start: 6451, length: 6, convRule: rule124 }
  , CharBlock { start: 6457, length: 3, convRule: rule92 }
  , CharBlock { start: 6464, length: 1, convRule: rule13 }
  , CharBlock { start: 6468, length: 2, convRule: rule2 }
  , CharBlock { start: 6470, length: 10, convRule: rule8 }
  , CharBlock { start: 6480, length: 30, convRule: rule14 }
  , CharBlock { start: 6512, length: 5, convRule: rule14 }
  , CharBlock { start: 6528, length: 44, convRule: rule14 }
  , CharBlock { start: 6576, length: 26, convRule: rule14 }
  , CharBlock { start: 6608, length: 10, convRule: rule8 }
  , CharBlock { start: 6618, length: 1, convRule: rule17 }
  , CharBlock { start: 6622, length: 34, convRule: rule13 }
  , CharBlock { start: 6656, length: 23, convRule: rule14 }
  , CharBlock { start: 6679, length: 2, convRule: rule92 }
  , CharBlock { start: 6681, length: 2, convRule: rule124 }
  , CharBlock { start: 6683, length: 1, convRule: rule92 }
  , CharBlock { start: 6686, length: 2, convRule: rule2 }
  , CharBlock { start: 6688, length: 53, convRule: rule14 }
  , CharBlock { start: 6741, length: 1, convRule: rule124 }
  , CharBlock { start: 6742, length: 1, convRule: rule92 }
  , CharBlock { start: 6743, length: 1, convRule: rule124 }
  , CharBlock { start: 6744, length: 7, convRule: rule92 }
  , CharBlock { start: 6752, length: 1, convRule: rule92 }
  , CharBlock { start: 6753, length: 1, convRule: rule124 }
  , CharBlock { start: 6754, length: 1, convRule: rule92 }
  , CharBlock { start: 6755, length: 2, convRule: rule124 }
  , CharBlock { start: 6757, length: 8, convRule: rule92 }
  , CharBlock { start: 6765, length: 6, convRule: rule124 }
  , CharBlock { start: 6771, length: 10, convRule: rule92 }
  , CharBlock { start: 6783, length: 1, convRule: rule92 }
  , CharBlock { start: 6784, length: 10, convRule: rule8 }
  , CharBlock { start: 6800, length: 10, convRule: rule8 }
  , CharBlock { start: 6816, length: 7, convRule: rule2 }
  , CharBlock { start: 6823, length: 1, convRule: rule91 }
  , CharBlock { start: 6824, length: 6, convRule: rule2 }
  , CharBlock { start: 6832, length: 14, convRule: rule92 }
  , CharBlock { start: 6846, length: 1, convRule: rule119 }
  , CharBlock { start: 6847, length: 2, convRule: rule92 }
  , CharBlock { start: 6912, length: 4, convRule: rule92 }
  , CharBlock { start: 6916, length: 1, convRule: rule124 }
  , CharBlock { start: 6917, length: 47, convRule: rule14 }
  , CharBlock { start: 6964, length: 1, convRule: rule92 }
  , CharBlock { start: 6965, length: 1, convRule: rule124 }
  , CharBlock { start: 6966, length: 5, convRule: rule92 }
  , CharBlock { start: 6971, length: 1, convRule: rule124 }
  , CharBlock { start: 6972, length: 1, convRule: rule92 }
  , CharBlock { start: 6973, length: 5, convRule: rule124 }
  , CharBlock { start: 6978, length: 1, convRule: rule92 }
  , CharBlock { start: 6979, length: 2, convRule: rule124 }
  , CharBlock { start: 6981, length: 7, convRule: rule14 }
  , CharBlock { start: 6992, length: 10, convRule: rule8 }
  , CharBlock { start: 7002, length: 7, convRule: rule2 }
  , CharBlock { start: 7009, length: 10, convRule: rule13 }
  , CharBlock { start: 7019, length: 9, convRule: rule92 }
  , CharBlock { start: 7028, length: 9, convRule: rule13 }
  , CharBlock { start: 7040, length: 2, convRule: rule92 }
  , CharBlock { start: 7042, length: 1, convRule: rule124 }
  , CharBlock { start: 7043, length: 30, convRule: rule14 }
  , CharBlock { start: 7073, length: 1, convRule: rule124 }
  , CharBlock { start: 7074, length: 4, convRule: rule92 }
  , CharBlock { start: 7078, length: 2, convRule: rule124 }
  , CharBlock { start: 7080, length: 2, convRule: rule92 }
  , CharBlock { start: 7082, length: 1, convRule: rule124 }
  , CharBlock { start: 7083, length: 3, convRule: rule92 }
  , CharBlock { start: 7086, length: 2, convRule: rule14 }
  , CharBlock { start: 7088, length: 10, convRule: rule8 }
  , CharBlock { start: 7098, length: 44, convRule: rule14 }
  , CharBlock { start: 7142, length: 1, convRule: rule92 }
  , CharBlock { start: 7143, length: 1, convRule: rule124 }
  , CharBlock { start: 7144, length: 2, convRule: rule92 }
  , CharBlock { start: 7146, length: 3, convRule: rule124 }
  , CharBlock { start: 7149, length: 1, convRule: rule92 }
  , CharBlock { start: 7150, length: 1, convRule: rule124 }
  , CharBlock { start: 7151, length: 3, convRule: rule92 }
  , CharBlock { start: 7154, length: 2, convRule: rule124 }
  , CharBlock { start: 7164, length: 4, convRule: rule2 }
  , CharBlock { start: 7168, length: 36, convRule: rule14 }
  , CharBlock { start: 7204, length: 8, convRule: rule124 }
  , CharBlock { start: 7212, length: 8, convRule: rule92 }
  , CharBlock { start: 7220, length: 2, convRule: rule124 }
  , CharBlock { start: 7222, length: 2, convRule: rule92 }
  , CharBlock { start: 7227, length: 5, convRule: rule2 }
  , CharBlock { start: 7232, length: 10, convRule: rule8 }
  , CharBlock { start: 7245, length: 3, convRule: rule14 }
  , CharBlock { start: 7248, length: 10, convRule: rule8 }
  , CharBlock { start: 7258, length: 30, convRule: rule14 }
  , CharBlock { start: 7288, length: 6, convRule: rule91 }
  , CharBlock { start: 7294, length: 2, convRule: rule2 }
  , CharBlock { start: 7296, length: 1, convRule: rule129 }
  , CharBlock { start: 7297, length: 1, convRule: rule130 }
  , CharBlock { start: 7298, length: 1, convRule: rule131 }
  , CharBlock { start: 7299, length: 2, convRule: rule132 }
  , CharBlock { start: 7301, length: 1, convRule: rule133 }
  , CharBlock { start: 7302, length: 1, convRule: rule134 }
  , CharBlock { start: 7303, length: 1, convRule: rule135 }
  , CharBlock { start: 7304, length: 1, convRule: rule136 }
  , CharBlock { start: 7312, length: 43, convRule: rule137 }
  , CharBlock { start: 7357, length: 3, convRule: rule137 }
  , CharBlock { start: 7360, length: 8, convRule: rule2 }
  , CharBlock { start: 7376, length: 3, convRule: rule92 }
  , CharBlock { start: 7379, length: 1, convRule: rule2 }
  , CharBlock { start: 7380, length: 13, convRule: rule92 }
  , CharBlock { start: 7393, length: 1, convRule: rule124 }
  , CharBlock { start: 7394, length: 7, convRule: rule92 }
  , CharBlock { start: 7401, length: 4, convRule: rule14 }
  , CharBlock { start: 7405, length: 1, convRule: rule92 }
  , CharBlock { start: 7406, length: 6, convRule: rule14 }
  , CharBlock { start: 7412, length: 1, convRule: rule92 }
  , CharBlock { start: 7413, length: 2, convRule: rule14 }
  , CharBlock { start: 7415, length: 1, convRule: rule124 }
  , CharBlock { start: 7416, length: 2, convRule: rule92 }
  , CharBlock { start: 7418, length: 1, convRule: rule14 }
  , CharBlock { start: 7424, length: 44, convRule: rule20 }
  , CharBlock { start: 7468, length: 63, convRule: rule91 }
  , CharBlock { start: 7531, length: 13, convRule: rule20 }
  , CharBlock { start: 7544, length: 1, convRule: rule91 }
  , CharBlock { start: 7545, length: 1, convRule: rule138 }
  , CharBlock { start: 7546, length: 3, convRule: rule20 }
  , CharBlock { start: 7549, length: 1, convRule: rule139 }
  , CharBlock { start: 7550, length: 16, convRule: rule20 }
  , CharBlock { start: 7566, length: 1, convRule: rule140 }
  , CharBlock { start: 7567, length: 12, convRule: rule20 }
  , CharBlock { start: 7579, length: 37, convRule: rule91 }
  , CharBlock { start: 7616, length: 58, convRule: rule92 }
  , CharBlock { start: 7675, length: 5, convRule: rule92 }
  , CharBlock { start: 7680, length: 1, convRule: rule22 }
  , CharBlock { start: 7681, length: 1, convRule: rule23 }
  , CharBlock { start: 7682, length: 1, convRule: rule22 }
  , CharBlock { start: 7683, length: 1, convRule: rule23 }
  , CharBlock { start: 7684, length: 1, convRule: rule22 }
  , CharBlock { start: 7685, length: 1, convRule: rule23 }
  , CharBlock { start: 7686, length: 1, convRule: rule22 }
  , CharBlock { start: 7687, length: 1, convRule: rule23 }
  , CharBlock { start: 7688, length: 1, convRule: rule22 }
  , CharBlock { start: 7689, length: 1, convRule: rule23 }
  , CharBlock { start: 7690, length: 1, convRule: rule22 }
  , CharBlock { start: 7691, length: 1, convRule: rule23 }
  , CharBlock { start: 7692, length: 1, convRule: rule22 }
  , CharBlock { start: 7693, length: 1, convRule: rule23 }
  , CharBlock { start: 7694, length: 1, convRule: rule22 }
  , CharBlock { start: 7695, length: 1, convRule: rule23 }
  , CharBlock { start: 7696, length: 1, convRule: rule22 }
  , CharBlock { start: 7697, length: 1, convRule: rule23 }
  , CharBlock { start: 7698, length: 1, convRule: rule22 }
  , CharBlock { start: 7699, length: 1, convRule: rule23 }
  , CharBlock { start: 7700, length: 1, convRule: rule22 }
  , CharBlock { start: 7701, length: 1, convRule: rule23 }
  , CharBlock { start: 7702, length: 1, convRule: rule22 }
  , CharBlock { start: 7703, length: 1, convRule: rule23 }
  , CharBlock { start: 7704, length: 1, convRule: rule22 }
  , CharBlock { start: 7705, length: 1, convRule: rule23 }
  , CharBlock { start: 7706, length: 1, convRule: rule22 }
  , CharBlock { start: 7707, length: 1, convRule: rule23 }
  , CharBlock { start: 7708, length: 1, convRule: rule22 }
  , CharBlock { start: 7709, length: 1, convRule: rule23 }
  , CharBlock { start: 7710, length: 1, convRule: rule22 }
  , CharBlock { start: 7711, length: 1, convRule: rule23 }
  , CharBlock { start: 7712, length: 1, convRule: rule22 }
  , CharBlock { start: 7713, length: 1, convRule: rule23 }
  , CharBlock { start: 7714, length: 1, convRule: rule22 }
  , CharBlock { start: 7715, length: 1, convRule: rule23 }
  , CharBlock { start: 7716, length: 1, convRule: rule22 }
  , CharBlock { start: 7717, length: 1, convRule: rule23 }
  , CharBlock { start: 7718, length: 1, convRule: rule22 }
  , CharBlock { start: 7719, length: 1, convRule: rule23 }
  , CharBlock { start: 7720, length: 1, convRule: rule22 }
  , CharBlock { start: 7721, length: 1, convRule: rule23 }
  , CharBlock { start: 7722, length: 1, convRule: rule22 }
  , CharBlock { start: 7723, length: 1, convRule: rule23 }
  , CharBlock { start: 7724, length: 1, convRule: rule22 }
  , CharBlock { start: 7725, length: 1, convRule: rule23 }
  , CharBlock { start: 7726, length: 1, convRule: rule22 }
  , CharBlock { start: 7727, length: 1, convRule: rule23 }
  , CharBlock { start: 7728, length: 1, convRule: rule22 }
  , CharBlock { start: 7729, length: 1, convRule: rule23 }
  , CharBlock { start: 7730, length: 1, convRule: rule22 }
  , CharBlock { start: 7731, length: 1, convRule: rule23 }
  , CharBlock { start: 7732, length: 1, convRule: rule22 }
  , CharBlock { start: 7733, length: 1, convRule: rule23 }
  , CharBlock { start: 7734, length: 1, convRule: rule22 }
  , CharBlock { start: 7735, length: 1, convRule: rule23 }
  , CharBlock { start: 7736, length: 1, convRule: rule22 }
  , CharBlock { start: 7737, length: 1, convRule: rule23 }
  , CharBlock { start: 7738, length: 1, convRule: rule22 }
  , CharBlock { start: 7739, length: 1, convRule: rule23 }
  , CharBlock { start: 7740, length: 1, convRule: rule22 }
  , CharBlock { start: 7741, length: 1, convRule: rule23 }
  , CharBlock { start: 7742, length: 1, convRule: rule22 }
  , CharBlock { start: 7743, length: 1, convRule: rule23 }
  , CharBlock { start: 7744, length: 1, convRule: rule22 }
  , CharBlock { start: 7745, length: 1, convRule: rule23 }
  , CharBlock { start: 7746, length: 1, convRule: rule22 }
  , CharBlock { start: 7747, length: 1, convRule: rule23 }
  , CharBlock { start: 7748, length: 1, convRule: rule22 }
  , CharBlock { start: 7749, length: 1, convRule: rule23 }
  , CharBlock { start: 7750, length: 1, convRule: rule22 }
  , CharBlock { start: 7751, length: 1, convRule: rule23 }
  , CharBlock { start: 7752, length: 1, convRule: rule22 }
  , CharBlock { start: 7753, length: 1, convRule: rule23 }
  , CharBlock { start: 7754, length: 1, convRule: rule22 }
  , CharBlock { start: 7755, length: 1, convRule: rule23 }
  , CharBlock { start: 7756, length: 1, convRule: rule22 }
  , CharBlock { start: 7757, length: 1, convRule: rule23 }
  , CharBlock { start: 7758, length: 1, convRule: rule22 }
  , CharBlock { start: 7759, length: 1, convRule: rule23 }
  , CharBlock { start: 7760, length: 1, convRule: rule22 }
  , CharBlock { start: 7761, length: 1, convRule: rule23 }
  , CharBlock { start: 7762, length: 1, convRule: rule22 }
  , CharBlock { start: 7763, length: 1, convRule: rule23 }
  , CharBlock { start: 7764, length: 1, convRule: rule22 }
  , CharBlock { start: 7765, length: 1, convRule: rule23 }
  , CharBlock { start: 7766, length: 1, convRule: rule22 }
  , CharBlock { start: 7767, length: 1, convRule: rule23 }
  , CharBlock { start: 7768, length: 1, convRule: rule22 }
  , CharBlock { start: 7769, length: 1, convRule: rule23 }
  , CharBlock { start: 7770, length: 1, convRule: rule22 }
  , CharBlock { start: 7771, length: 1, convRule: rule23 }
  , CharBlock { start: 7772, length: 1, convRule: rule22 }
  , CharBlock { start: 7773, length: 1, convRule: rule23 }
  , CharBlock { start: 7774, length: 1, convRule: rule22 }
  , CharBlock { start: 7775, length: 1, convRule: rule23 }
  , CharBlock { start: 7776, length: 1, convRule: rule22 }
  , CharBlock { start: 7777, length: 1, convRule: rule23 }
  , CharBlock { start: 7778, length: 1, convRule: rule22 }
  , CharBlock { start: 7779, length: 1, convRule: rule23 }
  , CharBlock { start: 7780, length: 1, convRule: rule22 }
  , CharBlock { start: 7781, length: 1, convRule: rule23 }
  , CharBlock { start: 7782, length: 1, convRule: rule22 }
  , CharBlock { start: 7783, length: 1, convRule: rule23 }
  , CharBlock { start: 7784, length: 1, convRule: rule22 }
  , CharBlock { start: 7785, length: 1, convRule: rule23 }
  , CharBlock { start: 7786, length: 1, convRule: rule22 }
  , CharBlock { start: 7787, length: 1, convRule: rule23 }
  , CharBlock { start: 7788, length: 1, convRule: rule22 }
  , CharBlock { start: 7789, length: 1, convRule: rule23 }
  , CharBlock { start: 7790, length: 1, convRule: rule22 }
  , CharBlock { start: 7791, length: 1, convRule: rule23 }
  , CharBlock { start: 7792, length: 1, convRule: rule22 }
  , CharBlock { start: 7793, length: 1, convRule: rule23 }
  , CharBlock { start: 7794, length: 1, convRule: rule22 }
  , CharBlock { start: 7795, length: 1, convRule: rule23 }
  , CharBlock { start: 7796, length: 1, convRule: rule22 }
  , CharBlock { start: 7797, length: 1, convRule: rule23 }
  , CharBlock { start: 7798, length: 1, convRule: rule22 }
  , CharBlock { start: 7799, length: 1, convRule: rule23 }
  , CharBlock { start: 7800, length: 1, convRule: rule22 }
  , CharBlock { start: 7801, length: 1, convRule: rule23 }
  , CharBlock { start: 7802, length: 1, convRule: rule22 }
  , CharBlock { start: 7803, length: 1, convRule: rule23 }
  , CharBlock { start: 7804, length: 1, convRule: rule22 }
  , CharBlock { start: 7805, length: 1, convRule: rule23 }
  , CharBlock { start: 7806, length: 1, convRule: rule22 }
  , CharBlock { start: 7807, length: 1, convRule: rule23 }
  , CharBlock { start: 7808, length: 1, convRule: rule22 }
  , CharBlock { start: 7809, length: 1, convRule: rule23 }
  , CharBlock { start: 7810, length: 1, convRule: rule22 }
  , CharBlock { start: 7811, length: 1, convRule: rule23 }
  , CharBlock { start: 7812, length: 1, convRule: rule22 }
  , CharBlock { start: 7813, length: 1, convRule: rule23 }
  , CharBlock { start: 7814, length: 1, convRule: rule22 }
  , CharBlock { start: 7815, length: 1, convRule: rule23 }
  , CharBlock { start: 7816, length: 1, convRule: rule22 }
  , CharBlock { start: 7817, length: 1, convRule: rule23 }
  , CharBlock { start: 7818, length: 1, convRule: rule22 }
  , CharBlock { start: 7819, length: 1, convRule: rule23 }
  , CharBlock { start: 7820, length: 1, convRule: rule22 }
  , CharBlock { start: 7821, length: 1, convRule: rule23 }
  , CharBlock { start: 7822, length: 1, convRule: rule22 }
  , CharBlock { start: 7823, length: 1, convRule: rule23 }
  , CharBlock { start: 7824, length: 1, convRule: rule22 }
  , CharBlock { start: 7825, length: 1, convRule: rule23 }
  , CharBlock { start: 7826, length: 1, convRule: rule22 }
  , CharBlock { start: 7827, length: 1, convRule: rule23 }
  , CharBlock { start: 7828, length: 1, convRule: rule22 }
  , CharBlock { start: 7829, length: 1, convRule: rule23 }
  , CharBlock { start: 7830, length: 5, convRule: rule20 }
  , CharBlock { start: 7835, length: 1, convRule: rule141 }
  , CharBlock { start: 7836, length: 2, convRule: rule20 }
  , CharBlock { start: 7838, length: 1, convRule: rule142 }
  , CharBlock { start: 7839, length: 1, convRule: rule20 }
  , CharBlock { start: 7840, length: 1, convRule: rule22 }
  , CharBlock { start: 7841, length: 1, convRule: rule23 }
  , CharBlock { start: 7842, length: 1, convRule: rule22 }
  , CharBlock { start: 7843, length: 1, convRule: rule23 }
  , CharBlock { start: 7844, length: 1, convRule: rule22 }
  , CharBlock { start: 7845, length: 1, convRule: rule23 }
  , CharBlock { start: 7846, length: 1, convRule: rule22 }
  , CharBlock { start: 7847, length: 1, convRule: rule23 }
  , CharBlock { start: 7848, length: 1, convRule: rule22 }
  , CharBlock { start: 7849, length: 1, convRule: rule23 }
  , CharBlock { start: 7850, length: 1, convRule: rule22 }
  , CharBlock { start: 7851, length: 1, convRule: rule23 }
  , CharBlock { start: 7852, length: 1, convRule: rule22 }
  , CharBlock { start: 7853, length: 1, convRule: rule23 }
  , CharBlock { start: 7854, length: 1, convRule: rule22 }
  , CharBlock { start: 7855, length: 1, convRule: rule23 }
  , CharBlock { start: 7856, length: 1, convRule: rule22 }
  , CharBlock { start: 7857, length: 1, convRule: rule23 }
  , CharBlock { start: 7858, length: 1, convRule: rule22 }
  , CharBlock { start: 7859, length: 1, convRule: rule23 }
  , CharBlock { start: 7860, length: 1, convRule: rule22 }
  , CharBlock { start: 7861, length: 1, convRule: rule23 }
  , CharBlock { start: 7862, length: 1, convRule: rule22 }
  , CharBlock { start: 7863, length: 1, convRule: rule23 }
  , CharBlock { start: 7864, length: 1, convRule: rule22 }
  , CharBlock { start: 7865, length: 1, convRule: rule23 }
  , CharBlock { start: 7866, length: 1, convRule: rule22 }
  , CharBlock { start: 7867, length: 1, convRule: rule23 }
  , CharBlock { start: 7868, length: 1, convRule: rule22 }
  , CharBlock { start: 7869, length: 1, convRule: rule23 }
  , CharBlock { start: 7870, length: 1, convRule: rule22 }
  , CharBlock { start: 7871, length: 1, convRule: rule23 }
  , CharBlock { start: 7872, length: 1, convRule: rule22 }
  , CharBlock { start: 7873, length: 1, convRule: rule23 }
  , CharBlock { start: 7874, length: 1, convRule: rule22 }
  , CharBlock { start: 7875, length: 1, convRule: rule23 }
  , CharBlock { start: 7876, length: 1, convRule: rule22 }
  , CharBlock { start: 7877, length: 1, convRule: rule23 }
  , CharBlock { start: 7878, length: 1, convRule: rule22 }
  , CharBlock { start: 7879, length: 1, convRule: rule23 }
  , CharBlock { start: 7880, length: 1, convRule: rule22 }
  , CharBlock { start: 7881, length: 1, convRule: rule23 }
  , CharBlock { start: 7882, length: 1, convRule: rule22 }
  , CharBlock { start: 7883, length: 1, convRule: rule23 }
  , CharBlock { start: 7884, length: 1, convRule: rule22 }
  , CharBlock { start: 7885, length: 1, convRule: rule23 }
  , CharBlock { start: 7886, length: 1, convRule: rule22 }
  , CharBlock { start: 7887, length: 1, convRule: rule23 }
  , CharBlock { start: 7888, length: 1, convRule: rule22 }
  , CharBlock { start: 7889, length: 1, convRule: rule23 }
  , CharBlock { start: 7890, length: 1, convRule: rule22 }
  , CharBlock { start: 7891, length: 1, convRule: rule23 }
  , CharBlock { start: 7892, length: 1, convRule: rule22 }
  , CharBlock { start: 7893, length: 1, convRule: rule23 }
  , CharBlock { start: 7894, length: 1, convRule: rule22 }
  , CharBlock { start: 7895, length: 1, convRule: rule23 }
  , CharBlock { start: 7896, length: 1, convRule: rule22 }
  , CharBlock { start: 7897, length: 1, convRule: rule23 }
  , CharBlock { start: 7898, length: 1, convRule: rule22 }
  , CharBlock { start: 7899, length: 1, convRule: rule23 }
  , CharBlock { start: 7900, length: 1, convRule: rule22 }
  , CharBlock { start: 7901, length: 1, convRule: rule23 }
  , CharBlock { start: 7902, length: 1, convRule: rule22 }
  , CharBlock { start: 7903, length: 1, convRule: rule23 }
  , CharBlock { start: 7904, length: 1, convRule: rule22 }
  , CharBlock { start: 7905, length: 1, convRule: rule23 }
  , CharBlock { start: 7906, length: 1, convRule: rule22 }
  , CharBlock { start: 7907, length: 1, convRule: rule23 }
  , CharBlock { start: 7908, length: 1, convRule: rule22 }
  , CharBlock { start: 7909, length: 1, convRule: rule23 }
  , CharBlock { start: 7910, length: 1, convRule: rule22 }
  , CharBlock { start: 7911, length: 1, convRule: rule23 }
  , CharBlock { start: 7912, length: 1, convRule: rule22 }
  , CharBlock { start: 7913, length: 1, convRule: rule23 }
  , CharBlock { start: 7914, length: 1, convRule: rule22 }
  , CharBlock { start: 7915, length: 1, convRule: rule23 }
  , CharBlock { start: 7916, length: 1, convRule: rule22 }
  , CharBlock { start: 7917, length: 1, convRule: rule23 }
  , CharBlock { start: 7918, length: 1, convRule: rule22 }
  , CharBlock { start: 7919, length: 1, convRule: rule23 }
  , CharBlock { start: 7920, length: 1, convRule: rule22 }
  , CharBlock { start: 7921, length: 1, convRule: rule23 }
  , CharBlock { start: 7922, length: 1, convRule: rule22 }
  , CharBlock { start: 7923, length: 1, convRule: rule23 }
  , CharBlock { start: 7924, length: 1, convRule: rule22 }
  , CharBlock { start: 7925, length: 1, convRule: rule23 }
  , CharBlock { start: 7926, length: 1, convRule: rule22 }
  , CharBlock { start: 7927, length: 1, convRule: rule23 }
  , CharBlock { start: 7928, length: 1, convRule: rule22 }
  , CharBlock { start: 7929, length: 1, convRule: rule23 }
  , CharBlock { start: 7930, length: 1, convRule: rule22 }
  , CharBlock { start: 7931, length: 1, convRule: rule23 }
  , CharBlock { start: 7932, length: 1, convRule: rule22 }
  , CharBlock { start: 7933, length: 1, convRule: rule23 }
  , CharBlock { start: 7934, length: 1, convRule: rule22 }
  , CharBlock { start: 7935, length: 1, convRule: rule23 }
  , CharBlock { start: 7936, length: 8, convRule: rule143 }
  , CharBlock { start: 7944, length: 8, convRule: rule144 }
  , CharBlock { start: 7952, length: 6, convRule: rule143 }
  , CharBlock { start: 7960, length: 6, convRule: rule144 }
  , CharBlock { start: 7968, length: 8, convRule: rule143 }
  , CharBlock { start: 7976, length: 8, convRule: rule144 }
  , CharBlock { start: 7984, length: 8, convRule: rule143 }
  , CharBlock { start: 7992, length: 8, convRule: rule144 }
  , CharBlock { start: 8000, length: 6, convRule: rule143 }
  , CharBlock { start: 8008, length: 6, convRule: rule144 }
  , CharBlock { start: 8016, length: 1, convRule: rule20 }
  , CharBlock { start: 8017, length: 1, convRule: rule143 }
  , CharBlock { start: 8018, length: 1, convRule: rule20 }
  , CharBlock { start: 8019, length: 1, convRule: rule143 }
  , CharBlock { start: 8020, length: 1, convRule: rule20 }
  , CharBlock { start: 8021, length: 1, convRule: rule143 }
  , CharBlock { start: 8022, length: 1, convRule: rule20 }
  , CharBlock { start: 8023, length: 1, convRule: rule143 }
  , CharBlock { start: 8025, length: 1, convRule: rule144 }
  , CharBlock { start: 8027, length: 1, convRule: rule144 }
  , CharBlock { start: 8029, length: 1, convRule: rule144 }
  , CharBlock { start: 8031, length: 1, convRule: rule144 }
  , CharBlock { start: 8032, length: 8, convRule: rule143 }
  , CharBlock { start: 8040, length: 8, convRule: rule144 }
  , CharBlock { start: 8048, length: 2, convRule: rule145 }
  , CharBlock { start: 8050, length: 4, convRule: rule146 }
  , CharBlock { start: 8054, length: 2, convRule: rule147 }
  , CharBlock { start: 8056, length: 2, convRule: rule148 }
  , CharBlock { start: 8058, length: 2, convRule: rule149 }
  , CharBlock { start: 8060, length: 2, convRule: rule150 }
  , CharBlock { start: 8064, length: 8, convRule: rule143 }
  , CharBlock { start: 8072, length: 8, convRule: rule151 }
  , CharBlock { start: 8080, length: 8, convRule: rule143 }
  , CharBlock { start: 8088, length: 8, convRule: rule151 }
  , CharBlock { start: 8096, length: 8, convRule: rule143 }
  , CharBlock { start: 8104, length: 8, convRule: rule151 }
  , CharBlock { start: 8112, length: 2, convRule: rule143 }
  , CharBlock { start: 8114, length: 1, convRule: rule20 }
  , CharBlock { start: 8115, length: 1, convRule: rule152 }
  , CharBlock { start: 8116, length: 1, convRule: rule20 }
  , CharBlock { start: 8118, length: 2, convRule: rule20 }
  , CharBlock { start: 8120, length: 2, convRule: rule144 }
  , CharBlock { start: 8122, length: 2, convRule: rule153 }
  , CharBlock { start: 8124, length: 1, convRule: rule154 }
  , CharBlock { start: 8125, length: 1, convRule: rule10 }
  , CharBlock { start: 8126, length: 1, convRule: rule155 }
  , CharBlock { start: 8127, length: 3, convRule: rule10 }
  , CharBlock { start: 8130, length: 1, convRule: rule20 }
  , CharBlock { start: 8131, length: 1, convRule: rule152 }
  , CharBlock { start: 8132, length: 1, convRule: rule20 }
  , CharBlock { start: 8134, length: 2, convRule: rule20 }
  , CharBlock { start: 8136, length: 4, convRule: rule156 }
  , CharBlock { start: 8140, length: 1, convRule: rule154 }
  , CharBlock { start: 8141, length: 3, convRule: rule10 }
  , CharBlock { start: 8144, length: 2, convRule: rule143 }
  , CharBlock { start: 8146, length: 2, convRule: rule20 }
  , CharBlock { start: 8150, length: 2, convRule: rule20 }
  , CharBlock { start: 8152, length: 2, convRule: rule144 }
  , CharBlock { start: 8154, length: 2, convRule: rule157 }
  , CharBlock { start: 8157, length: 3, convRule: rule10 }
  , CharBlock { start: 8160, length: 2, convRule: rule143 }
  , CharBlock { start: 8162, length: 3, convRule: rule20 }
  , CharBlock { start: 8165, length: 1, convRule: rule113 }
  , CharBlock { start: 8166, length: 2, convRule: rule20 }
  , CharBlock { start: 8168, length: 2, convRule: rule144 }
  , CharBlock { start: 8170, length: 2, convRule: rule158 }
  , CharBlock { start: 8172, length: 1, convRule: rule117 }
  , CharBlock { start: 8173, length: 3, convRule: rule10 }
  , CharBlock { start: 8178, length: 1, convRule: rule20 }
  , CharBlock { start: 8179, length: 1, convRule: rule152 }
  , CharBlock { start: 8180, length: 1, convRule: rule20 }
  , CharBlock { start: 8182, length: 2, convRule: rule20 }
  , CharBlock { start: 8184, length: 2, convRule: rule159 }
  , CharBlock { start: 8186, length: 2, convRule: rule160 }
  , CharBlock { start: 8188, length: 1, convRule: rule154 }
  , CharBlock { start: 8189, length: 2, convRule: rule10 }
  , CharBlock { start: 8192, length: 11, convRule: rule1 }
  , CharBlock { start: 8203, length: 5, convRule: rule16 }
  , CharBlock { start: 8208, length: 6, convRule: rule7 }
  , CharBlock { start: 8214, length: 2, convRule: rule2 }
  , CharBlock { start: 8216, length: 1, convRule: rule15 }
  , CharBlock { start: 8217, length: 1, convRule: rule19 }
  , CharBlock { start: 8218, length: 1, convRule: rule4 }
  , CharBlock { start: 8219, length: 2, convRule: rule15 }
  , CharBlock { start: 8221, length: 1, convRule: rule19 }
  , CharBlock { start: 8222, length: 1, convRule: rule4 }
  , CharBlock { start: 8223, length: 1, convRule: rule15 }
  , CharBlock { start: 8224, length: 8, convRule: rule2 }
  , CharBlock { start: 8232, length: 1, convRule: rule161 }
  , CharBlock { start: 8233, length: 1, convRule: rule162 }
  , CharBlock { start: 8234, length: 5, convRule: rule16 }
  , CharBlock { start: 8239, length: 1, convRule: rule1 }
  , CharBlock { start: 8240, length: 9, convRule: rule2 }
  , CharBlock { start: 8249, length: 1, convRule: rule15 }
  , CharBlock { start: 8250, length: 1, convRule: rule19 }
  , CharBlock { start: 8251, length: 4, convRule: rule2 }
  , CharBlock { start: 8255, length: 2, convRule: rule11 }
  , CharBlock { start: 8257, length: 3, convRule: rule2 }
  , CharBlock { start: 8260, length: 1, convRule: rule6 }
  , CharBlock { start: 8261, length: 1, convRule: rule4 }
  , CharBlock { start: 8262, length: 1, convRule: rule5 }
  , CharBlock { start: 8263, length: 11, convRule: rule2 }
  , CharBlock { start: 8274, length: 1, convRule: rule6 }
  , CharBlock { start: 8275, length: 1, convRule: rule2 }
  , CharBlock { start: 8276, length: 1, convRule: rule11 }
  , CharBlock { start: 8277, length: 10, convRule: rule2 }
  , CharBlock { start: 8287, length: 1, convRule: rule1 }
  , CharBlock { start: 8288, length: 5, convRule: rule16 }
  , CharBlock { start: 8294, length: 10, convRule: rule16 }
  , CharBlock { start: 8304, length: 1, convRule: rule17 }
  , CharBlock { start: 8305, length: 1, convRule: rule91 }
  , CharBlock { start: 8308, length: 6, convRule: rule17 }
  , CharBlock { start: 8314, length: 3, convRule: rule6 }
  , CharBlock { start: 8317, length: 1, convRule: rule4 }
  , CharBlock { start: 8318, length: 1, convRule: rule5 }
  , CharBlock { start: 8319, length: 1, convRule: rule91 }
  , CharBlock { start: 8320, length: 10, convRule: rule17 }
  , CharBlock { start: 8330, length: 3, convRule: rule6 }
  , CharBlock { start: 8333, length: 1, convRule: rule4 }
  , CharBlock { start: 8334, length: 1, convRule: rule5 }
  , CharBlock { start: 8336, length: 13, convRule: rule91 }
  , CharBlock { start: 8352, length: 32, convRule: rule3 }
  , CharBlock { start: 8400, length: 13, convRule: rule92 }
  , CharBlock { start: 8413, length: 4, convRule: rule119 }
  , CharBlock { start: 8417, length: 1, convRule: rule92 }
  , CharBlock { start: 8418, length: 3, convRule: rule119 }
  , CharBlock { start: 8421, length: 12, convRule: rule92 }
  , CharBlock { start: 8448, length: 2, convRule: rule13 }
  , CharBlock { start: 8450, length: 1, convRule: rule107 }
  , CharBlock { start: 8451, length: 4, convRule: rule13 }
  , CharBlock { start: 8455, length: 1, convRule: rule107 }
  , CharBlock { start: 8456, length: 2, convRule: rule13 }
  , CharBlock { start: 8458, length: 1, convRule: rule20 }
  , CharBlock { start: 8459, length: 3, convRule: rule107 }
  , CharBlock { start: 8462, length: 2, convRule: rule20 }
  , CharBlock { start: 8464, length: 3, convRule: rule107 }
  , CharBlock { start: 8467, length: 1, convRule: rule20 }
  , CharBlock { start: 8468, length: 1, convRule: rule13 }
  , CharBlock { start: 8469, length: 1, convRule: rule107 }
  , CharBlock { start: 8470, length: 2, convRule: rule13 }
  , CharBlock { start: 8472, length: 1, convRule: rule6 }
  , CharBlock { start: 8473, length: 5, convRule: rule107 }
  , CharBlock { start: 8478, length: 6, convRule: rule13 }
  , CharBlock { start: 8484, length: 1, convRule: rule107 }
  , CharBlock { start: 8485, length: 1, convRule: rule13 }
  , CharBlock { start: 8486, length: 1, convRule: rule163 }
  , CharBlock { start: 8487, length: 1, convRule: rule13 }
  , CharBlock { start: 8488, length: 1, convRule: rule107 }
  , CharBlock { start: 8489, length: 1, convRule: rule13 }
  , CharBlock { start: 8490, length: 1, convRule: rule164 }
  , CharBlock { start: 8491, length: 1, convRule: rule165 }
  , CharBlock { start: 8492, length: 2, convRule: rule107 }
  , CharBlock { start: 8494, length: 1, convRule: rule13 }
  , CharBlock { start: 8495, length: 1, convRule: rule20 }
  , CharBlock { start: 8496, length: 2, convRule: rule107 }
  , CharBlock { start: 8498, length: 1, convRule: rule166 }
  , CharBlock { start: 8499, length: 1, convRule: rule107 }
  , CharBlock { start: 8500, length: 1, convRule: rule20 }
  , CharBlock { start: 8501, length: 4, convRule: rule14 }
  , CharBlock { start: 8505, length: 1, convRule: rule20 }
  , CharBlock { start: 8506, length: 2, convRule: rule13 }
  , CharBlock { start: 8508, length: 2, convRule: rule20 }
  , CharBlock { start: 8510, length: 2, convRule: rule107 }
  , CharBlock { start: 8512, length: 5, convRule: rule6 }
  , CharBlock { start: 8517, length: 1, convRule: rule107 }
  , CharBlock { start: 8518, length: 4, convRule: rule20 }
  , CharBlock { start: 8522, length: 1, convRule: rule13 }
  , CharBlock { start: 8523, length: 1, convRule: rule6 }
  , CharBlock { start: 8524, length: 2, convRule: rule13 }
  , CharBlock { start: 8526, length: 1, convRule: rule167 }
  , CharBlock { start: 8527, length: 1, convRule: rule13 }
  , CharBlock { start: 8528, length: 16, convRule: rule17 }
  , CharBlock { start: 8544, length: 16, convRule: rule168 }
  , CharBlock { start: 8560, length: 16, convRule: rule169 }
  , CharBlock { start: 8576, length: 3, convRule: rule128 }
  , CharBlock { start: 8579, length: 1, convRule: rule22 }
  , CharBlock { start: 8580, length: 1, convRule: rule23 }
  , CharBlock { start: 8581, length: 4, convRule: rule128 }
  , CharBlock { start: 8585, length: 1, convRule: rule17 }
  , CharBlock { start: 8586, length: 2, convRule: rule13 }
  , CharBlock { start: 8592, length: 5, convRule: rule6 }
  , CharBlock { start: 8597, length: 5, convRule: rule13 }
  , CharBlock { start: 8602, length: 2, convRule: rule6 }
  , CharBlock { start: 8604, length: 4, convRule: rule13 }
  , CharBlock { start: 8608, length: 1, convRule: rule6 }
  , CharBlock { start: 8609, length: 2, convRule: rule13 }
  , CharBlock { start: 8611, length: 1, convRule: rule6 }
  , CharBlock { start: 8612, length: 2, convRule: rule13 }
  , CharBlock { start: 8614, length: 1, convRule: rule6 }
  , CharBlock { start: 8615, length: 7, convRule: rule13 }
  , CharBlock { start: 8622, length: 1, convRule: rule6 }
  , CharBlock { start: 8623, length: 31, convRule: rule13 }
  , CharBlock { start: 8654, length: 2, convRule: rule6 }
  , CharBlock { start: 8656, length: 2, convRule: rule13 }
  , CharBlock { start: 8658, length: 1, convRule: rule6 }
  , CharBlock { start: 8659, length: 1, convRule: rule13 }
  , CharBlock { start: 8660, length: 1, convRule: rule6 }
  , CharBlock { start: 8661, length: 31, convRule: rule13 }
  , CharBlock { start: 8692, length: 268, convRule: rule6 }
  , CharBlock { start: 8960, length: 8, convRule: rule13 }
  , CharBlock { start: 8968, length: 1, convRule: rule4 }
  , CharBlock { start: 8969, length: 1, convRule: rule5 }
  , CharBlock { start: 8970, length: 1, convRule: rule4 }
  , CharBlock { start: 8971, length: 1, convRule: rule5 }
  , CharBlock { start: 8972, length: 20, convRule: rule13 }
  , CharBlock { start: 8992, length: 2, convRule: rule6 }
  , CharBlock { start: 8994, length: 7, convRule: rule13 }
  , CharBlock { start: 9001, length: 1, convRule: rule4 }
  , CharBlock { start: 9002, length: 1, convRule: rule5 }
  , CharBlock { start: 9003, length: 81, convRule: rule13 }
  , CharBlock { start: 9084, length: 1, convRule: rule6 }
  , CharBlock { start: 9085, length: 30, convRule: rule13 }
  , CharBlock { start: 9115, length: 25, convRule: rule6 }
  , CharBlock { start: 9140, length: 40, convRule: rule13 }
  , CharBlock { start: 9180, length: 6, convRule: rule6 }
  , CharBlock { start: 9186, length: 69, convRule: rule13 }
  , CharBlock { start: 9280, length: 11, convRule: rule13 }
  , CharBlock { start: 9312, length: 60, convRule: rule17 }
  , CharBlock { start: 9372, length: 26, convRule: rule13 }
  , CharBlock { start: 9398, length: 26, convRule: rule170 }
  , CharBlock { start: 9424, length: 26, convRule: rule171 }
  , CharBlock { start: 9450, length: 22, convRule: rule17 }
  , CharBlock { start: 9472, length: 183, convRule: rule13 }
  , CharBlock { start: 9655, length: 1, convRule: rule6 }
  , CharBlock { start: 9656, length: 9, convRule: rule13 }
  , CharBlock { start: 9665, length: 1, convRule: rule6 }
  , CharBlock { start: 9666, length: 54, convRule: rule13 }
  , CharBlock { start: 9720, length: 8, convRule: rule6 }
  , CharBlock { start: 9728, length: 111, convRule: rule13 }
  , CharBlock { start: 9839, length: 1, convRule: rule6 }
  , CharBlock { start: 9840, length: 248, convRule: rule13 }
  , CharBlock { start: 10088, length: 1, convRule: rule4 }
  , CharBlock { start: 10089, length: 1, convRule: rule5 }
  , CharBlock { start: 10090, length: 1, convRule: rule4 }
  , CharBlock { start: 10091, length: 1, convRule: rule5 }
  , CharBlock { start: 10092, length: 1, convRule: rule4 }
  , CharBlock { start: 10093, length: 1, convRule: rule5 }
  , CharBlock { start: 10094, length: 1, convRule: rule4 }
  , CharBlock { start: 10095, length: 1, convRule: rule5 }
  , CharBlock { start: 10096, length: 1, convRule: rule4 }
  , CharBlock { start: 10097, length: 1, convRule: rule5 }
  , CharBlock { start: 10098, length: 1, convRule: rule4 }
  , CharBlock { start: 10099, length: 1, convRule: rule5 }
  , CharBlock { start: 10100, length: 1, convRule: rule4 }
  , CharBlock { start: 10101, length: 1, convRule: rule5 }
  , CharBlock { start: 10102, length: 30, convRule: rule17 }
  , CharBlock { start: 10132, length: 44, convRule: rule13 }
  , CharBlock { start: 10176, length: 5, convRule: rule6 }
  , CharBlock { start: 10181, length: 1, convRule: rule4 }
  , CharBlock { start: 10182, length: 1, convRule: rule5 }
  , CharBlock { start: 10183, length: 31, convRule: rule6 }
  , CharBlock { start: 10214, length: 1, convRule: rule4 }
  , CharBlock { start: 10215, length: 1, convRule: rule5 }
  , CharBlock { start: 10216, length: 1, convRule: rule4 }
  , CharBlock { start: 10217, length: 1, convRule: rule5 }
  , CharBlock { start: 10218, length: 1, convRule: rule4 }
  , CharBlock { start: 10219, length: 1, convRule: rule5 }
  , CharBlock { start: 10220, length: 1, convRule: rule4 }
  , CharBlock { start: 10221, length: 1, convRule: rule5 }
  , CharBlock { start: 10222, length: 1, convRule: rule4 }
  , CharBlock { start: 10223, length: 1, convRule: rule5 }
  , CharBlock { start: 10224, length: 16, convRule: rule6 }
  , CharBlock { start: 10240, length: 256, convRule: rule13 }
  , CharBlock { start: 10496, length: 131, convRule: rule6 }
  , CharBlock { start: 10627, length: 1, convRule: rule4 }
  , CharBlock { start: 10628, length: 1, convRule: rule5 }
  , CharBlock { start: 10629, length: 1, convRule: rule4 }
  , CharBlock { start: 10630, length: 1, convRule: rule5 }
  , CharBlock { start: 10631, length: 1, convRule: rule4 }
  , CharBlock { start: 10632, length: 1, convRule: rule5 }
  , CharBlock { start: 10633, length: 1, convRule: rule4 }
  , CharBlock { start: 10634, length: 1, convRule: rule5 }
  , CharBlock { start: 10635, length: 1, convRule: rule4 }
  , CharBlock { start: 10636, length: 1, convRule: rule5 }
  , CharBlock { start: 10637, length: 1, convRule: rule4 }
  , CharBlock { start: 10638, length: 1, convRule: rule5 }
  , CharBlock { start: 10639, length: 1, convRule: rule4 }
  , CharBlock { start: 10640, length: 1, convRule: rule5 }
  , CharBlock { start: 10641, length: 1, convRule: rule4 }
  , CharBlock { start: 10642, length: 1, convRule: rule5 }
  , CharBlock { start: 10643, length: 1, convRule: rule4 }
  , CharBlock { start: 10644, length: 1, convRule: rule5 }
  , CharBlock { start: 10645, length: 1, convRule: rule4 }
  , CharBlock { start: 10646, length: 1, convRule: rule5 }
  , CharBlock { start: 10647, length: 1, convRule: rule4 }
  , CharBlock { start: 10648, length: 1, convRule: rule5 }
  , CharBlock { start: 10649, length: 63, convRule: rule6 }
  , CharBlock { start: 10712, length: 1, convRule: rule4 }
  , CharBlock { start: 10713, length: 1, convRule: rule5 }
  , CharBlock { start: 10714, length: 1, convRule: rule4 }
  , CharBlock { start: 10715, length: 1, convRule: rule5 }
  , CharBlock { start: 10716, length: 32, convRule: rule6 }
  , CharBlock { start: 10748, length: 1, convRule: rule4 }
  , CharBlock { start: 10749, length: 1, convRule: rule5 }
  , CharBlock { start: 10750, length: 258, convRule: rule6 }
  , CharBlock { start: 11008, length: 48, convRule: rule13 }
  , CharBlock { start: 11056, length: 21, convRule: rule6 }
  , CharBlock { start: 11077, length: 2, convRule: rule13 }
  , CharBlock { start: 11079, length: 6, convRule: rule6 }
  , CharBlock { start: 11085, length: 39, convRule: rule13 }
  , CharBlock { start: 11126, length: 32, convRule: rule13 }
  , CharBlock { start: 11159, length: 105, convRule: rule13 }
  , CharBlock { start: 11264, length: 47, convRule: rule122 }
  , CharBlock { start: 11312, length: 47, convRule: rule123 }
  , CharBlock { start: 11360, length: 1, convRule: rule22 }
  , CharBlock { start: 11361, length: 1, convRule: rule23 }
  , CharBlock { start: 11362, length: 1, convRule: rule172 }
  , CharBlock { start: 11363, length: 1, convRule: rule173 }
  , CharBlock { start: 11364, length: 1, convRule: rule174 }
  , CharBlock { start: 11365, length: 1, convRule: rule175 }
  , CharBlock { start: 11366, length: 1, convRule: rule176 }
  , CharBlock { start: 11367, length: 1, convRule: rule22 }
  , CharBlock { start: 11368, length: 1, convRule: rule23 }
  , CharBlock { start: 11369, length: 1, convRule: rule22 }
  , CharBlock { start: 11370, length: 1, convRule: rule23 }
  , CharBlock { start: 11371, length: 1, convRule: rule22 }
  , CharBlock { start: 11372, length: 1, convRule: rule23 }
  , CharBlock { start: 11373, length: 1, convRule: rule177 }
  , CharBlock { start: 11374, length: 1, convRule: rule178 }
  , CharBlock { start: 11375, length: 1, convRule: rule179 }
  , CharBlock { start: 11376, length: 1, convRule: rule180 }
  , CharBlock { start: 11377, length: 1, convRule: rule20 }
  , CharBlock { start: 11378, length: 1, convRule: rule22 }
  , CharBlock { start: 11379, length: 1, convRule: rule23 }
  , CharBlock { start: 11380, length: 1, convRule: rule20 }
  , CharBlock { start: 11381, length: 1, convRule: rule22 }
  , CharBlock { start: 11382, length: 1, convRule: rule23 }
  , CharBlock { start: 11383, length: 5, convRule: rule20 }
  , CharBlock { start: 11388, length: 2, convRule: rule91 }
  , CharBlock { start: 11390, length: 2, convRule: rule181 }
  , CharBlock { start: 11392, length: 1, convRule: rule22 }
  , CharBlock { start: 11393, length: 1, convRule: rule23 }
  , CharBlock { start: 11394, length: 1, convRule: rule22 }
  , CharBlock { start: 11395, length: 1, convRule: rule23 }
  , CharBlock { start: 11396, length: 1, convRule: rule22 }
  , CharBlock { start: 11397, length: 1, convRule: rule23 }
  , CharBlock { start: 11398, length: 1, convRule: rule22 }
  , CharBlock { start: 11399, length: 1, convRule: rule23 }
  , CharBlock { start: 11400, length: 1, convRule: rule22 }
  , CharBlock { start: 11401, length: 1, convRule: rule23 }
  , CharBlock { start: 11402, length: 1, convRule: rule22 }
  , CharBlock { start: 11403, length: 1, convRule: rule23 }
  , CharBlock { start: 11404, length: 1, convRule: rule22 }
  , CharBlock { start: 11405, length: 1, convRule: rule23 }
  , CharBlock { start: 11406, length: 1, convRule: rule22 }
  , CharBlock { start: 11407, length: 1, convRule: rule23 }
  , CharBlock { start: 11408, length: 1, convRule: rule22 }
  , CharBlock { start: 11409, length: 1, convRule: rule23 }
  , CharBlock { start: 11410, length: 1, convRule: rule22 }
  , CharBlock { start: 11411, length: 1, convRule: rule23 }
  , CharBlock { start: 11412, length: 1, convRule: rule22 }
  , CharBlock { start: 11413, length: 1, convRule: rule23 }
  , CharBlock { start: 11414, length: 1, convRule: rule22 }
  , CharBlock { start: 11415, length: 1, convRule: rule23 }
  , CharBlock { start: 11416, length: 1, convRule: rule22 }
  , CharBlock { start: 11417, length: 1, convRule: rule23 }
  , CharBlock { start: 11418, length: 1, convRule: rule22 }
  , CharBlock { start: 11419, length: 1, convRule: rule23 }
  , CharBlock { start: 11420, length: 1, convRule: rule22 }
  , CharBlock { start: 11421, length: 1, convRule: rule23 }
  , CharBlock { start: 11422, length: 1, convRule: rule22 }
  , CharBlock { start: 11423, length: 1, convRule: rule23 }
  , CharBlock { start: 11424, length: 1, convRule: rule22 }
  , CharBlock { start: 11425, length: 1, convRule: rule23 }
  , CharBlock { start: 11426, length: 1, convRule: rule22 }
  , CharBlock { start: 11427, length: 1, convRule: rule23 }
  , CharBlock { start: 11428, length: 1, convRule: rule22 }
  , CharBlock { start: 11429, length: 1, convRule: rule23 }
  , CharBlock { start: 11430, length: 1, convRule: rule22 }
  , CharBlock { start: 11431, length: 1, convRule: rule23 }
  , CharBlock { start: 11432, length: 1, convRule: rule22 }
  , CharBlock { start: 11433, length: 1, convRule: rule23 }
  , CharBlock { start: 11434, length: 1, convRule: rule22 }
  , CharBlock { start: 11435, length: 1, convRule: rule23 }
  , CharBlock { start: 11436, length: 1, convRule: rule22 }
  , CharBlock { start: 11437, length: 1, convRule: rule23 }
  , CharBlock { start: 11438, length: 1, convRule: rule22 }
  , CharBlock { start: 11439, length: 1, convRule: rule23 }
  , CharBlock { start: 11440, length: 1, convRule: rule22 }
  , CharBlock { start: 11441, length: 1, convRule: rule23 }
  , CharBlock { start: 11442, length: 1, convRule: rule22 }
  , CharBlock { start: 11443, length: 1, convRule: rule23 }
  , CharBlock { start: 11444, length: 1, convRule: rule22 }
  , CharBlock { start: 11445, length: 1, convRule: rule23 }
  , CharBlock { start: 11446, length: 1, convRule: rule22 }
  , CharBlock { start: 11447, length: 1, convRule: rule23 }
  , CharBlock { start: 11448, length: 1, convRule: rule22 }
  , CharBlock { start: 11449, length: 1, convRule: rule23 }
  , CharBlock { start: 11450, length: 1, convRule: rule22 }
  , CharBlock { start: 11451, length: 1, convRule: rule23 }
  , CharBlock { start: 11452, length: 1, convRule: rule22 }
  , CharBlock { start: 11453, length: 1, convRule: rule23 }
  , CharBlock { start: 11454, length: 1, convRule: rule22 }
  , CharBlock { start: 11455, length: 1, convRule: rule23 }
  , CharBlock { start: 11456, length: 1, convRule: rule22 }
  , CharBlock { start: 11457, length: 1, convRule: rule23 }
  , CharBlock { start: 11458, length: 1, convRule: rule22 }
  , CharBlock { start: 11459, length: 1, convRule: rule23 }
  , CharBlock { start: 11460, length: 1, convRule: rule22 }
  , CharBlock { start: 11461, length: 1, convRule: rule23 }
  , CharBlock { start: 11462, length: 1, convRule: rule22 }
  , CharBlock { start: 11463, length: 1, convRule: rule23 }
  , CharBlock { start: 11464, length: 1, convRule: rule22 }
  , CharBlock { start: 11465, length: 1, convRule: rule23 }
  , CharBlock { start: 11466, length: 1, convRule: rule22 }
  , CharBlock { start: 11467, length: 1, convRule: rule23 }
  , CharBlock { start: 11468, length: 1, convRule: rule22 }
  , CharBlock { start: 11469, length: 1, convRule: rule23 }
  , CharBlock { start: 11470, length: 1, convRule: rule22 }
  , CharBlock { start: 11471, length: 1, convRule: rule23 }
  , CharBlock { start: 11472, length: 1, convRule: rule22 }
  , CharBlock { start: 11473, length: 1, convRule: rule23 }
  , CharBlock { start: 11474, length: 1, convRule: rule22 }
  , CharBlock { start: 11475, length: 1, convRule: rule23 }
  , CharBlock { start: 11476, length: 1, convRule: rule22 }
  , CharBlock { start: 11477, length: 1, convRule: rule23 }
  , CharBlock { start: 11478, length: 1, convRule: rule22 }
  , CharBlock { start: 11479, length: 1, convRule: rule23 }
  , CharBlock { start: 11480, length: 1, convRule: rule22 }
  , CharBlock { start: 11481, length: 1, convRule: rule23 }
  , CharBlock { start: 11482, length: 1, convRule: rule22 }
  , CharBlock { start: 11483, length: 1, convRule: rule23 }
  , CharBlock { start: 11484, length: 1, convRule: rule22 }
  , CharBlock { start: 11485, length: 1, convRule: rule23 }
  , CharBlock { start: 11486, length: 1, convRule: rule22 }
  , CharBlock { start: 11487, length: 1, convRule: rule23 }
  , CharBlock { start: 11488, length: 1, convRule: rule22 }
  , CharBlock { start: 11489, length: 1, convRule: rule23 }
  , CharBlock { start: 11490, length: 1, convRule: rule22 }
  , CharBlock { start: 11491, length: 1, convRule: rule23 }
  , CharBlock { start: 11492, length: 1, convRule: rule20 }
  , CharBlock { start: 11493, length: 6, convRule: rule13 }
  , CharBlock { start: 11499, length: 1, convRule: rule22 }
  , CharBlock { start: 11500, length: 1, convRule: rule23 }
  , CharBlock { start: 11501, length: 1, convRule: rule22 }
  , CharBlock { start: 11502, length: 1, convRule: rule23 }
  , CharBlock { start: 11503, length: 3, convRule: rule92 }
  , CharBlock { start: 11506, length: 1, convRule: rule22 }
  , CharBlock { start: 11507, length: 1, convRule: rule23 }
  , CharBlock { start: 11513, length: 4, convRule: rule2 }
  , CharBlock { start: 11517, length: 1, convRule: rule17 }
  , CharBlock { start: 11518, length: 2, convRule: rule2 }
  , CharBlock { start: 11520, length: 38, convRule: rule182 }
  , CharBlock { start: 11559, length: 1, convRule: rule182 }
  , CharBlock { start: 11565, length: 1, convRule: rule182 }
  , CharBlock { start: 11568, length: 56, convRule: rule14 }
  , CharBlock { start: 11631, length: 1, convRule: rule91 }
  , CharBlock { start: 11632, length: 1, convRule: rule2 }
  , CharBlock { start: 11647, length: 1, convRule: rule92 }
  , CharBlock { start: 11648, length: 23, convRule: rule14 }
  , CharBlock { start: 11680, length: 7, convRule: rule14 }
  , CharBlock { start: 11688, length: 7, convRule: rule14 }
  , CharBlock { start: 11696, length: 7, convRule: rule14 }
  , CharBlock { start: 11704, length: 7, convRule: rule14 }
  , CharBlock { start: 11712, length: 7, convRule: rule14 }
  , CharBlock { start: 11720, length: 7, convRule: rule14 }
  , CharBlock { start: 11728, length: 7, convRule: rule14 }
  , CharBlock { start: 11736, length: 7, convRule: rule14 }
  , CharBlock { start: 11744, length: 32, convRule: rule92 }
  , CharBlock { start: 11776, length: 2, convRule: rule2 }
  , CharBlock { start: 11778, length: 1, convRule: rule15 }
  , CharBlock { start: 11779, length: 1, convRule: rule19 }
  , CharBlock { start: 11780, length: 1, convRule: rule15 }
  , CharBlock { start: 11781, length: 1, convRule: rule19 }
  , CharBlock { start: 11782, length: 3, convRule: rule2 }
  , CharBlock { start: 11785, length: 1, convRule: rule15 }
  , CharBlock { start: 11786, length: 1, convRule: rule19 }
  , CharBlock { start: 11787, length: 1, convRule: rule2 }
  , CharBlock { start: 11788, length: 1, convRule: rule15 }
  , CharBlock { start: 11789, length: 1, convRule: rule19 }
  , CharBlock { start: 11790, length: 9, convRule: rule2 }
  , CharBlock { start: 11799, length: 1, convRule: rule7 }
  , CharBlock { start: 11800, length: 2, convRule: rule2 }
  , CharBlock { start: 11802, length: 1, convRule: rule7 }
  , CharBlock { start: 11803, length: 1, convRule: rule2 }
  , CharBlock { start: 11804, length: 1, convRule: rule15 }
  , CharBlock { start: 11805, length: 1, convRule: rule19 }
  , CharBlock { start: 11806, length: 2, convRule: rule2 }
  , CharBlock { start: 11808, length: 1, convRule: rule15 }
  , CharBlock { start: 11809, length: 1, convRule: rule19 }
  , CharBlock { start: 11810, length: 1, convRule: rule4 }
  , CharBlock { start: 11811, length: 1, convRule: rule5 }
  , CharBlock { start: 11812, length: 1, convRule: rule4 }
  , CharBlock { start: 11813, length: 1, convRule: rule5 }
  , CharBlock { start: 11814, length: 1, convRule: rule4 }
  , CharBlock { start: 11815, length: 1, convRule: rule5 }
  , CharBlock { start: 11816, length: 1, convRule: rule4 }
  , CharBlock { start: 11817, length: 1, convRule: rule5 }
  , CharBlock { start: 11818, length: 5, convRule: rule2 }
  , CharBlock { start: 11823, length: 1, convRule: rule91 }
  , CharBlock { start: 11824, length: 10, convRule: rule2 }
  , CharBlock { start: 11834, length: 2, convRule: rule7 }
  , CharBlock { start: 11836, length: 4, convRule: rule2 }
  , CharBlock { start: 11840, length: 1, convRule: rule7 }
  , CharBlock { start: 11841, length: 1, convRule: rule2 }
  , CharBlock { start: 11842, length: 1, convRule: rule4 }
  , CharBlock { start: 11843, length: 13, convRule: rule2 }
  , CharBlock { start: 11856, length: 2, convRule: rule13 }
  , CharBlock { start: 11858, length: 1, convRule: rule2 }
  , CharBlock { start: 11904, length: 26, convRule: rule13 }
  , CharBlock { start: 11931, length: 89, convRule: rule13 }
  , CharBlock { start: 12032, length: 214, convRule: rule13 }
  , CharBlock { start: 12272, length: 12, convRule: rule13 }
  , CharBlock { start: 12288, length: 1, convRule: rule1 }
  , CharBlock { start: 12289, length: 3, convRule: rule2 }
  , CharBlock { start: 12292, length: 1, convRule: rule13 }
  , CharBlock { start: 12293, length: 1, convRule: rule91 }
  , CharBlock { start: 12294, length: 1, convRule: rule14 }
  , CharBlock { start: 12295, length: 1, convRule: rule128 }
  , CharBlock { start: 12296, length: 1, convRule: rule4 }
  , CharBlock { start: 12297, length: 1, convRule: rule5 }
  , CharBlock { start: 12298, length: 1, convRule: rule4 }
  , CharBlock { start: 12299, length: 1, convRule: rule5 }
  , CharBlock { start: 12300, length: 1, convRule: rule4 }
  , CharBlock { start: 12301, length: 1, convRule: rule5 }
  , CharBlock { start: 12302, length: 1, convRule: rule4 }
  , CharBlock { start: 12303, length: 1, convRule: rule5 }
  , CharBlock { start: 12304, length: 1, convRule: rule4 }
  , CharBlock { start: 12305, length: 1, convRule: rule5 }
  , CharBlock { start: 12306, length: 2, convRule: rule13 }
  , CharBlock { start: 12308, length: 1, convRule: rule4 }
  , CharBlock { start: 12309, length: 1, convRule: rule5 }
  , CharBlock { start: 12310, length: 1, convRule: rule4 }
  , CharBlock { start: 12311, length: 1, convRule: rule5 }
  , CharBlock { start: 12312, length: 1, convRule: rule4 }
  , CharBlock { start: 12313, length: 1, convRule: rule5 }
  , CharBlock { start: 12314, length: 1, convRule: rule4 }
  , CharBlock { start: 12315, length: 1, convRule: rule5 }
  , CharBlock { start: 12316, length: 1, convRule: rule7 }
  , CharBlock { start: 12317, length: 1, convRule: rule4 }
  , CharBlock { start: 12318, length: 2, convRule: rule5 }
  , CharBlock { start: 12320, length: 1, convRule: rule13 }
  , CharBlock { start: 12321, length: 9, convRule: rule128 }
  , CharBlock { start: 12330, length: 4, convRule: rule92 }
  , CharBlock { start: 12334, length: 2, convRule: rule124 }
  , CharBlock { start: 12336, length: 1, convRule: rule7 }
  , CharBlock { start: 12337, length: 5, convRule: rule91 }
  , CharBlock { start: 12342, length: 2, convRule: rule13 }
  , CharBlock { start: 12344, length: 3, convRule: rule128 }
  , CharBlock { start: 12347, length: 1, convRule: rule91 }
  , CharBlock { start: 12348, length: 1, convRule: rule14 }
  , CharBlock { start: 12349, length: 1, convRule: rule2 }
  , CharBlock { start: 12350, length: 2, convRule: rule13 }
  , CharBlock { start: 12353, length: 86, convRule: rule14 }
  , CharBlock { start: 12441, length: 2, convRule: rule92 }
  , CharBlock { start: 12443, length: 2, convRule: rule10 }
  , CharBlock { start: 12445, length: 2, convRule: rule91 }
  , CharBlock { start: 12447, length: 1, convRule: rule14 }
  , CharBlock { start: 12448, length: 1, convRule: rule7 }
  , CharBlock { start: 12449, length: 90, convRule: rule14 }
  , CharBlock { start: 12539, length: 1, convRule: rule2 }
  , CharBlock { start: 12540, length: 3, convRule: rule91 }
  , CharBlock { start: 12543, length: 1, convRule: rule14 }
  , CharBlock { start: 12549, length: 43, convRule: rule14 }
  , CharBlock { start: 12593, length: 94, convRule: rule14 }
  , CharBlock { start: 12688, length: 2, convRule: rule13 }
  , CharBlock { start: 12690, length: 4, convRule: rule17 }
  , CharBlock { start: 12694, length: 10, convRule: rule13 }
  , CharBlock { start: 12704, length: 32, convRule: rule14 }
  , CharBlock { start: 12736, length: 36, convRule: rule13 }
  , CharBlock { start: 12784, length: 16, convRule: rule14 }
  , CharBlock { start: 12800, length: 31, convRule: rule13 }
  , CharBlock { start: 12832, length: 10, convRule: rule17 }
  , CharBlock { start: 12842, length: 30, convRule: rule13 }
  , CharBlock { start: 12872, length: 8, convRule: rule17 }
  , CharBlock { start: 12880, length: 1, convRule: rule13 }
  , CharBlock { start: 12881, length: 15, convRule: rule17 }
  , CharBlock { start: 12896, length: 32, convRule: rule13 }
  , CharBlock { start: 12928, length: 10, convRule: rule17 }
  , CharBlock { start: 12938, length: 39, convRule: rule13 }
  , CharBlock { start: 12977, length: 15, convRule: rule17 }
  , CharBlock { start: 12992, length: 320, convRule: rule13 }
  , CharBlock { start: 13312, length: 6592, convRule: rule14 }
  , CharBlock { start: 19904, length: 64, convRule: rule13 }
  , CharBlock { start: 19968, length: 20989, convRule: rule14 }
  , CharBlock { start: 40960, length: 21, convRule: rule14 }
  , CharBlock { start: 40981, length: 1, convRule: rule91 }
  , CharBlock { start: 40982, length: 1143, convRule: rule14 }
  , CharBlock { start: 42128, length: 55, convRule: rule13 }
  , CharBlock { start: 42192, length: 40, convRule: rule14 }
  , CharBlock { start: 42232, length: 6, convRule: rule91 }
  , CharBlock { start: 42238, length: 2, convRule: rule2 }
  , CharBlock { start: 42240, length: 268, convRule: rule14 }
  , CharBlock { start: 42508, length: 1, convRule: rule91 }
  , CharBlock { start: 42509, length: 3, convRule: rule2 }
  , CharBlock { start: 42512, length: 16, convRule: rule14 }
  , CharBlock { start: 42528, length: 10, convRule: rule8 }
  , CharBlock { start: 42538, length: 2, convRule: rule14 }
  , CharBlock { start: 42560, length: 1, convRule: rule22 }
  , CharBlock { start: 42561, length: 1, convRule: rule23 }
  , CharBlock { start: 42562, length: 1, convRule: rule22 }
  , CharBlock { start: 42563, length: 1, convRule: rule23 }
  , CharBlock { start: 42564, length: 1, convRule: rule22 }
  , CharBlock { start: 42565, length: 1, convRule: rule23 }
  , CharBlock { start: 42566, length: 1, convRule: rule22 }
  , CharBlock { start: 42567, length: 1, convRule: rule23 }
  , CharBlock { start: 42568, length: 1, convRule: rule22 }
  , CharBlock { start: 42569, length: 1, convRule: rule23 }
  , CharBlock { start: 42570, length: 1, convRule: rule22 }
  , CharBlock { start: 42571, length: 1, convRule: rule23 }
  , CharBlock { start: 42572, length: 1, convRule: rule22 }
  , CharBlock { start: 42573, length: 1, convRule: rule23 }
  , CharBlock { start: 42574, length: 1, convRule: rule22 }
  , CharBlock { start: 42575, length: 1, convRule: rule23 }
  , CharBlock { start: 42576, length: 1, convRule: rule22 }
  , CharBlock { start: 42577, length: 1, convRule: rule23 }
  , CharBlock { start: 42578, length: 1, convRule: rule22 }
  , CharBlock { start: 42579, length: 1, convRule: rule23 }
  , CharBlock { start: 42580, length: 1, convRule: rule22 }
  , CharBlock { start: 42581, length: 1, convRule: rule23 }
  , CharBlock { start: 42582, length: 1, convRule: rule22 }
  , CharBlock { start: 42583, length: 1, convRule: rule23 }
  , CharBlock { start: 42584, length: 1, convRule: rule22 }
  , CharBlock { start: 42585, length: 1, convRule: rule23 }
  , CharBlock { start: 42586, length: 1, convRule: rule22 }
  , CharBlock { start: 42587, length: 1, convRule: rule23 }
  , CharBlock { start: 42588, length: 1, convRule: rule22 }
  , CharBlock { start: 42589, length: 1, convRule: rule23 }
  , CharBlock { start: 42590, length: 1, convRule: rule22 }
  , CharBlock { start: 42591, length: 1, convRule: rule23 }
  , CharBlock { start: 42592, length: 1, convRule: rule22 }
  , CharBlock { start: 42593, length: 1, convRule: rule23 }
  , CharBlock { start: 42594, length: 1, convRule: rule22 }
  , CharBlock { start: 42595, length: 1, convRule: rule23 }
  , CharBlock { start: 42596, length: 1, convRule: rule22 }
  , CharBlock { start: 42597, length: 1, convRule: rule23 }
  , CharBlock { start: 42598, length: 1, convRule: rule22 }
  , CharBlock { start: 42599, length: 1, convRule: rule23 }
  , CharBlock { start: 42600, length: 1, convRule: rule22 }
  , CharBlock { start: 42601, length: 1, convRule: rule23 }
  , CharBlock { start: 42602, length: 1, convRule: rule22 }
  , CharBlock { start: 42603, length: 1, convRule: rule23 }
  , CharBlock { start: 42604, length: 1, convRule: rule22 }
  , CharBlock { start: 42605, length: 1, convRule: rule23 }
  , CharBlock { start: 42606, length: 1, convRule: rule14 }
  , CharBlock { start: 42607, length: 1, convRule: rule92 }
  , CharBlock { start: 42608, length: 3, convRule: rule119 }
  , CharBlock { start: 42611, length: 1, convRule: rule2 }
  , CharBlock { start: 42612, length: 10, convRule: rule92 }
  , CharBlock { start: 42622, length: 1, convRule: rule2 }
  , CharBlock { start: 42623, length: 1, convRule: rule91 }
  , CharBlock { start: 42624, length: 1, convRule: rule22 }
  , CharBlock { start: 42625, length: 1, convRule: rule23 }
  , CharBlock { start: 42626, length: 1, convRule: rule22 }
  , CharBlock { start: 42627, length: 1, convRule: rule23 }
  , CharBlock { start: 42628, length: 1, convRule: rule22 }
  , CharBlock { start: 42629, length: 1, convRule: rule23 }
  , CharBlock { start: 42630, length: 1, convRule: rule22 }
  , CharBlock { start: 42631, length: 1, convRule: rule23 }
  , CharBlock { start: 42632, length: 1, convRule: rule22 }
  , CharBlock { start: 42633, length: 1, convRule: rule23 }
  , CharBlock { start: 42634, length: 1, convRule: rule22 }
  , CharBlock { start: 42635, length: 1, convRule: rule23 }
  , CharBlock { start: 42636, length: 1, convRule: rule22 }
  , CharBlock { start: 42637, length: 1, convRule: rule23 }
  , CharBlock { start: 42638, length: 1, convRule: rule22 }
  , CharBlock { start: 42639, length: 1, convRule: rule23 }
  , CharBlock { start: 42640, length: 1, convRule: rule22 }
  , CharBlock { start: 42641, length: 1, convRule: rule23 }
  , CharBlock { start: 42642, length: 1, convRule: rule22 }
  , CharBlock { start: 42643, length: 1, convRule: rule23 }
  , CharBlock { start: 42644, length: 1, convRule: rule22 }
  , CharBlock { start: 42645, length: 1, convRule: rule23 }
  , CharBlock { start: 42646, length: 1, convRule: rule22 }
  , CharBlock { start: 42647, length: 1, convRule: rule23 }
  , CharBlock { start: 42648, length: 1, convRule: rule22 }
  , CharBlock { start: 42649, length: 1, convRule: rule23 }
  , CharBlock { start: 42650, length: 1, convRule: rule22 }
  , CharBlock { start: 42651, length: 1, convRule: rule23 }
  , CharBlock { start: 42652, length: 2, convRule: rule91 }
  , CharBlock { start: 42654, length: 2, convRule: rule92 }
  , CharBlock { start: 42656, length: 70, convRule: rule14 }
  , CharBlock { start: 42726, length: 10, convRule: rule128 }
  , CharBlock { start: 42736, length: 2, convRule: rule92 }
  , CharBlock { start: 42738, length: 6, convRule: rule2 }
  , CharBlock { start: 42752, length: 23, convRule: rule10 }
  , CharBlock { start: 42775, length: 9, convRule: rule91 }
  , CharBlock { start: 42784, length: 2, convRule: rule10 }
  , CharBlock { start: 42786, length: 1, convRule: rule22 }
  , CharBlock { start: 42787, length: 1, convRule: rule23 }
  , CharBlock { start: 42788, length: 1, convRule: rule22 }
  , CharBlock { start: 42789, length: 1, convRule: rule23 }
  , CharBlock { start: 42790, length: 1, convRule: rule22 }
  , CharBlock { start: 42791, length: 1, convRule: rule23 }
  , CharBlock { start: 42792, length: 1, convRule: rule22 }
  , CharBlock { start: 42793, length: 1, convRule: rule23 }
  , CharBlock { start: 42794, length: 1, convRule: rule22 }
  , CharBlock { start: 42795, length: 1, convRule: rule23 }
  , CharBlock { start: 42796, length: 1, convRule: rule22 }
  , CharBlock { start: 42797, length: 1, convRule: rule23 }
  , CharBlock { start: 42798, length: 1, convRule: rule22 }
  , CharBlock { start: 42799, length: 1, convRule: rule23 }
  , CharBlock { start: 42800, length: 2, convRule: rule20 }
  , CharBlock { start: 42802, length: 1, convRule: rule22 }
  , CharBlock { start: 42803, length: 1, convRule: rule23 }
  , CharBlock { start: 42804, length: 1, convRule: rule22 }
  , CharBlock { start: 42805, length: 1, convRule: rule23 }
  , CharBlock { start: 42806, length: 1, convRule: rule22 }
  , CharBlock { start: 42807, length: 1, convRule: rule23 }
  , CharBlock { start: 42808, length: 1, convRule: rule22 }
  , CharBlock { start: 42809, length: 1, convRule: rule23 }
  , CharBlock { start: 42810, length: 1, convRule: rule22 }
  , CharBlock { start: 42811, length: 1, convRule: rule23 }
  , CharBlock { start: 42812, length: 1, convRule: rule22 }
  , CharBlock { start: 42813, length: 1, convRule: rule23 }
  , CharBlock { start: 42814, length: 1, convRule: rule22 }
  , CharBlock { start: 42815, length: 1, convRule: rule23 }
  , CharBlock { start: 42816, length: 1, convRule: rule22 }
  , CharBlock { start: 42817, length: 1, convRule: rule23 }
  , CharBlock { start: 42818, length: 1, convRule: rule22 }
  , CharBlock { start: 42819, length: 1, convRule: rule23 }
  , CharBlock { start: 42820, length: 1, convRule: rule22 }
  , CharBlock { start: 42821, length: 1, convRule: rule23 }
  , CharBlock { start: 42822, length: 1, convRule: rule22 }
  , CharBlock { start: 42823, length: 1, convRule: rule23 }
  , CharBlock { start: 42824, length: 1, convRule: rule22 }
  , CharBlock { start: 42825, length: 1, convRule: rule23 }
  , CharBlock { start: 42826, length: 1, convRule: rule22 }
  , CharBlock { start: 42827, length: 1, convRule: rule23 }
  , CharBlock { start: 42828, length: 1, convRule: rule22 }
  , CharBlock { start: 42829, length: 1, convRule: rule23 }
  , CharBlock { start: 42830, length: 1, convRule: rule22 }
  , CharBlock { start: 42831, length: 1, convRule: rule23 }
  , CharBlock { start: 42832, length: 1, convRule: rule22 }
  , CharBlock { start: 42833, length: 1, convRule: rule23 }
  , CharBlock { start: 42834, length: 1, convRule: rule22 }
  , CharBlock { start: 42835, length: 1, convRule: rule23 }
  , CharBlock { start: 42836, length: 1, convRule: rule22 }
  , CharBlock { start: 42837, length: 1, convRule: rule23 }
  , CharBlock { start: 42838, length: 1, convRule: rule22 }
  , CharBlock { start: 42839, length: 1, convRule: rule23 }
  , CharBlock { start: 42840, length: 1, convRule: rule22 }
  , CharBlock { start: 42841, length: 1, convRule: rule23 }
  , CharBlock { start: 42842, length: 1, convRule: rule22 }
  , CharBlock { start: 42843, length: 1, convRule: rule23 }
  , CharBlock { start: 42844, length: 1, convRule: rule22 }
  , CharBlock { start: 42845, length: 1, convRule: rule23 }
  , CharBlock { start: 42846, length: 1, convRule: rule22 }
  , CharBlock { start: 42847, length: 1, convRule: rule23 }
  , CharBlock { start: 42848, length: 1, convRule: rule22 }
  , CharBlock { start: 42849, length: 1, convRule: rule23 }
  , CharBlock { start: 42850, length: 1, convRule: rule22 }
  , CharBlock { start: 42851, length: 1, convRule: rule23 }
  , CharBlock { start: 42852, length: 1, convRule: rule22 }
  , CharBlock { start: 42853, length: 1, convRule: rule23 }
  , CharBlock { start: 42854, length: 1, convRule: rule22 }
  , CharBlock { start: 42855, length: 1, convRule: rule23 }
  , CharBlock { start: 42856, length: 1, convRule: rule22 }
  , CharBlock { start: 42857, length: 1, convRule: rule23 }
  , CharBlock { start: 42858, length: 1, convRule: rule22 }
  , CharBlock { start: 42859, length: 1, convRule: rule23 }
  , CharBlock { start: 42860, length: 1, convRule: rule22 }
  , CharBlock { start: 42861, length: 1, convRule: rule23 }
  , CharBlock { start: 42862, length: 1, convRule: rule22 }
  , CharBlock { start: 42863, length: 1, convRule: rule23 }
  , CharBlock { start: 42864, length: 1, convRule: rule91 }
  , CharBlock { start: 42865, length: 8, convRule: rule20 }
  , CharBlock { start: 42873, length: 1, convRule: rule22 }
  , CharBlock { start: 42874, length: 1, convRule: rule23 }
  , CharBlock { start: 42875, length: 1, convRule: rule22 }
  , CharBlock { start: 42876, length: 1, convRule: rule23 }
  , CharBlock { start: 42877, length: 1, convRule: rule183 }
  , CharBlock { start: 42878, length: 1, convRule: rule22 }
  , CharBlock { start: 42879, length: 1, convRule: rule23 }
  , CharBlock { start: 42880, length: 1, convRule: rule22 }
  , CharBlock { start: 42881, length: 1, convRule: rule23 }
  , CharBlock { start: 42882, length: 1, convRule: rule22 }
  , CharBlock { start: 42883, length: 1, convRule: rule23 }
  , CharBlock { start: 42884, length: 1, convRule: rule22 }
  , CharBlock { start: 42885, length: 1, convRule: rule23 }
  , CharBlock { start: 42886, length: 1, convRule: rule22 }
  , CharBlock { start: 42887, length: 1, convRule: rule23 }
  , CharBlock { start: 42888, length: 1, convRule: rule91 }
  , CharBlock { start: 42889, length: 2, convRule: rule10 }
  , CharBlock { start: 42891, length: 1, convRule: rule22 }
  , CharBlock { start: 42892, length: 1, convRule: rule23 }
  , CharBlock { start: 42893, length: 1, convRule: rule184 }
  , CharBlock { start: 42894, length: 1, convRule: rule20 }
  , CharBlock { start: 42895, length: 1, convRule: rule14 }
  , CharBlock { start: 42896, length: 1, convRule: rule22 }
  , CharBlock { start: 42897, length: 1, convRule: rule23 }
  , CharBlock { start: 42898, length: 1, convRule: rule22 }
  , CharBlock { start: 42899, length: 1, convRule: rule23 }
  , CharBlock { start: 42900, length: 1, convRule: rule185 }
  , CharBlock { start: 42901, length: 1, convRule: rule20 }
  , CharBlock { start: 42902, length: 1, convRule: rule22 }
  , CharBlock { start: 42903, length: 1, convRule: rule23 }
  , CharBlock { start: 42904, length: 1, convRule: rule22 }
  , CharBlock { start: 42905, length: 1, convRule: rule23 }
  , CharBlock { start: 42906, length: 1, convRule: rule22 }
  , CharBlock { start: 42907, length: 1, convRule: rule23 }
  , CharBlock { start: 42908, length: 1, convRule: rule22 }
  , CharBlock { start: 42909, length: 1, convRule: rule23 }
  , CharBlock { start: 42910, length: 1, convRule: rule22 }
  , CharBlock { start: 42911, length: 1, convRule: rule23 }
  , CharBlock { start: 42912, length: 1, convRule: rule22 }
  , CharBlock { start: 42913, length: 1, convRule: rule23 }
  , CharBlock { start: 42914, length: 1, convRule: rule22 }
  , CharBlock { start: 42915, length: 1, convRule: rule23 }
  , CharBlock { start: 42916, length: 1, convRule: rule22 }
  , CharBlock { start: 42917, length: 1, convRule: rule23 }
  , CharBlock { start: 42918, length: 1, convRule: rule22 }
  , CharBlock { start: 42919, length: 1, convRule: rule23 }
  , CharBlock { start: 42920, length: 1, convRule: rule22 }
  , CharBlock { start: 42921, length: 1, convRule: rule23 }
  , CharBlock { start: 42922, length: 1, convRule: rule186 }
  , CharBlock { start: 42923, length: 1, convRule: rule187 }
  , CharBlock { start: 42924, length: 1, convRule: rule188 }
  , CharBlock { start: 42925, length: 1, convRule: rule189 }
  , CharBlock { start: 42926, length: 1, convRule: rule186 }
  , CharBlock { start: 42927, length: 1, convRule: rule20 }
  , CharBlock { start: 42928, length: 1, convRule: rule190 }
  , CharBlock { start: 42929, length: 1, convRule: rule191 }
  , CharBlock { start: 42930, length: 1, convRule: rule192 }
  , CharBlock { start: 42931, length: 1, convRule: rule193 }
  , CharBlock { start: 42932, length: 1, convRule: rule22 }
  , CharBlock { start: 42933, length: 1, convRule: rule23 }
  , CharBlock { start: 42934, length: 1, convRule: rule22 }
  , CharBlock { start: 42935, length: 1, convRule: rule23 }
  , CharBlock { start: 42936, length: 1, convRule: rule22 }
  , CharBlock { start: 42937, length: 1, convRule: rule23 }
  , CharBlock { start: 42938, length: 1, convRule: rule22 }
  , CharBlock { start: 42939, length: 1, convRule: rule23 }
  , CharBlock { start: 42940, length: 1, convRule: rule22 }
  , CharBlock { start: 42941, length: 1, convRule: rule23 }
  , CharBlock { start: 42942, length: 1, convRule: rule22 }
  , CharBlock { start: 42943, length: 1, convRule: rule23 }
  , CharBlock { start: 42946, length: 1, convRule: rule22 }
  , CharBlock { start: 42947, length: 1, convRule: rule23 }
  , CharBlock { start: 42948, length: 1, convRule: rule194 }
  , CharBlock { start: 42949, length: 1, convRule: rule195 }
  , CharBlock { start: 42950, length: 1, convRule: rule196 }
  , CharBlock { start: 42951, length: 1, convRule: rule22 }
  , CharBlock { start: 42952, length: 1, convRule: rule23 }
  , CharBlock { start: 42953, length: 1, convRule: rule22 }
  , CharBlock { start: 42954, length: 1, convRule: rule23 }
  , CharBlock { start: 42997, length: 1, convRule: rule22 }
  , CharBlock { start: 42998, length: 1, convRule: rule23 }
  , CharBlock { start: 42999, length: 1, convRule: rule14 }
  , CharBlock { start: 43000, length: 2, convRule: rule91 }
  , CharBlock { start: 43002, length: 1, convRule: rule20 }
  , CharBlock { start: 43003, length: 7, convRule: rule14 }
  , CharBlock { start: 43010, length: 1, convRule: rule92 }
  , CharBlock { start: 43011, length: 3, convRule: rule14 }
  , CharBlock { start: 43014, length: 1, convRule: rule92 }
  , CharBlock { start: 43015, length: 4, convRule: rule14 }
  , CharBlock { start: 43019, length: 1, convRule: rule92 }
  , CharBlock { start: 43020, length: 23, convRule: rule14 }
  , CharBlock { start: 43043, length: 2, convRule: rule124 }
  , CharBlock { start: 43045, length: 2, convRule: rule92 }
  , CharBlock { start: 43047, length: 1, convRule: rule124 }
  , CharBlock { start: 43048, length: 4, convRule: rule13 }
  , CharBlock { start: 43052, length: 1, convRule: rule92 }
  , CharBlock { start: 43056, length: 6, convRule: rule17 }
  , CharBlock { start: 43062, length: 2, convRule: rule13 }
  , CharBlock { start: 43064, length: 1, convRule: rule3 }
  , CharBlock { start: 43065, length: 1, convRule: rule13 }
  , CharBlock { start: 43072, length: 52, convRule: rule14 }
  , CharBlock { start: 43124, length: 4, convRule: rule2 }
  , CharBlock { start: 43136, length: 2, convRule: rule124 }
  , CharBlock { start: 43138, length: 50, convRule: rule14 }
  , CharBlock { start: 43188, length: 16, convRule: rule124 }
  , CharBlock { start: 43204, length: 2, convRule: rule92 }
  , CharBlock { start: 43214, length: 2, convRule: rule2 }
  , CharBlock { start: 43216, length: 10, convRule: rule8 }
  , CharBlock { start: 43232, length: 18, convRule: rule92 }
  , CharBlock { start: 43250, length: 6, convRule: rule14 }
  , CharBlock { start: 43256, length: 3, convRule: rule2 }
  , CharBlock { start: 43259, length: 1, convRule: rule14 }
  , CharBlock { start: 43260, length: 1, convRule: rule2 }
  , CharBlock { start: 43261, length: 2, convRule: rule14 }
  , CharBlock { start: 43263, length: 1, convRule: rule92 }
  , CharBlock { start: 43264, length: 10, convRule: rule8 }
  , CharBlock { start: 43274, length: 28, convRule: rule14 }
  , CharBlock { start: 43302, length: 8, convRule: rule92 }
  , CharBlock { start: 43310, length: 2, convRule: rule2 }
  , CharBlock { start: 43312, length: 23, convRule: rule14 }
  , CharBlock { start: 43335, length: 11, convRule: rule92 }
  , CharBlock { start: 43346, length: 2, convRule: rule124 }
  , CharBlock { start: 43359, length: 1, convRule: rule2 }
  , CharBlock { start: 43360, length: 29, convRule: rule14 }
  , CharBlock { start: 43392, length: 3, convRule: rule92 }
  , CharBlock { start: 43395, length: 1, convRule: rule124 }
  , CharBlock { start: 43396, length: 47, convRule: rule14 }
  , CharBlock { start: 43443, length: 1, convRule: rule92 }
  , CharBlock { start: 43444, length: 2, convRule: rule124 }
  , CharBlock { start: 43446, length: 4, convRule: rule92 }
  , CharBlock { start: 43450, length: 2, convRule: rule124 }
  , CharBlock { start: 43452, length: 2, convRule: rule92 }
  , CharBlock { start: 43454, length: 3, convRule: rule124 }
  , CharBlock { start: 43457, length: 13, convRule: rule2 }
  , CharBlock { start: 43471, length: 1, convRule: rule91 }
  , CharBlock { start: 43472, length: 10, convRule: rule8 }
  , CharBlock { start: 43486, length: 2, convRule: rule2 }
  , CharBlock { start: 43488, length: 5, convRule: rule14 }
  , CharBlock { start: 43493, length: 1, convRule: rule92 }
  , CharBlock { start: 43494, length: 1, convRule: rule91 }
  , CharBlock { start: 43495, length: 9, convRule: rule14 }
  , CharBlock { start: 43504, length: 10, convRule: rule8 }
  , CharBlock { start: 43514, length: 5, convRule: rule14 }
  , CharBlock { start: 43520, length: 41, convRule: rule14 }
  , CharBlock { start: 43561, length: 6, convRule: rule92 }
  , CharBlock { start: 43567, length: 2, convRule: rule124 }
  , CharBlock { start: 43569, length: 2, convRule: rule92 }
  , CharBlock { start: 43571, length: 2, convRule: rule124 }
  , CharBlock { start: 43573, length: 2, convRule: rule92 }
  , CharBlock { start: 43584, length: 3, convRule: rule14 }
  , CharBlock { start: 43587, length: 1, convRule: rule92 }
  , CharBlock { start: 43588, length: 8, convRule: rule14 }
  , CharBlock { start: 43596, length: 1, convRule: rule92 }
  , CharBlock { start: 43597, length: 1, convRule: rule124 }
  , CharBlock { start: 43600, length: 10, convRule: rule8 }
  , CharBlock { start: 43612, length: 4, convRule: rule2 }
  , CharBlock { start: 43616, length: 16, convRule: rule14 }
  , CharBlock { start: 43632, length: 1, convRule: rule91 }
  , CharBlock { start: 43633, length: 6, convRule: rule14 }
  , CharBlock { start: 43639, length: 3, convRule: rule13 }
  , CharBlock { start: 43642, length: 1, convRule: rule14 }
  , CharBlock { start: 43643, length: 1, convRule: rule124 }
  , CharBlock { start: 43644, length: 1, convRule: rule92 }
  , CharBlock { start: 43645, length: 1, convRule: rule124 }
  , CharBlock { start: 43646, length: 50, convRule: rule14 }
  , CharBlock { start: 43696, length: 1, convRule: rule92 }
  , CharBlock { start: 43697, length: 1, convRule: rule14 }
  , CharBlock { start: 43698, length: 3, convRule: rule92 }
  , CharBlock { start: 43701, length: 2, convRule: rule14 }
  , CharBlock { start: 43703, length: 2, convRule: rule92 }
  , CharBlock { start: 43705, length: 5, convRule: rule14 }
  , CharBlock { start: 43710, length: 2, convRule: rule92 }
  , CharBlock { start: 43712, length: 1, convRule: rule14 }
  , CharBlock { start: 43713, length: 1, convRule: rule92 }
  , CharBlock { start: 43714, length: 1, convRule: rule14 }
  , CharBlock { start: 43739, length: 2, convRule: rule14 }
  , CharBlock { start: 43741, length: 1, convRule: rule91 }
  , CharBlock { start: 43742, length: 2, convRule: rule2 }
  , CharBlock { start: 43744, length: 11, convRule: rule14 }
  , CharBlock { start: 43755, length: 1, convRule: rule124 }
  , CharBlock { start: 43756, length: 2, convRule: rule92 }
  , CharBlock { start: 43758, length: 2, convRule: rule124 }
  , CharBlock { start: 43760, length: 2, convRule: rule2 }
  , CharBlock { start: 43762, length: 1, convRule: rule14 }
  , CharBlock { start: 43763, length: 2, convRule: rule91 }
  , CharBlock { start: 43765, length: 1, convRule: rule124 }
  , CharBlock { start: 43766, length: 1, convRule: rule92 }
  , CharBlock { start: 43777, length: 6, convRule: rule14 }
  , CharBlock { start: 43785, length: 6, convRule: rule14 }
  , CharBlock { start: 43793, length: 6, convRule: rule14 }
  , CharBlock { start: 43808, length: 7, convRule: rule14 }
  , CharBlock { start: 43816, length: 7, convRule: rule14 }
  , CharBlock { start: 43824, length: 35, convRule: rule20 }
  , CharBlock { start: 43859, length: 1, convRule: rule197 }
  , CharBlock { start: 43860, length: 7, convRule: rule20 }
  , CharBlock { start: 43867, length: 1, convRule: rule10 }
  , CharBlock { start: 43868, length: 4, convRule: rule91 }
  , CharBlock { start: 43872, length: 9, convRule: rule20 }
  , CharBlock { start: 43881, length: 1, convRule: rule91 }
  , CharBlock { start: 43882, length: 2, convRule: rule10 }
  , CharBlock { start: 43888, length: 80, convRule: rule198 }
  , CharBlock { start: 43968, length: 35, convRule: rule14 }
  , CharBlock { start: 44003, length: 2, convRule: rule124 }
  , CharBlock { start: 44005, length: 1, convRule: rule92 }
  , CharBlock { start: 44006, length: 2, convRule: rule124 }
  , CharBlock { start: 44008, length: 1, convRule: rule92 }
  , CharBlock { start: 44009, length: 2, convRule: rule124 }
  , CharBlock { start: 44011, length: 1, convRule: rule2 }
  , CharBlock { start: 44012, length: 1, convRule: rule124 }
  , CharBlock { start: 44013, length: 1, convRule: rule92 }
  , CharBlock { start: 44016, length: 10, convRule: rule8 }
  , CharBlock { start: 44032, length: 11172, convRule: rule14 }
  , CharBlock { start: 55216, length: 23, convRule: rule14 }
  , CharBlock { start: 55243, length: 49, convRule: rule14 }
  , CharBlock { start: 55296, length: 896, convRule: rule199 }
  , CharBlock { start: 56192, length: 128, convRule: rule199 }
  , CharBlock { start: 56320, length: 1024, convRule: rule199 }
  , CharBlock { start: 57344, length: 6400, convRule: rule200 }
  , CharBlock { start: 63744, length: 366, convRule: rule14 }
  , CharBlock { start: 64112, length: 106, convRule: rule14 }
  , CharBlock { start: 64256, length: 7, convRule: rule20 }
  , CharBlock { start: 64275, length: 5, convRule: rule20 }
  , CharBlock { start: 64285, length: 1, convRule: rule14 }
  , CharBlock { start: 64286, length: 1, convRule: rule92 }
  , CharBlock { start: 64287, length: 10, convRule: rule14 }
  , CharBlock { start: 64297, length: 1, convRule: rule6 }
  , CharBlock { start: 64298, length: 13, convRule: rule14 }
  , CharBlock { start: 64312, length: 5, convRule: rule14 }
  , CharBlock { start: 64318, length: 1, convRule: rule14 }
  , CharBlock { start: 64320, length: 2, convRule: rule14 }
  , CharBlock { start: 64323, length: 2, convRule: rule14 }
  , CharBlock { start: 64326, length: 108, convRule: rule14 }
  , CharBlock { start: 64434, length: 16, convRule: rule10 }
  , CharBlock { start: 64467, length: 363, convRule: rule14 }
  , CharBlock { start: 64830, length: 1, convRule: rule5 }
  , CharBlock { start: 64831, length: 1, convRule: rule4 }
  , CharBlock { start: 64848, length: 64, convRule: rule14 }
  , CharBlock { start: 64914, length: 54, convRule: rule14 }
  , CharBlock { start: 65008, length: 12, convRule: rule14 }
  , CharBlock { start: 65020, length: 1, convRule: rule3 }
  , CharBlock { start: 65021, length: 1, convRule: rule13 }
  , CharBlock { start: 65024, length: 16, convRule: rule92 }
  , CharBlock { start: 65040, length: 7, convRule: rule2 }
  , CharBlock { start: 65047, length: 1, convRule: rule4 }
  , CharBlock { start: 65048, length: 1, convRule: rule5 }
  , CharBlock { start: 65049, length: 1, convRule: rule2 }
  , CharBlock { start: 65056, length: 16, convRule: rule92 }
  , CharBlock { start: 65072, length: 1, convRule: rule2 }
  , CharBlock { start: 65073, length: 2, convRule: rule7 }
  , CharBlock { start: 65075, length: 2, convRule: rule11 }
  , CharBlock { start: 65077, length: 1, convRule: rule4 }
  , CharBlock { start: 65078, length: 1, convRule: rule5 }
  , CharBlock { start: 65079, length: 1, convRule: rule4 }
  , CharBlock { start: 65080, length: 1, convRule: rule5 }
  , CharBlock { start: 65081, length: 1, convRule: rule4 }
  , CharBlock { start: 65082, length: 1, convRule: rule5 }
  , CharBlock { start: 65083, length: 1, convRule: rule4 }
  , CharBlock { start: 65084, length: 1, convRule: rule5 }
  , CharBlock { start: 65085, length: 1, convRule: rule4 }
  , CharBlock { start: 65086, length: 1, convRule: rule5 }
  , CharBlock { start: 65087, length: 1, convRule: rule4 }
  , CharBlock { start: 65088, length: 1, convRule: rule5 }
  , CharBlock { start: 65089, length: 1, convRule: rule4 }
  , CharBlock { start: 65090, length: 1, convRule: rule5 }
  , CharBlock { start: 65091, length: 1, convRule: rule4 }
  , CharBlock { start: 65092, length: 1, convRule: rule5 }
  , CharBlock { start: 65093, length: 2, convRule: rule2 }
  , CharBlock { start: 65095, length: 1, convRule: rule4 }
  , CharBlock { start: 65096, length: 1, convRule: rule5 }
  , CharBlock { start: 65097, length: 4, convRule: rule2 }
  , CharBlock { start: 65101, length: 3, convRule: rule11 }
  , CharBlock { start: 65104, length: 3, convRule: rule2 }
  , CharBlock { start: 65108, length: 4, convRule: rule2 }
  , CharBlock { start: 65112, length: 1, convRule: rule7 }
  , CharBlock { start: 65113, length: 1, convRule: rule4 }
  , CharBlock { start: 65114, length: 1, convRule: rule5 }
  , CharBlock { start: 65115, length: 1, convRule: rule4 }
  , CharBlock { start: 65116, length: 1, convRule: rule5 }
  , CharBlock { start: 65117, length: 1, convRule: rule4 }
  , CharBlock { start: 65118, length: 1, convRule: rule5 }
  , CharBlock { start: 65119, length: 3, convRule: rule2 }
  , CharBlock { start: 65122, length: 1, convRule: rule6 }
  , CharBlock { start: 65123, length: 1, convRule: rule7 }
  , CharBlock { start: 65124, length: 3, convRule: rule6 }
  , CharBlock { start: 65128, length: 1, convRule: rule2 }
  , CharBlock { start: 65129, length: 1, convRule: rule3 }
  , CharBlock { start: 65130, length: 2, convRule: rule2 }
  , CharBlock { start: 65136, length: 5, convRule: rule14 }
  , CharBlock { start: 65142, length: 135, convRule: rule14 }
  , CharBlock { start: 65279, length: 1, convRule: rule16 }
  , CharBlock { start: 65281, length: 3, convRule: rule2 }
  , CharBlock { start: 65284, length: 1, convRule: rule3 }
  , CharBlock { start: 65285, length: 3, convRule: rule2 }
  , CharBlock { start: 65288, length: 1, convRule: rule4 }
  , CharBlock { start: 65289, length: 1, convRule: rule5 }
  , CharBlock { start: 65290, length: 1, convRule: rule2 }
  , CharBlock { start: 65291, length: 1, convRule: rule6 }
  , CharBlock { start: 65292, length: 1, convRule: rule2 }
  , CharBlock { start: 65293, length: 1, convRule: rule7 }
  , CharBlock { start: 65294, length: 2, convRule: rule2 }
  , CharBlock { start: 65296, length: 10, convRule: rule8 }
  , CharBlock { start: 65306, length: 2, convRule: rule2 }
  , CharBlock { start: 65308, length: 3, convRule: rule6 }
  , CharBlock { start: 65311, length: 2, convRule: rule2 }
  , CharBlock { start: 65313, length: 26, convRule: rule9 }
  , CharBlock { start: 65339, length: 1, convRule: rule4 }
  , CharBlock { start: 65340, length: 1, convRule: rule2 }
  , CharBlock { start: 65341, length: 1, convRule: rule5 }
  , CharBlock { start: 65342, length: 1, convRule: rule10 }
  , CharBlock { start: 65343, length: 1, convRule: rule11 }
  , CharBlock { start: 65344, length: 1, convRule: rule10 }
  , CharBlock { start: 65345, length: 26, convRule: rule12 }
  , CharBlock { start: 65371, length: 1, convRule: rule4 }
  , CharBlock { start: 65372, length: 1, convRule: rule6 }
  , CharBlock { start: 65373, length: 1, convRule: rule5 }
  , CharBlock { start: 65374, length: 1, convRule: rule6 }
  , CharBlock { start: 65375, length: 1, convRule: rule4 }
  , CharBlock { start: 65376, length: 1, convRule: rule5 }
  , CharBlock { start: 65377, length: 1, convRule: rule2 }
  , CharBlock { start: 65378, length: 1, convRule: rule4 }
  , CharBlock { start: 65379, length: 1, convRule: rule5 }
  , CharBlock { start: 65380, length: 2, convRule: rule2 }
  , CharBlock { start: 65382, length: 10, convRule: rule14 }
  , CharBlock { start: 65392, length: 1, convRule: rule91 }
  , CharBlock { start: 65393, length: 45, convRule: rule14 }
  , CharBlock { start: 65438, length: 2, convRule: rule91 }
  , CharBlock { start: 65440, length: 31, convRule: rule14 }
  , CharBlock { start: 65474, length: 6, convRule: rule14 }
  , CharBlock { start: 65482, length: 6, convRule: rule14 }
  , CharBlock { start: 65490, length: 6, convRule: rule14 }
  , CharBlock { start: 65498, length: 3, convRule: rule14 }
  , CharBlock { start: 65504, length: 2, convRule: rule3 }
  , CharBlock { start: 65506, length: 1, convRule: rule6 }
  , CharBlock { start: 65507, length: 1, convRule: rule10 }
  , CharBlock { start: 65508, length: 1, convRule: rule13 }
  , CharBlock { start: 65509, length: 2, convRule: rule3 }
  , CharBlock { start: 65512, length: 1, convRule: rule13 }
  , CharBlock { start: 65513, length: 4, convRule: rule6 }
  , CharBlock { start: 65517, length: 2, convRule: rule13 }
  , CharBlock { start: 65529, length: 3, convRule: rule16 }
  , CharBlock { start: 65532, length: 2, convRule: rule13 }
  , CharBlock { start: 65536, length: 12, convRule: rule14 }
  , CharBlock { start: 65549, length: 26, convRule: rule14 }
  , CharBlock { start: 65576, length: 19, convRule: rule14 }
  , CharBlock { start: 65596, length: 2, convRule: rule14 }
  , CharBlock { start: 65599, length: 15, convRule: rule14 }
  , CharBlock { start: 65616, length: 14, convRule: rule14 }
  , CharBlock { start: 65664, length: 123, convRule: rule14 }
  , CharBlock { start: 65792, length: 3, convRule: rule2 }
  , CharBlock { start: 65799, length: 45, convRule: rule17 }
  , CharBlock { start: 65847, length: 9, convRule: rule13 }
  , CharBlock { start: 65856, length: 53, convRule: rule128 }
  , CharBlock { start: 65909, length: 4, convRule: rule17 }
  , CharBlock { start: 65913, length: 17, convRule: rule13 }
  , CharBlock { start: 65930, length: 2, convRule: rule17 }
  , CharBlock { start: 65932, length: 3, convRule: rule13 }
  , CharBlock { start: 65936, length: 13, convRule: rule13 }
  , CharBlock { start: 65952, length: 1, convRule: rule13 }
  , CharBlock { start: 66000, length: 45, convRule: rule13 }
  , CharBlock { start: 66045, length: 1, convRule: rule92 }
  , CharBlock { start: 66176, length: 29, convRule: rule14 }
  , CharBlock { start: 66208, length: 49, convRule: rule14 }
  , CharBlock { start: 66272, length: 1, convRule: rule92 }
  , CharBlock { start: 66273, length: 27, convRule: rule17 }
  , CharBlock { start: 66304, length: 32, convRule: rule14 }
  , CharBlock { start: 66336, length: 4, convRule: rule17 }
  , CharBlock { start: 66349, length: 20, convRule: rule14 }
  , CharBlock { start: 66369, length: 1, convRule: rule128 }
  , CharBlock { start: 66370, length: 8, convRule: rule14 }
  , CharBlock { start: 66378, length: 1, convRule: rule128 }
  , CharBlock { start: 66384, length: 38, convRule: rule14 }
  , CharBlock { start: 66422, length: 5, convRule: rule92 }
  , CharBlock { start: 66432, length: 30, convRule: rule14 }
  , CharBlock { start: 66463, length: 1, convRule: rule2 }
  , CharBlock { start: 66464, length: 36, convRule: rule14 }
  , CharBlock { start: 66504, length: 8, convRule: rule14 }
  , CharBlock { start: 66512, length: 1, convRule: rule2 }
  , CharBlock { start: 66513, length: 5, convRule: rule128 }
  , CharBlock { start: 66560, length: 40, convRule: rule201 }
  , CharBlock { start: 66600, length: 40, convRule: rule202 }
  , CharBlock { start: 66640, length: 78, convRule: rule14 }
  , CharBlock { start: 66720, length: 10, convRule: rule8 }
  , CharBlock { start: 66736, length: 36, convRule: rule201 }
  , CharBlock { start: 66776, length: 36, convRule: rule202 }
  , CharBlock { start: 66816, length: 40, convRule: rule14 }
  , CharBlock { start: 66864, length: 52, convRule: rule14 }
  , CharBlock { start: 66927, length: 1, convRule: rule2 }
  , CharBlock { start: 67072, length: 311, convRule: rule14 }
  , CharBlock { start: 67392, length: 22, convRule: rule14 }
  , CharBlock { start: 67424, length: 8, convRule: rule14 }
  , CharBlock { start: 67584, length: 6, convRule: rule14 }
  , CharBlock { start: 67592, length: 1, convRule: rule14 }
  , CharBlock { start: 67594, length: 44, convRule: rule14 }
  , CharBlock { start: 67639, length: 2, convRule: rule14 }
  , CharBlock { start: 67644, length: 1, convRule: rule14 }
  , CharBlock { start: 67647, length: 23, convRule: rule14 }
  , CharBlock { start: 67671, length: 1, convRule: rule2 }
  , CharBlock { start: 67672, length: 8, convRule: rule17 }
  , CharBlock { start: 67680, length: 23, convRule: rule14 }
  , CharBlock { start: 67703, length: 2, convRule: rule13 }
  , CharBlock { start: 67705, length: 7, convRule: rule17 }
  , CharBlock { start: 67712, length: 31, convRule: rule14 }
  , CharBlock { start: 67751, length: 9, convRule: rule17 }
  , CharBlock { start: 67808, length: 19, convRule: rule14 }
  , CharBlock { start: 67828, length: 2, convRule: rule14 }
  , CharBlock { start: 67835, length: 5, convRule: rule17 }
  , CharBlock { start: 67840, length: 22, convRule: rule14 }
  , CharBlock { start: 67862, length: 6, convRule: rule17 }
  , CharBlock { start: 67871, length: 1, convRule: rule2 }
  , CharBlock { start: 67872, length: 26, convRule: rule14 }
  , CharBlock { start: 67903, length: 1, convRule: rule2 }
  , CharBlock { start: 67968, length: 56, convRule: rule14 }
  , CharBlock { start: 68028, length: 2, convRule: rule17 }
  , CharBlock { start: 68030, length: 2, convRule: rule14 }
  , CharBlock { start: 68032, length: 16, convRule: rule17 }
  , CharBlock { start: 68050, length: 46, convRule: rule17 }
  , CharBlock { start: 68096, length: 1, convRule: rule14 }
  , CharBlock { start: 68097, length: 3, convRule: rule92 }
  , CharBlock { start: 68101, length: 2, convRule: rule92 }
  , CharBlock { start: 68108, length: 4, convRule: rule92 }
  , CharBlock { start: 68112, length: 4, convRule: rule14 }
  , CharBlock { start: 68117, length: 3, convRule: rule14 }
  , CharBlock { start: 68121, length: 29, convRule: rule14 }
  , CharBlock { start: 68152, length: 3, convRule: rule92 }
  , CharBlock { start: 68159, length: 1, convRule: rule92 }
  , CharBlock { start: 68160, length: 9, convRule: rule17 }
  , CharBlock { start: 68176, length: 9, convRule: rule2 }
  , CharBlock { start: 68192, length: 29, convRule: rule14 }
  , CharBlock { start: 68221, length: 2, convRule: rule17 }
  , CharBlock { start: 68223, length: 1, convRule: rule2 }
  , CharBlock { start: 68224, length: 29, convRule: rule14 }
  , CharBlock { start: 68253, length: 3, convRule: rule17 }
  , CharBlock { start: 68288, length: 8, convRule: rule14 }
  , CharBlock { start: 68296, length: 1, convRule: rule13 }
  , CharBlock { start: 68297, length: 28, convRule: rule14 }
  , CharBlock { start: 68325, length: 2, convRule: rule92 }
  , CharBlock { start: 68331, length: 5, convRule: rule17 }
  , CharBlock { start: 68336, length: 7, convRule: rule2 }
  , CharBlock { start: 68352, length: 54, convRule: rule14 }
  , CharBlock { start: 68409, length: 7, convRule: rule2 }
  , CharBlock { start: 68416, length: 22, convRule: rule14 }
  , CharBlock { start: 68440, length: 8, convRule: rule17 }
  , CharBlock { start: 68448, length: 19, convRule: rule14 }
  , CharBlock { start: 68472, length: 8, convRule: rule17 }
  , CharBlock { start: 68480, length: 18, convRule: rule14 }
  , CharBlock { start: 68505, length: 4, convRule: rule2 }
  , CharBlock { start: 68521, length: 7, convRule: rule17 }
  , CharBlock { start: 68608, length: 73, convRule: rule14 }
  , CharBlock { start: 68736, length: 51, convRule: rule97 }
  , CharBlock { start: 68800, length: 51, convRule: rule102 }
  , CharBlock { start: 68858, length: 6, convRule: rule17 }
  , CharBlock { start: 68864, length: 36, convRule: rule14 }
  , CharBlock { start: 68900, length: 4, convRule: rule92 }
  , CharBlock { start: 68912, length: 10, convRule: rule8 }
  , CharBlock { start: 69216, length: 31, convRule: rule17 }
  , CharBlock { start: 69248, length: 42, convRule: rule14 }
  , CharBlock { start: 69291, length: 2, convRule: rule92 }
  , CharBlock { start: 69293, length: 1, convRule: rule7 }
  , CharBlock { start: 69296, length: 2, convRule: rule14 }
  , CharBlock { start: 69376, length: 29, convRule: rule14 }
  , CharBlock { start: 69405, length: 10, convRule: rule17 }
  , CharBlock { start: 69415, length: 1, convRule: rule14 }
  , CharBlock { start: 69424, length: 22, convRule: rule14 }
  , CharBlock { start: 69446, length: 11, convRule: rule92 }
  , CharBlock { start: 69457, length: 4, convRule: rule17 }
  , CharBlock { start: 69461, length: 5, convRule: rule2 }
  , CharBlock { start: 69552, length: 21, convRule: rule14 }
  , CharBlock { start: 69573, length: 7, convRule: rule17 }
  , CharBlock { start: 69600, length: 23, convRule: rule14 }
  , CharBlock { start: 69632, length: 1, convRule: rule124 }
  , CharBlock { start: 69633, length: 1, convRule: rule92 }
  , CharBlock { start: 69634, length: 1, convRule: rule124 }
  , CharBlock { start: 69635, length: 53, convRule: rule14 }
  , CharBlock { start: 69688, length: 15, convRule: rule92 }
  , CharBlock { start: 69703, length: 7, convRule: rule2 }
  , CharBlock { start: 69714, length: 20, convRule: rule17 }
  , CharBlock { start: 69734, length: 10, convRule: rule8 }
  , CharBlock { start: 69759, length: 3, convRule: rule92 }
  , CharBlock { start: 69762, length: 1, convRule: rule124 }
  , CharBlock { start: 69763, length: 45, convRule: rule14 }
  , CharBlock { start: 69808, length: 3, convRule: rule124 }
  , CharBlock { start: 69811, length: 4, convRule: rule92 }
  , CharBlock { start: 69815, length: 2, convRule: rule124 }
  , CharBlock { start: 69817, length: 2, convRule: rule92 }
  , CharBlock { start: 69819, length: 2, convRule: rule2 }
  , CharBlock { start: 69821, length: 1, convRule: rule16 }
  , CharBlock { start: 69822, length: 4, convRule: rule2 }
  , CharBlock { start: 69837, length: 1, convRule: rule16 }
  , CharBlock { start: 69840, length: 25, convRule: rule14 }
  , CharBlock { start: 69872, length: 10, convRule: rule8 }
  , CharBlock { start: 69888, length: 3, convRule: rule92 }
  , CharBlock { start: 69891, length: 36, convRule: rule14 }
  , CharBlock { start: 69927, length: 5, convRule: rule92 }
  , CharBlock { start: 69932, length: 1, convRule: rule124 }
  , CharBlock { start: 69933, length: 8, convRule: rule92 }
  , CharBlock { start: 69942, length: 10, convRule: rule8 }
  , CharBlock { start: 69952, length: 4, convRule: rule2 }
  , CharBlock { start: 69956, length: 1, convRule: rule14 }
  , CharBlock { start: 69957, length: 2, convRule: rule124 }
  , CharBlock { start: 69959, length: 1, convRule: rule14 }
  , CharBlock { start: 69968, length: 35, convRule: rule14 }
  , CharBlock { start: 70003, length: 1, convRule: rule92 }
  , CharBlock { start: 70004, length: 2, convRule: rule2 }
  , CharBlock { start: 70006, length: 1, convRule: rule14 }
  , CharBlock { start: 70016, length: 2, convRule: rule92 }
  , CharBlock { start: 70018, length: 1, convRule: rule124 }
  , CharBlock { start: 70019, length: 48, convRule: rule14 }
  , CharBlock { start: 70067, length: 3, convRule: rule124 }
  , CharBlock { start: 70070, length: 9, convRule: rule92 }
  , CharBlock { start: 70079, length: 2, convRule: rule124 }
  , CharBlock { start: 70081, length: 4, convRule: rule14 }
  , CharBlock { start: 70085, length: 4, convRule: rule2 }
  , CharBlock { start: 70089, length: 4, convRule: rule92 }
  , CharBlock { start: 70093, length: 1, convRule: rule2 }
  , CharBlock { start: 70094, length: 1, convRule: rule124 }
  , CharBlock { start: 70095, length: 1, convRule: rule92 }
  , CharBlock { start: 70096, length: 10, convRule: rule8 }
  , CharBlock { start: 70106, length: 1, convRule: rule14 }
  , CharBlock { start: 70107, length: 1, convRule: rule2 }
  , CharBlock { start: 70108, length: 1, convRule: rule14 }
  , CharBlock { start: 70109, length: 3, convRule: rule2 }
  , CharBlock { start: 70113, length: 20, convRule: rule17 }
  , CharBlock { start: 70144, length: 18, convRule: rule14 }
  , CharBlock { start: 70163, length: 25, convRule: rule14 }
  , CharBlock { start: 70188, length: 3, convRule: rule124 }
  , CharBlock { start: 70191, length: 3, convRule: rule92 }
  , CharBlock { start: 70194, length: 2, convRule: rule124 }
  , CharBlock { start: 70196, length: 1, convRule: rule92 }
  , CharBlock { start: 70197, length: 1, convRule: rule124 }
  , CharBlock { start: 70198, length: 2, convRule: rule92 }
  , CharBlock { start: 70200, length: 6, convRule: rule2 }
  , CharBlock { start: 70206, length: 1, convRule: rule92 }
  , CharBlock { start: 70272, length: 7, convRule: rule14 }
  , CharBlock { start: 70280, length: 1, convRule: rule14 }
  , CharBlock { start: 70282, length: 4, convRule: rule14 }
  , CharBlock { start: 70287, length: 15, convRule: rule14 }
  , CharBlock { start: 70303, length: 10, convRule: rule14 }
  , CharBlock { start: 70313, length: 1, convRule: rule2 }
  , CharBlock { start: 70320, length: 47, convRule: rule14 }
  , CharBlock { start: 70367, length: 1, convRule: rule92 }
  , CharBlock { start: 70368, length: 3, convRule: rule124 }
  , CharBlock { start: 70371, length: 8, convRule: rule92 }
  , CharBlock { start: 70384, length: 10, convRule: rule8 }
  , CharBlock { start: 70400, length: 2, convRule: rule92 }
  , CharBlock { start: 70402, length: 2, convRule: rule124 }
  , CharBlock { start: 70405, length: 8, convRule: rule14 }
  , CharBlock { start: 70415, length: 2, convRule: rule14 }
  , CharBlock { start: 70419, length: 22, convRule: rule14 }
  , CharBlock { start: 70442, length: 7, convRule: rule14 }
  , CharBlock { start: 70450, length: 2, convRule: rule14 }
  , CharBlock { start: 70453, length: 5, convRule: rule14 }
  , CharBlock { start: 70459, length: 2, convRule: rule92 }
  , CharBlock { start: 70461, length: 1, convRule: rule14 }
  , CharBlock { start: 70462, length: 2, convRule: rule124 }
  , CharBlock { start: 70464, length: 1, convRule: rule92 }
  , CharBlock { start: 70465, length: 4, convRule: rule124 }
  , CharBlock { start: 70471, length: 2, convRule: rule124 }
  , CharBlock { start: 70475, length: 3, convRule: rule124 }
  , CharBlock { start: 70480, length: 1, convRule: rule14 }
  , CharBlock { start: 70487, length: 1, convRule: rule124 }
  , CharBlock { start: 70493, length: 5, convRule: rule14 }
  , CharBlock { start: 70498, length: 2, convRule: rule124 }
  , CharBlock { start: 70502, length: 7, convRule: rule92 }
  , CharBlock { start: 70512, length: 5, convRule: rule92 }
  , CharBlock { start: 70656, length: 53, convRule: rule14 }
  , CharBlock { start: 70709, length: 3, convRule: rule124 }
  , CharBlock { start: 70712, length: 8, convRule: rule92 }
  , CharBlock { start: 70720, length: 2, convRule: rule124 }
  , CharBlock { start: 70722, length: 3, convRule: rule92 }
  , CharBlock { start: 70725, length: 1, convRule: rule124 }
  , CharBlock { start: 70726, length: 1, convRule: rule92 }
  , CharBlock { start: 70727, length: 4, convRule: rule14 }
  , CharBlock { start: 70731, length: 5, convRule: rule2 }
  , CharBlock { start: 70736, length: 10, convRule: rule8 }
  , CharBlock { start: 70746, length: 2, convRule: rule2 }
  , CharBlock { start: 70749, length: 1, convRule: rule2 }
  , CharBlock { start: 70750, length: 1, convRule: rule92 }
  , CharBlock { start: 70751, length: 3, convRule: rule14 }
  , CharBlock { start: 70784, length: 48, convRule: rule14 }
  , CharBlock { start: 70832, length: 3, convRule: rule124 }
  , CharBlock { start: 70835, length: 6, convRule: rule92 }
  , CharBlock { start: 70841, length: 1, convRule: rule124 }
  , CharBlock { start: 70842, length: 1, convRule: rule92 }
  , CharBlock { start: 70843, length: 4, convRule: rule124 }
  , CharBlock { start: 70847, length: 2, convRule: rule92 }
  , CharBlock { start: 70849, length: 1, convRule: rule124 }
  , CharBlock { start: 70850, length: 2, convRule: rule92 }
  , CharBlock { start: 70852, length: 2, convRule: rule14 }
  , CharBlock { start: 70854, length: 1, convRule: rule2 }
  , CharBlock { start: 70855, length: 1, convRule: rule14 }
  , CharBlock { start: 70864, length: 10, convRule: rule8 }
  , CharBlock { start: 71040, length: 47, convRule: rule14 }
  , CharBlock { start: 71087, length: 3, convRule: rule124 }
  , CharBlock { start: 71090, length: 4, convRule: rule92 }
  , CharBlock { start: 71096, length: 4, convRule: rule124 }
  , CharBlock { start: 71100, length: 2, convRule: rule92 }
  , CharBlock { start: 71102, length: 1, convRule: rule124 }
  , CharBlock { start: 71103, length: 2, convRule: rule92 }
  , CharBlock { start: 71105, length: 23, convRule: rule2 }
  , CharBlock { start: 71128, length: 4, convRule: rule14 }
  , CharBlock { start: 71132, length: 2, convRule: rule92 }
  , CharBlock { start: 71168, length: 48, convRule: rule14 }
  , CharBlock { start: 71216, length: 3, convRule: rule124 }
  , CharBlock { start: 71219, length: 8, convRule: rule92 }
  , CharBlock { start: 71227, length: 2, convRule: rule124 }
  , CharBlock { start: 71229, length: 1, convRule: rule92 }
  , CharBlock { start: 71230, length: 1, convRule: rule124 }
  , CharBlock { start: 71231, length: 2, convRule: rule92 }
  , CharBlock { start: 71233, length: 3, convRule: rule2 }
  , CharBlock { start: 71236, length: 1, convRule: rule14 }
  , CharBlock { start: 71248, length: 10, convRule: rule8 }
  , CharBlock { start: 71264, length: 13, convRule: rule2 }
  , CharBlock { start: 71296, length: 43, convRule: rule14 }
  , CharBlock { start: 71339, length: 1, convRule: rule92 }
  , CharBlock { start: 71340, length: 1, convRule: rule124 }
  , CharBlock { start: 71341, length: 1, convRule: rule92 }
  , CharBlock { start: 71342, length: 2, convRule: rule124 }
  , CharBlock { start: 71344, length: 6, convRule: rule92 }
  , CharBlock { start: 71350, length: 1, convRule: rule124 }
  , CharBlock { start: 71351, length: 1, convRule: rule92 }
  , CharBlock { start: 71352, length: 1, convRule: rule14 }
  , CharBlock { start: 71360, length: 10, convRule: rule8 }
  , CharBlock { start: 71424, length: 27, convRule: rule14 }
  , CharBlock { start: 71453, length: 3, convRule: rule92 }
  , CharBlock { start: 71456, length: 2, convRule: rule124 }
  , CharBlock { start: 71458, length: 4, convRule: rule92 }
  , CharBlock { start: 71462, length: 1, convRule: rule124 }
  , CharBlock { start: 71463, length: 5, convRule: rule92 }
  , CharBlock { start: 71472, length: 10, convRule: rule8 }
  , CharBlock { start: 71482, length: 2, convRule: rule17 }
  , CharBlock { start: 71484, length: 3, convRule: rule2 }
  , CharBlock { start: 71487, length: 1, convRule: rule13 }
  , CharBlock { start: 71680, length: 44, convRule: rule14 }
  , CharBlock { start: 71724, length: 3, convRule: rule124 }
  , CharBlock { start: 71727, length: 9, convRule: rule92 }
  , CharBlock { start: 71736, length: 1, convRule: rule124 }
  , CharBlock { start: 71737, length: 2, convRule: rule92 }
  , CharBlock { start: 71739, length: 1, convRule: rule2 }
  , CharBlock { start: 71840, length: 32, convRule: rule9 }
  , CharBlock { start: 71872, length: 32, convRule: rule12 }
  , CharBlock { start: 71904, length: 10, convRule: rule8 }
  , CharBlock { start: 71914, length: 9, convRule: rule17 }
  , CharBlock { start: 71935, length: 8, convRule: rule14 }
  , CharBlock { start: 71945, length: 1, convRule: rule14 }
  , CharBlock { start: 71948, length: 8, convRule: rule14 }
  , CharBlock { start: 71957, length: 2, convRule: rule14 }
  , CharBlock { start: 71960, length: 24, convRule: rule14 }
  , CharBlock { start: 71984, length: 6, convRule: rule124 }
  , CharBlock { start: 71991, length: 2, convRule: rule124 }
  , CharBlock { start: 71995, length: 2, convRule: rule92 }
  , CharBlock { start: 71997, length: 1, convRule: rule124 }
  , CharBlock { start: 71998, length: 1, convRule: rule92 }
  , CharBlock { start: 71999, length: 1, convRule: rule14 }
  , CharBlock { start: 72000, length: 1, convRule: rule124 }
  , CharBlock { start: 72001, length: 1, convRule: rule14 }
  , CharBlock { start: 72002, length: 1, convRule: rule124 }
  , CharBlock { start: 72003, length: 1, convRule: rule92 }
  , CharBlock { start: 72004, length: 3, convRule: rule2 }
  , CharBlock { start: 72016, length: 10, convRule: rule8 }
  , CharBlock { start: 72096, length: 8, convRule: rule14 }
  , CharBlock { start: 72106, length: 39, convRule: rule14 }
  , CharBlock { start: 72145, length: 3, convRule: rule124 }
  , CharBlock { start: 72148, length: 4, convRule: rule92 }
  , CharBlock { start: 72154, length: 2, convRule: rule92 }
  , CharBlock { start: 72156, length: 4, convRule: rule124 }
  , CharBlock { start: 72160, length: 1, convRule: rule92 }
  , CharBlock { start: 72161, length: 1, convRule: rule14 }
  , CharBlock { start: 72162, length: 1, convRule: rule2 }
  , CharBlock { start: 72163, length: 1, convRule: rule14 }
  , CharBlock { start: 72164, length: 1, convRule: rule124 }
  , CharBlock { start: 72192, length: 1, convRule: rule14 }
  , CharBlock { start: 72193, length: 10, convRule: rule92 }
  , CharBlock { start: 72203, length: 40, convRule: rule14 }
  , CharBlock { start: 72243, length: 6, convRule: rule92 }
  , CharBlock { start: 72249, length: 1, convRule: rule124 }
  , CharBlock { start: 72250, length: 1, convRule: rule14 }
  , CharBlock { start: 72251, length: 4, convRule: rule92 }
  , CharBlock { start: 72255, length: 8, convRule: rule2 }
  , CharBlock { start: 72263, length: 1, convRule: rule92 }
  , CharBlock { start: 72272, length: 1, convRule: rule14 }
  , CharBlock { start: 72273, length: 6, convRule: rule92 }
  , CharBlock { start: 72279, length: 2, convRule: rule124 }
  , CharBlock { start: 72281, length: 3, convRule: rule92 }
  , CharBlock { start: 72284, length: 46, convRule: rule14 }
  , CharBlock { start: 72330, length: 13, convRule: rule92 }
  , CharBlock { start: 72343, length: 1, convRule: rule124 }
  , CharBlock { start: 72344, length: 2, convRule: rule92 }
  , CharBlock { start: 72346, length: 3, convRule: rule2 }
  , CharBlock { start: 72349, length: 1, convRule: rule14 }
  , CharBlock { start: 72350, length: 5, convRule: rule2 }
  , CharBlock { start: 72384, length: 57, convRule: rule14 }
  , CharBlock { start: 72704, length: 9, convRule: rule14 }
  , CharBlock { start: 72714, length: 37, convRule: rule14 }
  , CharBlock { start: 72751, length: 1, convRule: rule124 }
  , CharBlock { start: 72752, length: 7, convRule: rule92 }
  , CharBlock { start: 72760, length: 6, convRule: rule92 }
  , CharBlock { start: 72766, length: 1, convRule: rule124 }
  , CharBlock { start: 72767, length: 1, convRule: rule92 }
  , CharBlock { start: 72768, length: 1, convRule: rule14 }
  , CharBlock { start: 72769, length: 5, convRule: rule2 }
  , CharBlock { start: 72784, length: 10, convRule: rule8 }
  , CharBlock { start: 72794, length: 19, convRule: rule17 }
  , CharBlock { start: 72816, length: 2, convRule: rule2 }
  , CharBlock { start: 72818, length: 30, convRule: rule14 }
  , CharBlock { start: 72850, length: 22, convRule: rule92 }
  , CharBlock { start: 72873, length: 1, convRule: rule124 }
  , CharBlock { start: 72874, length: 7, convRule: rule92 }
  , CharBlock { start: 72881, length: 1, convRule: rule124 }
  , CharBlock { start: 72882, length: 2, convRule: rule92 }
  , CharBlock { start: 72884, length: 1, convRule: rule124 }
  , CharBlock { start: 72885, length: 2, convRule: rule92 }
  , CharBlock { start: 72960, length: 7, convRule: rule14 }
  , CharBlock { start: 72968, length: 2, convRule: rule14 }
  , CharBlock { start: 72971, length: 38, convRule: rule14 }
  , CharBlock { start: 73009, length: 6, convRule: rule92 }
  , CharBlock { start: 73018, length: 1, convRule: rule92 }
  , CharBlock { start: 73020, length: 2, convRule: rule92 }
  , CharBlock { start: 73023, length: 7, convRule: rule92 }
  , CharBlock { start: 73030, length: 1, convRule: rule14 }
  , CharBlock { start: 73031, length: 1, convRule: rule92 }
  , CharBlock { start: 73040, length: 10, convRule: rule8 }
  , CharBlock { start: 73056, length: 6, convRule: rule14 }
  , CharBlock { start: 73063, length: 2, convRule: rule14 }
  , CharBlock { start: 73066, length: 32, convRule: rule14 }
  , CharBlock { start: 73098, length: 5, convRule: rule124 }
  , CharBlock { start: 73104, length: 2, convRule: rule92 }
  , CharBlock { start: 73107, length: 2, convRule: rule124 }
  , CharBlock { start: 73109, length: 1, convRule: rule92 }
  , CharBlock { start: 73110, length: 1, convRule: rule124 }
  , CharBlock { start: 73111, length: 1, convRule: rule92 }
  , CharBlock { start: 73112, length: 1, convRule: rule14 }
  , CharBlock { start: 73120, length: 10, convRule: rule8 }
  , CharBlock { start: 73440, length: 19, convRule: rule14 }
  , CharBlock { start: 73459, length: 2, convRule: rule92 }
  , CharBlock { start: 73461, length: 2, convRule: rule124 }
  , CharBlock { start: 73463, length: 2, convRule: rule2 }
  , CharBlock { start: 73648, length: 1, convRule: rule14 }
  , CharBlock { start: 73664, length: 21, convRule: rule17 }
  , CharBlock { start: 73685, length: 8, convRule: rule13 }
  , CharBlock { start: 73693, length: 4, convRule: rule3 }
  , CharBlock { start: 73697, length: 17, convRule: rule13 }
  , CharBlock { start: 73727, length: 1, convRule: rule2 }
  , CharBlock { start: 73728, length: 922, convRule: rule14 }
  , CharBlock { start: 74752, length: 111, convRule: rule128 }
  , CharBlock { start: 74864, length: 5, convRule: rule2 }
  , CharBlock { start: 74880, length: 196, convRule: rule14 }
  , CharBlock { start: 77824, length: 1071, convRule: rule14 }
  , CharBlock { start: 78896, length: 9, convRule: rule16 }
  , CharBlock { start: 82944, length: 583, convRule: rule14 }
  , CharBlock { start: 92160, length: 569, convRule: rule14 }
  , CharBlock { start: 92736, length: 31, convRule: rule14 }
  , CharBlock { start: 92768, length: 10, convRule: rule8 }
  , CharBlock { start: 92782, length: 2, convRule: rule2 }
  , CharBlock { start: 92880, length: 30, convRule: rule14 }
  , CharBlock { start: 92912, length: 5, convRule: rule92 }
  , CharBlock { start: 92917, length: 1, convRule: rule2 }
  , CharBlock { start: 92928, length: 48, convRule: rule14 }
  , CharBlock { start: 92976, length: 7, convRule: rule92 }
  , CharBlock { start: 92983, length: 5, convRule: rule2 }
  , CharBlock { start: 92988, length: 4, convRule: rule13 }
  , CharBlock { start: 92992, length: 4, convRule: rule91 }
  , CharBlock { start: 92996, length: 1, convRule: rule2 }
  , CharBlock { start: 92997, length: 1, convRule: rule13 }
  , CharBlock { start: 93008, length: 10, convRule: rule8 }
  , CharBlock { start: 93019, length: 7, convRule: rule17 }
  , CharBlock { start: 93027, length: 21, convRule: rule14 }
  , CharBlock { start: 93053, length: 19, convRule: rule14 }
  , CharBlock { start: 93760, length: 32, convRule: rule9 }
  , CharBlock { start: 93792, length: 32, convRule: rule12 }
  , CharBlock { start: 93824, length: 23, convRule: rule17 }
  , CharBlock { start: 93847, length: 4, convRule: rule2 }
  , CharBlock { start: 93952, length: 75, convRule: rule14 }
  , CharBlock { start: 94031, length: 1, convRule: rule92 }
  , CharBlock { start: 94032, length: 1, convRule: rule14 }
  , CharBlock { start: 94033, length: 55, convRule: rule124 }
  , CharBlock { start: 94095, length: 4, convRule: rule92 }
  , CharBlock { start: 94099, length: 13, convRule: rule91 }
  , CharBlock { start: 94176, length: 2, convRule: rule91 }
  , CharBlock { start: 94178, length: 1, convRule: rule2 }
  , CharBlock { start: 94179, length: 1, convRule: rule91 }
  , CharBlock { start: 94180, length: 1, convRule: rule92 }
  , CharBlock { start: 94192, length: 2, convRule: rule124 }
  , CharBlock { start: 94208, length: 6136, convRule: rule14 }
  , CharBlock { start: 100352, length: 1238, convRule: rule14 }
  , CharBlock { start: 101632, length: 9, convRule: rule14 }
  , CharBlock { start: 110592, length: 287, convRule: rule14 }
  , CharBlock { start: 110928, length: 3, convRule: rule14 }
  , CharBlock { start: 110948, length: 4, convRule: rule14 }
  , CharBlock { start: 110960, length: 396, convRule: rule14 }
  , CharBlock { start: 113664, length: 107, convRule: rule14 }
  , CharBlock { start: 113776, length: 13, convRule: rule14 }
  , CharBlock { start: 113792, length: 9, convRule: rule14 }
  , CharBlock { start: 113808, length: 10, convRule: rule14 }
  , CharBlock { start: 113820, length: 1, convRule: rule13 }
  , CharBlock { start: 113821, length: 2, convRule: rule92 }
  , CharBlock { start: 113823, length: 1, convRule: rule2 }
  , CharBlock { start: 113824, length: 4, convRule: rule16 }
  , CharBlock { start: 118784, length: 246, convRule: rule13 }
  , CharBlock { start: 119040, length: 39, convRule: rule13 }
  , CharBlock { start: 119081, length: 60, convRule: rule13 }
  , CharBlock { start: 119141, length: 2, convRule: rule124 }
  , CharBlock { start: 119143, length: 3, convRule: rule92 }
  , CharBlock { start: 119146, length: 3, convRule: rule13 }
  , CharBlock { start: 119149, length: 6, convRule: rule124 }
  , CharBlock { start: 119155, length: 8, convRule: rule16 }
  , CharBlock { start: 119163, length: 8, convRule: rule92 }
  , CharBlock { start: 119171, length: 2, convRule: rule13 }
  , CharBlock { start: 119173, length: 7, convRule: rule92 }
  , CharBlock { start: 119180, length: 30, convRule: rule13 }
  , CharBlock { start: 119210, length: 4, convRule: rule92 }
  , CharBlock { start: 119214, length: 59, convRule: rule13 }
  , CharBlock { start: 119296, length: 66, convRule: rule13 }
  , CharBlock { start: 119362, length: 3, convRule: rule92 }
  , CharBlock { start: 119365, length: 1, convRule: rule13 }
  , CharBlock { start: 119520, length: 20, convRule: rule17 }
  , CharBlock { start: 119552, length: 87, convRule: rule13 }
  , CharBlock { start: 119648, length: 25, convRule: rule17 }
  , CharBlock { start: 119808, length: 26, convRule: rule107 }
  , CharBlock { start: 119834, length: 26, convRule: rule20 }
  , CharBlock { start: 119860, length: 26, convRule: rule107 }
  , CharBlock { start: 119886, length: 7, convRule: rule20 }
  , CharBlock { start: 119894, length: 18, convRule: rule20 }
  , CharBlock { start: 119912, length: 26, convRule: rule107 }
  , CharBlock { start: 119938, length: 26, convRule: rule20 }
  , CharBlock { start: 119964, length: 1, convRule: rule107 }
  , CharBlock { start: 119966, length: 2, convRule: rule107 }
  , CharBlock { start: 119970, length: 1, convRule: rule107 }
  , CharBlock { start: 119973, length: 2, convRule: rule107 }
  , CharBlock { start: 119977, length: 4, convRule: rule107 }
  , CharBlock { start: 119982, length: 8, convRule: rule107 }
  , CharBlock { start: 119990, length: 4, convRule: rule20 }
  , CharBlock { start: 119995, length: 1, convRule: rule20 }
  , CharBlock { start: 119997, length: 7, convRule: rule20 }
  , CharBlock { start: 120005, length: 11, convRule: rule20 }
  , CharBlock { start: 120016, length: 26, convRule: rule107 }
  , CharBlock { start: 120042, length: 26, convRule: rule20 }
  , CharBlock { start: 120068, length: 2, convRule: rule107 }
  , CharBlock { start: 120071, length: 4, convRule: rule107 }
  , CharBlock { start: 120077, length: 8, convRule: rule107 }
  , CharBlock { start: 120086, length: 7, convRule: rule107 }
  , CharBlock { start: 120094, length: 26, convRule: rule20 }
  , CharBlock { start: 120120, length: 2, convRule: rule107 }
  , CharBlock { start: 120123, length: 4, convRule: rule107 }
  , CharBlock { start: 120128, length: 5, convRule: rule107 }
  , CharBlock { start: 120134, length: 1, convRule: rule107 }
  , CharBlock { start: 120138, length: 7, convRule: rule107 }
  , CharBlock { start: 120146, length: 26, convRule: rule20 }
  , CharBlock { start: 120172, length: 26, convRule: rule107 }
  , CharBlock { start: 120198, length: 26, convRule: rule20 }
  , CharBlock { start: 120224, length: 26, convRule: rule107 }
  , CharBlock { start: 120250, length: 26, convRule: rule20 }
  , CharBlock { start: 120276, length: 26, convRule: rule107 }
  , CharBlock { start: 120302, length: 26, convRule: rule20 }
  , CharBlock { start: 120328, length: 26, convRule: rule107 }
  , CharBlock { start: 120354, length: 26, convRule: rule20 }
  , CharBlock { start: 120380, length: 26, convRule: rule107 }
  , CharBlock { start: 120406, length: 26, convRule: rule20 }
  , CharBlock { start: 120432, length: 26, convRule: rule107 }
  , CharBlock { start: 120458, length: 28, convRule: rule20 }
  , CharBlock { start: 120488, length: 25, convRule: rule107 }
  , CharBlock { start: 120513, length: 1, convRule: rule6 }
  , CharBlock { start: 120514, length: 25, convRule: rule20 }
  , CharBlock { start: 120539, length: 1, convRule: rule6 }
  , CharBlock { start: 120540, length: 6, convRule: rule20 }
  , CharBlock { start: 120546, length: 25, convRule: rule107 }
  , CharBlock { start: 120571, length: 1, convRule: rule6 }
  , CharBlock { start: 120572, length: 25, convRule: rule20 }
  , CharBlock { start: 120597, length: 1, convRule: rule6 }
  , CharBlock { start: 120598, length: 6, convRule: rule20 }
  , CharBlock { start: 120604, length: 25, convRule: rule107 }
  , CharBlock { start: 120629, length: 1, convRule: rule6 }
  , CharBlock { start: 120630, length: 25, convRule: rule20 }
  , CharBlock { start: 120655, length: 1, convRule: rule6 }
  , CharBlock { start: 120656, length: 6, convRule: rule20 }
  , CharBlock { start: 120662, length: 25, convRule: rule107 }
  , CharBlock { start: 120687, length: 1, convRule: rule6 }
  , CharBlock { start: 120688, length: 25, convRule: rule20 }
  , CharBlock { start: 120713, length: 1, convRule: rule6 }
  , CharBlock { start: 120714, length: 6, convRule: rule20 }
  , CharBlock { start: 120720, length: 25, convRule: rule107 }
  , CharBlock { start: 120745, length: 1, convRule: rule6 }
  , CharBlock { start: 120746, length: 25, convRule: rule20 }
  , CharBlock { start: 120771, length: 1, convRule: rule6 }
  , CharBlock { start: 120772, length: 6, convRule: rule20 }
  , CharBlock { start: 120778, length: 1, convRule: rule107 }
  , CharBlock { start: 120779, length: 1, convRule: rule20 }
  , CharBlock { start: 120782, length: 50, convRule: rule8 }
  , CharBlock { start: 120832, length: 512, convRule: rule13 }
  , CharBlock { start: 121344, length: 55, convRule: rule92 }
  , CharBlock { start: 121399, length: 4, convRule: rule13 }
  , CharBlock { start: 121403, length: 50, convRule: rule92 }
  , CharBlock { start: 121453, length: 8, convRule: rule13 }
  , CharBlock { start: 121461, length: 1, convRule: rule92 }
  , CharBlock { start: 121462, length: 14, convRule: rule13 }
  , CharBlock { start: 121476, length: 1, convRule: rule92 }
  , CharBlock { start: 121477, length: 2, convRule: rule13 }
  , CharBlock { start: 121479, length: 5, convRule: rule2 }
  , CharBlock { start: 121499, length: 5, convRule: rule92 }
  , CharBlock { start: 121505, length: 15, convRule: rule92 }
  , CharBlock { start: 122880, length: 7, convRule: rule92 }
  , CharBlock { start: 122888, length: 17, convRule: rule92 }
  , CharBlock { start: 122907, length: 7, convRule: rule92 }
  , CharBlock { start: 122915, length: 2, convRule: rule92 }
  , CharBlock { start: 122918, length: 5, convRule: rule92 }
  , CharBlock { start: 123136, length: 45, convRule: rule14 }
  , CharBlock { start: 123184, length: 7, convRule: rule92 }
  , CharBlock { start: 123191, length: 7, convRule: rule91 }
  , CharBlock { start: 123200, length: 10, convRule: rule8 }
  , CharBlock { start: 123214, length: 1, convRule: rule14 }
  , CharBlock { start: 123215, length: 1, convRule: rule13 }
  , CharBlock { start: 123584, length: 44, convRule: rule14 }
  , CharBlock { start: 123628, length: 4, convRule: rule92 }
  , CharBlock { start: 123632, length: 10, convRule: rule8 }
  , CharBlock { start: 123647, length: 1, convRule: rule3 }
  , CharBlock { start: 124928, length: 197, convRule: rule14 }
  , CharBlock { start: 125127, length: 9, convRule: rule17 }
  , CharBlock { start: 125136, length: 7, convRule: rule92 }
  , CharBlock { start: 125184, length: 34, convRule: rule203 }
  , CharBlock { start: 125218, length: 34, convRule: rule204 }
  , CharBlock { start: 125252, length: 7, convRule: rule92 }
  , CharBlock { start: 125259, length: 1, convRule: rule91 }
  , CharBlock { start: 125264, length: 10, convRule: rule8 }
  , CharBlock { start: 125278, length: 2, convRule: rule2 }
  , CharBlock { start: 126065, length: 59, convRule: rule17 }
  , CharBlock { start: 126124, length: 1, convRule: rule13 }
  , CharBlock { start: 126125, length: 3, convRule: rule17 }
  , CharBlock { start: 126128, length: 1, convRule: rule3 }
  , CharBlock { start: 126129, length: 4, convRule: rule17 }
  , CharBlock { start: 126209, length: 45, convRule: rule17 }
  , CharBlock { start: 126254, length: 1, convRule: rule13 }
  , CharBlock { start: 126255, length: 15, convRule: rule17 }
  , CharBlock { start: 126464, length: 4, convRule: rule14 }
  , CharBlock { start: 126469, length: 27, convRule: rule14 }
  , CharBlock { start: 126497, length: 2, convRule: rule14 }
  , CharBlock { start: 126500, length: 1, convRule: rule14 }
  , CharBlock { start: 126503, length: 1, convRule: rule14 }
  , CharBlock { start: 126505, length: 10, convRule: rule14 }
  , CharBlock { start: 126516, length: 4, convRule: rule14 }
  , CharBlock { start: 126521, length: 1, convRule: rule14 }
  , CharBlock { start: 126523, length: 1, convRule: rule14 }
  , CharBlock { start: 126530, length: 1, convRule: rule14 }
  , CharBlock { start: 126535, length: 1, convRule: rule14 }
  , CharBlock { start: 126537, length: 1, convRule: rule14 }
  , CharBlock { start: 126539, length: 1, convRule: rule14 }
  , CharBlock { start: 126541, length: 3, convRule: rule14 }
  , CharBlock { start: 126545, length: 2, convRule: rule14 }
  , CharBlock { start: 126548, length: 1, convRule: rule14 }
  , CharBlock { start: 126551, length: 1, convRule: rule14 }
  , CharBlock { start: 126553, length: 1, convRule: rule14 }
  , CharBlock { start: 126555, length: 1, convRule: rule14 }
  , CharBlock { start: 126557, length: 1, convRule: rule14 }
  , CharBlock { start: 126559, length: 1, convRule: rule14 }
  , CharBlock { start: 126561, length: 2, convRule: rule14 }
  , CharBlock { start: 126564, length: 1, convRule: rule14 }
  , CharBlock { start: 126567, length: 4, convRule: rule14 }
  , CharBlock { start: 126572, length: 7, convRule: rule14 }
  , CharBlock { start: 126580, length: 4, convRule: rule14 }
  , CharBlock { start: 126585, length: 4, convRule: rule14 }
  , CharBlock { start: 126590, length: 1, convRule: rule14 }
  , CharBlock { start: 126592, length: 10, convRule: rule14 }
  , CharBlock { start: 126603, length: 17, convRule: rule14 }
  , CharBlock { start: 126625, length: 3, convRule: rule14 }
  , CharBlock { start: 126629, length: 5, convRule: rule14 }
  , CharBlock { start: 126635, length: 17, convRule: rule14 }
  , CharBlock { start: 126704, length: 2, convRule: rule6 }
  , CharBlock { start: 126976, length: 44, convRule: rule13 }
  , CharBlock { start: 127024, length: 100, convRule: rule13 }
  , CharBlock { start: 127136, length: 15, convRule: rule13 }
  , CharBlock { start: 127153, length: 15, convRule: rule13 }
  , CharBlock { start: 127169, length: 15, convRule: rule13 }
  , CharBlock { start: 127185, length: 37, convRule: rule13 }
  , CharBlock { start: 127232, length: 13, convRule: rule17 }
  , CharBlock { start: 127245, length: 161, convRule: rule13 }
  , CharBlock { start: 127462, length: 29, convRule: rule13 }
  , CharBlock { start: 127504, length: 44, convRule: rule13 }
  , CharBlock { start: 127552, length: 9, convRule: rule13 }
  , CharBlock { start: 127568, length: 2, convRule: rule13 }
  , CharBlock { start: 127584, length: 6, convRule: rule13 }
  , CharBlock { start: 127744, length: 251, convRule: rule13 }
  , CharBlock { start: 127995, length: 5, convRule: rule10 }
  , CharBlock { start: 128000, length: 728, convRule: rule13 }
  , CharBlock { start: 128736, length: 13, convRule: rule13 }
  , CharBlock { start: 128752, length: 13, convRule: rule13 }
  , CharBlock { start: 128768, length: 116, convRule: rule13 }
  , CharBlock { start: 128896, length: 89, convRule: rule13 }
  , CharBlock { start: 128992, length: 12, convRule: rule13 }
  , CharBlock { start: 129024, length: 12, convRule: rule13 }
  , CharBlock { start: 129040, length: 56, convRule: rule13 }
  , CharBlock { start: 129104, length: 10, convRule: rule13 }
  , CharBlock { start: 129120, length: 40, convRule: rule13 }
  , CharBlock { start: 129168, length: 30, convRule: rule13 }
  , CharBlock { start: 129200, length: 2, convRule: rule13 }
  , CharBlock { start: 129280, length: 121, convRule: rule13 }
  , CharBlock { start: 129402, length: 82, convRule: rule13 }
  , CharBlock { start: 129485, length: 135, convRule: rule13 }
  , CharBlock { start: 129632, length: 14, convRule: rule13 }
  , CharBlock { start: 129648, length: 5, convRule: rule13 }
  , CharBlock { start: 129656, length: 3, convRule: rule13 }
  , CharBlock { start: 129664, length: 7, convRule: rule13 }
  , CharBlock { start: 129680, length: 25, convRule: rule13 }
  , CharBlock { start: 129712, length: 7, convRule: rule13 }
  , CharBlock { start: 129728, length: 3, convRule: rule13 }
  , CharBlock { start: 129744, length: 7, convRule: rule13 }
  , CharBlock { start: 129792, length: 147, convRule: rule13 }
  , CharBlock { start: 129940, length: 55, convRule: rule13 }
  , CharBlock { start: 130032, length: 10, convRule: rule8 }
  , CharBlock { start: 131072, length: 42718, convRule: rule14 }
  , CharBlock { start: 173824, length: 4149, convRule: rule14 }
  , CharBlock { start: 177984, length: 222, convRule: rule14 }
  , CharBlock { start: 178208, length: 5762, convRule: rule14 }
  , CharBlock { start: 183984, length: 7473, convRule: rule14 }
  , CharBlock { start: 194560, length: 542, convRule: rule14 }
  , CharBlock { start: 196608, length: 4939, convRule: rule14 }
  , CharBlock { start: 917505, length: 1, convRule: rule16 }
  , CharBlock { start: 917536, length: 96, convRule: rule16 }
  , CharBlock { start: 917760, length: 240, convRule: rule92 }
  , CharBlock { start: 983040, length: 65534, convRule: rule200 }
  , CharBlock { start: 1048576, length: 65534, convRule: rule200 }
  ]

convchars :: Array CharBlock
convchars =
  [ CharBlock { start: 65, length: 26, convRule: rule9 }
  , CharBlock { start: 97, length: 26, convRule: rule12 }
  , CharBlock { start: 181, length: 1, convRule: rule18 }
  , CharBlock { start: 192, length: 23, convRule: rule9 }
  , CharBlock { start: 216, length: 7, convRule: rule9 }
  , CharBlock { start: 224, length: 23, convRule: rule12 }
  , CharBlock { start: 248, length: 7, convRule: rule12 }
  , CharBlock { start: 255, length: 1, convRule: rule21 }
  , CharBlock { start: 256, length: 1, convRule: rule22 }
  , CharBlock { start: 257, length: 1, convRule: rule23 }
  , CharBlock { start: 258, length: 1, convRule: rule22 }
  , CharBlock { start: 259, length: 1, convRule: rule23 }
  , CharBlock { start: 260, length: 1, convRule: rule22 }
  , CharBlock { start: 261, length: 1, convRule: rule23 }
  , CharBlock { start: 262, length: 1, convRule: rule22 }
  , CharBlock { start: 263, length: 1, convRule: rule23 }
  , CharBlock { start: 264, length: 1, convRule: rule22 }
  , CharBlock { start: 265, length: 1, convRule: rule23 }
  , CharBlock { start: 266, length: 1, convRule: rule22 }
  , CharBlock { start: 267, length: 1, convRule: rule23 }
  , CharBlock { start: 268, length: 1, convRule: rule22 }
  , CharBlock { start: 269, length: 1, convRule: rule23 }
  , CharBlock { start: 270, length: 1, convRule: rule22 }
  , CharBlock { start: 271, length: 1, convRule: rule23 }
  , CharBlock { start: 272, length: 1, convRule: rule22 }
  , CharBlock { start: 273, length: 1, convRule: rule23 }
  , CharBlock { start: 274, length: 1, convRule: rule22 }
  , CharBlock { start: 275, length: 1, convRule: rule23 }
  , CharBlock { start: 276, length: 1, convRule: rule22 }
  , CharBlock { start: 277, length: 1, convRule: rule23 }
  , CharBlock { start: 278, length: 1, convRule: rule22 }
  , CharBlock { start: 279, length: 1, convRule: rule23 }
  , CharBlock { start: 280, length: 1, convRule: rule22 }
  , CharBlock { start: 281, length: 1, convRule: rule23 }
  , CharBlock { start: 282, length: 1, convRule: rule22 }
  , CharBlock { start: 283, length: 1, convRule: rule23 }
  , CharBlock { start: 284, length: 1, convRule: rule22 }
  , CharBlock { start: 285, length: 1, convRule: rule23 }
  , CharBlock { start: 286, length: 1, convRule: rule22 }
  , CharBlock { start: 287, length: 1, convRule: rule23 }
  , CharBlock { start: 288, length: 1, convRule: rule22 }
  , CharBlock { start: 289, length: 1, convRule: rule23 }
  , CharBlock { start: 290, length: 1, convRule: rule22 }
  , CharBlock { start: 291, length: 1, convRule: rule23 }
  , CharBlock { start: 292, length: 1, convRule: rule22 }
  , CharBlock { start: 293, length: 1, convRule: rule23 }
  , CharBlock { start: 294, length: 1, convRule: rule22 }
  , CharBlock { start: 295, length: 1, convRule: rule23 }
  , CharBlock { start: 296, length: 1, convRule: rule22 }
  , CharBlock { start: 297, length: 1, convRule: rule23 }
  , CharBlock { start: 298, length: 1, convRule: rule22 }
  , CharBlock { start: 299, length: 1, convRule: rule23 }
  , CharBlock { start: 300, length: 1, convRule: rule22 }
  , CharBlock { start: 301, length: 1, convRule: rule23 }
  , CharBlock { start: 302, length: 1, convRule: rule22 }
  , CharBlock { start: 303, length: 1, convRule: rule23 }
  , CharBlock { start: 304, length: 1, convRule: rule24 }
  , CharBlock { start: 305, length: 1, convRule: rule25 }
  , CharBlock { start: 306, length: 1, convRule: rule22 }
  , CharBlock { start: 307, length: 1, convRule: rule23 }
  , CharBlock { start: 308, length: 1, convRule: rule22 }
  , CharBlock { start: 309, length: 1, convRule: rule23 }
  , CharBlock { start: 310, length: 1, convRule: rule22 }
  , CharBlock { start: 311, length: 1, convRule: rule23 }
  , CharBlock { start: 313, length: 1, convRule: rule22 }
  , CharBlock { start: 314, length: 1, convRule: rule23 }
  , CharBlock { start: 315, length: 1, convRule: rule22 }
  , CharBlock { start: 316, length: 1, convRule: rule23 }
  , CharBlock { start: 317, length: 1, convRule: rule22 }
  , CharBlock { start: 318, length: 1, convRule: rule23 }
  , CharBlock { start: 319, length: 1, convRule: rule22 }
  , CharBlock { start: 320, length: 1, convRule: rule23 }
  , CharBlock { start: 321, length: 1, convRule: rule22 }
  , CharBlock { start: 322, length: 1, convRule: rule23 }
  , CharBlock { start: 323, length: 1, convRule: rule22 }
  , CharBlock { start: 324, length: 1, convRule: rule23 }
  , CharBlock { start: 325, length: 1, convRule: rule22 }
  , CharBlock { start: 326, length: 1, convRule: rule23 }
  , CharBlock { start: 327, length: 1, convRule: rule22 }
  , CharBlock { start: 328, length: 1, convRule: rule23 }
  , CharBlock { start: 330, length: 1, convRule: rule22 }
  , CharBlock { start: 331, length: 1, convRule: rule23 }
  , CharBlock { start: 332, length: 1, convRule: rule22 }
  , CharBlock { start: 333, length: 1, convRule: rule23 }
  , CharBlock { start: 334, length: 1, convRule: rule22 }
  , CharBlock { start: 335, length: 1, convRule: rule23 }
  , CharBlock { start: 336, length: 1, convRule: rule22 }
  , CharBlock { start: 337, length: 1, convRule: rule23 }
  , CharBlock { start: 338, length: 1, convRule: rule22 }
  , CharBlock { start: 339, length: 1, convRule: rule23 }
  , CharBlock { start: 340, length: 1, convRule: rule22 }
  , CharBlock { start: 341, length: 1, convRule: rule23 }
  , CharBlock { start: 342, length: 1, convRule: rule22 }
  , CharBlock { start: 343, length: 1, convRule: rule23 }
  , CharBlock { start: 344, length: 1, convRule: rule22 }
  , CharBlock { start: 345, length: 1, convRule: rule23 }
  , CharBlock { start: 346, length: 1, convRule: rule22 }
  , CharBlock { start: 347, length: 1, convRule: rule23 }
  , CharBlock { start: 348, length: 1, convRule: rule22 }
  , CharBlock { start: 349, length: 1, convRule: rule23 }
  , CharBlock { start: 350, length: 1, convRule: rule22 }
  , CharBlock { start: 351, length: 1, convRule: rule23 }
  , CharBlock { start: 352, length: 1, convRule: rule22 }
  , CharBlock { start: 353, length: 1, convRule: rule23 }
  , CharBlock { start: 354, length: 1, convRule: rule22 }
  , CharBlock { start: 355, length: 1, convRule: rule23 }
  , CharBlock { start: 356, length: 1, convRule: rule22 }
  , CharBlock { start: 357, length: 1, convRule: rule23 }
  , CharBlock { start: 358, length: 1, convRule: rule22 }
  , CharBlock { start: 359, length: 1, convRule: rule23 }
  , CharBlock { start: 360, length: 1, convRule: rule22 }
  , CharBlock { start: 361, length: 1, convRule: rule23 }
  , CharBlock { start: 362, length: 1, convRule: rule22 }
  , CharBlock { start: 363, length: 1, convRule: rule23 }
  , CharBlock { start: 364, length: 1, convRule: rule22 }
  , CharBlock { start: 365, length: 1, convRule: rule23 }
  , CharBlock { start: 366, length: 1, convRule: rule22 }
  , CharBlock { start: 367, length: 1, convRule: rule23 }
  , CharBlock { start: 368, length: 1, convRule: rule22 }
  , CharBlock { start: 369, length: 1, convRule: rule23 }
  , CharBlock { start: 370, length: 1, convRule: rule22 }
  , CharBlock { start: 371, length: 1, convRule: rule23 }
  , CharBlock { start: 372, length: 1, convRule: rule22 }
  , CharBlock { start: 373, length: 1, convRule: rule23 }
  , CharBlock { start: 374, length: 1, convRule: rule22 }
  , CharBlock { start: 375, length: 1, convRule: rule23 }
  , CharBlock { start: 376, length: 1, convRule: rule26 }
  , CharBlock { start: 377, length: 1, convRule: rule22 }
  , CharBlock { start: 378, length: 1, convRule: rule23 }
  , CharBlock { start: 379, length: 1, convRule: rule22 }
  , CharBlock { start: 380, length: 1, convRule: rule23 }
  , CharBlock { start: 381, length: 1, convRule: rule22 }
  , CharBlock { start: 382, length: 1, convRule: rule23 }
  , CharBlock { start: 383, length: 1, convRule: rule27 }
  , CharBlock { start: 384, length: 1, convRule: rule28 }
  , CharBlock { start: 385, length: 1, convRule: rule29 }
  , CharBlock { start: 386, length: 1, convRule: rule22 }
  , CharBlock { start: 387, length: 1, convRule: rule23 }
  , CharBlock { start: 388, length: 1, convRule: rule22 }
  , CharBlock { start: 389, length: 1, convRule: rule23 }
  , CharBlock { start: 390, length: 1, convRule: rule30 }
  , CharBlock { start: 391, length: 1, convRule: rule22 }
  , CharBlock { start: 392, length: 1, convRule: rule23 }
  , CharBlock { start: 393, length: 2, convRule: rule31 }
  , CharBlock { start: 395, length: 1, convRule: rule22 }
  , CharBlock { start: 396, length: 1, convRule: rule23 }
  , CharBlock { start: 398, length: 1, convRule: rule32 }
  , CharBlock { start: 399, length: 1, convRule: rule33 }
  , CharBlock { start: 400, length: 1, convRule: rule34 }
  , CharBlock { start: 401, length: 1, convRule: rule22 }
  , CharBlock { start: 402, length: 1, convRule: rule23 }
  , CharBlock { start: 403, length: 1, convRule: rule31 }
  , CharBlock { start: 404, length: 1, convRule: rule35 }
  , CharBlock { start: 405, length: 1, convRule: rule36 }
  , CharBlock { start: 406, length: 1, convRule: rule37 }
  , CharBlock { start: 407, length: 1, convRule: rule38 }
  , CharBlock { start: 408, length: 1, convRule: rule22 }
  , CharBlock { start: 409, length: 1, convRule: rule23 }
  , CharBlock { start: 410, length: 1, convRule: rule39 }
  , CharBlock { start: 412, length: 1, convRule: rule37 }
  , CharBlock { start: 413, length: 1, convRule: rule40 }
  , CharBlock { start: 414, length: 1, convRule: rule41 }
  , CharBlock { start: 415, length: 1, convRule: rule42 }
  , CharBlock { start: 416, length: 1, convRule: rule22 }
  , CharBlock { start: 417, length: 1, convRule: rule23 }
  , CharBlock { start: 418, length: 1, convRule: rule22 }
  , CharBlock { start: 419, length: 1, convRule: rule23 }
  , CharBlock { start: 420, length: 1, convRule: rule22 }
  , CharBlock { start: 421, length: 1, convRule: rule23 }
  , CharBlock { start: 422, length: 1, convRule: rule43 }
  , CharBlock { start: 423, length: 1, convRule: rule22 }
  , CharBlock { start: 424, length: 1, convRule: rule23 }
  , CharBlock { start: 425, length: 1, convRule: rule43 }
  , CharBlock { start: 428, length: 1, convRule: rule22 }
  , CharBlock { start: 429, length: 1, convRule: rule23 }
  , CharBlock { start: 430, length: 1, convRule: rule43 }
  , CharBlock { start: 431, length: 1, convRule: rule22 }
  , CharBlock { start: 432, length: 1, convRule: rule23 }
  , CharBlock { start: 433, length: 2, convRule: rule44 }
  , CharBlock { start: 435, length: 1, convRule: rule22 }
  , CharBlock { start: 436, length: 1, convRule: rule23 }
  , CharBlock { start: 437, length: 1, convRule: rule22 }
  , CharBlock { start: 438, length: 1, convRule: rule23 }
  , CharBlock { start: 439, length: 1, convRule: rule45 }
  , CharBlock { start: 440, length: 1, convRule: rule22 }
  , CharBlock { start: 441, length: 1, convRule: rule23 }
  , CharBlock { start: 444, length: 1, convRule: rule22 }
  , CharBlock { start: 445, length: 1, convRule: rule23 }
  , CharBlock { start: 447, length: 1, convRule: rule46 }
  , CharBlock { start: 452, length: 1, convRule: rule47 }
  , CharBlock { start: 453, length: 1, convRule: rule48 }
  , CharBlock { start: 454, length: 1, convRule: rule49 }
  , CharBlock { start: 455, length: 1, convRule: rule47 }
  , CharBlock { start: 456, length: 1, convRule: rule48 }
  , CharBlock { start: 457, length: 1, convRule: rule49 }
  , CharBlock { start: 458, length: 1, convRule: rule47 }
  , CharBlock { start: 459, length: 1, convRule: rule48 }
  , CharBlock { start: 460, length: 1, convRule: rule49 }
  , CharBlock { start: 461, length: 1, convRule: rule22 }
  , CharBlock { start: 462, length: 1, convRule: rule23 }
  , CharBlock { start: 463, length: 1, convRule: rule22 }
  , CharBlock { start: 464, length: 1, convRule: rule23 }
  , CharBlock { start: 465, length: 1, convRule: rule22 }
  , CharBlock { start: 466, length: 1, convRule: rule23 }
  , CharBlock { start: 467, length: 1, convRule: rule22 }
  , CharBlock { start: 468, length: 1, convRule: rule23 }
  , CharBlock { start: 469, length: 1, convRule: rule22 }
  , CharBlock { start: 470, length: 1, convRule: rule23 }
  , CharBlock { start: 471, length: 1, convRule: rule22 }
  , CharBlock { start: 472, length: 1, convRule: rule23 }
  , CharBlock { start: 473, length: 1, convRule: rule22 }
  , CharBlock { start: 474, length: 1, convRule: rule23 }
  , CharBlock { start: 475, length: 1, convRule: rule22 }
  , CharBlock { start: 476, length: 1, convRule: rule23 }
  , CharBlock { start: 477, length: 1, convRule: rule50 }
  , CharBlock { start: 478, length: 1, convRule: rule22 }
  , CharBlock { start: 479, length: 1, convRule: rule23 }
  , CharBlock { start: 480, length: 1, convRule: rule22 }
  , CharBlock { start: 481, length: 1, convRule: rule23 }
  , CharBlock { start: 482, length: 1, convRule: rule22 }
  , CharBlock { start: 483, length: 1, convRule: rule23 }
  , CharBlock { start: 484, length: 1, convRule: rule22 }
  , CharBlock { start: 485, length: 1, convRule: rule23 }
  , CharBlock { start: 486, length: 1, convRule: rule22 }
  , CharBlock { start: 487, length: 1, convRule: rule23 }
  , CharBlock { start: 488, length: 1, convRule: rule22 }
  , CharBlock { start: 489, length: 1, convRule: rule23 }
  , CharBlock { start: 490, length: 1, convRule: rule22 }
  , CharBlock { start: 491, length: 1, convRule: rule23 }
  , CharBlock { start: 492, length: 1, convRule: rule22 }
  , CharBlock { start: 493, length: 1, convRule: rule23 }
  , CharBlock { start: 494, length: 1, convRule: rule22 }
  , CharBlock { start: 495, length: 1, convRule: rule23 }
  , CharBlock { start: 497, length: 1, convRule: rule47 }
  , CharBlock { start: 498, length: 1, convRule: rule48 }
  , CharBlock { start: 499, length: 1, convRule: rule49 }
  , CharBlock { start: 500, length: 1, convRule: rule22 }
  , CharBlock { start: 501, length: 1, convRule: rule23 }
  , CharBlock { start: 502, length: 1, convRule: rule51 }
  , CharBlock { start: 503, length: 1, convRule: rule52 }
  , CharBlock { start: 504, length: 1, convRule: rule22 }
  , CharBlock { start: 505, length: 1, convRule: rule23 }
  , CharBlock { start: 506, length: 1, convRule: rule22 }
  , CharBlock { start: 507, length: 1, convRule: rule23 }
  , CharBlock { start: 508, length: 1, convRule: rule22 }
  , CharBlock { start: 509, length: 1, convRule: rule23 }
  , CharBlock { start: 510, length: 1, convRule: rule22 }
  , CharBlock { start: 511, length: 1, convRule: rule23 }
  , CharBlock { start: 512, length: 1, convRule: rule22 }
  , CharBlock { start: 513, length: 1, convRule: rule23 }
  , CharBlock { start: 514, length: 1, convRule: rule22 }
  , CharBlock { start: 515, length: 1, convRule: rule23 }
  , CharBlock { start: 516, length: 1, convRule: rule22 }
  , CharBlock { start: 517, length: 1, convRule: rule23 }
  , CharBlock { start: 518, length: 1, convRule: rule22 }
  , CharBlock { start: 519, length: 1, convRule: rule23 }
  , CharBlock { start: 520, length: 1, convRule: rule22 }
  , CharBlock { start: 521, length: 1, convRule: rule23 }
  , CharBlock { start: 522, length: 1, convRule: rule22 }
  , CharBlock { start: 523, length: 1, convRule: rule23 }
  , CharBlock { start: 524, length: 1, convRule: rule22 }
  , CharBlock { start: 525, length: 1, convRule: rule23 }
  , CharBlock { start: 526, length: 1, convRule: rule22 }
  , CharBlock { start: 527, length: 1, convRule: rule23 }
  , CharBlock { start: 528, length: 1, convRule: rule22 }
  , CharBlock { start: 529, length: 1, convRule: rule23 }
  , CharBlock { start: 530, length: 1, convRule: rule22 }
  , CharBlock { start: 531, length: 1, convRule: rule23 }
  , CharBlock { start: 532, length: 1, convRule: rule22 }
  , CharBlock { start: 533, length: 1, convRule: rule23 }
  , CharBlock { start: 534, length: 1, convRule: rule22 }
  , CharBlock { start: 535, length: 1, convRule: rule23 }
  , CharBlock { start: 536, length: 1, convRule: rule22 }
  , CharBlock { start: 537, length: 1, convRule: rule23 }
  , CharBlock { start: 538, length: 1, convRule: rule22 }
  , CharBlock { start: 539, length: 1, convRule: rule23 }
  , CharBlock { start: 540, length: 1, convRule: rule22 }
  , CharBlock { start: 541, length: 1, convRule: rule23 }
  , CharBlock { start: 542, length: 1, convRule: rule22 }
  , CharBlock { start: 543, length: 1, convRule: rule23 }
  , CharBlock { start: 544, length: 1, convRule: rule53 }
  , CharBlock { start: 546, length: 1, convRule: rule22 }
  , CharBlock { start: 547, length: 1, convRule: rule23 }
  , CharBlock { start: 548, length: 1, convRule: rule22 }
  , CharBlock { start: 549, length: 1, convRule: rule23 }
  , CharBlock { start: 550, length: 1, convRule: rule22 }
  , CharBlock { start: 551, length: 1, convRule: rule23 }
  , CharBlock { start: 552, length: 1, convRule: rule22 }
  , CharBlock { start: 553, length: 1, convRule: rule23 }
  , CharBlock { start: 554, length: 1, convRule: rule22 }
  , CharBlock { start: 555, length: 1, convRule: rule23 }
  , CharBlock { start: 556, length: 1, convRule: rule22 }
  , CharBlock { start: 557, length: 1, convRule: rule23 }
  , CharBlock { start: 558, length: 1, convRule: rule22 }
  , CharBlock { start: 559, length: 1, convRule: rule23 }
  , CharBlock { start: 560, length: 1, convRule: rule22 }
  , CharBlock { start: 561, length: 1, convRule: rule23 }
  , CharBlock { start: 562, length: 1, convRule: rule22 }
  , CharBlock { start: 563, length: 1, convRule: rule23 }
  , CharBlock { start: 570, length: 1, convRule: rule54 }
  , CharBlock { start: 571, length: 1, convRule: rule22 }
  , CharBlock { start: 572, length: 1, convRule: rule23 }
  , CharBlock { start: 573, length: 1, convRule: rule55 }
  , CharBlock { start: 574, length: 1, convRule: rule56 }
  , CharBlock { start: 575, length: 2, convRule: rule57 }
  , CharBlock { start: 577, length: 1, convRule: rule22 }
  , CharBlock { start: 578, length: 1, convRule: rule23 }
  , CharBlock { start: 579, length: 1, convRule: rule58 }
  , CharBlock { start: 580, length: 1, convRule: rule59 }
  , CharBlock { start: 581, length: 1, convRule: rule60 }
  , CharBlock { start: 582, length: 1, convRule: rule22 }
  , CharBlock { start: 583, length: 1, convRule: rule23 }
  , CharBlock { start: 584, length: 1, convRule: rule22 }
  , CharBlock { start: 585, length: 1, convRule: rule23 }
  , CharBlock { start: 586, length: 1, convRule: rule22 }
  , CharBlock { start: 587, length: 1, convRule: rule23 }
  , CharBlock { start: 588, length: 1, convRule: rule22 }
  , CharBlock { start: 589, length: 1, convRule: rule23 }
  , CharBlock { start: 590, length: 1, convRule: rule22 }
  , CharBlock { start: 591, length: 1, convRule: rule23 }
  , CharBlock { start: 592, length: 1, convRule: rule61 }
  , CharBlock { start: 593, length: 1, convRule: rule62 }
  , CharBlock { start: 594, length: 1, convRule: rule63 }
  , CharBlock { start: 595, length: 1, convRule: rule64 }
  , CharBlock { start: 596, length: 1, convRule: rule65 }
  , CharBlock { start: 598, length: 2, convRule: rule66 }
  , CharBlock { start: 601, length: 1, convRule: rule67 }
  , CharBlock { start: 603, length: 1, convRule: rule68 }
  , CharBlock { start: 604, length: 1, convRule: rule69 }
  , CharBlock { start: 608, length: 1, convRule: rule66 }
  , CharBlock { start: 609, length: 1, convRule: rule70 }
  , CharBlock { start: 611, length: 1, convRule: rule71 }
  , CharBlock { start: 613, length: 1, convRule: rule72 }
  , CharBlock { start: 614, length: 1, convRule: rule73 }
  , CharBlock { start: 616, length: 1, convRule: rule74 }
  , CharBlock { start: 617, length: 1, convRule: rule75 }
  , CharBlock { start: 618, length: 1, convRule: rule73 }
  , CharBlock { start: 619, length: 1, convRule: rule76 }
  , CharBlock { start: 620, length: 1, convRule: rule77 }
  , CharBlock { start: 623, length: 1, convRule: rule75 }
  , CharBlock { start: 625, length: 1, convRule: rule78 }
  , CharBlock { start: 626, length: 1, convRule: rule79 }
  , CharBlock { start: 629, length: 1, convRule: rule80 }
  , CharBlock { start: 637, length: 1, convRule: rule81 }
  , CharBlock { start: 640, length: 1, convRule: rule82 }
  , CharBlock { start: 642, length: 1, convRule: rule83 }
  , CharBlock { start: 643, length: 1, convRule: rule82 }
  , CharBlock { start: 647, length: 1, convRule: rule84 }
  , CharBlock { start: 648, length: 1, convRule: rule82 }
  , CharBlock { start: 649, length: 1, convRule: rule85 }
  , CharBlock { start: 650, length: 2, convRule: rule86 }
  , CharBlock { start: 652, length: 1, convRule: rule87 }
  , CharBlock { start: 658, length: 1, convRule: rule88 }
  , CharBlock { start: 669, length: 1, convRule: rule89 }
  , CharBlock { start: 670, length: 1, convRule: rule90 }
  , CharBlock { start: 837, length: 1, convRule: rule93 }
  , CharBlock { start: 880, length: 1, convRule: rule22 }
  , CharBlock { start: 881, length: 1, convRule: rule23 }
  , CharBlock { start: 882, length: 1, convRule: rule22 }
  , CharBlock { start: 883, length: 1, convRule: rule23 }
  , CharBlock { start: 886, length: 1, convRule: rule22 }
  , CharBlock { start: 887, length: 1, convRule: rule23 }
  , CharBlock { start: 891, length: 3, convRule: rule41 }
  , CharBlock { start: 895, length: 1, convRule: rule94 }
  , CharBlock { start: 902, length: 1, convRule: rule95 }
  , CharBlock { start: 904, length: 3, convRule: rule96 }
  , CharBlock { start: 908, length: 1, convRule: rule97 }
  , CharBlock { start: 910, length: 2, convRule: rule98 }
  , CharBlock { start: 913, length: 17, convRule: rule9 }
  , CharBlock { start: 931, length: 9, convRule: rule9 }
  , CharBlock { start: 940, length: 1, convRule: rule99 }
  , CharBlock { start: 941, length: 3, convRule: rule100 }
  , CharBlock { start: 945, length: 17, convRule: rule12 }
  , CharBlock { start: 962, length: 1, convRule: rule101 }
  , CharBlock { start: 963, length: 9, convRule: rule12 }
  , CharBlock { start: 972, length: 1, convRule: rule102 }
  , CharBlock { start: 973, length: 2, convRule: rule103 }
  , CharBlock { start: 975, length: 1, convRule: rule104 }
  , CharBlock { start: 976, length: 1, convRule: rule105 }
  , CharBlock { start: 977, length: 1, convRule: rule106 }
  , CharBlock { start: 981, length: 1, convRule: rule108 }
  , CharBlock { start: 982, length: 1, convRule: rule109 }
  , CharBlock { start: 983, length: 1, convRule: rule110 }
  , CharBlock { start: 984, length: 1, convRule: rule22 }
  , CharBlock { start: 985, length: 1, convRule: rule23 }
  , CharBlock { start: 986, length: 1, convRule: rule22 }
  , CharBlock { start: 987, length: 1, convRule: rule23 }
  , CharBlock { start: 988, length: 1, convRule: rule22 }
  , CharBlock { start: 989, length: 1, convRule: rule23 }
  , CharBlock { start: 990, length: 1, convRule: rule22 }
  , CharBlock { start: 991, length: 1, convRule: rule23 }
  , CharBlock { start: 992, length: 1, convRule: rule22 }
  , CharBlock { start: 993, length: 1, convRule: rule23 }
  , CharBlock { start: 994, length: 1, convRule: rule22 }
  , CharBlock { start: 995, length: 1, convRule: rule23 }
  , CharBlock { start: 996, length: 1, convRule: rule22 }
  , CharBlock { start: 997, length: 1, convRule: rule23 }
  , CharBlock { start: 998, length: 1, convRule: rule22 }
  , CharBlock { start: 999, length: 1, convRule: rule23 }
  , CharBlock { start: 1000, length: 1, convRule: rule22 }
  , CharBlock { start: 1001, length: 1, convRule: rule23 }
  , CharBlock { start: 1002, length: 1, convRule: rule22 }
  , CharBlock { start: 1003, length: 1, convRule: rule23 }
  , CharBlock { start: 1004, length: 1, convRule: rule22 }
  , CharBlock { start: 1005, length: 1, convRule: rule23 }
  , CharBlock { start: 1006, length: 1, convRule: rule22 }
  , CharBlock { start: 1007, length: 1, convRule: rule23 }
  , CharBlock { start: 1008, length: 1, convRule: rule111 }
  , CharBlock { start: 1009, length: 1, convRule: rule112 }
  , CharBlock { start: 1010, length: 1, convRule: rule113 }
  , CharBlock { start: 1011, length: 1, convRule: rule114 }
  , CharBlock { start: 1012, length: 1, convRule: rule115 }
  , CharBlock { start: 1013, length: 1, convRule: rule116 }
  , CharBlock { start: 1015, length: 1, convRule: rule22 }
  , CharBlock { start: 1016, length: 1, convRule: rule23 }
  , CharBlock { start: 1017, length: 1, convRule: rule117 }
  , CharBlock { start: 1018, length: 1, convRule: rule22 }
  , CharBlock { start: 1019, length: 1, convRule: rule23 }
  , CharBlock { start: 1021, length: 3, convRule: rule53 }
  , CharBlock { start: 1024, length: 16, convRule: rule118 }
  , CharBlock { start: 1040, length: 32, convRule: rule9 }
  , CharBlock { start: 1072, length: 32, convRule: rule12 }
  , CharBlock { start: 1104, length: 16, convRule: rule112 }
  , CharBlock { start: 1120, length: 1, convRule: rule22 }
  , CharBlock { start: 1121, length: 1, convRule: rule23 }
  , CharBlock { start: 1122, length: 1, convRule: rule22 }
  , CharBlock { start: 1123, length: 1, convRule: rule23 }
  , CharBlock { start: 1124, length: 1, convRule: rule22 }
  , CharBlock { start: 1125, length: 1, convRule: rule23 }
  , CharBlock { start: 1126, length: 1, convRule: rule22 }
  , CharBlock { start: 1127, length: 1, convRule: rule23 }
  , CharBlock { start: 1128, length: 1, convRule: rule22 }
  , CharBlock { start: 1129, length: 1, convRule: rule23 }
  , CharBlock { start: 1130, length: 1, convRule: rule22 }
  , CharBlock { start: 1131, length: 1, convRule: rule23 }
  , CharBlock { start: 1132, length: 1, convRule: rule22 }
  , CharBlock { start: 1133, length: 1, convRule: rule23 }
  , CharBlock { start: 1134, length: 1, convRule: rule22 }
  , CharBlock { start: 1135, length: 1, convRule: rule23 }
  , CharBlock { start: 1136, length: 1, convRule: rule22 }
  , CharBlock { start: 1137, length: 1, convRule: rule23 }
  , CharBlock { start: 1138, length: 1, convRule: rule22 }
  , CharBlock { start: 1139, length: 1, convRule: rule23 }
  , CharBlock { start: 1140, length: 1, convRule: rule22 }
  , CharBlock { start: 1141, length: 1, convRule: rule23 }
  , CharBlock { start: 1142, length: 1, convRule: rule22 }
  , CharBlock { start: 1143, length: 1, convRule: rule23 }
  , CharBlock { start: 1144, length: 1, convRule: rule22 }
  , CharBlock { start: 1145, length: 1, convRule: rule23 }
  , CharBlock { start: 1146, length: 1, convRule: rule22 }
  , CharBlock { start: 1147, length: 1, convRule: rule23 }
  , CharBlock { start: 1148, length: 1, convRule: rule22 }
  , CharBlock { start: 1149, length: 1, convRule: rule23 }
  , CharBlock { start: 1150, length: 1, convRule: rule22 }
  , CharBlock { start: 1151, length: 1, convRule: rule23 }
  , CharBlock { start: 1152, length: 1, convRule: rule22 }
  , CharBlock { start: 1153, length: 1, convRule: rule23 }
  , CharBlock { start: 1162, length: 1, convRule: rule22 }
  , CharBlock { start: 1163, length: 1, convRule: rule23 }
  , CharBlock { start: 1164, length: 1, convRule: rule22 }
  , CharBlock { start: 1165, length: 1, convRule: rule23 }
  , CharBlock { start: 1166, length: 1, convRule: rule22 }
  , CharBlock { start: 1167, length: 1, convRule: rule23 }
  , CharBlock { start: 1168, length: 1, convRule: rule22 }
  , CharBlock { start: 1169, length: 1, convRule: rule23 }
  , CharBlock { start: 1170, length: 1, convRule: rule22 }
  , CharBlock { start: 1171, length: 1, convRule: rule23 }
  , CharBlock { start: 1172, length: 1, convRule: rule22 }
  , CharBlock { start: 1173, length: 1, convRule: rule23 }
  , CharBlock { start: 1174, length: 1, convRule: rule22 }
  , CharBlock { start: 1175, length: 1, convRule: rule23 }
  , CharBlock { start: 1176, length: 1, convRule: rule22 }
  , CharBlock { start: 1177, length: 1, convRule: rule23 }
  , CharBlock { start: 1178, length: 1, convRule: rule22 }
  , CharBlock { start: 1179, length: 1, convRule: rule23 }
  , CharBlock { start: 1180, length: 1, convRule: rule22 }
  , CharBlock { start: 1181, length: 1, convRule: rule23 }
  , CharBlock { start: 1182, length: 1, convRule: rule22 }
  , CharBlock { start: 1183, length: 1, convRule: rule23 }
  , CharBlock { start: 1184, length: 1, convRule: rule22 }
  , CharBlock { start: 1185, length: 1, convRule: rule23 }
  , CharBlock { start: 1186, length: 1, convRule: rule22 }
  , CharBlock { start: 1187, length: 1, convRule: rule23 }
  , CharBlock { start: 1188, length: 1, convRule: rule22 }
  , CharBlock { start: 1189, length: 1, convRule: rule23 }
  , CharBlock { start: 1190, length: 1, convRule: rule22 }
  , CharBlock { start: 1191, length: 1, convRule: rule23 }
  , CharBlock { start: 1192, length: 1, convRule: rule22 }
  , CharBlock { start: 1193, length: 1, convRule: rule23 }
  , CharBlock { start: 1194, length: 1, convRule: rule22 }
  , CharBlock { start: 1195, length: 1, convRule: rule23 }
  , CharBlock { start: 1196, length: 1, convRule: rule22 }
  , CharBlock { start: 1197, length: 1, convRule: rule23 }
  , CharBlock { start: 1198, length: 1, convRule: rule22 }
  , CharBlock { start: 1199, length: 1, convRule: rule23 }
  , CharBlock { start: 1200, length: 1, convRule: rule22 }
  , CharBlock { start: 1201, length: 1, convRule: rule23 }
  , CharBlock { start: 1202, length: 1, convRule: rule22 }
  , CharBlock { start: 1203, length: 1, convRule: rule23 }
  , CharBlock { start: 1204, length: 1, convRule: rule22 }
  , CharBlock { start: 1205, length: 1, convRule: rule23 }
  , CharBlock { start: 1206, length: 1, convRule: rule22 }
  , CharBlock { start: 1207, length: 1, convRule: rule23 }
  , CharBlock { start: 1208, length: 1, convRule: rule22 }
  , CharBlock { start: 1209, length: 1, convRule: rule23 }
  , CharBlock { start: 1210, length: 1, convRule: rule22 }
  , CharBlock { start: 1211, length: 1, convRule: rule23 }
  , CharBlock { start: 1212, length: 1, convRule: rule22 }
  , CharBlock { start: 1213, length: 1, convRule: rule23 }
  , CharBlock { start: 1214, length: 1, convRule: rule22 }
  , CharBlock { start: 1215, length: 1, convRule: rule23 }
  , CharBlock { start: 1216, length: 1, convRule: rule120 }
  , CharBlock { start: 1217, length: 1, convRule: rule22 }
  , CharBlock { start: 1218, length: 1, convRule: rule23 }
  , CharBlock { start: 1219, length: 1, convRule: rule22 }
  , CharBlock { start: 1220, length: 1, convRule: rule23 }
  , CharBlock { start: 1221, length: 1, convRule: rule22 }
  , CharBlock { start: 1222, length: 1, convRule: rule23 }
  , CharBlock { start: 1223, length: 1, convRule: rule22 }
  , CharBlock { start: 1224, length: 1, convRule: rule23 }
  , CharBlock { start: 1225, length: 1, convRule: rule22 }
  , CharBlock { start: 1226, length: 1, convRule: rule23 }
  , CharBlock { start: 1227, length: 1, convRule: rule22 }
  , CharBlock { start: 1228, length: 1, convRule: rule23 }
  , CharBlock { start: 1229, length: 1, convRule: rule22 }
  , CharBlock { start: 1230, length: 1, convRule: rule23 }
  , CharBlock { start: 1231, length: 1, convRule: rule121 }
  , CharBlock { start: 1232, length: 1, convRule: rule22 }
  , CharBlock { start: 1233, length: 1, convRule: rule23 }
  , CharBlock { start: 1234, length: 1, convRule: rule22 }
  , CharBlock { start: 1235, length: 1, convRule: rule23 }
  , CharBlock { start: 1236, length: 1, convRule: rule22 }
  , CharBlock { start: 1237, length: 1, convRule: rule23 }
  , CharBlock { start: 1238, length: 1, convRule: rule22 }
  , CharBlock { start: 1239, length: 1, convRule: rule23 }
  , CharBlock { start: 1240, length: 1, convRule: rule22 }
  , CharBlock { start: 1241, length: 1, convRule: rule23 }
  , CharBlock { start: 1242, length: 1, convRule: rule22 }
  , CharBlock { start: 1243, length: 1, convRule: rule23 }
  , CharBlock { start: 1244, length: 1, convRule: rule22 }
  , CharBlock { start: 1245, length: 1, convRule: rule23 }
  , CharBlock { start: 1246, length: 1, convRule: rule22 }
  , CharBlock { start: 1247, length: 1, convRule: rule23 }
  , CharBlock { start: 1248, length: 1, convRule: rule22 }
  , CharBlock { start: 1249, length: 1, convRule: rule23 }
  , CharBlock { start: 1250, length: 1, convRule: rule22 }
  , CharBlock { start: 1251, length: 1, convRule: rule23 }
  , CharBlock { start: 1252, length: 1, convRule: rule22 }
  , CharBlock { start: 1253, length: 1, convRule: rule23 }
  , CharBlock { start: 1254, length: 1, convRule: rule22 }
  , CharBlock { start: 1255, length: 1, convRule: rule23 }
  , CharBlock { start: 1256, length: 1, convRule: rule22 }
  , CharBlock { start: 1257, length: 1, convRule: rule23 }
  , CharBlock { start: 1258, length: 1, convRule: rule22 }
  , CharBlock { start: 1259, length: 1, convRule: rule23 }
  , CharBlock { start: 1260, length: 1, convRule: rule22 }
  , CharBlock { start: 1261, length: 1, convRule: rule23 }
  , CharBlock { start: 1262, length: 1, convRule: rule22 }
  , CharBlock { start: 1263, length: 1, convRule: rule23 }
  , CharBlock { start: 1264, length: 1, convRule: rule22 }
  , CharBlock { start: 1265, length: 1, convRule: rule23 }
  , CharBlock { start: 1266, length: 1, convRule: rule22 }
  , CharBlock { start: 1267, length: 1, convRule: rule23 }
  , CharBlock { start: 1268, length: 1, convRule: rule22 }
  , CharBlock { start: 1269, length: 1, convRule: rule23 }
  , CharBlock { start: 1270, length: 1, convRule: rule22 }
  , CharBlock { start: 1271, length: 1, convRule: rule23 }
  , CharBlock { start: 1272, length: 1, convRule: rule22 }
  , CharBlock { start: 1273, length: 1, convRule: rule23 }
  , CharBlock { start: 1274, length: 1, convRule: rule22 }
  , CharBlock { start: 1275, length: 1, convRule: rule23 }
  , CharBlock { start: 1276, length: 1, convRule: rule22 }
  , CharBlock { start: 1277, length: 1, convRule: rule23 }
  , CharBlock { start: 1278, length: 1, convRule: rule22 }
  , CharBlock { start: 1279, length: 1, convRule: rule23 }
  , CharBlock { start: 1280, length: 1, convRule: rule22 }
  , CharBlock { start: 1281, length: 1, convRule: rule23 }
  , CharBlock { start: 1282, length: 1, convRule: rule22 }
  , CharBlock { start: 1283, length: 1, convRule: rule23 }
  , CharBlock { start: 1284, length: 1, convRule: rule22 }
  , CharBlock { start: 1285, length: 1, convRule: rule23 }
  , CharBlock { start: 1286, length: 1, convRule: rule22 }
  , CharBlock { start: 1287, length: 1, convRule: rule23 }
  , CharBlock { start: 1288, length: 1, convRule: rule22 }
  , CharBlock { start: 1289, length: 1, convRule: rule23 }
  , CharBlock { start: 1290, length: 1, convRule: rule22 }
  , CharBlock { start: 1291, length: 1, convRule: rule23 }
  , CharBlock { start: 1292, length: 1, convRule: rule22 }
  , CharBlock { start: 1293, length: 1, convRule: rule23 }
  , CharBlock { start: 1294, length: 1, convRule: rule22 }
  , CharBlock { start: 1295, length: 1, convRule: rule23 }
  , CharBlock { start: 1296, length: 1, convRule: rule22 }
  , CharBlock { start: 1297, length: 1, convRule: rule23 }
  , CharBlock { start: 1298, length: 1, convRule: rule22 }
  , CharBlock { start: 1299, length: 1, convRule: rule23 }
  , CharBlock { start: 1300, length: 1, convRule: rule22 }
  , CharBlock { start: 1301, length: 1, convRule: rule23 }
  , CharBlock { start: 1302, length: 1, convRule: rule22 }
  , CharBlock { start: 1303, length: 1, convRule: rule23 }
  , CharBlock { start: 1304, length: 1, convRule: rule22 }
  , CharBlock { start: 1305, length: 1, convRule: rule23 }
  , CharBlock { start: 1306, length: 1, convRule: rule22 }
  , CharBlock { start: 1307, length: 1, convRule: rule23 }
  , CharBlock { start: 1308, length: 1, convRule: rule22 }
  , CharBlock { start: 1309, length: 1, convRule: rule23 }
  , CharBlock { start: 1310, length: 1, convRule: rule22 }
  , CharBlock { start: 1311, length: 1, convRule: rule23 }
  , CharBlock { start: 1312, length: 1, convRule: rule22 }
  , CharBlock { start: 1313, length: 1, convRule: rule23 }
  , CharBlock { start: 1314, length: 1, convRule: rule22 }
  , CharBlock { start: 1315, length: 1, convRule: rule23 }
  , CharBlock { start: 1316, length: 1, convRule: rule22 }
  , CharBlock { start: 1317, length: 1, convRule: rule23 }
  , CharBlock { start: 1318, length: 1, convRule: rule22 }
  , CharBlock { start: 1319, length: 1, convRule: rule23 }
  , CharBlock { start: 1320, length: 1, convRule: rule22 }
  , CharBlock { start: 1321, length: 1, convRule: rule23 }
  , CharBlock { start: 1322, length: 1, convRule: rule22 }
  , CharBlock { start: 1323, length: 1, convRule: rule23 }
  , CharBlock { start: 1324, length: 1, convRule: rule22 }
  , CharBlock { start: 1325, length: 1, convRule: rule23 }
  , CharBlock { start: 1326, length: 1, convRule: rule22 }
  , CharBlock { start: 1327, length: 1, convRule: rule23 }
  , CharBlock { start: 1329, length: 38, convRule: rule122 }
  , CharBlock { start: 1377, length: 38, convRule: rule123 }
  , CharBlock { start: 4256, length: 38, convRule: rule125 }
  , CharBlock { start: 4295, length: 1, convRule: rule125 }
  , CharBlock { start: 4301, length: 1, convRule: rule125 }
  , CharBlock { start: 4304, length: 43, convRule: rule126 }
  , CharBlock { start: 4349, length: 3, convRule: rule126 }
  , CharBlock { start: 5024, length: 80, convRule: rule127 }
  , CharBlock { start: 5104, length: 6, convRule: rule104 }
  , CharBlock { start: 5112, length: 6, convRule: rule110 }
  , CharBlock { start: 7296, length: 1, convRule: rule129 }
  , CharBlock { start: 7297, length: 1, convRule: rule130 }
  , CharBlock { start: 7298, length: 1, convRule: rule131 }
  , CharBlock { start: 7299, length: 2, convRule: rule132 }
  , CharBlock { start: 7301, length: 1, convRule: rule133 }
  , CharBlock { start: 7302, length: 1, convRule: rule134 }
  , CharBlock { start: 7303, length: 1, convRule: rule135 }
  , CharBlock { start: 7304, length: 1, convRule: rule136 }
  , CharBlock { start: 7312, length: 43, convRule: rule137 }
  , CharBlock { start: 7357, length: 3, convRule: rule137 }
  , CharBlock { start: 7545, length: 1, convRule: rule138 }
  , CharBlock { start: 7549, length: 1, convRule: rule139 }
  , CharBlock { start: 7566, length: 1, convRule: rule140 }
  , CharBlock { start: 7680, length: 1, convRule: rule22 }
  , CharBlock { start: 7681, length: 1, convRule: rule23 }
  , CharBlock { start: 7682, length: 1, convRule: rule22 }
  , CharBlock { start: 7683, length: 1, convRule: rule23 }
  , CharBlock { start: 7684, length: 1, convRule: rule22 }
  , CharBlock { start: 7685, length: 1, convRule: rule23 }
  , CharBlock { start: 7686, length: 1, convRule: rule22 }
  , CharBlock { start: 7687, length: 1, convRule: rule23 }
  , CharBlock { start: 7688, length: 1, convRule: rule22 }
  , CharBlock { start: 7689, length: 1, convRule: rule23 }
  , CharBlock { start: 7690, length: 1, convRule: rule22 }
  , CharBlock { start: 7691, length: 1, convRule: rule23 }
  , CharBlock { start: 7692, length: 1, convRule: rule22 }
  , CharBlock { start: 7693, length: 1, convRule: rule23 }
  , CharBlock { start: 7694, length: 1, convRule: rule22 }
  , CharBlock { start: 7695, length: 1, convRule: rule23 }
  , CharBlock { start: 7696, length: 1, convRule: rule22 }
  , CharBlock { start: 7697, length: 1, convRule: rule23 }
  , CharBlock { start: 7698, length: 1, convRule: rule22 }
  , CharBlock { start: 7699, length: 1, convRule: rule23 }
  , CharBlock { start: 7700, length: 1, convRule: rule22 }
  , CharBlock { start: 7701, length: 1, convRule: rule23 }
  , CharBlock { start: 7702, length: 1, convRule: rule22 }
  , CharBlock { start: 7703, length: 1, convRule: rule23 }
  , CharBlock { start: 7704, length: 1, convRule: rule22 }
  , CharBlock { start: 7705, length: 1, convRule: rule23 }
  , CharBlock { start: 7706, length: 1, convRule: rule22 }
  , CharBlock { start: 7707, length: 1, convRule: rule23 }
  , CharBlock { start: 7708, length: 1, convRule: rule22 }
  , CharBlock { start: 7709, length: 1, convRule: rule23 }
  , CharBlock { start: 7710, length: 1, convRule: rule22 }
  , CharBlock { start: 7711, length: 1, convRule: rule23 }
  , CharBlock { start: 7712, length: 1, convRule: rule22 }
  , CharBlock { start: 7713, length: 1, convRule: rule23 }
  , CharBlock { start: 7714, length: 1, convRule: rule22 }
  , CharBlock { start: 7715, length: 1, convRule: rule23 }
  , CharBlock { start: 7716, length: 1, convRule: rule22 }
  , CharBlock { start: 7717, length: 1, convRule: rule23 }
  , CharBlock { start: 7718, length: 1, convRule: rule22 }
  , CharBlock { start: 7719, length: 1, convRule: rule23 }
  , CharBlock { start: 7720, length: 1, convRule: rule22 }
  , CharBlock { start: 7721, length: 1, convRule: rule23 }
  , CharBlock { start: 7722, length: 1, convRule: rule22 }
  , CharBlock { start: 7723, length: 1, convRule: rule23 }
  , CharBlock { start: 7724, length: 1, convRule: rule22 }
  , CharBlock { start: 7725, length: 1, convRule: rule23 }
  , CharBlock { start: 7726, length: 1, convRule: rule22 }
  , CharBlock { start: 7727, length: 1, convRule: rule23 }
  , CharBlock { start: 7728, length: 1, convRule: rule22 }
  , CharBlock { start: 7729, length: 1, convRule: rule23 }
  , CharBlock { start: 7730, length: 1, convRule: rule22 }
  , CharBlock { start: 7731, length: 1, convRule: rule23 }
  , CharBlock { start: 7732, length: 1, convRule: rule22 }
  , CharBlock { start: 7733, length: 1, convRule: rule23 }
  , CharBlock { start: 7734, length: 1, convRule: rule22 }
  , CharBlock { start: 7735, length: 1, convRule: rule23 }
  , CharBlock { start: 7736, length: 1, convRule: rule22 }
  , CharBlock { start: 7737, length: 1, convRule: rule23 }
  , CharBlock { start: 7738, length: 1, convRule: rule22 }
  , CharBlock { start: 7739, length: 1, convRule: rule23 }
  , CharBlock { start: 7740, length: 1, convRule: rule22 }
  , CharBlock { start: 7741, length: 1, convRule: rule23 }
  , CharBlock { start: 7742, length: 1, convRule: rule22 }
  , CharBlock { start: 7743, length: 1, convRule: rule23 }
  , CharBlock { start: 7744, length: 1, convRule: rule22 }
  , CharBlock { start: 7745, length: 1, convRule: rule23 }
  , CharBlock { start: 7746, length: 1, convRule: rule22 }
  , CharBlock { start: 7747, length: 1, convRule: rule23 }
  , CharBlock { start: 7748, length: 1, convRule: rule22 }
  , CharBlock { start: 7749, length: 1, convRule: rule23 }
  , CharBlock { start: 7750, length: 1, convRule: rule22 }
  , CharBlock { start: 7751, length: 1, convRule: rule23 }
  , CharBlock { start: 7752, length: 1, convRule: rule22 }
  , CharBlock { start: 7753, length: 1, convRule: rule23 }
  , CharBlock { start: 7754, length: 1, convRule: rule22 }
  , CharBlock { start: 7755, length: 1, convRule: rule23 }
  , CharBlock { start: 7756, length: 1, convRule: rule22 }
  , CharBlock { start: 7757, length: 1, convRule: rule23 }
  , CharBlock { start: 7758, length: 1, convRule: rule22 }
  , CharBlock { start: 7759, length: 1, convRule: rule23 }
  , CharBlock { start: 7760, length: 1, convRule: rule22 }
  , CharBlock { start: 7761, length: 1, convRule: rule23 }
  , CharBlock { start: 7762, length: 1, convRule: rule22 }
  , CharBlock { start: 7763, length: 1, convRule: rule23 }
  , CharBlock { start: 7764, length: 1, convRule: rule22 }
  , CharBlock { start: 7765, length: 1, convRule: rule23 }
  , CharBlock { start: 7766, length: 1, convRule: rule22 }
  , CharBlock { start: 7767, length: 1, convRule: rule23 }
  , CharBlock { start: 7768, length: 1, convRule: rule22 }
  , CharBlock { start: 7769, length: 1, convRule: rule23 }
  , CharBlock { start: 7770, length: 1, convRule: rule22 }
  , CharBlock { start: 7771, length: 1, convRule: rule23 }
  , CharBlock { start: 7772, length: 1, convRule: rule22 }
  , CharBlock { start: 7773, length: 1, convRule: rule23 }
  , CharBlock { start: 7774, length: 1, convRule: rule22 }
  , CharBlock { start: 7775, length: 1, convRule: rule23 }
  , CharBlock { start: 7776, length: 1, convRule: rule22 }
  , CharBlock { start: 7777, length: 1, convRule: rule23 }
  , CharBlock { start: 7778, length: 1, convRule: rule22 }
  , CharBlock { start: 7779, length: 1, convRule: rule23 }
  , CharBlock { start: 7780, length: 1, convRule: rule22 }
  , CharBlock { start: 7781, length: 1, convRule: rule23 }
  , CharBlock { start: 7782, length: 1, convRule: rule22 }
  , CharBlock { start: 7783, length: 1, convRule: rule23 }
  , CharBlock { start: 7784, length: 1, convRule: rule22 }
  , CharBlock { start: 7785, length: 1, convRule: rule23 }
  , CharBlock { start: 7786, length: 1, convRule: rule22 }
  , CharBlock { start: 7787, length: 1, convRule: rule23 }
  , CharBlock { start: 7788, length: 1, convRule: rule22 }
  , CharBlock { start: 7789, length: 1, convRule: rule23 }
  , CharBlock { start: 7790, length: 1, convRule: rule22 }
  , CharBlock { start: 7791, length: 1, convRule: rule23 }
  , CharBlock { start: 7792, length: 1, convRule: rule22 }
  , CharBlock { start: 7793, length: 1, convRule: rule23 }
  , CharBlock { start: 7794, length: 1, convRule: rule22 }
  , CharBlock { start: 7795, length: 1, convRule: rule23 }
  , CharBlock { start: 7796, length: 1, convRule: rule22 }
  , CharBlock { start: 7797, length: 1, convRule: rule23 }
  , CharBlock { start: 7798, length: 1, convRule: rule22 }
  , CharBlock { start: 7799, length: 1, convRule: rule23 }
  , CharBlock { start: 7800, length: 1, convRule: rule22 }
  , CharBlock { start: 7801, length: 1, convRule: rule23 }
  , CharBlock { start: 7802, length: 1, convRule: rule22 }
  , CharBlock { start: 7803, length: 1, convRule: rule23 }
  , CharBlock { start: 7804, length: 1, convRule: rule22 }
  , CharBlock { start: 7805, length: 1, convRule: rule23 }
  , CharBlock { start: 7806, length: 1, convRule: rule22 }
  , CharBlock { start: 7807, length: 1, convRule: rule23 }
  , CharBlock { start: 7808, length: 1, convRule: rule22 }
  , CharBlock { start: 7809, length: 1, convRule: rule23 }
  , CharBlock { start: 7810, length: 1, convRule: rule22 }
  , CharBlock { start: 7811, length: 1, convRule: rule23 }
  , CharBlock { start: 7812, length: 1, convRule: rule22 }
  , CharBlock { start: 7813, length: 1, convRule: rule23 }
  , CharBlock { start: 7814, length: 1, convRule: rule22 }
  , CharBlock { start: 7815, length: 1, convRule: rule23 }
  , CharBlock { start: 7816, length: 1, convRule: rule22 }
  , CharBlock { start: 7817, length: 1, convRule: rule23 }
  , CharBlock { start: 7818, length: 1, convRule: rule22 }
  , CharBlock { start: 7819, length: 1, convRule: rule23 }
  , CharBlock { start: 7820, length: 1, convRule: rule22 }
  , CharBlock { start: 7821, length: 1, convRule: rule23 }
  , CharBlock { start: 7822, length: 1, convRule: rule22 }
  , CharBlock { start: 7823, length: 1, convRule: rule23 }
  , CharBlock { start: 7824, length: 1, convRule: rule22 }
  , CharBlock { start: 7825, length: 1, convRule: rule23 }
  , CharBlock { start: 7826, length: 1, convRule: rule22 }
  , CharBlock { start: 7827, length: 1, convRule: rule23 }
  , CharBlock { start: 7828, length: 1, convRule: rule22 }
  , CharBlock { start: 7829, length: 1, convRule: rule23 }
  , CharBlock { start: 7835, length: 1, convRule: rule141 }
  , CharBlock { start: 7838, length: 1, convRule: rule142 }
  , CharBlock { start: 7840, length: 1, convRule: rule22 }
  , CharBlock { start: 7841, length: 1, convRule: rule23 }
  , CharBlock { start: 7842, length: 1, convRule: rule22 }
  , CharBlock { start: 7843, length: 1, convRule: rule23 }
  , CharBlock { start: 7844, length: 1, convRule: rule22 }
  , CharBlock { start: 7845, length: 1, convRule: rule23 }
  , CharBlock { start: 7846, length: 1, convRule: rule22 }
  , CharBlock { start: 7847, length: 1, convRule: rule23 }
  , CharBlock { start: 7848, length: 1, convRule: rule22 }
  , CharBlock { start: 7849, length: 1, convRule: rule23 }
  , CharBlock { start: 7850, length: 1, convRule: rule22 }
  , CharBlock { start: 7851, length: 1, convRule: rule23 }
  , CharBlock { start: 7852, length: 1, convRule: rule22 }
  , CharBlock { start: 7853, length: 1, convRule: rule23 }
  , CharBlock { start: 7854, length: 1, convRule: rule22 }
  , CharBlock { start: 7855, length: 1, convRule: rule23 }
  , CharBlock { start: 7856, length: 1, convRule: rule22 }
  , CharBlock { start: 7857, length: 1, convRule: rule23 }
  , CharBlock { start: 7858, length: 1, convRule: rule22 }
  , CharBlock { start: 7859, length: 1, convRule: rule23 }
  , CharBlock { start: 7860, length: 1, convRule: rule22 }
  , CharBlock { start: 7861, length: 1, convRule: rule23 }
  , CharBlock { start: 7862, length: 1, convRule: rule22 }
  , CharBlock { start: 7863, length: 1, convRule: rule23 }
  , CharBlock { start: 7864, length: 1, convRule: rule22 }
  , CharBlock { start: 7865, length: 1, convRule: rule23 }
  , CharBlock { start: 7866, length: 1, convRule: rule22 }
  , CharBlock { start: 7867, length: 1, convRule: rule23 }
  , CharBlock { start: 7868, length: 1, convRule: rule22 }
  , CharBlock { start: 7869, length: 1, convRule: rule23 }
  , CharBlock { start: 7870, length: 1, convRule: rule22 }
  , CharBlock { start: 7871, length: 1, convRule: rule23 }
  , CharBlock { start: 7872, length: 1, convRule: rule22 }
  , CharBlock { start: 7873, length: 1, convRule: rule23 }
  , CharBlock { start: 7874, length: 1, convRule: rule22 }
  , CharBlock { start: 7875, length: 1, convRule: rule23 }
  , CharBlock { start: 7876, length: 1, convRule: rule22 }
  , CharBlock { start: 7877, length: 1, convRule: rule23 }
  , CharBlock { start: 7878, length: 1, convRule: rule22 }
  , CharBlock { start: 7879, length: 1, convRule: rule23 }
  , CharBlock { start: 7880, length: 1, convRule: rule22 }
  , CharBlock { start: 7881, length: 1, convRule: rule23 }
  , CharBlock { start: 7882, length: 1, convRule: rule22 }
  , CharBlock { start: 7883, length: 1, convRule: rule23 }
  , CharBlock { start: 7884, length: 1, convRule: rule22 }
  , CharBlock { start: 7885, length: 1, convRule: rule23 }
  , CharBlock { start: 7886, length: 1, convRule: rule22 }
  , CharBlock { start: 7887, length: 1, convRule: rule23 }
  , CharBlock { start: 7888, length: 1, convRule: rule22 }
  , CharBlock { start: 7889, length: 1, convRule: rule23 }
  , CharBlock { start: 7890, length: 1, convRule: rule22 }
  , CharBlock { start: 7891, length: 1, convRule: rule23 }
  , CharBlock { start: 7892, length: 1, convRule: rule22 }
  , CharBlock { start: 7893, length: 1, convRule: rule23 }
  , CharBlock { start: 7894, length: 1, convRule: rule22 }
  , CharBlock { start: 7895, length: 1, convRule: rule23 }
  , CharBlock { start: 7896, length: 1, convRule: rule22 }
  , CharBlock { start: 7897, length: 1, convRule: rule23 }
  , CharBlock { start: 7898, length: 1, convRule: rule22 }
  , CharBlock { start: 7899, length: 1, convRule: rule23 }
  , CharBlock { start: 7900, length: 1, convRule: rule22 }
  , CharBlock { start: 7901, length: 1, convRule: rule23 }
  , CharBlock { start: 7902, length: 1, convRule: rule22 }
  , CharBlock { start: 7903, length: 1, convRule: rule23 }
  , CharBlock { start: 7904, length: 1, convRule: rule22 }
  , CharBlock { start: 7905, length: 1, convRule: rule23 }
  , CharBlock { start: 7906, length: 1, convRule: rule22 }
  , CharBlock { start: 7907, length: 1, convRule: rule23 }
  , CharBlock { start: 7908, length: 1, convRule: rule22 }
  , CharBlock { start: 7909, length: 1, convRule: rule23 }
  , CharBlock { start: 7910, length: 1, convRule: rule22 }
  , CharBlock { start: 7911, length: 1, convRule: rule23 }
  , CharBlock { start: 7912, length: 1, convRule: rule22 }
  , CharBlock { start: 7913, length: 1, convRule: rule23 }
  , CharBlock { start: 7914, length: 1, convRule: rule22 }
  , CharBlock { start: 7915, length: 1, convRule: rule23 }
  , CharBlock { start: 7916, length: 1, convRule: rule22 }
  , CharBlock { start: 7917, length: 1, convRule: rule23 }
  , CharBlock { start: 7918, length: 1, convRule: rule22 }
  , CharBlock { start: 7919, length: 1, convRule: rule23 }
  , CharBlock { start: 7920, length: 1, convRule: rule22 }
  , CharBlock { start: 7921, length: 1, convRule: rule23 }
  , CharBlock { start: 7922, length: 1, convRule: rule22 }
  , CharBlock { start: 7923, length: 1, convRule: rule23 }
  , CharBlock { start: 7924, length: 1, convRule: rule22 }
  , CharBlock { start: 7925, length: 1, convRule: rule23 }
  , CharBlock { start: 7926, length: 1, convRule: rule22 }
  , CharBlock { start: 7927, length: 1, convRule: rule23 }
  , CharBlock { start: 7928, length: 1, convRule: rule22 }
  , CharBlock { start: 7929, length: 1, convRule: rule23 }
  , CharBlock { start: 7930, length: 1, convRule: rule22 }
  , CharBlock { start: 7931, length: 1, convRule: rule23 }
  , CharBlock { start: 7932, length: 1, convRule: rule22 }
  , CharBlock { start: 7933, length: 1, convRule: rule23 }
  , CharBlock { start: 7934, length: 1, convRule: rule22 }
  , CharBlock { start: 7935, length: 1, convRule: rule23 }
  , CharBlock { start: 7936, length: 8, convRule: rule143 }
  , CharBlock { start: 7944, length: 8, convRule: rule144 }
  , CharBlock { start: 7952, length: 6, convRule: rule143 }
  , CharBlock { start: 7960, length: 6, convRule: rule144 }
  , CharBlock { start: 7968, length: 8, convRule: rule143 }
  , CharBlock { start: 7976, length: 8, convRule: rule144 }
  , CharBlock { start: 7984, length: 8, convRule: rule143 }
  , CharBlock { start: 7992, length: 8, convRule: rule144 }
  , CharBlock { start: 8000, length: 6, convRule: rule143 }
  , CharBlock { start: 8008, length: 6, convRule: rule144 }
  , CharBlock { start: 8017, length: 1, convRule: rule143 }
  , CharBlock { start: 8019, length: 1, convRule: rule143 }
  , CharBlock { start: 8021, length: 1, convRule: rule143 }
  , CharBlock { start: 8023, length: 1, convRule: rule143 }
  , CharBlock { start: 8025, length: 1, convRule: rule144 }
  , CharBlock { start: 8027, length: 1, convRule: rule144 }
  , CharBlock { start: 8029, length: 1, convRule: rule144 }
  , CharBlock { start: 8031, length: 1, convRule: rule144 }
  , CharBlock { start: 8032, length: 8, convRule: rule143 }
  , CharBlock { start: 8040, length: 8, convRule: rule144 }
  , CharBlock { start: 8048, length: 2, convRule: rule145 }
  , CharBlock { start: 8050, length: 4, convRule: rule146 }
  , CharBlock { start: 8054, length: 2, convRule: rule147 }
  , CharBlock { start: 8056, length: 2, convRule: rule148 }
  , CharBlock { start: 8058, length: 2, convRule: rule149 }
  , CharBlock { start: 8060, length: 2, convRule: rule150 }
  , CharBlock { start: 8064, length: 8, convRule: rule143 }
  , CharBlock { start: 8072, length: 8, convRule: rule151 }
  , CharBlock { start: 8080, length: 8, convRule: rule143 }
  , CharBlock { start: 8088, length: 8, convRule: rule151 }
  , CharBlock { start: 8096, length: 8, convRule: rule143 }
  , CharBlock { start: 8104, length: 8, convRule: rule151 }
  , CharBlock { start: 8112, length: 2, convRule: rule143 }
  , CharBlock { start: 8115, length: 1, convRule: rule152 }
  , CharBlock { start: 8120, length: 2, convRule: rule144 }
  , CharBlock { start: 8122, length: 2, convRule: rule153 }
  , CharBlock { start: 8124, length: 1, convRule: rule154 }
  , CharBlock { start: 8126, length: 1, convRule: rule155 }
  , CharBlock { start: 8131, length: 1, convRule: rule152 }
  , CharBlock { start: 8136, length: 4, convRule: rule156 }
  , CharBlock { start: 8140, length: 1, convRule: rule154 }
  , CharBlock { start: 8144, length: 2, convRule: rule143 }
  , CharBlock { start: 8152, length: 2, convRule: rule144 }
  , CharBlock { start: 8154, length: 2, convRule: rule157 }
  , CharBlock { start: 8160, length: 2, convRule: rule143 }
  , CharBlock { start: 8165, length: 1, convRule: rule113 }
  , CharBlock { start: 8168, length: 2, convRule: rule144 }
  , CharBlock { start: 8170, length: 2, convRule: rule158 }
  , CharBlock { start: 8172, length: 1, convRule: rule117 }
  , CharBlock { start: 8179, length: 1, convRule: rule152 }
  , CharBlock { start: 8184, length: 2, convRule: rule159 }
  , CharBlock { start: 8186, length: 2, convRule: rule160 }
  , CharBlock { start: 8188, length: 1, convRule: rule154 }
  , CharBlock { start: 8486, length: 1, convRule: rule163 }
  , CharBlock { start: 8490, length: 1, convRule: rule164 }
  , CharBlock { start: 8491, length: 1, convRule: rule165 }
  , CharBlock { start: 8498, length: 1, convRule: rule166 }
  , CharBlock { start: 8526, length: 1, convRule: rule167 }
  , CharBlock { start: 8544, length: 16, convRule: rule168 }
  , CharBlock { start: 8560, length: 16, convRule: rule169 }
  , CharBlock { start: 8579, length: 1, convRule: rule22 }
  , CharBlock { start: 8580, length: 1, convRule: rule23 }
  , CharBlock { start: 9398, length: 26, convRule: rule170 }
  , CharBlock { start: 9424, length: 26, convRule: rule171 }
  , CharBlock { start: 11264, length: 47, convRule: rule122 }
  , CharBlock { start: 11312, length: 47, convRule: rule123 }
  , CharBlock { start: 11360, length: 1, convRule: rule22 }
  , CharBlock { start: 11361, length: 1, convRule: rule23 }
  , CharBlock { start: 11362, length: 1, convRule: rule172 }
  , CharBlock { start: 11363, length: 1, convRule: rule173 }
  , CharBlock { start: 11364, length: 1, convRule: rule174 }
  , CharBlock { start: 11365, length: 1, convRule: rule175 }
  , CharBlock { start: 11366, length: 1, convRule: rule176 }
  , CharBlock { start: 11367, length: 1, convRule: rule22 }
  , CharBlock { start: 11368, length: 1, convRule: rule23 }
  , CharBlock { start: 11369, length: 1, convRule: rule22 }
  , CharBlock { start: 11370, length: 1, convRule: rule23 }
  , CharBlock { start: 11371, length: 1, convRule: rule22 }
  , CharBlock { start: 11372, length: 1, convRule: rule23 }
  , CharBlock { start: 11373, length: 1, convRule: rule177 }
  , CharBlock { start: 11374, length: 1, convRule: rule178 }
  , CharBlock { start: 11375, length: 1, convRule: rule179 }
  , CharBlock { start: 11376, length: 1, convRule: rule180 }
  , CharBlock { start: 11378, length: 1, convRule: rule22 }
  , CharBlock { start: 11379, length: 1, convRule: rule23 }
  , CharBlock { start: 11381, length: 1, convRule: rule22 }
  , CharBlock { start: 11382, length: 1, convRule: rule23 }
  , CharBlock { start: 11390, length: 2, convRule: rule181 }
  , CharBlock { start: 11392, length: 1, convRule: rule22 }
  , CharBlock { start: 11393, length: 1, convRule: rule23 }
  , CharBlock { start: 11394, length: 1, convRule: rule22 }
  , CharBlock { start: 11395, length: 1, convRule: rule23 }
  , CharBlock { start: 11396, length: 1, convRule: rule22 }
  , CharBlock { start: 11397, length: 1, convRule: rule23 }
  , CharBlock { start: 11398, length: 1, convRule: rule22 }
  , CharBlock { start: 11399, length: 1, convRule: rule23 }
  , CharBlock { start: 11400, length: 1, convRule: rule22 }
  , CharBlock { start: 11401, length: 1, convRule: rule23 }
  , CharBlock { start: 11402, length: 1, convRule: rule22 }
  , CharBlock { start: 11403, length: 1, convRule: rule23 }
  , CharBlock { start: 11404, length: 1, convRule: rule22 }
  , CharBlock { start: 11405, length: 1, convRule: rule23 }
  , CharBlock { start: 11406, length: 1, convRule: rule22 }
  , CharBlock { start: 11407, length: 1, convRule: rule23 }
  , CharBlock { start: 11408, length: 1, convRule: rule22 }
  , CharBlock { start: 11409, length: 1, convRule: rule23 }
  , CharBlock { start: 11410, length: 1, convRule: rule22 }
  , CharBlock { start: 11411, length: 1, convRule: rule23 }
  , CharBlock { start: 11412, length: 1, convRule: rule22 }
  , CharBlock { start: 11413, length: 1, convRule: rule23 }
  , CharBlock { start: 11414, length: 1, convRule: rule22 }
  , CharBlock { start: 11415, length: 1, convRule: rule23 }
  , CharBlock { start: 11416, length: 1, convRule: rule22 }
  , CharBlock { start: 11417, length: 1, convRule: rule23 }
  , CharBlock { start: 11418, length: 1, convRule: rule22 }
  , CharBlock { start: 11419, length: 1, convRule: rule23 }
  , CharBlock { start: 11420, length: 1, convRule: rule22 }
  , CharBlock { start: 11421, length: 1, convRule: rule23 }
  , CharBlock { start: 11422, length: 1, convRule: rule22 }
  , CharBlock { start: 11423, length: 1, convRule: rule23 }
  , CharBlock { start: 11424, length: 1, convRule: rule22 }
  , CharBlock { start: 11425, length: 1, convRule: rule23 }
  , CharBlock { start: 11426, length: 1, convRule: rule22 }
  , CharBlock { start: 11427, length: 1, convRule: rule23 }
  , CharBlock { start: 11428, length: 1, convRule: rule22 }
  , CharBlock { start: 11429, length: 1, convRule: rule23 }
  , CharBlock { start: 11430, length: 1, convRule: rule22 }
  , CharBlock { start: 11431, length: 1, convRule: rule23 }
  , CharBlock { start: 11432, length: 1, convRule: rule22 }
  , CharBlock { start: 11433, length: 1, convRule: rule23 }
  , CharBlock { start: 11434, length: 1, convRule: rule22 }
  , CharBlock { start: 11435, length: 1, convRule: rule23 }
  , CharBlock { start: 11436, length: 1, convRule: rule22 }
  , CharBlock { start: 11437, length: 1, convRule: rule23 }
  , CharBlock { start: 11438, length: 1, convRule: rule22 }
  , CharBlock { start: 11439, length: 1, convRule: rule23 }
  , CharBlock { start: 11440, length: 1, convRule: rule22 }
  , CharBlock { start: 11441, length: 1, convRule: rule23 }
  , CharBlock { start: 11442, length: 1, convRule: rule22 }
  , CharBlock { start: 11443, length: 1, convRule: rule23 }
  , CharBlock { start: 11444, length: 1, convRule: rule22 }
  , CharBlock { start: 11445, length: 1, convRule: rule23 }
  , CharBlock { start: 11446, length: 1, convRule: rule22 }
  , CharBlock { start: 11447, length: 1, convRule: rule23 }
  , CharBlock { start: 11448, length: 1, convRule: rule22 }
  , CharBlock { start: 11449, length: 1, convRule: rule23 }
  , CharBlock { start: 11450, length: 1, convRule: rule22 }
  , CharBlock { start: 11451, length: 1, convRule: rule23 }
  , CharBlock { start: 11452, length: 1, convRule: rule22 }
  , CharBlock { start: 11453, length: 1, convRule: rule23 }
  , CharBlock { start: 11454, length: 1, convRule: rule22 }
  , CharBlock { start: 11455, length: 1, convRule: rule23 }
  , CharBlock { start: 11456, length: 1, convRule: rule22 }
  , CharBlock { start: 11457, length: 1, convRule: rule23 }
  , CharBlock { start: 11458, length: 1, convRule: rule22 }
  , CharBlock { start: 11459, length: 1, convRule: rule23 }
  , CharBlock { start: 11460, length: 1, convRule: rule22 }
  , CharBlock { start: 11461, length: 1, convRule: rule23 }
  , CharBlock { start: 11462, length: 1, convRule: rule22 }
  , CharBlock { start: 11463, length: 1, convRule: rule23 }
  , CharBlock { start: 11464, length: 1, convRule: rule22 }
  , CharBlock { start: 11465, length: 1, convRule: rule23 }
  , CharBlock { start: 11466, length: 1, convRule: rule22 }
  , CharBlock { start: 11467, length: 1, convRule: rule23 }
  , CharBlock { start: 11468, length: 1, convRule: rule22 }
  , CharBlock { start: 11469, length: 1, convRule: rule23 }
  , CharBlock { start: 11470, length: 1, convRule: rule22 }
  , CharBlock { start: 11471, length: 1, convRule: rule23 }
  , CharBlock { start: 11472, length: 1, convRule: rule22 }
  , CharBlock { start: 11473, length: 1, convRule: rule23 }
  , CharBlock { start: 11474, length: 1, convRule: rule22 }
  , CharBlock { start: 11475, length: 1, convRule: rule23 }
  , CharBlock { start: 11476, length: 1, convRule: rule22 }
  , CharBlock { start: 11477, length: 1, convRule: rule23 }
  , CharBlock { start: 11478, length: 1, convRule: rule22 }
  , CharBlock { start: 11479, length: 1, convRule: rule23 }
  , CharBlock { start: 11480, length: 1, convRule: rule22 }
  , CharBlock { start: 11481, length: 1, convRule: rule23 }
  , CharBlock { start: 11482, length: 1, convRule: rule22 }
  , CharBlock { start: 11483, length: 1, convRule: rule23 }
  , CharBlock { start: 11484, length: 1, convRule: rule22 }
  , CharBlock { start: 11485, length: 1, convRule: rule23 }
  , CharBlock { start: 11486, length: 1, convRule: rule22 }
  , CharBlock { start: 11487, length: 1, convRule: rule23 }
  , CharBlock { start: 11488, length: 1, convRule: rule22 }
  , CharBlock { start: 11489, length: 1, convRule: rule23 }
  , CharBlock { start: 11490, length: 1, convRule: rule22 }
  , CharBlock { start: 11491, length: 1, convRule: rule23 }
  , CharBlock { start: 11499, length: 1, convRule: rule22 }
  , CharBlock { start: 11500, length: 1, convRule: rule23 }
  , CharBlock { start: 11501, length: 1, convRule: rule22 }
  , CharBlock { start: 11502, length: 1, convRule: rule23 }
  , CharBlock { start: 11506, length: 1, convRule: rule22 }
  , CharBlock { start: 11507, length: 1, convRule: rule23 }
  , CharBlock { start: 11520, length: 38, convRule: rule182 }
  , CharBlock { start: 11559, length: 1, convRule: rule182 }
  , CharBlock { start: 11565, length: 1, convRule: rule182 }
  , CharBlock { start: 42560, length: 1, convRule: rule22 }
  , CharBlock { start: 42561, length: 1, convRule: rule23 }
  , CharBlock { start: 42562, length: 1, convRule: rule22 }
  , CharBlock { start: 42563, length: 1, convRule: rule23 }
  , CharBlock { start: 42564, length: 1, convRule: rule22 }
  , CharBlock { start: 42565, length: 1, convRule: rule23 }
  , CharBlock { start: 42566, length: 1, convRule: rule22 }
  , CharBlock { start: 42567, length: 1, convRule: rule23 }
  , CharBlock { start: 42568, length: 1, convRule: rule22 }
  , CharBlock { start: 42569, length: 1, convRule: rule23 }
  , CharBlock { start: 42570, length: 1, convRule: rule22 }
  , CharBlock { start: 42571, length: 1, convRule: rule23 }
  , CharBlock { start: 42572, length: 1, convRule: rule22 }
  , CharBlock { start: 42573, length: 1, convRule: rule23 }
  , CharBlock { start: 42574, length: 1, convRule: rule22 }
  , CharBlock { start: 42575, length: 1, convRule: rule23 }
  , CharBlock { start: 42576, length: 1, convRule: rule22 }
  , CharBlock { start: 42577, length: 1, convRule: rule23 }
  , CharBlock { start: 42578, length: 1, convRule: rule22 }
  , CharBlock { start: 42579, length: 1, convRule: rule23 }
  , CharBlock { start: 42580, length: 1, convRule: rule22 }
  , CharBlock { start: 42581, length: 1, convRule: rule23 }
  , CharBlock { start: 42582, length: 1, convRule: rule22 }
  , CharBlock { start: 42583, length: 1, convRule: rule23 }
  , CharBlock { start: 42584, length: 1, convRule: rule22 }
  , CharBlock { start: 42585, length: 1, convRule: rule23 }
  , CharBlock { start: 42586, length: 1, convRule: rule22 }
  , CharBlock { start: 42587, length: 1, convRule: rule23 }
  , CharBlock { start: 42588, length: 1, convRule: rule22 }
  , CharBlock { start: 42589, length: 1, convRule: rule23 }
  , CharBlock { start: 42590, length: 1, convRule: rule22 }
  , CharBlock { start: 42591, length: 1, convRule: rule23 }
  , CharBlock { start: 42592, length: 1, convRule: rule22 }
  , CharBlock { start: 42593, length: 1, convRule: rule23 }
  , CharBlock { start: 42594, length: 1, convRule: rule22 }
  , CharBlock { start: 42595, length: 1, convRule: rule23 }
  , CharBlock { start: 42596, length: 1, convRule: rule22 }
  , CharBlock { start: 42597, length: 1, convRule: rule23 }
  , CharBlock { start: 42598, length: 1, convRule: rule22 }
  , CharBlock { start: 42599, length: 1, convRule: rule23 }
  , CharBlock { start: 42600, length: 1, convRule: rule22 }
  , CharBlock { start: 42601, length: 1, convRule: rule23 }
  , CharBlock { start: 42602, length: 1, convRule: rule22 }
  , CharBlock { start: 42603, length: 1, convRule: rule23 }
  , CharBlock { start: 42604, length: 1, convRule: rule22 }
  , CharBlock { start: 42605, length: 1, convRule: rule23 }
  , CharBlock { start: 42624, length: 1, convRule: rule22 }
  , CharBlock { start: 42625, length: 1, convRule: rule23 }
  , CharBlock { start: 42626, length: 1, convRule: rule22 }
  , CharBlock { start: 42627, length: 1, convRule: rule23 }
  , CharBlock { start: 42628, length: 1, convRule: rule22 }
  , CharBlock { start: 42629, length: 1, convRule: rule23 }
  , CharBlock { start: 42630, length: 1, convRule: rule22 }
  , CharBlock { start: 42631, length: 1, convRule: rule23 }
  , CharBlock { start: 42632, length: 1, convRule: rule22 }
  , CharBlock { start: 42633, length: 1, convRule: rule23 }
  , CharBlock { start: 42634, length: 1, convRule: rule22 }
  , CharBlock { start: 42635, length: 1, convRule: rule23 }
  , CharBlock { start: 42636, length: 1, convRule: rule22 }
  , CharBlock { start: 42637, length: 1, convRule: rule23 }
  , CharBlock { start: 42638, length: 1, convRule: rule22 }
  , CharBlock { start: 42639, length: 1, convRule: rule23 }
  , CharBlock { start: 42640, length: 1, convRule: rule22 }
  , CharBlock { start: 42641, length: 1, convRule: rule23 }
  , CharBlock { start: 42642, length: 1, convRule: rule22 }
  , CharBlock { start: 42643, length: 1, convRule: rule23 }
  , CharBlock { start: 42644, length: 1, convRule: rule22 }
  , CharBlock { start: 42645, length: 1, convRule: rule23 }
  , CharBlock { start: 42646, length: 1, convRule: rule22 }
  , CharBlock { start: 42647, length: 1, convRule: rule23 }
  , CharBlock { start: 42648, length: 1, convRule: rule22 }
  , CharBlock { start: 42649, length: 1, convRule: rule23 }
  , CharBlock { start: 42650, length: 1, convRule: rule22 }
  , CharBlock { start: 42651, length: 1, convRule: rule23 }
  , CharBlock { start: 42786, length: 1, convRule: rule22 }
  , CharBlock { start: 42787, length: 1, convRule: rule23 }
  , CharBlock { start: 42788, length: 1, convRule: rule22 }
  , CharBlock { start: 42789, length: 1, convRule: rule23 }
  , CharBlock { start: 42790, length: 1, convRule: rule22 }
  , CharBlock { start: 42791, length: 1, convRule: rule23 }
  , CharBlock { start: 42792, length: 1, convRule: rule22 }
  , CharBlock { start: 42793, length: 1, convRule: rule23 }
  , CharBlock { start: 42794, length: 1, convRule: rule22 }
  , CharBlock { start: 42795, length: 1, convRule: rule23 }
  , CharBlock { start: 42796, length: 1, convRule: rule22 }
  , CharBlock { start: 42797, length: 1, convRule: rule23 }
  , CharBlock { start: 42798, length: 1, convRule: rule22 }
  , CharBlock { start: 42799, length: 1, convRule: rule23 }
  , CharBlock { start: 42802, length: 1, convRule: rule22 }
  , CharBlock { start: 42803, length: 1, convRule: rule23 }
  , CharBlock { start: 42804, length: 1, convRule: rule22 }
  , CharBlock { start: 42805, length: 1, convRule: rule23 }
  , CharBlock { start: 42806, length: 1, convRule: rule22 }
  , CharBlock { start: 42807, length: 1, convRule: rule23 }
  , CharBlock { start: 42808, length: 1, convRule: rule22 }
  , CharBlock { start: 42809, length: 1, convRule: rule23 }
  , CharBlock { start: 42810, length: 1, convRule: rule22 }
  , CharBlock { start: 42811, length: 1, convRule: rule23 }
  , CharBlock { start: 42812, length: 1, convRule: rule22 }
  , CharBlock { start: 42813, length: 1, convRule: rule23 }
  , CharBlock { start: 42814, length: 1, convRule: rule22 }
  , CharBlock { start: 42815, length: 1, convRule: rule23 }
  , CharBlock { start: 42816, length: 1, convRule: rule22 }
  , CharBlock { start: 42817, length: 1, convRule: rule23 }
  , CharBlock { start: 42818, length: 1, convRule: rule22 }
  , CharBlock { start: 42819, length: 1, convRule: rule23 }
  , CharBlock { start: 42820, length: 1, convRule: rule22 }
  , CharBlock { start: 42821, length: 1, convRule: rule23 }
  , CharBlock { start: 42822, length: 1, convRule: rule22 }
  , CharBlock { start: 42823, length: 1, convRule: rule23 }
  , CharBlock { start: 42824, length: 1, convRule: rule22 }
  , CharBlock { start: 42825, length: 1, convRule: rule23 }
  , CharBlock { start: 42826, length: 1, convRule: rule22 }
  , CharBlock { start: 42827, length: 1, convRule: rule23 }
  , CharBlock { start: 42828, length: 1, convRule: rule22 }
  , CharBlock { start: 42829, length: 1, convRule: rule23 }
  , CharBlock { start: 42830, length: 1, convRule: rule22 }
  , CharBlock { start: 42831, length: 1, convRule: rule23 }
  , CharBlock { start: 42832, length: 1, convRule: rule22 }
  , CharBlock { start: 42833, length: 1, convRule: rule23 }
  , CharBlock { start: 42834, length: 1, convRule: rule22 }
  , CharBlock { start: 42835, length: 1, convRule: rule23 }
  , CharBlock { start: 42836, length: 1, convRule: rule22 }
  , CharBlock { start: 42837, length: 1, convRule: rule23 }
  , CharBlock { start: 42838, length: 1, convRule: rule22 }
  , CharBlock { start: 42839, length: 1, convRule: rule23 }
  , CharBlock { start: 42840, length: 1, convRule: rule22 }
  , CharBlock { start: 42841, length: 1, convRule: rule23 }
  , CharBlock { start: 42842, length: 1, convRule: rule22 }
  , CharBlock { start: 42843, length: 1, convRule: rule23 }
  , CharBlock { start: 42844, length: 1, convRule: rule22 }
  , CharBlock { start: 42845, length: 1, convRule: rule23 }
  , CharBlock { start: 42846, length: 1, convRule: rule22 }
  , CharBlock { start: 42847, length: 1, convRule: rule23 }
  , CharBlock { start: 42848, length: 1, convRule: rule22 }
  , CharBlock { start: 42849, length: 1, convRule: rule23 }
  , CharBlock { start: 42850, length: 1, convRule: rule22 }
  , CharBlock { start: 42851, length: 1, convRule: rule23 }
  , CharBlock { start: 42852, length: 1, convRule: rule22 }
  , CharBlock { start: 42853, length: 1, convRule: rule23 }
  , CharBlock { start: 42854, length: 1, convRule: rule22 }
  , CharBlock { start: 42855, length: 1, convRule: rule23 }
  , CharBlock { start: 42856, length: 1, convRule: rule22 }
  , CharBlock { start: 42857, length: 1, convRule: rule23 }
  , CharBlock { start: 42858, length: 1, convRule: rule22 }
  , CharBlock { start: 42859, length: 1, convRule: rule23 }
  , CharBlock { start: 42860, length: 1, convRule: rule22 }
  , CharBlock { start: 42861, length: 1, convRule: rule23 }
  , CharBlock { start: 42862, length: 1, convRule: rule22 }
  , CharBlock { start: 42863, length: 1, convRule: rule23 }
  , CharBlock { start: 42873, length: 1, convRule: rule22 }
  , CharBlock { start: 42874, length: 1, convRule: rule23 }
  , CharBlock { start: 42875, length: 1, convRule: rule22 }
  , CharBlock { start: 42876, length: 1, convRule: rule23 }
  , CharBlock { start: 42877, length: 1, convRule: rule183 }
  , CharBlock { start: 42878, length: 1, convRule: rule22 }
  , CharBlock { start: 42879, length: 1, convRule: rule23 }
  , CharBlock { start: 42880, length: 1, convRule: rule22 }
  , CharBlock { start: 42881, length: 1, convRule: rule23 }
  , CharBlock { start: 42882, length: 1, convRule: rule22 }
  , CharBlock { start: 42883, length: 1, convRule: rule23 }
  , CharBlock { start: 42884, length: 1, convRule: rule22 }
  , CharBlock { start: 42885, length: 1, convRule: rule23 }
  , CharBlock { start: 42886, length: 1, convRule: rule22 }
  , CharBlock { start: 42887, length: 1, convRule: rule23 }
  , CharBlock { start: 42891, length: 1, convRule: rule22 }
  , CharBlock { start: 42892, length: 1, convRule: rule23 }
  , CharBlock { start: 42893, length: 1, convRule: rule184 }
  , CharBlock { start: 42896, length: 1, convRule: rule22 }
  , CharBlock { start: 42897, length: 1, convRule: rule23 }
  , CharBlock { start: 42898, length: 1, convRule: rule22 }
  , CharBlock { start: 42899, length: 1, convRule: rule23 }
  , CharBlock { start: 42900, length: 1, convRule: rule185 }
  , CharBlock { start: 42902, length: 1, convRule: rule22 }
  , CharBlock { start: 42903, length: 1, convRule: rule23 }
  , CharBlock { start: 42904, length: 1, convRule: rule22 }
  , CharBlock { start: 42905, length: 1, convRule: rule23 }
  , CharBlock { start: 42906, length: 1, convRule: rule22 }
  , CharBlock { start: 42907, length: 1, convRule: rule23 }
  , CharBlock { start: 42908, length: 1, convRule: rule22 }
  , CharBlock { start: 42909, length: 1, convRule: rule23 }
  , CharBlock { start: 42910, length: 1, convRule: rule22 }
  , CharBlock { start: 42911, length: 1, convRule: rule23 }
  , CharBlock { start: 42912, length: 1, convRule: rule22 }
  , CharBlock { start: 42913, length: 1, convRule: rule23 }
  , CharBlock { start: 42914, length: 1, convRule: rule22 }
  , CharBlock { start: 42915, length: 1, convRule: rule23 }
  , CharBlock { start: 42916, length: 1, convRule: rule22 }
  , CharBlock { start: 42917, length: 1, convRule: rule23 }
  , CharBlock { start: 42918, length: 1, convRule: rule22 }
  , CharBlock { start: 42919, length: 1, convRule: rule23 }
  , CharBlock { start: 42920, length: 1, convRule: rule22 }
  , CharBlock { start: 42921, length: 1, convRule: rule23 }
  , CharBlock { start: 42922, length: 1, convRule: rule186 }
  , CharBlock { start: 42923, length: 1, convRule: rule187 }
  , CharBlock { start: 42924, length: 1, convRule: rule188 }
  , CharBlock { start: 42925, length: 1, convRule: rule189 }
  , CharBlock { start: 42926, length: 1, convRule: rule186 }
  , CharBlock { start: 42928, length: 1, convRule: rule190 }
  , CharBlock { start: 42929, length: 1, convRule: rule191 }
  , CharBlock { start: 42930, length: 1, convRule: rule192 }
  , CharBlock { start: 42931, length: 1, convRule: rule193 }
  , CharBlock { start: 42932, length: 1, convRule: rule22 }
  , CharBlock { start: 42933, length: 1, convRule: rule23 }
  , CharBlock { start: 42934, length: 1, convRule: rule22 }
  , CharBlock { start: 42935, length: 1, convRule: rule23 }
  , CharBlock { start: 42936, length: 1, convRule: rule22 }
  , CharBlock { start: 42937, length: 1, convRule: rule23 }
  , CharBlock { start: 42938, length: 1, convRule: rule22 }
  , CharBlock { start: 42939, length: 1, convRule: rule23 }
  , CharBlock { start: 42940, length: 1, convRule: rule22 }
  , CharBlock { start: 42941, length: 1, convRule: rule23 }
  , CharBlock { start: 42942, length: 1, convRule: rule22 }
  , CharBlock { start: 42943, length: 1, convRule: rule23 }
  , CharBlock { start: 42946, length: 1, convRule: rule22 }
  , CharBlock { start: 42947, length: 1, convRule: rule23 }
  , CharBlock { start: 42948, length: 1, convRule: rule194 }
  , CharBlock { start: 42949, length: 1, convRule: rule195 }
  , CharBlock { start: 42950, length: 1, convRule: rule196 }
  , CharBlock { start: 42951, length: 1, convRule: rule22 }
  , CharBlock { start: 42952, length: 1, convRule: rule23 }
  , CharBlock { start: 42953, length: 1, convRule: rule22 }
  , CharBlock { start: 42954, length: 1, convRule: rule23 }
  , CharBlock { start: 42997, length: 1, convRule: rule22 }
  , CharBlock { start: 42998, length: 1, convRule: rule23 }
  , CharBlock { start: 43859, length: 1, convRule: rule197 }
  , CharBlock { start: 43888, length: 80, convRule: rule198 }
  , CharBlock { start: 65313, length: 26, convRule: rule9 }
  , CharBlock { start: 65345, length: 26, convRule: rule12 }
  , CharBlock { start: 66560, length: 40, convRule: rule201 }
  , CharBlock { start: 66600, length: 40, convRule: rule202 }
  , CharBlock { start: 66736, length: 36, convRule: rule201 }
  , CharBlock { start: 66776, length: 36, convRule: rule202 }
  , CharBlock { start: 68736, length: 51, convRule: rule97 }
  , CharBlock { start: 68800, length: 51, convRule: rule102 }
  , CharBlock { start: 71840, length: 32, convRule: rule9 }
  , CharBlock { start: 71872, length: 32, convRule: rule12 }
  , CharBlock { start: 93760, length: 32, convRule: rule9 }
  , CharBlock { start: 93792, length: 32, convRule: rule12 }
  , CharBlock { start: 125184, length: 34, convRule: rule203 }
  , CharBlock { start: 125218, length: 34, convRule: rule204 }
  ]

spacechars :: Array CharBlock
spacechars =
  [ CharBlock { start: 32, length: 1, convRule: rule1 }
  , CharBlock { start: 160, length: 1, convRule: rule1 }
  , CharBlock { start: 5760, length: 1, convRule: rule1 }
  , CharBlock { start: 8192, length: 11, convRule: rule1 }
  , CharBlock { start: 8239, length: 1, convRule: rule1 }
  , CharBlock { start: 8287, length: 1, convRule: rule1 }
  , CharBlock { start: 12288, length: 1, convRule: rule1 }
  ]

-- Obtain the reference to character rule by doing
-- binary search over the specified array of blocks.
-- To make checkattr shorter, the address of
-- nullrule is returned if the search fails:
-- this rule defines no category and no conversion
-- distances. The compare function returns 0 when
-- key->start is within the block. Otherwise
-- result of comparison of key->start and start of the
-- current block is returned as usual.

nullrule :: ConversionRule
nullrule = ConversionRule { category: gencatLU, unicodeCat: NUMCAT_CN, possible: 0, updist: 0, lowdist: 0, titledist: 0 }

blkCmp :: CharBlock -> CharBlock -> Ordering
blkCmp (CharBlock key) (CharBlock cur)
  | key.start >= cur.start && key.start < (cur.start + cur.length) = EQ
  | key.start > cur.start = GT
  | otherwise = LT

-- TODO: This might be better if it returned Nothing instead of the nullrule.
getRule :: Array CharBlock -> Int -> Int -> Maybe ConversionRule
getRule blocks unichar size =
  -- TODO: does using the nullrule make sense here?
  let
    key = CharBlock { start: unichar, length: 1, convRule: nullrule }
    maybeCharBlock = bsearch key blocks size blkCmp
  in
    case maybeCharBlock of
      Nothing -> Nothing
      Just (CharBlock charBlock) -> Just charBlock.convRule

-- The third argument is a  size.  It says to only look at this many elements
-- in the array.  There are times when we don't want to look at ALL the
-- elements, but only a subset.
bsearch :: forall a. a -> Array a -> Int -> (a -> a -> Ordering) -> Maybe a
bsearch a array size compare = go 0 size
  where
  go i k
    | i > k || i >= Array.length array = Nothing
    | otherwise =
        let
          j = floor (toNumber (i + k) / 2.0)
          b = unsafePartial (Array.unsafeIndex array j)
        in
          case compare a b of
            EQ -> Just b
            GT -> go (j + 1) k
            _ -> go i (j - 1)

-- Check whether a character (internal code) has certain attributes.
-- Attributes (category flags) may be ORed. The function ANDs
-- character category flags and the mask and returns the result.
-- If the character belongs to one of the categories requested,
-- the result will be nonzero.
checkAttr :: Array Int -> Int -> Boolean
checkAttr categories char =
  let
    numOfBlocks = if char < 256 then numLat1Blocks else numBlocks
    maybeConversionRule = getRule allchars char numOfBlocks
  in
    case maybeConversionRule of
      Nothing -> false
      Just (ConversionRule rule) -> isJust (Array.elemIndex rule.category categories)

checkAttrS :: Array Int -> Int -> Boolean
checkAttrS categories char =
  let
    maybeConversionRule = getRule spacechars char numSpaceBlocks
  in
    case maybeConversionRule of
      Nothing -> false
      Just (ConversionRule rule) -> isJust (Array.elemIndex rule.category categories)

uIswcntrl :: Int -> Boolean
uIswcntrl = checkAttr [ gencatCC ]

uIswprint :: Int -> Boolean
uIswprint = checkAttr
  [ gencatMC
  , gencatNO
  , gencatSK
  , gencatME
  , gencatND
  , gencatPO
  , gencatLT
  , gencatPC
  , gencatSM
  , gencatZS
  , gencatLU
  , gencatPD
  , gencatSO
  , gencatPE
  , gencatPF
  , gencatPS
  , gencatSC
  , gencatLL
  , gencatLM
  , gencatPI
  , gencatNL
  , gencatMN
  , gencatLO
  ]

uIswupper :: Int -> Boolean
uIswupper = checkAttr [ gencatLU, gencatLT ]

uIswlower :: Int -> Boolean
uIswlower = checkAttr [ gencatLL ]

uIswalpha :: Int -> Boolean
uIswalpha = checkAttr [ gencatLL, gencatLU, gencatLT, gencatLM, gencatLO ]

uIswdigit :: Int -> Boolean
uIswdigit = checkAttr [ gencatND ]

uIswalnum :: Int -> Boolean
uIswalnum = checkAttr
  [ gencatLT
  , gencatLU
  , gencatLL
  , gencatLM
  , gencatLO
  , gencatMC
  , gencatME
  , gencatMN
  , gencatNO
  , gencatND
  , gencatNL
  ]

uIswspace :: Int -> Boolean
uIswspace = checkAttrS [ gencatZS ]

--
-- Define predicate functions for some combinations of categories.
--

caseConv :: (ConversionRule -> Int) -> Int -> Int
caseConv f char =
  let
    maybeConversionRule = getRule convchars char numConvBlocks
  in
    case maybeConversionRule of
      Nothing -> char
      Just conversionRule -> char + f conversionRule

uTowupper :: Int -> Int
uTowupper = caseConv (\(ConversionRule rule) -> rule.updist)

uTowlower :: Int -> Int
uTowlower = caseConv (\(ConversionRule rule) -> rule.lowdist)

uTowtitle :: Int -> Int
uTowtitle = caseConv (\(ConversionRule rule) -> rule.titledist)

uGencat :: Int -> Maybe UnicodeCategory
uGencat char =
  let
    conversionRule = getRule allchars char numBlocks
  in
    map (\(ConversionRule rule) -> rule.unicodeCat) conversionRule

